import {showToast} from "../toastService";
import {jwtDecode} from "jwt-decode";
import {useToast} from "@chakra-ui/react";
import {useReducer, useState, useEffect, createContext} from "react";
const TokenNotification = ({token}) => {
  const toast = useToast();
  useEffect(() => {
    let timer = null;
    let toastId = null;
    if (token) {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp;
      const currentTime = Math.floor(Date.now() / 1000);

      const timeUntilExpiration = expirationTime - currentTime;

      const tenMinutesInSeconds = 10 * 60;
      if (timeUntilExpiration > tenMinutesInSeconds) {
        const timeUntilNotification =
          (timeUntilExpiration - tenMinutesInSeconds) * 1000;

        timer = setTimeout(() => {
          showToast({
            title: "Session about to expire.",
            status: "warning",
            description:
              "Your session is about to expire, please log in again.",
            duration: 10000,
            isClosable: true,
            position: "top",
          });
        }, timeUntilNotification);
      } else {
        toastId = showToast({
          title: "Session about to expire.",
          status: "warning",
          description: "Your session is about to expire, please log in again.",
          duration: 10000,
          isClosable: true,
          position: "top",
        });
      }
    }
    return () => {
      clearTimeout(timer);
      if (toastId) toast.close(toastId);
    };
  }, [token]);

  return null;
};

export default TokenNotification;
