import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import {Box, Flex, Text, Badge, Avatar, Divider} from "@chakra-ui/react";
import {getCurrentTime} from "../helpers/timeFormatters";

export default function IntakeConversation({conversation, setConversation}) {
  return (
    <Drawer
      onClose={() => setConversation(false)}
      isOpen={conversation}
      size={"lg"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Intake Questions & Answers</DrawerHeader>

        <DrawerBody>
          {conversation &&
            conversation.map(({preface, question, response, time}, index) => (
              <Box key={index} my="4">
                <Flex>
                  <Avatar
                    name="Question"
                    bg="blue.800"
                    color="white"
                    size="xs"
                    ml="2.5"
                  />
                  <Text
                    pl="5"
                    mb="2"
                    as="span"
                    fontWeight={"light"}
                    dangerouslySetInnerHTML={{
                      __html: preface
                        ? "<div style='font-size:12px;margin-bottom:5px'>" +
                          preface +
                          "</div>" +
                          question
                        : question,
                    }}
                  />
                </Flex>
                <Flex>
                  <Avatar
                    name="Response"
                    bg="blue.500"
                    color="white"
                    size="xs"
                    ml="2.5"
                  />
                  <Text pl="5" color="gray.600" fontWeight={"semibold"}>
                    {response}
                  </Text>
                </Flex>
                <Badge colorScheme="blue" rounded="md" float={"right"}>
                  {getCurrentTime(time)}
                </Badge>
                <Divider orientation="horizontal" my="7" />
              </Box>
            ))}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
