import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  FormControl,
  Switch,
  Icon,
  Text,
  VStack,
  Textarea,
  Button,
  Center,
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  HStack,
  Select,
} from "@chakra-ui/react";
import React, {useState, useRef} from "react";
import {MdInfoOutline} from "react-icons/md";
import {TbReportMoney} from "react-icons/tb";
import SelectGiftCard from "./SelectGiftCard";
import {markAsPaidConfirm} from "./actions";
export default function MarkAsPaidModal({
  markAsPaid,
  setmarkAsPaid,
  appointment,
  state,
  dispatch,
  socket,
  toast,
}) {
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [isPartial, setIsPartial] = useState("");
  const [partialAmount, setPartialAmount] = useState("");
  const [method, setMethod] = useState("cash");
  const [selectedGiftCard, setSelectedGiftCard] = useState(null);

  const initialRef = useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={markAsPaid}
      onClose={() => setmarkAsPaid(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb="0">
          <HStack gap={1}>
            <Icon
              className="show-icon"
              boxSize={6}
              color="blue.500"
              as={TbReportMoney}
            />
            <Text
              fontSize={"2xl"}
              color="gray.800"
              textAlign={"center"}
              fontWeight={600}
            >
              {"Payment"}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="0">
          <form
            id="my-form"
            onSubmit={async (e) => {
              try {
                e.preventDefault();
                setIsLoading(true);
                await markAsPaidConfirm({
                  appt: appointment,
                  schState: state,
                  dispatch,
                  socket,
                  paymentMethod: method,
                  toast,
                  description,
                  isPartial,
                  partialAmount,
                  selectedGiftCard,
                });

                setmarkAsPaid(false);
              } catch (e) {
                toast({
                  title: "Payment Error",
                  description: e.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              } finally {
                setIsLoading(false);
              }
            }}
          >
            <VStack gap={3}>
              <Textarea
                ref={initialRef}
                required
                resize={"vertical"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={"Type the description..."}
                size="sm"
              />
              <HStack w="full" align={"self-start"} gap={4}>
                <FormControl id="method" isRequired flex={1}>
                  <FormLabel fontSize={"sm"} ml={"1"} color={"gray.700"}>
                    Method
                  </FormLabel>
                  <Select
                    variant={"outline"}
                    size={"sm"}
                    rounded={"md"}
                    w={"full"}
                    value={method}
                    onChange={(e) => {
                      let mtd = e.target.value;
                      if (mtd !== "giftCard") setSelectedGiftCard(null);
                      setMethod(mtd);
                    }}
                  >
                    <option value={"cash"}>Cash</option>
                    <option value={"check"}>Check</option>
                    <option value={"giftCard"}>Gift Card</option>
                  </Select>
                </FormControl>
                <Box display="flex" gap="1" flexDirection={"column"} flex={1}>
                  <FormControl w="full" display="flex" alignItems="center">
                    <FormLabel
                      color={isPartial ? "gray.700" : "gray.400"}
                      htmlFor="isPartial"
                      mb="0"
                      fontSize={"sm"}
                      cursor={"pointer"}
                    >
                      {"Partial payment"}
                    </FormLabel>
                    <Switch
                      defaultChecked={isPartial}
                      onChange={(e) => {
                        setIsPartial((prev) => !prev);
                        if (isPartial) {
                          setPartialAmount("");
                        }
                      }}
                      id="isPartial"
                    />
                  </FormControl>
                  {
                    <FormControl
                      w="full"
                      mt="1"
                      display="flex"
                      justifyContent={"flex-end"}
                      alignItems="center"
                    >
                      {/*<FormLabel
                    color={isPartial ? "gray.800" : "gray.400"}
                    htmlFor="partialAmount"
                    mb="0"
                  >
                    {"Partial Amount"}
                  </FormLabel>*/}
                      <InputGroup size="xs" w="full" gap={0} pl="0">
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.500"
                          fontSize="12px"
                          children="$"
                          h="100%"
                        />
                        <Input
                          h="2rem"
                          id="partialAmount"
                          isDisabled={!isPartial}
                          type="text"
                          value={partialAmount ?? "0"}
                          size={"md"}
                          onChange={(e) => {
                            const validNumber = /^\d+(\.\d{0,2})?$/;
                            if (
                              validNumber.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setPartialAmount(e.target.value);
                            }
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                  }
                </Box>
              </HStack>

              {method === "giftCard" && (
                <SelectGiftCard
                  {...{state, setSelectedGiftCard, selectedGiftCard}}
                />
              )}
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            type="submit"
            form="my-form"
            colorScheme="blue"
            mr={3}
          >
            Confirm
          </Button>
          <Button onClick={() => setmarkAsPaid(false)} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
