import {crud} from "../../../../crudRequests";

export async function updateSummary(
  e,
  summary,
  dashState,
  setIsLoading,
  toast
) {
  setIsLoading(true);
  e.preventDefault();
  const {_id, ...summaryToUpdate} = summary;
  crud(dashState, [
    {
      db: dashState.db,
      collection: "summaries",
      parameters: [{sid: summaryToUpdate.sid}, {$set: summaryToUpdate}],
      method: "updateOne",
    },
  ])
    .then((res) => {
      setIsLoading(false);
      toast({
        title: "Summary Updated",
        description: "Summary information successfully updated",
        status: "success",
        isClosable: true,
      });
    })
    .catch((err) => {
      setIsLoading(false);
      toast({
        title: "Summary Not Updated",
        description: err?.message || "An error occurred.",
        status: "error",
        isClosable: true,
      });
      console.log(err);
    });
}
