import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import Symptoms from "./Symptoms";

export default function Differentials({intake}) {
  return (
    <>
      {intake?.differentials ? (
        <>
          <Text mt="6" fontSize={"12px"} color="gray.600">
            Predicted Differentials
          </Text>
          <Accordion defaultIndex={[0]} my="3" allowMultiple>
            {intake.differentials.map((differential) => {
              return (
                <AccordionItem>
                  <h2>
                    <AccordionButton py="4" fontWeight={"semibold"}>
                      <Box as="span" flex="1" textAlign="left">
                        {differential.disorder.name}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px="6">
                    {differential.reasoning}
                    <Flex mt="3" gap="2" flexWrap="wrap">
                      <Symptoms
                        symptoms={intake?.symptoms}
                        differential={differential}
                      />
                    </Flex>
                    <Text mt="4" fontSize={"12px"} color="gray.600">
                      *Click on a symptom to see where it was indicated
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </>
      ) : (
        <Text mt="6" fontSize={"18px"} fontWeight="semibold" color="gray.600">
          No differentials predicted
        </Text>
      )}
    </>
  );
}
