import axios from "axios";
import {url} from "../../../../crudRequests";
export function crudStorageUpload(
  state,
  selectedFileUpload,
  orgShareName,
  directoryName
) {
  const formData = new FormData();
  formData.append("file", selectedFileUpload);
  return axios({
    method: "POST",
    url: `${url}/crudStorageUpload`, //`https://webserver.brightlight.ai/crudStorageUpload`, //only works on prod `${url}/crudStorageUpload`, //
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + state.jwt,
    },
    data: formData,
    params: {
      s3BucketName: state.organization?.s3BucketName,
      id: state.doctor?.did || state.admin?.id,
      orgShareName: orgShareName,
      directoryName: directoryName,
    },
  });
}
