import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Badge,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import {AiOutlineDelete} from "react-icons/ai";
export default function SelectedProducts({
  productSalesInformation,
  setProductSalesInformation,
}) {
  let products = productSalesInformation?.products || [];
  let productTax = productSalesInformation?.tax || 0;
  let productAmount = productSalesInformation?.amount || 0;
  let tableEntries = {
    "Product Name": (p) => p.name,
    Quantity: (p) => p.quantity,
    Price: (p) => p.price,
  };

  return (
    <Box mt="4" p="4">
      {products.length > 0 && (
        <>
          <Text
            ml="1"
            mb="2"
            fontSize="13px"
            fontWeight={500}
            color={"#7F7B91"}
          >
            Product Selection
          </Text>
          <TableContainer
            overflowY="auto"
            variant="simple"
            maxHeight={"250px"}
            mb="5"
            border={"1px solid"}
            borderColor={"white"}
            pt="0"
            rounded={"lg"}
          >
            <Table size="md">
              <Thead position="sticky" zIndex={10} top="0" bg="white">
                <Tr>
                  {Object.keys(tableEntries).map((field, i) => {
                    return (
                      <Th key={i} fontSize={"11px"}>
                        {field}
                      </Th>
                    );
                  })}
                  <Th fontSize={"11px"}>{"Action"}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {products?.map((product, i) => {
                  return (
                    <>
                      <Tr key={i}>
                        {Object.values(tableEntries).map((v, i) => (
                          <Td key={i} fontSize={"15px"}>
                            {v(product, i) ?? "N/A"}
                          </Td>
                        ))}
                        <Td fontSize={"15px"}>
                          <Badge
                            colorScheme="red"
                            rounded="md"
                            p="1"
                            cursor={"pointer"}
                            onClick={() => {
                              let p = products.filter(
                                (pd) => pd.id !== product.id
                              );
                              let amount = p.reduce((acc, el) => {
                                return (
                                  acc +
                                  parseFloat(el.price) * parseFloat(el.quantity)
                                );
                              }, 0);
                              amount = (
                                amount *
                                (1 + productTax / 100)
                              ).toFixed(2);
                              setProductSalesInformation((prev) => ({
                                ...prev,
                                products: p,
                                amount,
                              }));
                            }}
                          >
                            <Icon
                              as={AiOutlineDelete}
                              fontSize="15px"
                              mt="-1"
                              mr="1"
                            />
                            Remove
                          </Badge>
                        </Td>
                      </Tr>
                    </>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex gap={2} direction={"column"} align={"flex-end"} mb="2">
            <Flex align={"center"} gap={1} fontSize="sm">
              <Text fontWeight={600}>{`Products cost:`}</Text>
              <Badge
                colorScheme="blue"
                rounded={"md"}
              >{`$${productAmount}`}</Badge>
            </Flex>
            <Flex align={"center"} gap={1} fontSize="sm">
              <Text fontWeight={600}>{`Product Tax Rate:`}</Text>
              <Badge
                colorScheme="blue"
                rounded={"md"}
              >{`${productTax}%`}</Badge>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
}
