import React from "react";
import {View} from "@react-pdf/renderer";

import Table from "./Table/index";
import Section1 from "./Section1";
import Section2 from "./Section2";

export default function ({
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  state,
  type,
  returnDoc,
  receiptAmount,
  nonSessionService,
  location,
}) {
  return (
    <View style={{paddingHorizontal: 0}}>
      <Section1 type={type} transaction={transaction} />
      <Section2 type={type} tpp={tpp} patient={patient} />

      <Table
        transaction={transaction}
        type={type}
        receiptAmount={receiptAmount}
        nonSessionService={nonSessionService}
        location={location}
      />
    </View>
  );
}
