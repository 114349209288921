import React, {useState, useEffect, useRef} from "react";
import {View, Text, Image} from "@react-pdf/renderer";
import {lightFormat} from "date-fns";

import {nanoid} from "nanoid";

import Table from "./Table";
import Signature from "./Signature";

import Section1 from "./Section1";
import Section2 from "./Section2";

import Summary from "./Summary";
export default function ({transaction, patient, state, type, receiptAmount}) {
  return (
    <View style={{paddingHorizontal: 0}}>
      <Section1 type={type} transaction={transaction} />
      <Section2 type={type} patient={patient} transaction={transaction} />

      <Table
        transaction={transaction}
        type={type}
        receiptAmount={receiptAmount}
      />
      <Summary
        transaction={transaction}
        type={type}
        receiptAmount={receiptAmount}
      />
      <Signature state={state} />
    </View>
  );
}
