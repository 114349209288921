import React, {useEffect, useRef} from "react";

const AudioVisualizer = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const initializeAudioContext = async () => {
      try {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        const microphone = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        microphone.connect(analyser);

        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext("2d");

        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
          analyser.getByteTimeDomainData(dataArray);

          canvasContext.clearRect(0, 0, canvas.width, canvas.height);

          canvasContext.lineWidth = 2;
          canvasContext.strokeStyle = "rgb(255, 255, 255)";
          canvasContext.beginPath();

          const sliceWidth = (canvas.width * 1.0) / bufferLength;
          let x = 0;

          for (let i = 0; i < bufferLength; i++) {
            const v = dataArray[i] / 128.0;
            const y = (v * canvas.height) / 2;

            if (i === 0) {
              canvasContext.moveTo(x, y);
            } else {
              canvasContext.lineTo(x, y);
            }

            x += sliceWidth;
          }

          canvasContext.lineTo(canvas.width, canvas.height / 2);
          canvasContext.stroke();

          requestAnimationFrame(draw);
        };

        draw();
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    };

    initializeAudioContext();
  }, []);

  return <canvas ref={canvasRef} width={75} height={50} />;
};

export default AudioVisualizer;
