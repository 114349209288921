import {nanoid} from "nanoid";
import {processSummary} from "./processSummary";
import {crud} from "../../../../crudRequests";

export async function addSummary(
  transcript,
  selectedAppointment,
  elapsedTime,
  patient,
  dashState,
  setSummaries,
  toast,
  discardTimer
) {
  const newSummary = {
    sid: nanoid(10),
    pid: patient.pid,
    administeredBy: dashState.doctor?.name || dashState.admin?.name,
    aid: selectedAppointment,
    elapsedTime: elapsedTime,
    nonDiarizedTranscript: transcript,
    briefSummary: "",
    narrativeSummary: "",
    bulletptSummary: "",
    soapSummary: "",
    initiatedDatetime: new Date().toISOString(),
  };

  await crud(dashState, [
    {
      db: dashState.db,
      collection: "summaries",
      parameters: [newSummary],
      method: "insertOne",
    },
  ]);

  setSummaries((prevSummaries) => [...prevSummaries, newSummary]);

  toast({
    title: "Summarization Initiated",
    description:
      "Please wait a few minutes for your appointment transcript to be summarized. This process is done in the background.",
    status: "info",
    isClosable: true,
  });

  discardTimer();

  try {
    const updatedSummary = await processSummary(dashState, newSummary.sid);
    setSummaries((prevSummaries) => {
      const updatedSummaries = prevSummaries.map((summary) => {
        if (summary.sid === newSummary.sid) {
          return {...summary, ...updatedSummary.data};
        }
        return summary;
      });
      return updatedSummaries;
    });

    toast({
      title: "Summarization Processed",
      description:
        "Your appointment transcript has been successfully summarized.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    toast({
      title: "Summarization Failed",
      description:
        err?.message || "An error occurred during the summarization process.",
      status: "error",
      isClosable: true,
    });
  }
}
