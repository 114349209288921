import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
  Button,
  NumberInput,
  NumberInputField,
  FormControl,
  Grid,
  useToast,
} from "@chakra-ui/react";
import {nanoid} from "nanoid";
import {v4 as uuidv4} from "uuid";
import {useState, useMemo} from "react";
import createCard from "./helpers/createCard";
import sendNotification from "./helpers/sendNotification";

import {MdNumbers, MdAttachMoney} from "react-icons/md";

export default function CreateGiftCard({
  setCreateModal,
  createModal,
  dashState,
  dispatch,
  setGiftCards,
}) {
  const [waiting, setWaiting] = useState(false);
  const toast = useToast();
  const defaultData = useMemo(
    () => ({
      gcId: uuidv4(),
      amount: "",
      fName: "",
      lName: "",
      email: "",
      phone: "",
      creationDate: Date.now(),
      secretCode: nanoid(10),
      balance: "",
      lid: dashState.selectedLocation,
    }),
    []
  );

  const [fields, setFields] = useState(defaultData);

  return (
    <Drawer
      isOpen={createModal}
      placement="right"
      size={"sm"}
      onClose={() => {
        setCreateModal(false);
      }}
      trapFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottomWidth="1px"
          color={"#1A202C"}
          fontWeight={600}
        >
          {"Create New Gift Card"}
        </DrawerHeader>

        <DrawerBody pt="7">
          <Box h={"full"} w="full" overflow={"auto"}>
            <form
              id="create-gift-card"
              onSubmit={async (e) => {
                e.preventDefault();

                if (!waiting) {
                  setWaiting(true);
                  const newCard = {
                    ...fields,
                    amount: parseFloat(fields.amount),
                    balance: parseFloat(fields.amount),
                    creationDate: Date.now(),
                  };

                  let action = () => {
                    setGiftCards((prev) => [newCard, ...prev]);
                    setCreateModal(false);
                    setWaiting(false);
                  };
                  await createCard({
                    dashState,
                    newCard,
                    toast,
                    action,
                  });

                  await sendNotification({
                    dashState,
                    card: newCard,
                    toast,
                  });
                }
              }}
              m="2"
              display="none"
            >
              <Grid templateColumns={"repeat(2, 1fr)"} gap={6}>
                {/* Row 1 */}
                <FormControl id="fName" isRequired>
                  <FormLabel color={"gray.500"}>First Name</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    value={fields.fName}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          fName: e.target.value,
                        };
                      });
                    }}
                  />
                </FormControl>
                <FormControl id="lName" isRequired>
                  <FormLabel color={"gray.500"}>Last Name</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    value={fields.lName}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          lName: e.target.value,
                        };
                      });
                    }}
                  />
                </FormControl>
                <FormControl id="email" isRequired>
                  <FormLabel color={"gray.500"}>Email</FormLabel>
                  <Input
                    variant="flushed"
                    type="email"
                    value={fields.email}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          email: e.target.value,
                        };
                      });
                    }}
                  />
                </FormControl>
                <FormControl id="phone">
                  <FormLabel color={"gray.500"}>Phone</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    value={fields.phone}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          phone: e.target.value,
                        };
                      });
                    }}
                  />
                </FormControl>

                <FormControl id="amount" isRequired>
                  <FormLabel color={"gray.500"}>Amount</FormLabel>

                  <InputGroup>
                    <InputLeftAddon>
                      {" "}
                      <MdAttachMoney />
                    </InputLeftAddon>
                    <Input
                      type="number"
                      placeholder="0"
                      value={fields.amount}
                      onChange={(e) => {
                        const validNumber = /^\d+(\.\d{0,2})?$/;
                        if (
                          validNumber.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setFields((prev) => ({
                            ...prev,
                            amount: e.target.value,
                          }));
                        }
                      }}
                    />
                  </InputGroup>
                </FormControl>
              </Grid>
            </form>
          </Box>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            isDisabled={waiting}
            variant="outline"
            mr={3}
            px="10"
            onClick={() => {
              setCreateModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            isDisabled={waiting}
            type="submit"
            form="create-gift-card"
            mr={3}
            px="10"
            colorScheme="blue"
          >
            Generate Gift Card
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
