import {
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Text,
  Box,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {updatePatient} from "./helpers/updatePatient";
import Preferences from "../Settings/Preferences";

export default function Relations() {
  const [patient, setPatient] = useContext(PatientContext);
  const dispatch = useContext(DispatchContext);
  const dashState = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  return (
    <form
      onSubmit={(e) =>
        updatePatient(e, patient, dashState, setIsLoading, toast, dispatch)
      }
    >
      <VStack spacing={4}>
        <Box textAlign="center">
          <FormControl id="miscellaneousNotes">
            <FormLabel>Miscellaneous Notes</FormLabel>
            <Textarea
              variant="flushed"
              placeholder="Extra notes relating to the patient"
              h="10rem"
              w="30rem"
              value={patient?.specialNotes}
              onChange={(e) => {
                setPatient({...patient, specialNotes: e.target.value});
              }}
            ></Textarea>
          </FormControl>
        </Box>
        <Button
          isLoading={isLoading && true}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Save
        </Button>
      </VStack>
    </form>
  );
}
