import {Box, Divider, Flex, Input, Text} from "@chakra-ui/react";

export const PatientInfo = ({diagnosticCodesNotes, patient, clinician, claimDate, setClaimDate, refClinicianNumber}) => {

  return (
    <>
      <Flex w="100%">
        <Flex
          direction="column"
          w="40%"
          my="10px"
          sx={{"& > :not(:first-of-type)": {textIndent: "0.5rem"}}}
          fontSize="sm"
        >
          <Text fontWeight="bold" fontSize="md">
            Patient:
          </Text>
          <Text>
            {patient.lName}, {patient.fName}
          </Text>
          <Text>{patient.gender}</Text>
          <Text>DOB: {patient.dob}</Text>
          {refClinicianNumber &&
            <Text>Referral ID: {refClinicianNumber}</Text>
          }
        </Flex>
        <Flex
          direction="column"
          w="60%"
          sx={{"& > :not(:first-of-type)": {textIndent: "0.5rem"}}}
          fontSize="sm"
        >
          <Divider borderWidth="2px" borderColor="black" mb="0.5rem" />
          <Text fontWeight="bold" fontSize="md">
                Clinician:
              </Text>
          <Flex>
            <Box mx="0.75rem">
              <Text>
                {clinician.lastName}, {clinician.firstName}
              </Text>
              <Text>Group Number: {clinician.ontarioGroupNumber}</Text>
              <Text>
                Practitioner Billing Number: {clinician.practitionerNumber}
              </Text>
            </Box>
            <Box ml="0.5rem">
              <Text>Speciality: {clinician.ontarioSpecialty}</Text>
              <Text>Master Number: {clinician.ontarioMasterNumber}</Text>
              <Text>
                Service Location Indicator:{" "}
                {clinician.ontarioServiceLocationIndicator}
              </Text>
            </Box>
          </Flex>
          <Divider borderWidth="2px" borderColor="black" mt="0.5rem" />
        </Flex>
      </Flex>
      {diagnosticCodesNotes &&
        <Box my="0.5rem">
          <Text fontWeight="bold">Diagnostic Code Notes:</Text>
          <Text fontSize="sm">{diagnosticCodesNotes}</Text>
        </Box>
      }
      <Box width="20%" marginLeft="auto" my="0.75rem">
        <Input type="date" size="sm" value={claimDate} onChange={(e) => setClaimDate(e.target.value)}/>
      </Box>
    </>
  );
};
