import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
} from "@chakra-ui/react";
import AppointmentListItem from "./AppointmentListItem";

const fields = [
  "Clinician",
  "Date",
  "Service",
  "Status",
  "Notes",
  "Options",
  "INVOICE / RECEIPT",
  "Summary",
];

export default function AppointmentsList({
  appointments,
  setApptNotes,
  summaries,
  dispatch,
  state,
  setPreview,
  handleLinkToChart,
  setPreviewInvRec,
  setSelectedTr,
  doctorsMap,
  billingMap,
  selectedSummary,
  setSelectedSummary,
}) {
  return (
    <Box overflowY="auto" w="full" flex={1}>
      <Table size="md">
        <Thead position="sticky" zIndex={5} top="0" bg="gray.100">
          <Tr>
            {fields.map((field, i) => {
              return (
                <Th key={i} whiteSpace={"nowrap"} fontSize={"11px"}>
                  {field}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {appointments.map((appointment, i) => {
            if (!doctorsMap[appointment.did]) {
              return null;
            }
            return (
              <AppointmentListItem
                key={i}
                apptDoctor={doctorsMap[appointment.did]}
                setApptNotes={setApptNotes}
                dispatch={dispatch}
                state={state}
                appointment={appointment}
                summaries={summaries}
                transaction={billingMap[appointment.aid]}
                setPreview={setPreview}
                handleLinkToChart={handleLinkToChart}
                setPreviewInvRec={setPreviewInvRec}
                setSelectedTr={setSelectedTr}
                {...{selectedSummary, setSelectedSummary}}
              />
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}
