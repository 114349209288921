import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import {VscFileSymlinkFile} from "react-icons/vsc";
import React, {useState, useEffect, useRef} from "react";
import {lightFormat} from "date-fns";
import {LuGanttChartSquare} from "react-icons/lu";
import {crudStorage} from "../../appointments/payment/helpers/crudStorage";
import {previewFile} from "../Files/helpers/previewFile";
import {fetchReports} from "./helpers/fetchReports";
export default function RelatedCharts({
  dashState,
  patient,
  appointment,
  toast,
  handleLinkToChart,
  setPreview,
}) {
  return (
    <Popover isLazy placement="top-start">
      <PopoverTrigger onClick={(e) => e.stopPropagation()}>
        <Box onClick={(e) => e.stopPropagation()} display={"inline"}>
          <Tooltip label={"View Charts"}>
            <Button
              variant="ghost"
              p="0"
              colorScheme={"whatsapp"}
              cursor={"pointer"}
            >
              <Icon as={LuGanttChartSquare} fontSize="24" />
            </Button>
          </Tooltip>
        </Box>
      </PopoverTrigger>

      <PopoverContent zIndex={99999}>
        <PopoverHeader fontWeight="semibold">
          <Flex justify={"space-between"} align={"center"}>
            <Text>Chart Reports</Text>{" "}
            <Button
              size={"sm"}
              variant="ghost"
              colorScheme={"blue"}
              cursor={"pointer"}
              leftIcon={<VscFileSymlinkFile />}
              onClick={async (e) => {
                e.stopPropagation();
                handleLinkToChart(appointment);
              }}
            >
              <Text>Create Note</Text>
            </Button>{" "}
          </Flex>
        </PopoverHeader>
        <PopoverArrow />

        <PopoverBody>
          <ReportsTable
            {...{dashState, patient, setPreview, appointment, toast}}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function ReportsTable({dashState, patient, appointment, toast, setPreview}) {
  const [reports, setReports] = useState(null);

  useEffect(() => {
    fetchReports(setReports, dashState, patient, appointment, toast);
  }, []);

  return (
    <>
      {reports === null ? (
        <Loader />
      ) : (
        <TableContainer overflowY="auto" minH={"60px"} maxH={"200px"}>
          <Table size="md">
            <Tbody>
              {reports?.map((o, i) => {
                return (
                  <Tr
                    p="0"
                    cursor={"pointer"}
                    _hover={{
                      backgroundColor: "gray.200",
                    }}
                    key={i}
                    onClick={() => {}}
                  >
                    <Td
                      p="2"
                      key={i}
                      fontSize={"15px"}
                      onClick={async (e) => {
                        e.stopPropagation();
                        let path = patient["pid"] + `/Charts/`;
                        let reports = await crudStorage(
                          dashState,
                          "view",
                          "",
                          "brightlighthealth",
                          path
                        );
                        let file = reports.data?.find(
                          (f) =>
                            f.name.slice(-1) !== "/" &&
                            f.name.includes(
                              `${o.title}_(${o.id}_${
                                appointment.aid || ""
                              }).pdf`
                            )
                        );
                        if (file) {
                          previewFile(
                            dashState,
                            path,
                            file.name,
                            setPreview,
                            toast
                          );
                        } else {
                          toast({
                            title: "This file cannot be previewed.",
                            description: "Chart report could not be found!",
                            status: "warning",
                            isClosable: true,
                          });
                        }
                      }}
                    >
                      {`${o.title}`}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            {reports?.length === 0 && (
              <TableCaption mb="4">No charts reports to display.</TableCaption>
            )}
          </Table>
        </TableContainer>
      )}
    </>
  );
}

function Loader() {
  return (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  );
}
