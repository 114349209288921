import React, {useState, useEffect, useRef} from "react";
import {crud} from "../crudRequests";
import {MdModeEditOutline} from "react-icons/md";
import {AiFillSave} from "react-icons/ai";
import TriggerInfoSearch from "./TriggerInfoSearch";

import ModalAddLocation from "./AddLocation";
import ModalEditPeople from "./EditPeople";
import {useToast} from "@chakra-ui/react";
export default function ({schState, dispatch}) {
  const defaultLocationData = {
    name: "",
    description: "",
    logo: "",
    imgList: "",
    address: "",
    phone: "",
    fax: "",
    phaxioNumber: "",
    productsTaxRate: 0,
    stpPublicKey: "",
    stpSecretKey: "",
    invRecNotes: "",
    timeZone: "",
  };

  const searchRef = useRef(null);
  const searchText = useRef("");
  const allLocations = useRef([]);

  const oid = schState.admin?.oid;
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [editMode, _] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const [locationsMap, setLocationsMap] = useState(new Map());
  const [showPeople, setShowPeople] = useState(false);
  const [currentLid, setCurrentLid] = useState(null);
  const [locationData, setLocationData] = useState(defaultLocationData);

  const populatePeopleByLid = async (lid) => {
    let requestObjs = [
      {
        db: schState.db,
        collection: "admins",
        parameters: [{location: lid}],
        method: "find",
      },
      {
        db: schState.db,
        collection: "doctors",
        parameters: [{lid, oid}],
        method: "find",
      },
    ];

    const res = await crud(schState, requestObjs);
    const [admins, drs] = res.data;

    const body = {
      admin: admins,
      doctor: drs,
    };

    setLocationsMap((prev) => {
      if (prev) {
        return new Map(prev.set(lid, body));
      } else {
        return new Map().set(lid, body);
      }
    });
  };

  const cycleAndPopulateByLid = (lids) => {
    for (let i = 0; i < lids.length; i++) {
      const lid = lids[i];
      populatePeopleByLid(lid);
    }
  };

  const getPeopleFromLocation = (lid) => {
    const people = locationsMap.get(lid);
    if (!people) return [];

    const {admin, doctor} = people;
    return [...admin, ...doctor];
  };

  const removeLidFromCurrentAdmin = async (state, newLid) => {
    if (schState.userType !== "admin") return;
    const prevLocations = schState.admin.location;
    const newLocations = prevLocations.filter((lid) => lid !== newLid);
    const updateBody = {
      super: true,
      id: schState.admin.id,
      location: newLocations,
    };

    if (newLocations.length < 1) {
      throw new Error("Admin must have at least one location");
    }

    const res = await crud(state, [
      {
        db: schState.db,
        collection: "admins",
        parameters: [{id: updateBody.id}, {$set: updateBody}],
        method: "updateOne",
      },
    ]);
    if (res.status === 200) {
      console.log("removed Lid from admin", res);
      dispatch({type: "UPDATE_USER", payload: {location: newLocations}});
    } else {
      alert("Error updating admin");
    }
  };

  const promptDeleteService = (lid) => {
    if (window.confirm("Are you sure you want to delete this location?")) {
      deleteServiceHandler(lid);
    }
  };

  const deleteServiceHandler = async (lid) => {
    const res = await crud(schState, [
      {
        db: schState.db,
        collection: "locations",
        parameters: [{lid}],
        method: "deleteOne",
      },
    ]);

    if (res?.status === 200) {
      console.log("res deleted:", res);
      await removeLidFromCurrentAdmin(schState, lid);
      allLocations.current = allLocations.current.filter(
        (location) => location.lid !== lid
      );

      setLocations(allLocations.current);
      dispatch({type: "UPDATE_LOCATIONS", payload: allLocations.current});
    } else {
      alert("Error deleting service");
    }
  };

  const updateLocationHandler = async (lid) => {
    // find the corresponding service from allClinicians
    const currLocation = allLocations.current.find(
      (location) => location.lid === lid
    );

    if (!currLocation) {
      toast({
        title: "Location not found!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    setModalMode("edit");
    setShowAdd(true);
    setLocationData({
      lid: currLocation.lid,
      oid,
      name: currLocation.name,
      description: currLocation.description,
      address: currLocation.address,
      phone: currLocation.phone,
      fax: currLocation.fax,
      phaxioNumber: currLocation.phaxioNumber,
      logo: currLocation.logo || "",
      productsTaxRate: currLocation.productsTaxRate || 0,
      stpSecretKey: "",
      stpPublicKey: currLocation.stpPublicKey || "",
      invRecNotes: currLocation.invRecNotes || "",
      timeZone: currLocation.timeZone || "",
      additionalAddress: currLocation.additionalAddress || [],
    });
  };

  function search(s) {
    const update = (service) => {
      if (s.length === 0) {
        return service;
      } else if (service["name"].toLowerCase().includes(s)) {
        return service;
      } else if (service["description"]?.toLowerCase().includes(s)) {
        return service;
      } else if (service["picture"]?.toLowerCase().includes(s)) {
        return service;
      } else {
        return null;
      }
    };

    let filteredServices = allLocations.current.filter(update);

    setLocations(filteredServices);
  }

  // map users and locations and save into map to display in column (assigned to)
  useEffect(() => {
    cycleAndPopulateByLid(schState.admin.location);
  }, []);

  // populate locations based on current user's lids
  useEffect(() => {
    const abortController = new AbortController();

    const lids = schState.admin.location;

    crud(schState, [
      {
        db: schState.db,
        collection: "locations",
        parameters: [{lid: {$in: lids}}],
        method: "find",
      },
    ])
      .then((res) => {
        allLocations.current = res.data[0];
        setLocations(res.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.code !== "ERR_CANCELED");
      });
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      {showAdd && (
        <ModalAddLocation
          locationsMap={locationsMap}
          modalMode={modalMode}
          show={showAdd}
          setShow={setShowAdd}
          locationData={locationData}
          setLocationData={setLocationData}
          state={schState}
          dispatch={dispatch}
          allLocations={allLocations}
          setLocations={setLocations}
          populatePeopleByLid={populatePeopleByLid}
        />
      )}
      <ModalEditPeople
        populatePeopleByLid={populatePeopleByLid}
        state={schState}
        show={showPeople}
        setShow={setShowPeople}
        locationsMap={locationsMap}
        setLocationsMap={setLocationsMap}
        locationData={locationData}
        setLocationData={setLocationData}
        currentLid={currentLid}
      />

      <div className=" p-6 pb-4 pt-5 w-full h-full flex flex-col patients-table-container ">
        <div className="bg-off relative mx-4  bg-opacity-25 text-opacity-100 flex items-center rounded-full">
          <span
            className="text-xl text-off absolute left-3"
            ref={searchRef}
            onClick={() => {
              searchRef.current.className = "hidden";
              searchText.current.focus();
            }}
          >
            <span className="font-semibold">Search locations</span>{" "}
            <span className="font-light">
              by any field<span className="font-semibold">...</span>
            </span>
          </span>
          <span className="text-xl text-off absolute right-2">
            <img src="/images/Doctor/search_icon.png" alt="" className="w-6" />
          </span>
          <input
            type="text"
            className="w-full bg-off p-3 px-4 bg-opacity-0 text-off rounded-full focus:outline-none  ring-[#8F8CFF] focus:ring-2 focus:ring-[#8F8CFF]"
            ref={searchText}
            onChange={() => search(searchText.current.value.toLowerCase())}
            onFocus={() => {
              searchRef.current.className = "hidden";
            }}
            onBlur={() => {
              if (!searchText.current.value) {
                setTimeout(() => {
                  if (searchRef.current) {
                    searchRef.current.className =
                      "text-xl text-off absolute left-3";
                  }
                }, 100);
              }
            }}
          />
        </div>
        <TriggerInfoSearch text="Service records are searchable on this page by their name, cost or id." />
        <div className="flex mt-4">
          <button
            className={
              "cursor-pointer text-sm bg-background border-[3px] border-dark text-dark px-4 py-1.5 rounded-md m-4 font-semibold"
            }
            onClick={() => {
              setLocations(allLocations.current);
              searchText.current.value = "";
              setTimeout(() => {
                searchRef.current.className =
                  "text-xl absolute mt-[0.4rem] ml-2 text-off";
              }, 100);
            }}
          >
            <span> Clear </span>
          </button>
          <button
            className={
              "cursor-pointer text-sm bg-dark border-[3px] border-dark text-background px-4 py-1.5 rounded-md m-4 font-semibold"
            }
            onClick={() => {
              setLocationData(defaultLocationData);
              setModalMode("add");
              setShowAdd(true);
            }}
          >
            <span> Add Location </span>
          </button>
        </div>
        <div
          className="rounded-md m-4 mt-2 py-2 px-5 text-sm text-background font-medium text-center"
          style={{backgroundColor: "#8F8CFF"}}
        >
          <ol className="grid grid-cols-8 gap-3 text-center align-middle sm:text-[13px] lg:text-base">
            <li>Logo</li>
            <li>Name</li>
            <li>Description</li>

            <li>Main Address</li>
            <li>Phone</li>
            <li>Fax</li>
            <li>Products Tax Rate</li>

            <li>Actions</li>
          </ol>
        </div>
        <div className="overflow-hidden flex justify-center">
          <div className="overflow-y-scroll  w-full">
            {locations.length !== 0 ? (
              locations.map((location, idx) => {
                return (
                  <div
                    key={idx}
                    className="rounded-md m-4 mt-0 py-3 px-5 text-sm text-dark text-opacity-75 font-medium text-center h-fit"
                    style={{backgroundColor: "#DFDEFF"}}
                  >
                    <ol className="grid grid-cols-8 gap-4 place-items-center  text-xs md:text-sm align-middle text-center">
                      <li>
                        <img
                          src={location.logo}
                          alt=""
                          className="w-20 h-auto"
                        />
                      </li>
                      <li>
                        <span>{location.name}</span>
                      </li>
                      <li>
                        <span>{location.description}</span>
                      </li>

                      <li>
                        <span>{location.address}</span>
                      </li>
                      <li>
                        <span>{location.phone}</span>
                      </li>
                      <li>
                        <span>{location.phaxioNumber}</span>
                      </li>
                      <li>
                        <span>{location.productsTaxRate || 0}</span>
                      </li>

                      <li>
                        <button
                          className="text-xl"
                          onClick={() => {
                            updateLocationHandler(location.lid);
                          }}
                        >
                          {editMode === location.lid && <AiFillSave />}
                          {editMode !== location.lid && <MdModeEditOutline />}
                        </button>
                        <button
                          className="font-bold text-xl ml-2"
                          onClick={() => promptDeleteService(location.lid)}
                        >
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              strokeWidth={10}
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </li>
                    </ol>
                  </div>
                );
              })
            ) : (
              <span className="ml-5 text-sm text-off">
                No location to display
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
