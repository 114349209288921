import {
  Box,
  Image,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import Controls from "./Controls";
export default function PreviewFile({preview, setPreview, appointment, state}) {
  const toast = useToast();

  const getFileType = (fileName) => {
    const ext = fileName
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
    const fileTypes = {
      image: ["jpg", "png", "jpeg"],
      pdf: ["pdf"],
      //video: ["mp4", "mov", "avi"],
      //audio: ["mp3", "wav", "ogg"],
      text: ["txt", "csv", "json", "js", "ts", "html", "css", "py"],
    };
    return (
      Object.keys(fileTypes).find((key) => fileTypes[key].includes(ext)) ||
      "unsupported"
    );
  };

  const fileType = preview && getFileType(preview?.fileName);

  if (fileType === "unsupported") {
    toast({
      title: "Unsupported file type",
      description:
        "This file is in an incompatible format, and therefore cannot be previewed. Please download it to view.",
      status: "warning",
      isClosable: true,
    });
    setPreview(false);
    return null;
  }

  const renderPreview = () => {
    switch (fileType) {
      case "image": {
        return (
          <Image src={`data:image/jpeg;base64,${preview.data}`} m="auto" />
        );
      }

      case "pdf": {
        return (
          <Box
            as="iframe"
            src={`data:application/pdf;base64,${preview.data}`}
            width="full"
            height="full"
          />
        );
      }

      case "video": {
        return (
          <Box
            as="video"
            controls
            src={`data:video/*;base64,${preview.data}`}
            width="full"
            height="full"
            m="auto"
          />
        );
      }

      case "audio": {
        return (
          <Box
            as="audio"
            controls
            src={`data:audio/*;base64,${preview.data}`}
            width="full"
            height="full"
          />
        );
      }

      case "text":
        return (
          <Box
            as="textarea"
            readOnly
            value={atob(preview.data)}
            width="full"
            height="full"
            p={4}
          />
        );
      default:
        return null;
    }
  };

  return (
    preview && (
      <Modal
        isOpen={preview}
        size={"4xl"}
        onClose={() => setPreview(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p="4">
          <ModalCloseButton />
          <ModalBody p="4">
            {" "}
            <Box h="85vh" position={"relative"} zIndex={50} p={4}>
              <Box h="full" w="full" overflow="auto" zIndex={40}>
                {renderPreview()}
              </Box>
              {appointment.type !== "product" && preview.relatedData && (
                <Controls {...{state, preview, toast, appointment}} />
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
}
