import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  VStack,
  Badge,
  Icon,
  StackDivider,
} from "@chakra-ui/react";
import {BsPlusCircleDotted} from "react-icons/bs";
import {AiOutlineDelete} from "react-icons/ai";
import React, {useState, useEffect, useRef} from "react";
import {useToast} from "@chakra-ui/react";

export default function AdditionalAddressModal({
  setLocationData,
  locationData,
  show,
  setShow,
}) {
  const toast = useToast();
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  const [list, setList] = useState(
    Array.isArray(locationData?.additionalAddress)
      ? locationData?.additionalAddress.length > 0
        ? locationData?.additionalAddress
        : [""]
      : [""]
  );

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setShow(false);
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{locationData.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Flex direction="column" align="start">
              <VStack
                gap={2}
                w="full"
                divider={<StackDivider borderColor="gray.200" />}
              >
                <VStack
                  maxH="200px"
                  minH="100px"
                  align="start"
                  overflowY="scroll"
                  p={1}
                  w="full"
                >
                  <Text fontSize="11px" color="gray.600">
                    Additional Address
                  </Text>
                  {list.map((v, i) => (
                    <Flex w="full" key={i} align="center" mb={2} gap="2">
                      <Box flex={1}>
                        <Input
                          size="md"
                          w="full"
                          value={v}
                          onChange={(e) => {
                            setList((prev) => {
                              let newValue = [...prev];
                              newValue[i] = e.target.value;
                              return newValue;
                            });
                          }}
                        />
                      </Box>

                      <Badge
                        cursor={"pointer"}
                        onClick={() => {
                          setList((prev) => prev.filter((_, ind) => i !== ind));
                        }}
                        fontSize="10px"
                        rounded="md"
                        p="1"
                        px="2"
                        colorScheme="red"
                      >
                        <Icon as={AiOutlineDelete} fontSize="12px" mt="-0.5" />{" "}
                        Remove
                      </Badge>
                    </Flex>
                  ))}
                </VStack>

                <Icon
                  cursor="pointer"
                  onClick={() => {
                    if (list[0]) setList((prev) => ["", ...prev]);
                  }}
                  as={BsPlusCircleDotted}
                  fontSize={"26px"}
                  mb={"4"}
                ></Icon>
              </VStack>
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            cursor={"pointer"}
            onClick={() => {
              setLocationData({
                ...locationData,
                additionalAddress: list.filter((a) => a.trim()),
              });
              onClose();
              setShow(false);
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              onClose();
              setShow(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
