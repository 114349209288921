import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {Icon, Box, Flex, Button, useToast, Text} from "@chakra-ui/react";
import PreviewForm from "./PreviewForm";
import AssignForm from "./AssignForm";
import {RiDatabase2Line} from "react-icons/ri";
import FormMetadataModal from "./FormMetadataModal";
import AssignedAndCompletedForms from "./AssignedAndCompletedForms";
import {fetchForms} from "./helpers/fetchForms";
import {MdDelete, MdEmail} from "react-icons/md";
import {deleteForm} from "./helpers/deleteForm";
import {AlertDialogComponent} from "../../AlertDialogComponent";
import {formatDateTime} from "../helpers/timeFormatters";
import {crud} from "../../../crudRequests";
import {nanoid} from "nanoid";

export default function Forms() {
  const [patient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const toast = useToast();
  const [forms, setForms] = useState([]);
  const [assignedForms, setAssignedForms] = useState([]);
  const [completedForms, setCompletedForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [formMetadata, setFormMetadata] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchForms(
      setForms,
      setAssignedForms,
      setCompletedForms,
      dashState,
      patient,
      toast
    );
  }, []);

  const resendEmail = async (form) => {
    const newUrlToken = nanoid(10);
    const newAssignedDate = new Date();
    setIsLoading(true);
    await crud(
      dashState,
      [
        {
          db: dashState.db,
          collection: "form_submissions",
          parameters: [
            {fsid: form.fsid},
            {
              $set: {
                expirationLink: new Date(Date.now() + 48 * 60 * 60 * 1000),
                urlToken: newUrlToken,
                reassigned: true,
                assignedDate: newAssignedDate,
              },
            },
          ],
          method: "updateOne",
        },
      ],
      {
        emailForm: {
          type: "new_form_submission",
          content: {
            assignedBy: dashState?.doctor
              ? dashState?.doctor.name
              : dashState?.admin?.name,
            fsid: form.fsid,
            pEmail: patient.email,
            pName: patient.fName + " " + patient.lName,
            urlToken: newUrlToken,
          },
          sender: dashState.organization?.name,
          options: {db: dashState.db},
          org: dashState.organization?.s3BucketName ?? "brightlight-clinic",
          location: dashState.selectedLocation,
        },
      }
    )
      .then(() => {
        setSelectedForm((prev) => ({
          ...prev,
          reassigned: true,
          assignedDate: newAssignedDate,
        }));
        setAssignedForms((prevForms) =>
          prevForms.map((form) =>
            form.fsid === selectedForm.fsid
              ? {...form, reassigned: true, assignedDate: newAssignedDate}
              : form
          )
        );
        toast({
          title: "Form resent",
          description: "The form has been resent to the patient's email.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.error(e);
        toast({
          title: "Error",
          description: "Failed to resend the form. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    setIsLoading(false);
  };

  return (
    <Box mx="auto" my="6" pb="12" px="8" boxShadow="md" bg="white">
      <Flex>
        <Box>
          <AssignedAndCompletedForms
            completedForms={completedForms}
            setSelectedForm={setSelectedForm}
            assignedForms={assignedForms}
          />
          <AssignForm forms={forms} setAssignedForms={setAssignedForms} />
        </Box>
        <Box ml="10">
          {selectedForm && (
            <Box>
              <Box
                mb="2"
                display="flex"
                justifyContent={
                  !selectedForm.completed ? "space-between" : "flex-end"
                }
              >
                {!selectedForm.completed && (
                  <Box
                    display="flex"
                    w="80%"
                    justifyContent="space-between"
                    mr={2}
                  >
                    <Box
                      textColor="blue.600"
                      fontSize="sm"
                      display="flex"
                      alignItems="center"
                    >
                      <Text as="b" mr={1}>
                        {selectedForm.reassigned
                          ? "Reassigned on:"
                          : "Assigned on:"}
                      </Text>
                      <Text>
                        {formatDateTime(selectedForm.assignedDate, true)}
                      </Text>
                    </Box>
                    <Box>
                      <AlertDialogComponent
                        btnMessage="Delete"
                        rightIcon={<Icon as={MdDelete} ml="1" />}
                        leftIcon={null}
                        title=""
                        action={() =>
                          deleteForm(
                            dashState,
                            selectedForm.fsid,
                            setAssignedForms,
                            toast,
                            setSelectedForm
                          )
                        }
                        btnText="Delete"
                        size="sm"
                      />
                      <Button
                        isLoading={isLoading}
                        leftIcon={<MdEmail />}
                        colorScheme="blue"
                        variant="outline"
                        size="sm"
                        onClick={() => resendEmail(selectedForm)}
                        ml={2}
                      >
                        Resend
                      </Button>
                    </Box>
                  </Box>
                )}
                <Button
                  onClick={() => setFormMetadata(selectedForm)}
                  colorScheme="blue"
                  variant="ghost"
                  size="sm"
                  w="20%"
                >
                  View Metadata <Icon as={RiDatabase2Line} ml="1" />
                </Button>
              </Box>
              <PreviewForm
                completed={selectedForm?.completed}
                formFields={JSON.parse(selectedForm?.formFields)}
              />
            </Box>
          )}
          <FormMetadataModal
            formMetadata={formMetadata}
            setFormMetadata={setFormMetadata}
          />
        </Box>
      </Flex>
    </Box>
  );
}
