import React, {useState, useEffect, useRef} from "react";

import {View, Text, Image, Link} from "@react-pdf/renderer";

export default function ({type, transaction, patient}) {
  let display = <></>;
  let infoStyle = {
    fontWeight: 500,
    marginTop: 1,
    fontSize: "11px",
  };

  switch (type) {
    case "PATIENT_INVOICE":
    case "PATIENT_RECEIPT":
    case "FULL_INVOICE":
    case "FULL_RECEIPT":
      display = (
        <View
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            fontSize: 12,
            marginTop: 20,
            flexWrap: "wrap",
            paddingHorizontal: 15,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-start",
              flex: 1,
              borderRadius: 15,
              backgroundColor: "#e2e8f0",

              padding: 15,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
              }}
            >
              {`Billed To:`}
            </Text>
            <Text style={{...infoStyle, color: "#075985"}}>
              {`${transaction.pName}`}
            </Text>
            <Text style={infoStyle}>
              {`${patient?.address || "No address available"}`}
            </Text>
            <Text style={infoStyle}>
              {transaction?.pEmail || patient?.email ? (
                <Link style={{...infoStyle, color: "#075985"}}>{`${
                  transaction?.pEmail || patient?.email
                }`}</Link>
              ) : (
                <Text style={infoStyle}>{`${"No email available"}`}</Text>
              )}
            </Text>
            <Text style={infoStyle}>
              {`${
                transaction?.phone || patient?.phone || "No phone available"
              }`}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-start",
              flex: 1,
              borderRadius: 15,

              padding: 15,
            }}
          ></View>
        </View>
      );

      break;

    default:
      break;
  }

  return display;
}
