import {useState, useCallback, useEffect, useRef, useMemo} from "react";
import {
  Box,
  Button,
  Tooltip,
  FormControl,
  FormLabel,
  Heading,
  Input,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  HStack,
  Text,
  InputLeftElement,
  Flex,
  InputGroup,
  Icon,
  useToast,
} from "@chakra-ui/react";
import {HiOutlinePlus} from "react-icons/hi";
import sendNotification from "./helpers/sendNotification";
import {IoSearch} from "react-icons/io5";
import {ViewIcon, EmailIcon, EditIcon, DeleteIcon} from "@chakra-ui/icons";
import CreateGiftCard from "./CreateGiftCard";
import fetchGiftCards from "./helpers/fetchGiftCards";
import updateGiftCard from "./helpers/updateGiftCard";
import deleteCard from "./helpers/deleteCard";
export default function GiftCardsModule({dashState}) {
  const [waiting, setWaiting] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [searchText, setSearchText] = useState("");
  const toast = useToast();
  useEffect(() => {
    const loadedCards = localStorage.getItem("giftCards");
    if (loadedCards) {
      setGiftCards(JSON.parse(loadedCards));
    }
  }, []);

  useEffect(() => {
    fetchGiftCards(dashState, setGiftCards, toast);
  }, []);

  let filteredList = useMemo(() => {
    function filterText(card, text) {
      try {
        let regex = new RegExp(text, "i");
        if (text.length === 0) {
          return card;
        }
        return (
          card["fName"]?.match(regex) ||
          card["lName"]?.match(regex) ||
          card["secretCode"]?.match(regex) ||
          String(card["amount"]).match(regex)
        );
      } catch (error) {
        return false;
      }
    }

    return giftCards.filter((p) => filterText(p, searchText));
  }, [searchText, giftCards]);

  return (
    <Box
      w="full"
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      overflowY={"hidden"}
    >
      <Box
        w="full"
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        overflowY={"hidden"}
      >
        {createModal && (
          <CreateGiftCard
            {...{
              setCreateModal,
              createModal,
              dashState,
              setGiftCards,
            }}
          />
        )}
        <Heading as="h2" color={"blue.500"} size="lg" mb={8}>
          Gift Cards Module
        </Heading>

        <Flex
          justify={"space-between"}
          w="full"
          borderBottom={"1px solid "}
          borderColor={"gray.200"}
          align={"center"}
          p="5"
          pl="0"
        >
          <Flex align={"center"} gap={4}>
            <InputGroup p="1" w="60">
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" mt="2" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearchText(e.target.value.toLowerCase());
                }}
              />
            </InputGroup>
          </Flex>

          <Button
            leftIcon={<HiOutlinePlus />}
            colorScheme="blue"
            pl={2}
            onClick={() => setCreateModal(true)}
          >
            Create
          </Button>
        </Flex>

        <TableContainer h="full" flex={1} overflowY="auto">
          <Table variant="simple">
            <Thead position="sticky" top="0" bg="gray.100" zIndex={10}>
              <Tr>
                <Th>Purchased by</Th>
                <Th>Balance</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Creation Date</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredList.map((card, i) => (
                <Tr key={i}>
                  <Td>{`${card.fName || ""} ${card.lName || ""}`}</Td>
                  <Td>${Number(card.balance).toFixed(2)}</Td>
                  <Td>{card.email}</Td>
                  <Td>{card.phone}</Td>
                  <Td>{new Date(card.creationDate).toLocaleDateString()}</Td>
                  <Td>
                    <Button
                      leftIcon={<ViewIcon />}
                      size="sm"
                      onClick={() => {
                        setSelectedCard(card);
                        setIsEditing(false);
                        onOpen();
                      }}
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Gift Card Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedCard &&
                (isEditing ? (
                  <VStack spacing={4} align="stretch">
                    <FormControl>
                      <FormLabel htmlFor="edit-amount">
                        Current Balance
                      </FormLabel>
                      <Input
                        id="edit-amount"
                        type="number"
                        value={selectedCard.balance}
                        onChange={(e) => {
                          const validNumber = /^\d+(\.\d{0,2})?$/;
                          if (
                            validNumber.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setSelectedCard({
                              ...selectedCard,
                              balance: e.target.value,
                            });
                          }
                        }}
                        required
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="edit-email">Email</FormLabel>
                      <Input
                        id="edit-email"
                        type="email"
                        value={selectedCard.email}
                        onChange={(e) =>
                          setSelectedCard({
                            ...selectedCard,
                            email: e.target.value,
                          })
                        }
                        required
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="edit-phone">Phone</FormLabel>
                      <Input
                        id="edit-phone"
                        type="tel"
                        value={selectedCard.phone}
                        onChange={(e) =>
                          setSelectedCard({
                            ...selectedCard,
                            phone: e.target.value,
                          })
                        }
                        required
                      />
                    </FormControl>
                  </VStack>
                ) : (
                  <VStack align="stretch" spacing={2}>
                    <Text>
                      <strong>Purchased by:</strong>{" "}
                      {`${selectedCard.fName} ${selectedCard.lName}`}
                    </Text>
                    <Text>
                      <strong>Original Amount:</strong> $
                      {Number(selectedCard.amount).toFixed(2)}
                    </Text>
                    <Text>
                      <strong>Current Balance:</strong> $
                      {Number(selectedCard.balance).toFixed(2)}
                    </Text>
                    <Text>
                      <strong>Email:</strong> {selectedCard.email}
                    </Text>
                    <Text>
                      <strong>Phone:</strong> {selectedCard.phone}
                    </Text>
                    <Text>
                      <strong>Creation Date:</strong>{" "}
                      {new Date(selectedCard.creationDate).toLocaleString()}
                    </Text>
                    <Text>
                      <strong>Secret Code:</strong> {selectedCard.secretCode}
                    </Text>
                  </VStack>
                ))}
            </ModalBody>
            <ModalFooter>
              <HStack spacing={2}>
                {!isEditing && (
                  <>
                    <Button
                      leftIcon={<EmailIcon />}
                      onClick={() =>
                        selectedCard &&
                        sendNotification({
                          dashState,
                          card: selectedCard,
                          toast,
                          isResending: true,
                        })
                      }
                    >
                      Resend Email
                    </Button>
                    <Button
                      leftIcon={<EditIcon />}
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      leftIcon={<DeleteIcon />}
                      isDisabled={waiting}
                      onClick={async () => {
                        if (!waiting && selectedCard) {
                          setWaiting(true);

                          let action = () => {
                            setGiftCards((prev) =>
                              prev.filter(
                                (card) => card.gcId !== selectedCard.gcId
                              )
                            );

                            setSelectedCard(null);
                            setIsEditing(false);
                            onClose();
                          };
                          await deleteCard({
                            dashState,
                            selectedCard,
                            toast,
                            action,
                          });
                          setWaiting(false);
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </>
                )}
                {isEditing && (
                  <Button
                    isDisabled={
                      waiting ||
                      isNaN(selectedCard.balance) ||
                      selectedCard.balance === ""
                    }
                    colorScheme="blue"
                    onClick={async () => {
                      if (!waiting && selectedCard) {
                        setWaiting(true);

                        let action = () => {
                          setGiftCards((prev) =>
                            prev.map((card) =>
                              card.gcId === selectedCard.gcId
                                ? selectedCard
                                : card
                            )
                          );

                          setWaiting(false);
                          setSelectedCard(null);
                          setIsEditing(false);
                          onClose();
                        };
                        await updateGiftCard({
                          dashState,
                          selectedCard,
                          toast,
                          action,
                        });
                      }
                    }}
                  >
                    Save Changes
                  </Button>
                )}
                <Button variant="ghost" onClick={onClose}>
                  Close
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}

export function TruncatedTextWithTooltip({children, ...props}) {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTruncated(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [children]);

  return (
    <Tooltip label={children} isDisabled={!isTruncated}>
      <Text ref={textRef} isTruncated {...props}>
        {children}
      </Text>
    </Tooltip>
  );
}
