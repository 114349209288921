import {crud} from "../../../crudRequests";

export default async function createCard({dashState, newCard, toast, action}) {
  try {
    const generatingToast = toast({
      title: "Creating Gift Card...",
      status: "loading",
      variant: "subtle",
      duration: null,
      isClosable: true,
    });
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "giftCards",
        parameters: [newCard],
        method: "insertOne",
      },
    ]);

    toast.close(generatingToast);
    action?.();
  } catch (error) {
    toast({
      title: "Failed to create new Gift Card",
      description:
        error?.message || "An error occurred while creating the card.",
      status: "error",
      isClosable: true,
    });
  }
}
