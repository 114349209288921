import {useState, useEffect} from "react";
import {BiMoneyWithdraw} from "react-icons/bi";
import Swal from "sweetalert2";
import {
  MdPayment,
  MdPriceChange,
  MdOutlinePriceCheck,
  MdEmail,
} from "react-icons/md";

//import PrintInvRec from "./PrintInvRec";
import {Records} from "./Records";
import CreateChargeModal from "./CreateChargeModal";
import {markAsSubmitted, markAsRecieved} from "./actions";
//import InvRecpDF from "../InvRecPDF";
//import {isoToApptDate} from "../../additional_files/helpers";
//import {pdf} from "@react-pdf/renderer";
//import {sendInvRecEmail, crud} from "../../../crudRequests";
import {useToast} from "@chakra-ui/react";
export default function TppPayment({
  appointment,
  state,
  socket,
  dispatch,
  setInvOrRecExist,
  invOrRecExist,
}) {
  const [chargeModal, setChargeModal] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const toast = useToast();
  return (
    <div className="flex-1 flex flex-col px-5 p-2  rounded-lg  bg-[#BBBBFF]">
      <div>
        <p className="text-[#FFF] text-sm mt-3">
          insurer &nbsp;
          <span
            style={{
              backgroundColor: appointment?.received
                ? "#03A700"
                : appointment?.claim
                ? "#FF8A00"
                : "#FF3636",
            }}
            className=" text-xs  rounded-full text-white p-[3px] px-2"
          >
            {appointment?.received
              ? "claim received"
              : appointment?.claim
              ? "claim submitted"
              : "claim unsubmitted"}
          </span>
        </p>
      </div>

      <div className="w-full flex justify-center space-x-4 mt-5">
        {!appointment?.claim && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (
                !appointment?.claim &&
                appointment &&
                appointment?.tpp &&
                !appointment?.cancelled
              )
                markAsSubmitted(appointment, state, dispatch, socket, toast);
            }}
            disabled={
              (appointment?.claim && appointment && appointment?.received) ||
              !appointment?.tpp ||
              appointment?.cancelled
            }
            className={`focus:outline-none  text-[#827FFF] disabled:cursor-not-allowed flex justify-center items-center bg-transparent ring-[1px] active:ring-0 ring-[#827FFF]  rounded-md p-2  w-full whitespace-nowrap`}
          >
            <span className="font-medium text-sm">Mark claim as submitted</span>
          </button>
        )}

        {appointment?.claim && appointment && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (
                !appointment?.received &&
                appointment &&
                !appointment?.cancelled
              )
                markAsRecieved(appointment, state, dispatch, socket, toast);
            }}
            disabled={
              appointment?.cancelled ||
              (appointment?.claim && appointment && appointment?.received)
            }
            className={`focus:outline-none  bg-[#827FFF] text-[#FFF] disabled:cursor-not-allowed flex justify-center items-center rounded-md p-2  w-full whitespace-nowrap`}
          >
            <span className="font-medium text-sm">Mark claim as received</span>
          </button>
        )}
      </div>
      {appointment && (
        <Records
          appointment={appointment}
          type="insurer"
          state={state}
          setInvOrRecExist={setInvOrRecExist}
          invOrRecExist={invOrRecExist}
        />
      )}

      <div className="flex justify-center items-center w-full my-3">
        <div className="bg-[#5754FF] p-2 rounded-xl flex min-w-[10rem] flex-col justify-center items-center">
          <p className="text-white text-[11px]">
            {" "}
            <span className="">{"outstanding balance: "}</span>
            <span className="font-semibold text-xs">
              {`$${(
                (appointment?.tppAmount ?? 0) -
                (appointment?.amountPaidByTpp ?? 0)
              ).toFixed(2)}`}
            </span>
          </p>
          <p className="text-white text-[11px]">
            {" "}
            <span className="">{"amount paid: "}</span>
            <span className="font-semibold text-xs">
              {`$${parseFloat(appointment?.amountPaidByTpp ?? 0).toFixed(2)}`}
            </span>
          </p>
        </div>
      </div>

      {chargeModal && appointment && !appointment?.cancelled && (
        <CreateChargeModal
          state={state}
          appointment={appointment}
          stateChanger={setChargeModal}
          dispatch={dispatch}
          socket={socket}
        />
      )}
    </div>
  );
}
