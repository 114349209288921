import React, {useState, useEffect, useRef} from "react";

import {View, Text, Image} from "@react-pdf/renderer";

export default function ({supervisor, doctor}) {
  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        paddingHorizontal: 20,
        marginTop: 30,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flex: 1,
          position: "relative",
        }}
      >
        <View
          style={{
            flexDirection: "col",
            position: "relative",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {doctor?.signature && (
            <View
              style={{
                flexDirection: "col",
                position: "relative",
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid #1f2937",
              }}
            >
              {
                <Image
                  style={{
                    height: "40px",
                    padding: "4px",
                  }}
                  src={doctor.signature}
                ></Image>
              }
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              position: "relative",
              justifyContent: "flex-start",
              width: "100%",
              marginTop: 5,
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: 600,
              }}
            >
              {"Clinician: "}
            </Text>
            <Text
              style={{
                fontSize: "9px",
              }}
            >
              {`${doctor.lastName}, ${doctor.firstName}`}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              position: "relative",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: 600,
                textAlign: "start",
              }}
            >
              {"Registration Number: "}
            </Text>
            <Text
              style={{
                fontSize: "9px",
              }}
            >
              {doctor.registrationId || "N/A"}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              position: "relative",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: 600,
                textAlign: "start",
              }}
            >
              {"License number: "}
            </Text>
            <Text
              style={{
                fontSize: "9px",
              }}
            >
              {doctor.licenseNumber || "N/A"}
            </Text>
          </View>
        </View>

        {supervisor && supervisor.did !== doctor?.did && (
          <View
            style={{
              flexDirection: "col",
              position: "relative",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {supervisor?.signature && (
              <View
                style={{
                  flexDirection: "col",
                  position: "relative",
                  width: "100%",
                  alignItems: "center",
                  borderBottom: "1px solid #1f2937",
                }}
              >
                {
                  <Image
                    style={{
                      height: "40px",
                      padding: "4px",
                    }}
                    src={supervisor.signature}
                  ></Image>
                }
              </View>
            )}
            <View
              style={{
                flexDirection: "row",
                position: "relative",
                justifyContent: "flex-start",
                width: "100%",
                marginTop: 5,
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: 600,
                }}
              >
                {"Supervised By: "}
              </Text>
              <Text
                style={{
                  fontSize: "9px",
                }}
              >
                {supervisor.name}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: 600,
                  textAlign: "start",
                }}
              >
                {"Registration Number: "}
              </Text>
              <Text
                style={{
                  fontSize: "9px",
                }}
              >
                {supervisor.registrationId || "-"}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: 600,
                  textAlign: "start",
                }}
              >
                {"License number: "}
              </Text>
              <Text
                style={{
                  fontSize: "9px",
                }}
              >
                {supervisor.licenseNumber || "N/A"}
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}
