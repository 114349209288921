import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  Divider,
  Box,
  Text,
} from "@chakra-ui/react";
import {AiOutlineDelete} from "react-icons/ai";

export const InvoiceTable = ({cList, setCList, total}) => {

  const headers = [
    "Service Codes",
    "Diagnostic Codes",
    "Amount",
    "Unit",
    "%",
    "Total",
    "Override Fee",
    ""
  ]

  const handleDelete = (index) => {
    const newList = cList.filter((_, i) => i !== index)
    setCList(newList)
  }

  return (
    <>
      <TableContainer flex={1} overflowY="auto" height="100%" width="100%">
        <Table width="100%" variant="simple">
          <Thead>
            <Tr>
              {headers.map((header)=>(
                <Th
                  key={header}
                  whiteSpace="normal"
                  textAlign="center"
                >
                  {header}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {cList.map((c, i) => (
              <Tr
                key={i}
                cursor="pointer"
                fontSize="14px"
              >
                <Td maxW="200px" p="8px" whiteSpace="wrap" textAlign="center">
                  {c.serviceCode ?? ""}
                </Td>
                <Td maxW="200px" p="8px" whiteSpace="wrap" textAlign="center">
                  {c.diagnosticCode ?? ""}
                </Td>
                <Td maxW="200px" p="8px" whiteSpace="wrap" textAlign="center">
                  ${parseFloat(c.amount).toFixed(2) ?? ""}
                </Td>
                <Td maxW="200px" p="8px" whiteSpace="wrap" textAlign="center">
                  {c.unit ?? ""}
                </Td>
                <Td maxW="200px" p="8px" whiteSpace="wrap" textAlign="center">
                  {c.percentage ?? ""}
                </Td>
                <Td maxW="200px" p="8px" whiteSpace="wrap" textAlign="center">
                  ${parseFloat(c.total).toFixed(2) ?? ""}
                </Td>
                <Td maxW="200px" p="8px" whiteSpace="wrap" textAlign="center">
                  {c.overrideFee ? `$${parseFloat(c.overrideFee).toFixed(2)}` : ""}
                </Td>
                <Td maxW="200px" p="8px" whiteSpace="wrap" textAlign="center">
                  <Icon
                    cursor={"pointer"}
                    onClick={() => {
                      handleDelete(i)
                      total.current -= parseFloat(cList[i].total)
                      if (total.current < 0) total.current = 0
                    }}
                    as={AiOutlineDelete}
                    w={6}
                    h={6}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Divider borderWidth="1px" borderColor="black" my="0.5rem"/>
      <Box width="20%" marginLeft="auto">
        <Text fontWeight="bold" fontSize="lg">Total: ${parseFloat(total.current).toFixed(2)}</Text>
      </Box>
    </>
  )
}
