import axios from "axios";

import {url} from "../../../../crudRequests";

export function crudStorage(
  state,
  type,
  fileName,
  orgShareName,
  directoryName,
  subdirectoryName
) {
  return axios({
    method: "GET",
    url: `${url}/crudStorage`,
    headers: {
      Authorization: "Bearer " + state.jwt,
    },
    params: {
      s3BucketName: state.organization?.s3BucketName,
      id: state.doctor?.did || state.admin?.id,
      type: type,
      fileName: fileName,
      orgShareName: orgShareName,
      directoryName: directoryName,
      subdirectoryName: subdirectoryName,
    },
  });
}
