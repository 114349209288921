import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Text,
  Divider,
} from "@chakra-ui/react";

export default function Loader() {
  return (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />

      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Flex gap={2} justify="flex-end">
        <Skeleton height="20px" width="50px" />
        <Skeleton height="20px" width="50px" />
      </Flex>
    </Stack>
  );
}
