import searchMatch from "./helpers/searchMatch";
import renderTextByAccess from "../../../utils/renderTextByAccess";
import verifyAccess from "../../../utils/verifyAccess";
import {crud} from "../../../crudRequests";
import {PiArrowCircleDownFill} from "react-icons/pi";
import React, {useState, useRef, useEffect} from "react";
import {useToast} from "@chakra-ui/react";
export default function Selections(options) {
  const [value, setValue] = useState("");
  const [showSearchList, setShowSearchList] = useState(true);
  const [showOptionsList, setShowOptionsList] = useState(false);
  const schState = options.schState;
  const currentPatient = options.currentPatient;
  const currentDoctor = options.currentDoctor;
  const role = options.role;
  const selectedDoctor = options.doctor;
  const patient = options.patient;

  const clickRef = useRef(false);
  const [doctors, setDoctors] = useState(schState.doctors || []);
  const [prevDoctor, setPrevDoctor] = useState(selectedDoctor);
  const [prevPatient, setPrevPatient] = useState(patient);
  const toast = useToast();

  if (!currentDoctor && role === "Doctor" && selectedDoctor === prevDoctor) {
    let preferredClinician = doctors.find(
      (d) => d.did === patient?.preferredClinicianId
    );
    if (preferredClinician && value === "") {
      setValue(
        `${preferredClinician?.lastName}, ${preferredClinician?.firstName}`
      );
      options.onSelect(preferredClinician);
    }
  }

  if (selectedDoctor !== prevDoctor && role === "Supervisor") {
    let sup = doctors.find((d) => d.did === selectedDoctor?.supervisor);
    if (sup && value !== `${sup?.lastName}, ${sup?.firstName}`) {
      setPrevDoctor(selectedDoctor);
      setValue(`${sup?.lastName}, ${sup?.firstName}`);
      options.onSelect(sup);
    }
  } else if (selectedDoctor !== prevDoctor && role === "Doctor") {
    if (value !== `${selectedDoctor?.lastName}, ${selectedDoctor?.firstName}`) {
      setPrevDoctor(selectedDoctor);
      setValue(`${selectedDoctor?.lastName}, ${selectedDoctor?.firstName}`);
    }
  }

  if (patient !== prevPatient && role === "Patient") {
    if (value !== `${patient?.lName}, ${patient?.fName}`) {
      setPrevPatient(patient);
      setValue(`${patient?.lName}, ${patient?.fName}`);
    }
  }

  useEffect(() => {
    let arr = [];

    let f = (a) => {
      if (role === "Supervisor" && selectedDoctor) {
        let sup = a?.find((d) => d.did === selectedDoctor?.supervisor);
        if (sup) {
          setValue(`${sup?.lastName}, ${sup?.firstName}`);
          options.onSelect(sup);
        } else {
          setValue(`${sup?.lastName}, ${sup?.firstName}`);
          options.onSelect(selectedDoctor);
        }
      }
    };

    if (schState.userType === "doctor") {
      crud(schState, [
        {
          db: schState.db,
          collection: "doctors",
          parameters: [{}],
          method: "find",
        },
      ]).then((res) => {
        if (res?.data?.[0]) {
          setDoctors(res?.data?.[0]);
          f(res?.data?.[0]);
        }
      });
    } else {
      f(schState.doctors);
    }

    if (options.defaultDoctor && role === "Doctor") {
      setValue(
        `${options.defaultDoctor.lastName}, ${options.defaultDoctor.firstName}`
      );
    }
  }, []);

  useEffect(() => {
    const handler = () => {
      setShowSearchList((e) => e && !e);
      if (!clickRef.current) {
        setShowOptionsList((e) => e && !e);
      }

      clickRef.current = false;
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  const handleSelect = (e) => {
    const isPatientRole = role === "Patient";
    const preferredClinicianId = isPatientRole
      ? e.preferredClinicianId
      : patient.preferredClinicianId;
    const selectedClinicianId = isPatientRole ? selectedDoctor?.did : e?.did;

    if (preferredClinicianId && preferredClinicianId !== selectedClinicianId) {
      toast({
        title: "Warning",
        status: "warning",
        duration: 3000,
        isClosable: true,
        description:
          "The selected clinician is not the patient's preferred clinician.",
      });
    }

    setValue(
      role === "Doctor" || role === "Supervisor"
        ? e.lastName + ", " + e.firstName
        : e.lName + ", " + e.fName
    );

    options.onSelect(e);
  };

  return (
    <label className="block  mt-1" htmlFor="doctor">
      <span className="text-[11px] text-off ml-2.5">
        {`${currentPatient || currentDoctor ? "Selected" : "Select"}  ${
          role == "Doctor" ? "Clinician" : role
        }`}
      </span>
      {(!schState.selectedDoctor && role === "Doctor") ||
      (!currentPatient && role === "Patient") ||
      role === "Supervisor" ? (
        <div className="relative w-[17rem] flex  justify-center items-center">
          {showSearchList && (
            <SearchList
              onSelect={(e) => handleSelect(e)}
              {...{schState, doctors, role}}
              value={value}
              fields={
                role === "Doctor" || role === "Supervisor"
                  ? ["email", "name", "memberships"]
                  : [
                      "fName",
                      "lName",
                      "email",
                      "healthCard",
                      "phone",
                      "memberships",
                    ]
              }
            />
          )}

          {showOptionsList && (
            <OptionsList
              {...{schState, role, doctors, showOptionsList}}
              onSelect={(e) => {
                const isPatientRole = role === "Patient";
                const preferredClinicianId = isPatientRole
                  ? e?.preferredClinicianId
                  : patient?.preferredClinicianId;
                const selectedClinicianId = isPatientRole
                  ? selectedDoctor?.did
                  : e?.did;

                if (
                  preferredClinicianId &&
                  preferredClinicianId !== selectedClinicianId
                ) {
                  toast({
                    title: "Warning",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                    description:
                      "The selected clinician is not the patient's preferred clinician.",
                  });
                }

                setValue(
                  role === "Doctor" || role === "Supervisor"
                    ? e.lastName + ", " + e.firstName
                    : e.lName + ", " + e.fName
                );
                options.onSelect(e);
              }}
            />
          )}

          <input
            onClick={(e) => {
              setShowSearchList(true);
              setShowOptionsList(false);
            }}
            className="drop-shadow block w-[17rem]  px-4 py-3 pr-8 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            type="text"
            value={value}
            onChange={(e) => {
              setValue(e.target.value.trim());
              setShowOptionsList(false);
              setShowSearchList(true);
            }}
          />
          <span
            className="absolute right-2  h-full flex justify-center items-center hover:cursor-pointer"
            onClick={(e) => {
              //e.stopPropagation();
              clickRef.current = true;
              setShowSearchList(false);
              setShowOptionsList((s) => !s);
            }}
          >
            <PiArrowCircleDownFill
              className="h-5 w-5 text-off"
              aria-hidden="true"
            />
          </span>
        </div>
      ) : (
        <input
          type="text"
          className="drop-shadow block w-[17rem] whitespace-nowrap overflow-hidden px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          readOnly={true}
          defaultValue={
            currentPatient
              ? currentPatient.lName +
                ", " +
                currentPatient.fName +
                " (" +
                currentPatient.email +
                ")"
              : `${currentDoctor.lastName}, ${currentDoctor.firstName}`
          }
        />
      )}
    </label>
  );
}

function SearchList({schState, value, fields, role, onSelect, doctors}) {
  const [searchArr, setSearchArr] = useState([]);

  useEffect(() => {
    let search = () => {
      let a = searchMatch(
        role === "Doctor" || role === "Supervisor"
          ? schState.doctors || doctors
          : schState.patients,
        value,
        fields
      );
      setSearchArr(a);
    };
    let id = setTimeout(search, 200);
    return () => {
      clearTimeout(id);
    };
  }, [value]);

  return (
    <>
      {searchArr.length !== 0 ? (
        <div className="max-h-[5rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-[100] bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 text-sm text-off font-medium flex flex-col">
            {searchArr.map((e, i) => (
              <li
                className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-4"
                key={i}
                onClick={() => onSelect(e)}
              >
                {role === "Doctor" || role === "Supervisor"
                  ? `${e.name}`
                  : e.lName + " " + e.fName}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}

function OptionsList({onSelect, schState, role, doctors}) {
  let {doctorsPayload} = schState;
  return (
    <>
      {(role === "Doctor" || role === "Supervisor") && doctors ? (
        <div className="max-h-[5rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-10 bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 overflow-x-hidden text-sm text-off font-medium flex flex-col">
            {doctors.map((e, i) => {
              return (
                <li
                  className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-4 "
                  key={i}
                  onClick={() => onSelect(e)}
                >
                  {`${e.lastName}, ${e.firstName}`}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="max-h-[8rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-20 bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 text-sm text-off font-medium flex flex-col overflow-x-hidden">
            {schState.userType === "doctor" ? (
              schState.patients.map((pat, i) => {
                return (
                  <li
                    className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-4 whitespace-nowrap"
                    key={i}
                    onClick={
                      /* If user has no access to name, then cant schedule appointment */
                      verifyAccess(schState, "fName")
                        ? () => onSelect(pat)
                        : null
                    }
                  >
                    {renderTextByAccess(schState, pat, "lName", undefined, " ")}
                    {", "}
                    {renderTextByAccess(
                      schState,
                      pat,
                      "fName",
                      undefined,
                      " "
                    )}{" "}
                  </li>
                );
              })
            ) : (
              <>
                {[...doctors]
                  .filter((ele) => doctorsPayload[ele.did].patients.length > 0)
                  .sort((p1, p2) => {
                    let name1 =
                      `${p1.lastName}, ${p1.firstName}`.toLocaleLowerCase(
                        "en-CA"
                      );
                    let name2 =
                      `${p2.lastName}, ${p2.firstName}`.toLocaleLowerCase(
                        "en-CA"
                      );
                    if (name1 < name2) {
                      return -1;
                    }
                    if (name1 > name2) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((d, i) => (
                    <li className="" key={i}>
                      <div className="text-[#9F9DFA] px-4">{`${d.lastName}, ${d.firstName}`}</div>
                      <ul className="">
                        {doctorsPayload[d.did].patients.map((pat, i) => (
                          <li
                            className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-8 whitespace-nowrap"
                            key={i}
                            onClick={
                              /* If user has no access to name, then cant schedule appointment */
                              verifyAccess(schState, "fName")
                                ? () => onSelect(pat)
                                : null
                            }
                          >
                            {/* Admin Profile View: Dr-Patient */}
                            {renderTextByAccess(schState, pat, "lName")}
                            {", "}
                            {renderTextByAccess(schState, pat, "fName")}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                {schState.unassignedPatients.length > 0 && (
                  <li className="">
                    <div className="text-indigo-600 px-4">{"Unassigned"}</div>
                    <ul className="">
                      {schState.unassignedPatients.map((pat, i) => (
                        <li
                          className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-8 whitespace-nowrap"
                          key={i}
                          onClick={
                            /* If user has no access to name, then cant schedule appointment */
                            verifyAccess(schState, "fName")
                              ? () => onSelect(pat)
                              : null
                          }
                        >
                          {/* Admin Profile View: Unassigned patients */}
                          {renderTextByAccess(schState, pat, "lName")}
                          {", "}
                          {renderTextByAccess(schState, pat, "fName")}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
}
