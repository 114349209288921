import {crud} from "../../../crudRequests";

export default async function updateCard({
  dashState,
  selectedCard,
  toast,
  action,
}) {
  try {
    const generatingToast = toast({
      title: "Updating card...",
      status: "loading",
      variant: "subtle",
      duration: null,
      isClosable: true,
    });

    let {_id, ...payload} = selectedCard;
    payload.creationDate = Date.now();
    payload.balance = parseFloat(payload.balance);
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "giftCards",
        parameters: [
          {gcId: selectedCard.gcId},
          {$set: payload},
          {returnNewDocument: true},
        ],
        method: "findOneAndUpdate",
      },
    ]);

    toast.close(generatingToast);
    action?.();
  } catch (error) {
    toast({
      title: "Failed to update card",
      description:
        error?.message || "An error occurred while updating the gift card.",
      status: "error",
      isClosable: true,
    });
  }
}
