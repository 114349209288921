import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Box,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Textarea,
  Button,
} from "@chakra-ui/react";
import {useContext, useState, useEffect, useMemo} from "react";
import AppointmentModal from "../../AppointmentModal";
export default function AppointmentModalWraper({dispatch, schState}) {
  const InsurersMap = useMemo(() => {
    return (schState.insurers || []).reduce((acc, el) => {
      acc[el.iid] = el;
      return acc;
    }, {});
  }, [schState.insurers]);

  return (
    <Drawer
      isOpen={schState.selectedAppointment}
      placement="right"
      size={"full"}
      onClose={() => {
        dispatch({type: "SELECT_APPOINTMENT", payload: null});
      }}
      trapFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent
        onClick={() => dispatch({type: "SELECT_APPOINTMENT", payload: null})}
        bg="transparent"
      >
        <DrawerBody p={0}>
          <AppointmentModal
            isFromPatient
            {...{
              dispatch,
              schState,
              InsurersMap,
            }}
          />
        </DrawerBody>

        {/*<DrawerFooter borderTopWidth="1px">
          <Button
            variant="outline"
            mr={3}
            px="10"
            onClick={() => {
              
            }}
          >
            Cancel
          </Button>
          </DrawerFooter>*/}
      </DrawerContent>
    </Drawer>
  );
}
