import {
  Box,
  AvatarGroup,
  Avatar,
  Text,
  Flex,
  VStack,
  Badge,
  Tooltip,
  Tag,
  Icon,
  Button,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {ViewIcon} from "@chakra-ui/icons";
import Swal from "sweetalert2";
import {isFutureOrPast} from "../helpers/timeFormatters";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";

import {IoVideocam} from "react-icons/io5";
import {MdOutlineAttachMoney} from "react-icons/md";
import {HiOutlineDocumentText} from "react-icons/hi";
import {getJitsiJWT} from "./helpers/jitsiFunctions";
import {FaRegEye} from "react-icons/fa6";
import RelatedCharts from "./RelatedCharts";
import InvoicOrReceipt from "../../Billing/InvoiceOrReceipt";
import {IoEyeOutline} from "react-icons/io5";
export default function Appointment({
  state,
  appointment,
  summaries,
  transaction,
  setPreview,
  handleLinkToChart,
  setPreviewInvRec,
  setSelectedTr,
  dispatch,
  setApptNotes,
  apptDoctor,
  selectedSummary,
  setSelectedSummary,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();

  const [printInvoiceOrReciept, setPrintInvoiceOrReciept] = useState("");
  const [fetchingFileData, setFetchingFileData] = useState("");

  const [payment, setPayment] = useState(null);

  useEffect(() => {
    setPayment(transaction);
  }, [transaction]);

  const status = appointment.arrived
    ? {name: "Arrived", color: "green"}
    : appointment.noShow
    ? {name: "No Show", color: "black"}
    : appointment.cancelled
    ? {name: "Cancelled", color: "red"}
    : appointment.rescheduled
    ? {name: "Rescheduled", color: "orange"}
    : appointment.pConfirmed
    ? {name: "Confirmed", color: "blue"}
    : {name: "Unconfirmed", color: "gray"};

  let individualSummary = summaries?.find((p) => p.aid === appointment.aid);
  let summary = individualSummary?.briefSummary
    ? {text: "VIEW AUTO-GENERATED SUMMARY", color: "green", display: true}
    : individualSummary?.sid
    ? {
        text: (
          <Flex alignItems="center" justifyContent="center">
            SUMMARY AUTO-GENERATING <Spinner size={"xs"} ml="1.5" />
          </Flex>
        ),
        color: "orange",
        display: false,
      }
    : {
        text: "NO SUMMARY AVAILABLE",
        color: "blackAlpha",
        display: false,
      };

  return (
    <Box
      key={appointment.aid}
      bg={isFutureOrPast(appointment.date) ? "white" : "gray.100"}
      boxShadow="md"
      borderRadius="md"
      p="6"
    >
      <VStack spacing={0} align="start">
        <Flex justifyContent="space-between" w="100%">
          <Box>
            <Badge
              fontSize="12px"
              rounded="md"
              p="1"
              px="2"
              mb="4"
              colorScheme={status.color}
            >
              {status.name}
            </Badge>
          </Box>{" "}
          <Box>
            <Icon
              onClick={() => {
                dispatch({type: "SELECT_APPOINTMENT", payload: appointment});
              }}
              cursor={"pointer"}
              as={FaRegEye}
              fontSize={"1.7rem"}
              color="gray.400"
              _hover={{color: "blue.400"}}
              transition={"0.2s"}
            />
          </Box>
        </Flex>
        <Flex mb="3">
          <AvatarGroup max={2}>
            <Tooltip label={`${apptDoctor.lastName}, ${apptDoctor.firstName}`}>
              <Avatar
                size="md"
                name={`${
                  apptDoctor.lastName ? apptDoctor.lastName + ", " : ""
                }${apptDoctor.firstName || ""}`}
                src={apptDoctor?.photo}
              />
            </Tooltip>
            <Tooltip label={patient.lName + ", " + patient.fName}>
              <Avatar
                size="sm"
                ml={"-1"}
                name={patient.lName + ", " + patient.fName}
              />
            </Tooltip>
          </AvatarGroup>
          <Text fontSize="lg" fontWeight="bold"></Text>
        </Flex>
        <Text
          fontWeight={"light"}
          color={
            appointment.note || appointment.cancellationReason
              ? "blue.500"
              : "gray.500"
          }
          fontSize="12px"
          mb="1"
          cursor={
            appointment.note || appointment.cancellationReason
              ? "pointer"
              : "default"
          }
          onClick={() =>
            (appointment.note || appointment.cancellationReason) &&
            setApptNotes(appointment)
          }
        >
          {appointment.note || appointment.cancellationReason
            ? "View Notes"
            : "No Notes Added"}
        </Text>
        <Box gap={2} textAlign={"start"}>
          <Text textAlign={"start"} as="span" fontWeight={"medium"}>
            {appointment.date}
          </Text>
          <Text fontWeight={"light"} as="span" fontSize="14px" ml={2}>
            {appointment.teleconference ? 'Virtual' : appointment.telephone ? 'Telephone' : 'In Person'}
          </Text>
        </Box>
        <Text fontWeight={"light"}>
          @ {appointment.time}
          <a
            className="cursor-pointer"
            // onClick={async () => {
            //   if (state.userType === "admin")
            //     Swal.fire({
            //       position: "top",
            //       width: 850,
            //       html: "Only the patient and clinician associated with this appointment are allowed to go in the call.",
            //       icon: "info",
            //       title: "You are not authorized to join this call.",
            //       showConfirmButton: false,
            //       timer: 3000,
            //     });
            //   else {
            //     let jwt = "";
            //     try {
            //       jwt = await getJitsiJWT(
            //         state,
            //         appointment.aid,
            //         patient.pid,
            //         state.db,
            //         state.selectedDoctor
            //       );
            //     } catch (error) {
            //       toast({
            //         title: "Please try again",
            //         description: "Something went wrong",
            //         status: "error",
            //         duration: 9000,
            //         isClosable: true,
            //       });
            //       console.log(error);
            //     }

            //     // const {roomName} = await updateRoomLink(
            //     //   state,
            //     //   appointment.aid,
            //     //   patient.pid,
            //     //   state.db
            //     // );
            //     if (appointment?.clinicApptLink) {
            //       return;
            //       window.open(
            //         `${appointment.clinicApptLink}?jwt=${jwt}`,
            //         "_blank"
            //       );
            //     }
            //   }
            //   // console.log({
            //   //   roomName,
            //   //   // ptUrl,
            //   //   link: roomName,
            //   // });
            // }}
            onClick={async () => {
              if (state?.userType === "admin")
                toast({
                  title: "You are not authorized to join this call.",
                  status: "info",
                  description:
                    "Only the patient and clinician associated with this appointment are allowed to go in the call.",
                  duration: 3000,
                  isClosable: true,
                  position: "top",
                });
              else {
                if (appointment?.clinicApptLink) {
                  // Will display the loading toast until the promise is either resolved
                  // or rejected.
                  // console.log(appointment);
                  // return;
                  toast.promise(
                    getJitsiJWT(
                      state,
                      appointment?.aid,
                      appointment?.pid,
                      state?.db,
                      state?.selectedDoctor,
                      appointment?.clinicApptLink
                    ),
                    {
                      success: {
                        title: "Authenticated",
                        description: "Successfully joined meeting!",
                      },
                      error: {
                        title: "Please try again",
                        description:
                          "Something wrong authenticating you to the meeting",
                      },
                      loading: {
                        title: "Authenticating...",
                        description: "Please wait",
                      },
                    }
                  );
                }
              }
            }}
            target="_blank"
            rel="noreferrer"
          >
            {/* <a
            onClick={() =>
              getHash(state, appointment.aid, appointment.pid, state.db)
            }
            target="_blank"
            rel="noreferrer"
          > */}
            <Icon
              as={IoVideocam}
              fontSize={"20px"}
              mt="-1"
              ml="2"
              color="gray.600"
            />
          </a>
        </Text>
        <Tag colorScheme="blackAlpha" my="3">
          {appointment.service}
        </Tag>
        <Flex justifyContent={"space-between"} w="100%">
          <Box>
            <Tooltip
              label={
                !appointment.claim
                  ? "Claim is unsumbitted"
                  : appointment.received
                  ? "Claim is submitted"
                  : "Claim is recieved"
              }
            >
              <Button
                variant="ghost"
                p="0"
                colorScheme={
                  !appointment.claim
                    ? "blackAlpha"
                    : appointment.received
                    ? "whatsapp"
                    : "blue"
                }
                cursor={"default"}
              >
                <Icon as={HiOutlineDocumentText} fontSize="24" />
              </Button>
            </Tooltip>
            <Tooltip
              label={
                parseFloat(payment?.amount) !== parseFloat(payment?.paid || 0)
                  ? "Payment is pending"
                  : "Payment is recieved"
              }
            >
              <Button
                variant="ghost"
                p="0"
                colorScheme={
                  parseFloat(payment?.amount) !== parseFloat(payment?.paid || 0)
                    ? "blackAlpha"
                    : "whatsapp"
                }
                cursor={"default"}
              >
                <Icon as={MdOutlineAttachMoney} fontSize="24" />
              </Button>
            </Tooltip>

            <RelatedCharts
              {...{
                handleLinkToChart,
                setPreview,
                dashState,
                patient,
                appointment,
                toast,
              }}
            />
            {/*report[0] && (
              <Tooltip
                label={
                  report[0]?.completed
                    ? "Completed report"
                    : "Uncompleted report"
                }
              >
                <Button
                  variant="ghost"
                  p="0"
                  colorScheme={
                    !report[0]?.completed ? "blackAlpha" : "whatsapp"
                  }
                  cursor={"default"}
                  onClick={async () => {
                    let path = patient["pid"] + `/Charts/`;
                    let reports = await crudStorage(
                      dashState,
                      "view",
                      "",
                      "brightlighthealth",
                      path
                    );
                    let file = reports.data?.find(
                      (f) =>
                        f.name.slice(-1) !== "/" &&
                        f.name.includes(appointment.aid)
                    );
                    if (file) {
                      previewFile(
                        dashState,
                        path,
                        file.name,
                        setPreview,
                        toast
                      );
                    } else {
                      toast({
                        title: "This file cannot be previewed.",
                        description: "Chart report could not be found!",
                        status: "warning",
                        isClosable: true,
                      });
                    }
                  }}
                >
                  <Icon as={LuGanttChartSquare} fontSize="24" />
                </Button>
              </Tooltip>
            )*/}
          </Box>
          <Box>
            <Box p="3">
              <InvoicOrReceipt
                {...{
                  transaction: payment,
                  dashState,
                  setPreview: setPreviewInvRec,
                  fetchingFileData,
                  setFetchingFileData,
                  setSelectedTr,
                  toast,
                }}
              />
            </Box>
          </Box>
        </Flex>
      </VStack>
      <Box mt="3" rounded="lg">
        <Badge
          onClick={() =>
            summary.display &&
            setSelectedSummary(
              summaries?.find((p) => p.aid === appointment.aid)
            )
          }
          rounded="md"
          variant="outline"
          colorScheme={summary.color}
          fontSize={"12px"}
          p="1"
          px="2"
          textAlign={"center"}
          w="full"
          cursor={summary.display ? "pointer" : "default"}
        >
          {summary.text}
        </Badge>
      </Box>

      {/*<InvoiceOrRecieptModal
        dashState={dashState}
        printInvoiceOrReciept={printInvoiceOrReciept}
        setPrintInvoiceOrReciept={setPrintInvoiceOrReciept}
        />*/}
    </Box>
  );
}
