import {nanoid} from "nanoid";
import {addPatient} from "../../../crudRequests";
import {fetchPatient} from "./fetchPatient";
import {crudStorage} from "../Files/helpers/crudStorage";

export default async function createPatient(
  dashState,
  dispatch,
  setPatient,
  fields,
  setFields,
  createClose,
  setIsLoading,
  toast
) {
  setIsLoading(true);

  const pid = nanoid(10);

  if (fields.fName.trim() == "" || fields.lName.trim() == "") {
    toast({
      title: "Missing Name",
      description: "Please enter the patient's first and last name to add them",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
    return;
  } else if (
    fields.email.trim() &&
    !fields.email
      .trim()
      .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  ) {
    toast({
      title: "Invalid Email",
      description: "Please ensure the email is in the correct format",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
    return;
  } else if (!fields.email.trim() && fields.welcome) {
    toast({
      title: "Invalid Email",
      description:
        "Please enter the patient's email to receive the welcome message.",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
    return;
  } else {
    let payload = {
      pid: pid,
      fName: fields.fName.trim(),
      lName: fields.lName.trim(),
      phone: fields.phone.trim(),
      email: fields.email.trim(),
      scheduleBeforeIntake: false,
      oid:
        dashState.userType === "admin"
          ? dashState.admin.oid
          : dashState.doctor.oid,
      lid: [dashState.selectedLocation],
      did:
        dashState.userType === "doctor"
          ? [dashState.doctor.did]
          : fields.did
          ? [fields.did]
          : [],
      emailPermission: true,
      textPermission: true,
      referralVia: fields.referralVia,
      welcome: fields.welcome,
    };
    addPatient(dashState, payload, {
      emailInvitation: true,
      phoneInvitation: true,
    })
      .then((res) => {
        dispatch({type: "ADD_PATIENT", payload});
        try {
          crudStorage(
            dashState,
            "createDirectory",
            "",
            "brightlighthealth",
            pid + "/Charts"
          );
        } catch (error) {
          console.error("Error creating directory:", error);
        }
        toast({
          title: "New Patient Added",
          description:
            "The patient has been added successfully." +
            (fields.email || fields.phone
              ? " An invitation has been sent to their email/phone to access their Brightlight patient portal as well."
              : ""),
          status: "success",
          isClosable: true,
        });
        setIsLoading(false);
        setFields({
          fName: "",
          lName: "",
          email: "",
          phone: "",
          welcome: true,
        });
        createClose.current.click();
        fetchPatient(dashState, pid, setPatient, toast);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          setIsLoading(false);
          toast({
            title: "Duplicate Email/Phone",
            description:
              "A patient with the same email/phone already exists. Please enter in a unique email/phone for the new patient.",
            status: "error",
            isClosable: true,
          });
        } else if (error.response.status === 403) {
          window.location.href = "../login";
        } else {
          toast({
            title: "As error occured",
            description: error.response?.data || error.message,
            status: "error",
            isClosable: true,
          });
          setIsLoading(false);
        }
      });
  }
}
