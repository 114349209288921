import React, {useState, useEffect, useRef, useMemo} from "react";
import {crud} from "../crudRequests";
import {
  Heading,
  Spinner,
  Box,
  Text,
  Button,
  Grid,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowUpDownIcon,
} from "@chakra-ui/icons";

export const SortingArrow = ({direction}) => {
  if (direction === "asc") {
    return <ChevronUpIcon />;
  } else if (direction === "desc") {
    return <ChevronDownIcon />;
  } else {
    return <ArrowUpDownIcon color="gray.300" />;
  }
};

export const SortableColumnHeader = ({label, onSort, currentSort, column, onClick}) => {
  const handleClick = () => {
    if (currentSort.column === column) {
      onSort({
        ...currentSort,
        direction: currentSort.direction === "asc" ? "desc" : "asc",
      });
    } else {
      onSort({column, direction: currentSort.direction === "asc" ? "desc" : "asc"});
    }
    if (onClick){
      onClick(column, currentSort.direction === "asc" ? "desc" : "asc")
    }
  };
  return (
    <Th>
      <Flex align="center" onClick={handleClick} cursor="pointer">
        <Text>{label}</Text>
        <Box ml={1}>
          <SortingArrow
            direction={
              currentSort.column === column ? currentSort.direction : ""
            }
          />
        </Box>
      </Flex>
    </Th>
  );
};
