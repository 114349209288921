import React, {useState, useEffect, useRef} from "react";
import {View, Text, Image} from "@react-pdf/renderer";
import {lightFormat} from "date-fns";

export default function ({type, transaction}) {
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "row",
        marginTop: 40,
        paddingHorizontal: 15,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <Text
          style={{
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {type.includes("INVOICE") ? `INVOICE` : `RECEIPT`}
        </Text>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "col",
          alignItems: "flex-end",
          flex: 1,
          paddingLeft: 60,
          fontSize: 11,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 2,
            lignItems: "center",
          }}
        >
          <Text
            style={{
              fontWeight: 700,
            }}
          >
            {`${type.includes("INVOICE") ? "Invoice" : "Receipt"} ID: #`}
          </Text>
          <Text
            style={{
              fontWeight: 500,
            }}
          >{`${
            type.includes("INVOICE")
              ? transaction.invoiceId
              : transaction.receiptId
          }`}</Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            marginTop: 2,
            lignItems: "center",
          }}
        >
          <Text
            style={{
              fontWeight: 700,
            }}
          >
            {`Issued: `}
          </Text>
          <Text
            style={{
              fontWeight: 500,
            }}
          >{`${lightFormat(new Date(), "dd/MM/yy HH:mm")}`}</Text>
        </View>
      </View>
    </View>
  );
}
