import React, {useState, useEffect, useRef} from "react";
import {View, Text, Image} from "@react-pdf/renderer";
import {lightFormat} from "date-fns";
import PatientInformation from "./PatientInformation";
import ClinicianInformation from "./ClinicianInformation";
import {nanoid} from "nanoid";
import List from "./List";
import Table from "./Table/index";
import Signature from "./Signature";
import BillTo from "./BillTo";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Summary from "./Summary";
import ProductsTable from "./ProductsTable";
export default function ({
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  state,
  type,
  returnDoc,
  receiptAmount,
  nonSessionService,
  location,
}) {
  return (
    <View style={{paddingHorizontal: 0}}>
      <Section1 type={type} transaction={transaction} />
      <Section2 type={type} tpp={tpp} patient={patient} />

      <Table
        transaction={transaction}
        type={type}
        receiptAmount={receiptAmount}
        nonSessionService={nonSessionService}
        location={location}
      />
      {/*transaction?.products?.length > 0 && (
        <ProductsTable transaction={transaction} type={type} />
      )*/}

      <Signature doctor={doctor} supervisor={supervisor} />
    </View>
  );
}
