import Selections from "./Selections";
import AvailabilityList from "./AvailabilityList";
import AvailabilityListMultipleClinicians from "./AvailabilityListMultipleClinicians";

export default function NextAvailable({
  schState,
  currentPatient,
  setPatient,
  patient,
  setDuration,
  duration,
  services,
  service,
  setService,
  defaultData,
  setDoctor,
  doctor,
  onSelectAvailability,
}) {
  return (
    <div>
      <div className="flex justify-between pb-5 border-b border-gray-200">
        <div className="flex-1 flex flex-col items-start">
          <Selections
            {...{schState}}
            currentDoctor={schState.selectedDoctor}
            role="Doctor"
            onSelect={setDoctor}
            defaultDoctor={defaultData?.doctor}
            doctor={doctor}
            label="next_Doctor"
          />
          <Selections
            {...{
              schState,
              currentPatient: currentPatient || schState.selectedPatient,
            }}
            role="Patient"
            onSelect={setPatient}
            patientData={patient}
            label="next_patient"
            patient={patient}
          />
        </div>
        <div className="flex-1 flex flex-col items-end ">
          <label className="block mx-1 mb-1" htmlFor="service">
            <span className="text-[11px] text-off ml-2">Select Service</span>
            <select
              onChange={(e) => {
                let srv = services.find((s) => s.serviceId === e.target.value);
                setService(srv || "");
                setDuration(srv?.defaultDuration || "15 min");
              }}
              value={service?.serviceId || ""}
              type="service"
              name="service"
              id="service"
              className="drop-shadow m-0 block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            >
              <option value="">-</option>
              {services.map((s, i) => {
                return (
                  <option key={i} value={s.serviceId}>
                    {s.serviceName}
                  </option>
                );
              })}
            </select>
          </label>

          <label className="block mx-1 mt-1" htmlFor="duration">
            <span className="text-[11px] text-off ml-2">Select Duration</span>
            {
              <select
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
                value={duration || "15 min"}
                type="duration"
                name="duration"
                id="duration"
                className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
              >
                <option value="15 min">15 minutes</option>
                <option value="30 min">30 minutes</option>
                <option value="45 min">45 minutes</option>
                <option value="60 min">60 minutes</option>
                <option value="75 min">75 minutes</option>
                <option value="90 min">90 minutes</option>
                <option value="120 min">120 minutes</option>
              </select>
            }
          </label>
        </div>
      </div>
      {service ? (
        doctor ? (
          <AvailabilityList
            {...{
              state: schState,
              clinician: doctor,
              duration,
              onSelectAvailability,
              service,
            }}
          />
        ) : (
          <AvailabilityListMultipleClinicians
            {...{
              state: schState,
              clinician: doctor,
              duration,
              onSelectAvailability,
              service,
            }}
          />
        )
      ) : null}
    </div>
  );
}
