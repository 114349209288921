import {crud} from "../../../crudRequests";

export default async function fetchGiftCards(state, setGiftCards, toast) {
  //let matchQuery = {lid: state.selectedLocation};
  let matchQuery = {};

  try {
    const res = await crud(state, [
      {
        db: state.db,
        collection: "giftCards",
        parameters: [matchQuery],
        method: "find",
        findCursor: [{method: "sort", arguments: [{creationDate: -1}]}],
      },
    ]);

    setGiftCards(res.data[0]);
  } catch (err) {
    console.log("error", err);
    if (toast)
      toast({
        title: "Failed to fetch Gift Cards data",
        description:
          err?.message || "An error occurred while fetching cards data.",
        status: "error",
        isClosable: true,
      });
  }
}
