import {crud} from "../../../../crudRequests";

export async function updatePatient(
  e,
  patient,
  dashState,
  setIsLoading,
  toast,
  dispatch
) {
  setIsLoading && setIsLoading(true);
  e && e.preventDefault();
  const {_id, ...patientToUpdate} = patient;
  crud(dashState, [
    {
      db: dashState.db,
      collection: "patients",
      parameters: [{pid: patientToUpdate.pid}, {$set: patientToUpdate}],
      method: "updateOne",
    },
  ])
    .then((res) => {
      setIsLoading && setIsLoading(false);
      dispatch({
        type: "UPDATE_PATIENT",
        pid: patientToUpdate.pid,
        payload: patientToUpdate,
        options: null,
      });
      toast({
        title: "Information Updated",
        description: "Patient information successfully updated",
        status: "success",
        isClosable: true,
      });
    })
    .catch((err) => {
      setIsLoading && setIsLoading(false);
      toast({
        title: "Information Not Updated",
        description: err?.message || "An error occurred.",
        status: "error",
        isClosable: true,
      });
      console.log(err);
    });
}
