import React, {useEffect} from "react";
import {Box, Grid} from "@chakra-ui/react";

export default function PreviewForm({completed, formFields}) {
  function renderElement(obj) {
    const {element, key, props = {}, children} = obj;

    if (children && !Array.isArray(children)) {
      return React.createElement(element, {key, ...props}, children);
    }

    const childElements =
      children?.map((child, index) =>
        renderElement({...child, key: child.key || index})
      ) || null;

    return completed
      ? React.createElement(
          element,
          {key, ...props, disabled: "true"},
          childElements
        )
      : React.createElement(element, {key, ...props}, childElements);
  }

  return (
    <>
      <Box
        w="40rem"
        h="30rem"
        overflowY="scroll"
        boxShadow={"lg"}
        bg="gray.50"
        pb="8"
      >
        {Object.keys(formFields).map((rowIdx) => {
          return (
            <Grid
              templateColumns={`repeat(${
                Object.keys(formFields[rowIdx]).length
              }, 1fr)`}
              gap="4"
              mb="2"
              px="8"
            >
              {Object.keys(formFields[rowIdx]).map((colIdx) => {
                return (
                  <Box>
                    {formFields[rowIdx][colIdx] &&
                      renderElement(formFields[rowIdx][colIdx])}
                  </Box>
                );
              })}
            </Grid>
          );
        })}
      </Box>
    </>
  );
}
