import Swal from "sweetalert2";
import {crud, sendInvRecEmail} from "../../../crudRequests";
import {
  fullInvoice,
  fullReceipt,
  patientInvoice,
  patientReceipt,
  insurerReceipt,
} from "./uploads";
import createPaymentRecord from "./helpers/createPaymentRecord";
import {isoToApptDate} from "../../../additional_files/helpers";

export async function markAsPaid(appt, schState, dispatch, socket, toast) {
  try {
    if (appt && appt?.patientPaymentStatus !== "paid") {
      const {
        value: description,
        isConfirmed: isCashPayment,
        isDenied: isCheckPayment,
      } = await Swal.fire({
        input: "textarea",
        title: "Payment Description",
        inputPlaceholder: "Type the description...",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Cash",
        denyButtonText: `Check`,
        returnInputValueOnDeny: true,
        inputValidator: (value) => {
          if (!value.match(/\w+/)) {
            return "You must write a description!";
          }
        },
        target: document.getElementById("modal-center"),
      });
      if (description) {
        Swal.fire({
          title: "Please select the appropriate option!",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Full amount",
          denyButtonText: `Partial amount`,
          target: document.getElementById("modal-center"),
        }).then(async (result) => {
          let sendInvEmail = async (
            schState,
            transaction,
            doctor,
            patient,
            amountPaid,
            tpp,
            supervisor
          ) => {
            try {
              let invData = {
                state: schState,
                transaction,
                doctor,
                patient,
                tpp,
                supervisor,
                receiptAmount: amountPaid,
                nonSessionService: transaction.nonSessionService,
              };

              let blob = await patientReceipt(invData);
              await fullReceipt(invData);

              if (blob) {
                let dataURI = await new Promise((resolve, reject) => {
                  try {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                      resolve(reader.result);
                    };
                  } catch (error) {
                    reject(error);
                  }
                });
                let {date, time} = isoToApptDate(transaction.serviceDate);
                let location = await crud(schState, [
                  {
                    db: schState.db,
                    collection: "locations",
                    parameters: [{lid: schState.selectedLocation}],
                    method: "findOne",
                  },
                ]);
                location = location.data[0];
                let sender = location?.name;
                await sendInvRecEmail(schState, {
                  invoice: false,
                  date,
                  time,
                  pEmail: transaction.pEmail,
                  pName: transaction.pName,
                  dName: transaction.dName,
                  sender: sender || schState?.organization?.name,
                  attachments: [
                    {
                      filename: "Receipt.pdf",
                      path: dataURI,
                    },
                  ],
                });
              }
            } catch (err) {
              console.log(err);
            }
          };

          if (result.isConfirmed) {
            const generatingToast = toast({
              title: "Updating payment status.",
              status: "loading",
              variant: "subtle",
              duration: null,
              isClosable: true,
            });

            let patientAmount = parseFloat(appt?.patientAmount ?? 0);
            let tppAmount = parseFloat(appt?.tppAmount ?? 0);
            let amountPaidByPatient = parseFloat(appt?.patientAmount ?? 0);
            let amountPaidByTpp = parseFloat(appt?.amountPaidByTpp ?? 0);
            let otbp = patientAmount - amountPaidByPatient;
            let otbi = tppAmount - amountPaidByTpp;
            let paymentMethod;
            if (isCashPayment) {
              paymentMethod = "cash";
            } else if (isCheckPayment) {
              paymentMethod = "check";
            }
            const updateBody = {
              ...appt,
              patientPaymentStatus: "paid",
              amountPaidByPatient: appt?.patientAmount,
              paymentStatus: parseFloat(otbi) <= 0 ? "paid" : "pending",
              paymentMethod,
            };
            let receiptAmount = appt?.patientAmount - appt?.amountPaidByPatient;
            let {_id, ...data} = updateBody;

            crud(schState, [
              {
                db: schState.db,
                collection: appt?.nonSessionService
                  ? "nonSessionServices"
                  : "appointments",
                parameters: [{aid: data.aid}, {$set: data}],
                method: "updateOne",
              },
              {
                db: schState.db,
                collection: "billing",
                parameters: [
                  {
                    $and: [{aid: data.aid}, {cancelled: false}],
                  },
                  {
                    $set: {
                      patientPaymentStatus: "paid",
                      amountPaidByPatient: appt?.patientAmount,
                      paymentStatus:
                        parseFloat(otbp) <= 0 && parseFloat(otbi) <= 0
                          ? "paid"
                          : "pending",
                      paymentMethod,
                    },
                  },
                  {returnNewDocument: true},
                ],
                method: "findOneAndUpdate",
              },
              {
                db: schState.db,
                collection: "doctors",
                parameters: [{did: appt.did}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "patients",
                parameters: [{pid: appt?.pid}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "insurers",
                parameters: [{iid: appt?.tpp || ""}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "doctors",
                parameters: [{did: appt.supervisorId || ""}],
                method: "findOne",
              },
            ])
              .then(async (res) => {
                if (res.data[1])
                  await sendInvEmail(
                    schState,
                    {
                      ...res.data[1],
                      paid: (res.data[1]?.paid || 0) + receiptAmount,
                    },
                    res.data[2],
                    res.data[3],
                    receiptAmount,
                    res.data[4],
                    res.data[5]
                  );
                await crud(schState, [
                  {
                    db: schState.db,
                    collection: "billing",
                    parameters: [
                      {
                        $and: [{aid: data.aid}, {cancelled: false}],
                      },
                      {
                        $set: {
                          paid: (res.data[1]?.paid || 0) + receiptAmount,
                        },
                      },
                      {returnNewDocument: true},
                    ],
                    method: "findOneAndUpdate",
                  },
                ]);
                await createPaymentRecord({
                  state: schState,
                  transaction: res.data[1],
                  description,
                  date: Date.now(),
                  amount: parseFloat(receiptAmount),
                  payer: "patient",
                });
                toast.close(generatingToast);
                socket?.emit?.("update_appt", updateBody, {});
                // setCloseModals(true);
                toast({
                  title: "The patient amount has been paid!",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
              })
              .catch(function (error) {
                toast({
                  title: "Payment Error",
                  description: error.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
                console.log(error);
              });
          } else if (result.isDenied) {
            const {value: amt} = await Swal.fire({
              title: "Please record the partial amount paid!",
              input: "text",
              inputValue: 0,
              showCancelButton: true,
              inputValidator: (value) => {
                if (!value || !value.match(/^[0-9]+(\.[0-9]+)?$/)) {
                  return "You must write a number!";
                } else if (
                  !isNaN(value) &&
                  appt?.patientAmount - appt?.amountPaidByPatient <
                    parseFloat(value)
                ) {
                  return "The amount entered is greater than the required payment!";
                }
              },
            });
            if (amt) {
              const generatingToast = toast({
                title: "Updating payment status.",
                status: "loading",
                variant: "subtle",
                duration: null,
                isClosable: true,
              });

              let amountPaidByPatient =
                parseFloat(appt.amountPaidByPatient) + parseFloat(amt);
              let data = {
                amountPaidByPatient,
                patientPaymentStatus:
                  amountPaidByPatient === parseFloat(appt.patientAmount)
                    ? "paid"
                    : "pending",
              };

              let tppAmount = parseFloat(appt?.tppAmount ?? 0);
              let amountPaidByTpp = parseFloat(appt?.amountPaidByTpp ?? 0);

              let otbi = tppAmount - amountPaidByTpp;

              data["paymentStatus"] =
                data.patientPaymentStatus === "paid" && otbi <= 0
                  ? "paid"
                  : "pending";
              if (isCashPayment) {
                data["paymentMethod"] = "cash";
              } else if (isCheckPayment) {
                data["paymentMethod"] = "check";
              }
              crud(schState, [
                {
                  db: schState.db,
                  collection: appt?.nonSessionService
                    ? "nonSessionServices"
                    : "appointments",
                  parameters: [
                    {aid: appt?.aid},
                    {$set: data},
                    {returnNewDocument: true},
                  ],
                  method: "findOneAndUpdate",
                },
                {
                  db: schState.db,
                  collection: "billing",
                  parameters: [
                    {aid: appt?.aid},
                    {
                      $set: data,
                    },
                    {returnNewDocument: true},
                  ],
                  method: "findOneAndUpdate",
                },
                {
                  db: schState.db,
                  collection: "doctors",
                  parameters: [{did: appt.did}],
                  method: "findOne",
                },
                {
                  db: schState.db,
                  collection: "patients",
                  parameters: [{pid: appt?.pid}],
                  method: "findOne",
                },
                {
                  db: schState.db,
                  collection: "insurers",
                  parameters: [{iid: appt?.tpp || ""}],
                  method: "findOne",
                },
                {
                  db: schState.db,
                  collection: "doctors",
                  parameters: [{did: appt.supervisorId || ""}],
                  method: "findOne",
                },
              ])
                .then(async (res) => {
                  dispatch({
                    type: "UPDATE_APPOINTMENT",
                    payload: res.data[0],
                  });

                  if (res.data[1] && res.data[0])
                    await createPaymentRecord({
                      state: schState,
                      transaction: res.data[1],
                      description,
                      date: Date.now(),
                      amount: parseFloat(amt),
                      payer: "patient",
                    });

                  sendInvEmail(
                    schState,
                    {
                      ...res.data[1],
                      paid:
                        (res.data[1]?.paid || 0) +
                        res.data[1]?.amountPaidByPatient,
                    },
                    res.data[2],
                    res.data[3],
                    parseFloat(amt),
                    res.data[4],
                    res.data[5]
                  );

                  if (res.data[1].patientPaymentStatus !== "paid") {
                    await patientInvoice({
                      state: schState,
                      transaction: res.data[1],
                      supervisor: data[5],
                      tpp: res.data[4],
                      doctor: res.data[2],
                      patient: res.data[3],
                      nonSessionService: res.data[1]?.nonSessionService,
                    });
                  }

                  await crud(schState, [
                    {
                      db: schState.db,
                      collection: "billing",
                      parameters: [
                        {aid: data.aid},
                        {
                          $set: {
                            paid:
                              (res.data[1]?.paid || 0) +
                              res.data[1]?.amountPaidByPatient,
                          },
                        },
                        {returnNewDocument: true},
                      ],
                      method: "findOneAndUpdate",
                    },
                  ]);
                  toast.close(generatingToast);
                  socket?.emit?.("update_appt", res.data[0], {});

                  toast({
                    title: `This ${
                      res.data[1]?.nonSessionService ? "service" : "appointment"
                    } has been partially paid!`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                })
                .catch(function (error) {
                  toast({
                    title: "Payment Error",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                  console.log(error);
                });
            }
          }
        });
      }
    }
  } catch (error) {
    toast({
      title: "Payment Error",
      description: error.message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
}

export function markAsSubmitted(appt, schState, dispatch, socket, toast) {
  let updateBody = {...appt, claim: true};

  crud(schState, [
    {
      db: schState.db,
      collection: appt?.nonSessionService
        ? "nonSessionServices"
        : "appointments",
      parameters: [{aid: appt.aid}, {$set: {claim: true}}],
      method: "updateOne",
    },
    {
      db: schState.db,
      collection: "billing",
      parameters: [
        {aid: appt.aid},
        {$set: {claim: true}},
        {returnNewDocument: true},
      ],
      method: "findOneAndUpdate",
    },
  ])
    .then(async (res) => {
      dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});

      socket?.emit?.("update_appt", updateBody, {});
      toast({
        title: `This ${
          appt?.nonSessionService ? "service" : "appointment"
        } has been marked as Submitted!`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function markAsRecieved(appt, schState, dispatch, socket, toast) {
  try {
    const {value: description} = await Swal.fire({
      input: "textarea",
      title: "Payment Description",
      inputPlaceholder: "Type the description...",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value.match(/\w+/)) {
          return "You must write a description!";
        }
      },
    });
    if (description) {
      Swal.fire({
        title: "Please select the appropriate option!",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Full amount",
        denyButtonText: `Partial amount`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const generatingToast = toast({
            title: "Updating payment status.",
            status: "loading",
            variant: "subtle",
            duration: null,
            isClosable: true,
          });
          let updateBody = {
            ...appt,
            received: true,
            tppPaymentStatus: "paid",
            amountPaidByTpp: appt?.tppAmount,
            paymentStatus:
              appt?.patientPaymentStatus === "paid" ? "paid" : "pending",
          };

          crud(schState, [
            {
              db: schState.db,
              collection: appt?.nonSessionService
                ? "nonSessionServices"
                : "appointments",
              parameters: [
                {aid: appt.aid},
                {
                  $set: {
                    received: true,
                    tppPaymentStatus: "paid",
                    amountPaidByTpp: appt?.tppAmount,
                    paymentStatus:
                      appt?.patientPaymentStatus === "paid"
                        ? "paid"
                        : "pending",
                  },
                },
              ],
              method: "updateOne",
            },
            {
              db: schState.db,
              collection: "billing",
              parameters: [
                {aid: appt.aid},
                {
                  $set: {
                    received: true,
                    tppPaymentStatus: "paid",
                    paymentStatus:
                      appt?.patientPaymentStatus === "paid"
                        ? "paid"
                        : "pending",
                    amountPaidByTpp: appt?.tppAmount,
                  },
                },
                {returnNewDocument: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: schState.db,
              collection: "doctors",
              parameters: [{did: appt.did}],
              method: "findOne",
            },
            {
              db: schState.db,
              collection: "patients",
              parameters: [{pid: appt?.pid}],
              method: "findOne",
            },
            {
              db: schState.db,
              collection: "insurers",
              parameters: [{iid: appt?.tpp}],
              method: "findOne",
            },
            {
              db: schState.db,
              collection: "doctors",
              parameters: [{did: appt.supervisorId || ""}],
              method: "findOne",
            },
          ])
            .then(async (res) => {
              await createPaymentRecord({
                state: schState,
                transaction: res.data[1],
                description,
                date: Date.now(),
                amount: parseFloat(res.data[1]?.tppAmount || 0),
                payer: "tpp",
              });

              let invData = {
                state: schState,
                transaction: res.data[1],
                doctor: res.data[2],
                patient: res.data[3],
                tpp: res.data[4],
                supervisor: res.data[5],
                receiptAmount: parseFloat(res.data[1]?.tppAmount || 0),
                nonSessionService: res.data[1]?.nonSessionService,
              };

              await insurerReceipt(invData);
              await fullReceipt(invData);

              dispatch({
                type: "UPDATE_APPOINTMENT",
                payload: {
                  ...appt,
                  received: true,
                  tppPaymentStatus: "paid",
                  amountPaidByTpp: appt?.tppAmount,
                },
              });
              toast.close(generatingToast);
              !appt?.nonSessionService &&
                socket?.emit?.("update_appt", updateBody, {});
              toast({
                title: `This ${
                  appt?.nonSessionService ? "service" : "appointment"
                } has been marked as received!`,
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (result.isDenied) {
          const {value: amt} = await Swal.fire({
            title: "Please record the partial amount paid!",
            input: "text",
            inputValue: 0,
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value || !value.match(/^[0-9]+(\.[0-9]+)?$/)) {
                return "You must write a number!";
              } else if (!isNaN(value) && appt?.tppAmount < parseFloat(value)) {
                return "The amount entered is greater than the required payment!";
              }
            },
          });
          if (amt) {
            const generatingToast = toast({
              title: "Updating payment status.",
              status: "loading",
              variant: "subtle",
              duration: null,
              isClosable: true,
            });
            let data = {
              received: true,
              tppPaymentStatus: "paid",
              amountPaidByTpp: parseFloat(amt),
              patientAmount:
                parseFloat(appt.patientAmount) +
                parseFloat(appt?.tppAmount) -
                parseFloat(amt),
              tppAmount: parseFloat(amt),
              patientPaymentStatus: "pending",
              paymentStatus: "pending",
            };

            crud(schState, [
              {
                db: schState.db,
                collection: appt?.nonSessionService
                  ? "nonSessionServices"
                  : "appointments",
                parameters: [
                  {aid: appt.aid},
                  {
                    $set: data,
                  },
                ],
                method: "updateOne",
              },
              {
                db: schState.db,
                collection: "billing",
                parameters: [
                  {aid: appt.aid},
                  {
                    $set: data,
                  },
                  {returnNewDocument: true},
                ],
                method: "findOneAndUpdate",
              },
              {
                db: schState.db,
                collection: "doctors",
                parameters: [{did: appt.did}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "patients",
                parameters: [{pid: appt?.pid}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "insurers",
                parameters: [{iid: appt?.tpp || ""}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "doctors",
                parameters: [{did: appt.supervisorId || ""}],
                method: "findOne",
              },
            ])
              .then(async (res) => {
                await createPaymentRecord({
                  state: schState,
                  transaction: res.data[1],
                  description,
                  date: Date.now(),
                  amount: parseFloat(amt),
                  payer: "tpp",
                });

                let invData = {
                  state: schState,
                  transaction: res.data[1],
                  doctor: res.data[2],
                  patient: res.data[3],
                  tpp: res.data[4],
                  supervisor: res.data[5],
                  receiptAmount: parseFloat(amt),
                  nonSessionService: res.data[1]?.nonSessionService,
                };

                await Promise.all([
                  insurerReceipt(invData),
                  patientInvoice(invData),
                  fullReceipt(invData),
                  fullInvoice(invData),
                ]);

                dispatch({
                  type: "UPDATE_APPOINTMENT",
                  payload: {
                    ...appt,
                    ...data,
                  },
                });
                toast.close(generatingToast);
                !appt?.nonSessionService &&
                  socket?.emit?.(
                    "update_appt",
                    {
                      ...appt,
                      ...data,
                    },
                    {}
                  );

                toast({
                  title: `This ${
                    appt?.nonSessionService ? "service" : "appointment"
                  }  has been marked as received!`,
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export async function markAsPaidConfirm({
  appt,
  schState,
  dispatch,
  socket,
  paymentMethod,
  toast,
  description,
  isPartial,
  partialAmount,
  selectedGiftCard,
}) {
  let generatingToast;
  try {
    if (appt && appt?.patientPaymentStatus !== "paid") {
      if (description) {
        if (paymentMethod === "giftCard") {
          if (!selectedGiftCard) {
            throw new Error("Select a valid gift card!");
          } else {
            let giftCardBalance = parseFloat(selectedGiftCard?.balance || 0);
            let ob = appt?.patientAmount - appt?.amountPaidByPatient;
            let amountToPay = isPartial
              ? parseFloat(partialAmount)
              : parseFloat(ob);
            if (amountToPay > giftCardBalance) {
              throw new Error("Insufficient balance to make this payment!");
            }
          }
        }

        let sendInvEmail = async (
          schState,
          transaction,
          doctor,
          patient,
          amountPaid,
          tpp,
          supervisor
        ) => {
          try {
            let invData = {
              state: schState,
              transaction,
              doctor,
              patient,
              tpp,
              supervisor,
              receiptAmount: amountPaid,
              nonSessionService: transaction.nonSessionService,
            };

            let blob = await patientReceipt(invData);
            await fullReceipt(invData);

            if (blob) {
              let dataURI = await new Promise((resolve, reject) => {
                try {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = function () {
                    resolve(reader.result);
                  };
                } catch (error) {
                  reject(error);
                }
              });
              let {date, time} = isoToApptDate(transaction.serviceDate);
              let location = await crud(schState, [
                {
                  db: schState.db,
                  collection: "locations",
                  parameters: [{lid: schState.selectedLocation}],
                  method: "findOne",
                },
              ]);
              location = location.data[0];
              let sender = location?.name;
              await sendInvRecEmail(schState, {
                invoice: false,
                date,
                time,
                pEmail: transaction.pEmail,
                pName: transaction.pName,
                dName: transaction.dName,
                sender: sender || schState?.organization?.name,
                attachments: [
                  {
                    filename: "Receipt.pdf",
                    path: dataURI,
                  },
                ],
              });
            }
          } catch (err) {
            throw new err();
          }
        };

        if (!isPartial) {
          generatingToast = toast({
            title: "Updating payment status.",
            status: "loading",
            variant: "subtle",
            duration: null,
            isClosable: true,
          });

          let patientAmount = parseFloat(appt?.patientAmount ?? 0);
          let tppAmount = parseFloat(appt?.tppAmount ?? 0);
          let amountPaidByPatient = parseFloat(appt?.patientAmount ?? 0);
          let amountPaidByTpp = parseFloat(appt?.amountPaidByTpp ?? 0);
          let otbp = patientAmount - amountPaidByPatient;
          let otbi = tppAmount - amountPaidByTpp;

          const updateBody = {
            ...appt,
            patientPaymentStatus: "paid",
            amountPaidByPatient: appt?.patientAmount,
            paymentStatus: parseFloat(otbi) <= 0 ? "paid" : "pending",
            paymentMethod:
              paymentMethod === "giftCard" ? "gift card" : paymentMethod,
          };
          let receiptAmount = appt?.patientAmount - appt?.amountPaidByPatient;
          let {_id, ...data} = updateBody;

          await crud(schState, [
            {
              db: schState.db,
              collection: appt?.nonSessionService
                ? "nonSessionServices"
                : "appointments",
              parameters: [{aid: data.aid}, {$set: data}],
              method: "updateOne",
            },
            {
              db: schState.db,
              collection: "billing",
              parameters: [
                {
                  $and: [{aid: data.aid}, {cancelled: false}],
                },
                {
                  $set: {
                    patientPaymentStatus: "paid",
                    amountPaidByPatient: appt?.patientAmount,
                    paymentStatus:
                      parseFloat(otbp) <= 0 && parseFloat(otbi) <= 0
                        ? "paid"
                        : "pending",
                    paymentMethod,
                  },
                },
                {returnNewDocument: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: schState.db,
              collection: "doctors",
              parameters: [{did: appt.did}],
              method: "findOne",
            },
            {
              db: schState.db,
              collection: "patients",
              parameters: [{pid: appt?.pid}],
              method: "findOne",
            },
            {
              db: schState.db,
              collection: "insurers",
              parameters: [{iid: appt?.tpp || ""}],
              method: "findOne",
            },
            {
              db: schState.db,
              collection: "doctors",
              parameters: [{did: appt.supervisorId || ""}],
              method: "findOne",
            },
          ])
            .then(async (res) => {
              if (res.data[1])
                sendInvEmail(
                  schState,
                  {
                    ...res.data[1],
                    paid: (res.data[1]?.paid || 0) + receiptAmount,
                  },
                  res.data[2],
                  res.data[3],
                  receiptAmount,
                  res.data[4],
                  res.data[5]
                );

              let updateObj = [
                {
                  db: schState.db,
                  collection: "billing",
                  parameters: [
                    {
                      $and: [{aid: data.aid}, {cancelled: false}],
                    },
                    {
                      $set: {
                        paid: (res.data[1]?.paid || 0) + receiptAmount,
                      },
                    },
                    {returnNewDocument: true},
                  ],
                  method: "findOneAndUpdate",
                },
              ];

              if (paymentMethod === "giftCard") {
                let giftCardBalance = parseFloat(selectedGiftCard.balance);
                let ob = appt?.patientAmount - appt?.amountPaidByPatient;
                let amountToPay = isPartial
                  ? parseFloat(partialAmount)
                  : parseFloat(ob);

                updateObj.push({
                  db: schState.db,
                  collection: "giftCards",
                  parameters: [
                    {gcId: selectedGiftCard.gcId},
                    {
                      $set: {
                        balance: Number(
                          (giftCardBalance - amountToPay).toFixed(2)
                        ),
                      },
                    },
                  ],
                  method: "findOneAndUpdate",
                });
              }
              await crud(schState, updateObj);

              createPaymentRecord({
                state: schState,
                transaction: res.data[1],
                description,
                date: Date.now(),
                amount: parseFloat(receiptAmount),
                payer: "patient",
              });
              toast.close(generatingToast);
              socket?.emit?.("update_appt", updateBody, {});
              // setCloseModals(true);
              toast({
                title: "The patient amount has been paid!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
            })
            .catch(function (error) {
              throw error;
            });
        } else {
          let amt = partialAmount;

          if (
            appt?.patientAmount - appt?.amountPaidByPatient <
            parseFloat(partialAmount)
          ) {
            throw new Error(
              "The amount entered is greater than the required payment!"
            );
          }

          if (amt) {
            generatingToast = toast({
              title: "Updating payment status.",
              status: "loading",
              variant: "subtle",
              duration: null,
              isClosable: true,
            });

            let amountPaidByPatient =
              parseFloat(appt.amountPaidByPatient) + parseFloat(amt);
            let data = {
              amountPaidByPatient,
              patientPaymentStatus:
                amountPaidByPatient === parseFloat(appt.patientAmount)
                  ? "paid"
                  : "pending",
            };

            let tppAmount = parseFloat(appt?.tppAmount ?? 0);
            let amountPaidByTpp = parseFloat(appt?.amountPaidByTpp ?? 0);

            let otbi = tppAmount - amountPaidByTpp;

            data["paymentStatus"] =
              data.patientPaymentStatus === "paid" && otbi <= 0
                ? "paid"
                : "pending";
            data["paymentMethod"] = paymentMethod;
            await crud(schState, [
              {
                db: schState.db,
                collection: appt?.nonSessionService
                  ? "nonSessionServices"
                  : "appointments",
                parameters: [
                  {aid: appt?.aid},
                  {$set: data},
                  {returnNewDocument: true},
                ],
                method: "findOneAndUpdate",
              },
              {
                db: schState.db,
                collection: "billing",
                parameters: [
                  {aid: appt?.aid},
                  {
                    $set: data,
                  },
                  {returnNewDocument: true},
                ],
                method: "findOneAndUpdate",
              },
              {
                db: schState.db,
                collection: "doctors",
                parameters: [{did: appt.did}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "patients",
                parameters: [{pid: appt?.pid}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "insurers",
                parameters: [{iid: appt?.tpp || ""}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "doctors",
                parameters: [{did: appt.supervisorId || ""}],
                method: "findOne",
              },
            ])
              .then(async (res) => {
                if (res.data[1] && res.data[0])
                  createPaymentRecord({
                    state: schState,
                    transaction: res.data[1],
                    description,
                    date: Date.now(),
                    amount: parseFloat(amt),
                    payer: "patient",
                  });

                sendInvEmail(
                  schState,
                  {
                    ...res.data[1],
                    paid:
                      (res.data[1]?.paid || 0) +
                      res.data[1]?.amountPaidByPatient,
                  },
                  res.data[2],
                  res.data[3],
                  parseFloat(amt),
                  res.data[4],
                  res.data[5]
                );

                if (res.data[1].patientPaymentStatus !== "paid") {
                  await patientInvoice({
                    state: schState,
                    transaction: res.data[1],
                    supervisor: data[5],
                    tpp: res.data[4],
                    doctor: res.data[2],
                    patient: res.data[3],
                    nonSessionService: res.data[1]?.nonSessionService,
                  });
                }
                let updateObj = [
                  {
                    db: schState.db,
                    collection: "billing",
                    parameters: [
                      {aid: data.aid},
                      {
                        $set: {
                          paid:
                            (res.data[1]?.paid || 0) +
                            res.data[1]?.amountPaidByPatient,
                        },
                      },
                      {returnNewDocument: true},
                    ],
                    method: "findOneAndUpdate",
                  },
                ];
                if (paymentMethod === "giftCard") {
                  let giftCardBalance = parseFloat(selectedGiftCard.balance);
                  let ob = appt?.patientAmount - appt?.amountPaidByPatient;
                  let amountToPay = isPartial
                    ? parseFloat(partialAmount)
                    : parseFloat(ob);

                  updateObj.push({
                    db: schState.db,
                    collection: "giftCards",
                    parameters: [
                      {gcId: selectedGiftCard.gcId},
                      {
                        $set: {
                          balance: Number(
                            (giftCardBalance - amountToPay).toFixed(2)
                          ),
                        },
                      },
                    ],
                    method: "findOneAndUpdate",
                  });
                }
                let o = await crud(schState, updateObj);
                dispatch({
                  type: "UPDATE_APPOINTMENT",
                  payload: res.data[0],
                });
                toast.close(generatingToast);
                socket?.emit?.("update_appt", res.data[0], {});

                toast({
                  title: `This ${
                    res.data[1]?.nonSessionService ? "service" : "appointment"
                  } has been partially paid!`,
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
              })
              .catch(function (error) {
                throw error;
              });
          }
        }
      }
    }
  } catch (error) {
    throw error;
  } finally {
    toast.close(generatingToast);
  }
}
