import {
  Box,
  Text,
  useToast,
  Center,
  Flex,
  Button,
  Badge,
  Icon,
  InputGroup,
  InputRightElement,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  StatGroup,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormLabel,
  FormControl,
  InputLeftAddon,
  Portal,
  Select,
  HStack,
  VStack,
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import React, {useState, useEffect, useRef, useMemo} from "react";
import fetchGiftCards from "../../GiftCards/helpers/fetchGiftCards";

export default function SelectGiftCard({
  state,
  setSelectedGiftCard,
  selectedGiftCard,
}) {
  const [searchText, setSearchText] = useState("");
  const [showSearch, setShowSearch] = useState("");
  const searchId = useRef();
  const initialFocusRef = React.useRef();
  const [giftCards, setGiftCards] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    fetchGiftCards(state, setGiftCards);
  }, []);

  let filteredList = (searchText) => {
    function filterText(card, text) {
      try {
        let regex = new RegExp(text, "i");
        if (text.length === 0) {
          return false;
        }
        return (
          card["fName"]?.match(regex) ||
          card["lName"]?.match(regex) ||
          card["secretCode"]?.match(regex)
        );
      } catch (error) {
        return false;
      }
    }

    return giftCards.filter((p) => filterText(p, searchText));
  };

  return (
    <Flex
      className="anim_height"
      w="full"
      align={"center"}
      justify={"space-between"}
    >
      <Popover
        placement="bottom"
        isOpen={showSearch}
        onClose={() => {
          setShowSearch(false);
        }}
      >
        <HStack id="search" gap={4} w="full" align={"flex-end"}>
          <FormControl isRequired flex={1}>
            <FormLabel fontSize={"sm"} ml={"1"} color={"gray.700"}>
              Secret Code
            </FormLabel>
            <PopoverAnchor>
              <InputGroup size="sm">
                <InputRightElement>
                  <Icon as={IoSearch} color="gray.500" />
                </InputRightElement>

                <Input
                  size="sm"
                  variant="outline"
                  rounded={"md"}
                  type="text"
                  onChange={(e) => {
                    clearTimeout(searchId.current);
                    searchId.current = setTimeout(() => {
                      let d = filteredList(e.target.value);
                      setList(d);
                      setShowSearch(d.length > 0);
                    }, 250);
                    setSelectedGiftCard(null);
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                  ref={initialFocusRef}
                  placeholder="Search..."
                />
              </InputGroup>
            </PopoverAnchor>
          </FormControl>
          {
            <HStack gap="1" flex={1} pb="1">
              <Text fontSize={"sm"} fontWeight={600} color={"gray.700"}>
                Balance:
              </Text>

              {selectedGiftCard && (
                <Badge
                  fontSize={"md"}
                  rounded={"md"}
                  variant="subtle"
                  colorScheme="gray"
                >
                  {`$${Number(selectedGiftCard.balance).toFixed(2)}`}
                </Badge>
              )}
            </HStack>
          }
        </HStack>

        <PopoverContent zIndex={99999}>
          <PopoverArrow />

          <PopoverBody>
            <TableContainer overflowY="auto" maxH={"200px"}>
              <Table size="md">
                <Tbody>
                  {list.map((card, i) => {
                    return (
                      <Tr
                        p="0"
                        cursor={"pointer"}
                        _hover={{
                          backgroundColor: "gray.200",
                        }}
                        key={i}
                        onClick={() => {
                          setSelectedGiftCard(card);
                          setSearchText(card.secretCode);
                          setShowSearch(false);
                        }}
                      >
                        <Td p="2" key={i} fontSize={"15px"}>
                          {card.secretCode}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}
