import {Button, Stack, Switch, Text, useToast} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {updatePreferences} from "./helpers/updatePreferences";
import {crud} from "../../../crudRequests";

const Preferences = ({dashState, patient}) => {
  const [email, setEmail] = useState(
    dashState?.patient?.emailPermission || false
  );
  const [text, setText] = useState(dashState?.patient?.textPermission || false);

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    crud(dashState, [
      {
        db: dashState.db,
        collection: "patients",
        parameters: [{pid: patient?.pid}],
        method: "findOne",
      },
    ]).then((res) => {
      if (
        res?.data?.[0]?.emailPermission !== undefined &&
        res?.data?.[0]?.textPermission !== undefined
      ) {
        setEmail(res?.data?.[0]?.emailPermission);
        setText(res?.data?.[0]?.textPermission);
      }
    });
  }, [dashState]);

  return (
    // <Center>
    <Stack direction="column" paddingX="4rem" paddingY="2rem">
      <Stack direction="row">
        <Button
          isDisabled={loading}
          onClick={() => {
            setLoading(true);
            setText(!text);
            toast.promise(
              updatePreferences(dashState, patient?.pid || "", email, !text)
                .then(() => setLoading(false))
                .catch(() => setLoading(false)),
              {
                success: {
                  title: "Success",
                  description: "Preference updated",
                },
                error: {
                  title: "Error",
                  description: "Something wrong",
                },
                loading: {title: "Loading...", description: "Please wait"},
              }
            );
          }}
        >
          <Switch id="text-alerts" isChecked={text} isDisabled={loading} />
        </Button>
        <Text htmlFor="text-alerts" mb="0" marginLeft="2">
          Enable text notifications
        </Text>{" "}
      </Stack>
      <Stack direction="row">
        <Button
          isDisabled={loading}
          onClick={() => {
            setLoading(true);
            setEmail(!email);
            toast.promise(
              updatePreferences(dashState, patient?.pid || "", !email, text)
                .then(() => setLoading(false))
                .catch(() => setLoading(false)),
              {
                success: {
                  title: "Success",
                  description: "Preference updated",
                },
                error: {
                  title: "Error",
                  description: "Something wrong",
                },
                loading: {title: "Loading...", description: "Please wait"},
              }
            );
          }}
        >
          <Switch id="email-alerts" isChecked={email} />
        </Button>
        <Text htmlFor="email-alerts" mb="0" marginLeft="2">
          Enable email alerts
        </Text>
      </Stack>
    </Stack>
  );
};

export default Preferences;
