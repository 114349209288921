import {getWaveBlob} from "webm-to-wav-converter";
import {processAudio} from "./processAudio";

export default async function startRecording(
  selectedAppointment,
  setSummaries,
  dashState,
  setOpenMic,
  setProgress,
  setElapsedTime,
  audioChunksRef,
  mediaRecorderRef,
  abort,
  progressId,
  initialTimestamp,
  previousElapsedTime
) {
  try {
    if (!mediaRecorderRef.current) {
      abort.current = false;
      let stream = await navigator.mediaDevices.getUserMedia({audio: true});
      setOpenMic(false);
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm",
      });

      mediaRecorderRef.current.ondataavailable = function (event) {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async function () {
        try {
          if (!abort.current) {
            const audioBlob = await getWaveBlob(
              new Blob(audioChunksRef.current, {
                type: "audio/webm",
              }),
              false
            );
            setProgress(0);

            const diarization = await processAudio(
              dashState,
              audioBlob,
              selectedAppointment,
              (progressEvent) =>
                setProgress((progressEvent.loaded / progressEvent.total) * 100)
            );

            console.log(diarization);

            setSummaries((prevSummaries) => {
              const updatedSummaries = prevSummaries.map((summary) => {
                if (summary.aid === selectedAppointment) {
                  return {
                    ...summary,
                    diarizedTranscript: diarization.data.transcript,
                    diarizationProcessedDatetime: diarization.data.datetime,
                  };
                }
                return summary;
              });
              return updatedSummaries;
            });

            audioChunksRef.current = [];
            mediaRecorderRef.current = null;
          }
        } catch (error) {
          console.log(error);
        }
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        stream.getTracks()[0].stop();
        audioChunksRef.current = [];
        mediaRecorderRef.current = null;
      };
      mediaRecorderRef.current.error = function (event) {
        console.log(event.error.name);
      };
      mediaRecorderRef.current.start();
    }
  } catch (error) {
    console.log(error);
    return;
  }
  setOpenMic(false);
  initialTimestamp.current = Date.now();
  progressId.current = setInterval(() => {
    const elapsedTime =
      Date.now() - initialTimestamp.current + previousElapsedTime.current;
    setElapsedTime(elapsedTime);
  }, 1000);
}
