import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  Select,
  useToast,
  Text,
  InputGroup,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {updatePatient} from "./helpers/updatePatient";
import fetchReferralVia from "./helpers/fetchReferralVia";
export default function Personal() {
  const [patient, setPatient] = useContext(PatientContext);
  const dispatch = useContext(DispatchContext);
  const dashState = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [referralVia, setReferralVia] = useState([]);

  useEffect(() => {
    fetchReferralVia(dashState, setReferralVia);
  }, []);

  return (
    <form
      onSubmit={(e) =>
        updatePatient(e, patient, dashState, setIsLoading, toast, dispatch)
      }
    >
      <VStack spacing={4}>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={6}
        >
          {/* Row 1 */}
          <FormControl id="firstName" isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="John"
              value={patient.fName}
              onChange={(e) => {
                setPatient({...patient, fName: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="middleName">
            <FormLabel>Middle Name</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="-"
              value={patient.mName ?? ""}
              onChange={(e) => {
                setPatient({...patient, mName: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="lastName" isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="Doe"
              value={patient.lName}
              onChange={(e) => {
                setPatient({...patient, lName: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="preferredName">
            <FormLabel>Preferred Name</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="Joe"
              value={patient.preferredName}
              onChange={(e) => {
                setPatient({...patient, preferredName: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="preferredPronoun">
            <FormLabel>Preferred Pronoun</FormLabel>
            <Select
              variant="flushed"
              placeholder="-"
              value={patient.preferredPronoun}
              onChange={(e) => {
                setPatient({...patient, preferredPronoun: e.target.value});
              }}
            >
              <option value="he/him">he/him</option>
              <option value="she/her">she/her</option>
              <option value="they/them">they/them</option>
            </Select>
          </FormControl>

          <FormControl id="referralVia">
            <FormLabel>Referral Via</FormLabel>
            <Select
              variant="flushed"
              placeholder="-"
              value={patient.referralVia}
              onChange={(e) => {
                setPatient({
                  ...patient,
                  referralVia: e.target.value,
                });
              }}
            >
              {referralVia.map((rf, i) => (
                <option p="1" key={i} value={rf.name}>
                  {rf.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl id="age" isRequired>
            <FormLabel>Date of Birth</FormLabel>
            <Input
              variant="flushed"
              type="date"
              value={patient.dob}
              onChange={(e) => {
                setPatient({...patient, dob: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="gender" isRequired>
            <FormLabel>Gender</FormLabel>
            <Select
              variant="flushed"
              placeholder="-"
              value={patient.gender}
              onChange={(e) => {
                setPatient({...patient, gender: e.target.value});
              }}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Trans Man">Trans Man</option>
              <option value="Trans Women">Trans Women</option>
            </Select>
          </FormControl>

          {/* Row 2 */}
          <FormControl id="address">
            <FormLabel>Address 1</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="123 Elm St."
              value={patient.address1}
              onChange={(e) => {
                setPatient({...patient, address1: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="address">
            <FormLabel>Address 2</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="Unit 1"
              value={patient.address2}
              onChange={(e) => {
                setPatient({...patient, address2: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="city">
            <FormLabel>City</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="New York"
              value={patient.city}
              onChange={(e) => {
                setPatient({...patient, city: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="province">
            <FormLabel>Province</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="NY"
              value={patient.province}
              onChange={(e) => {
                setPatient({...patient, province: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="postalCode">
            <FormLabel>Postal Code</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="10001"
              value={patient.postalCode}
              onChange={(e) => {
                setPatient({...patient, postalCode: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="country">
            <FormLabel>Country</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="United States of America"
              value={patient.country}
              onChange={(e) => {
                setPatient({...patient, country: e.target.value});
              }}
            />
          </FormControl>

          {/* Row 3 */}
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              variant="flushed"
              type="email"
              placeholder="john.doe@example.com"
              value={patient.email}
              onChange={(e) => {
                setPatient({...patient, email: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="phoneNumber">
            <FormLabel>Phone Number (Cell)</FormLabel>
            <Input
              variant="flushed"
              type="tel"
              placeholder="123-456-7890"
              value={patient.phone}
              onChange={(e) => {
                setPatient({...patient, phone: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="phoneNumberHome">
            <FormLabel>Phone Number (Home)</FormLabel>
            <Input
              variant="flushed"
              type="tel"
              placeholder="123-456-7890"
              value={patient.homePhone}
              onChange={(e) => {
                setPatient({...patient, homePhone: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="phoneNumberAlternative">
            <FormLabel>Phone Number (Alternative)</FormLabel>
            <Input
              variant="flushed"
              type="tel"
              placeholder="123-456-7890"
              value={patient.alternativePhone}
              onChange={(e) => {
                setPatient({...patient, alternativePhone: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="emergencyContactName">
            <FormLabel>Emergency Contact Name</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="John Doe"
              value={patient.emergencyContactName}
              onChange={(e) => {
                setPatient({...patient, emergencyContactName: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="emergencyContactEmail">
            <FormLabel>Emergency Contact Email</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="john.doe@example.com"
              value={patient.emergencyContactEmail}
              onChange={(e) => {
                setPatient({...patient, emergencyContactEmail: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="emergencyContactPhone">
            <FormLabel>Emergency Contact Phone</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="123-456-7689"
              value={patient.emergencyContactPhone}
              onChange={(e) => {
                setPatient({...patient, emergencyContactPhone: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="emergencyContactRelation">
            <FormLabel>Emergency Contact Relation</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="Uncle"
              value={patient.emergencyContactRelation}
              onChange={(e) => {
                setPatient({
                  ...patient,
                  emergencyContactRelation: e.target.value,
                });
              }}
            />
          </FormControl>

          <FormControl id="height">
            <FormLabel>Height</FormLabel>
            <InputGroup>
              <Input
                variant="flushed"
                type="text"
                placeholder="175"
                value={patient.height}
                onChange={(e) => {
                  setPatient({...patient, height: e.target.value});
                }}
              />
              <Text fontWeight={"bold"} position="absolute" right="0" top="2">
                cm
              </Text>
            </InputGroup>
          </FormControl>

          <FormControl id="weight">
            <FormLabel>Weight</FormLabel>
            <InputGroup>
              <Input
                variant="flushed"
                type="text"
                placeholder="68"
                value={patient.weight}
                onChange={(e) => {
                  setPatient({...patient, weight: e.target.value});
                }}
              />
              <Text fontWeight={"bold"} position="absolute" right="0" top="2">
                kg
              </Text>
            </InputGroup>
          </FormControl>
        </Grid>
        <Button
          isLoading={isLoading && true}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Save
        </Button>
      </VStack>
    </form>
  );
}
