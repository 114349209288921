import {crudStorage} from "./crudStorage";

export function viewFiles(dashState, setFiles, loading, path, toast) {
  crudStorage(dashState, "view", "", "brightlighthealth", path)
    .then((res) => {
      setFiles(res.data);
      loading(false);
    })
    .catch((err) => {
      setFiles([]);
      loading(false);
      /*path.split("/").filter(Boolean).length > 1 && // if path is not root (due to first time loading when no azure folder for patient exists)
        toast({
          title: "Failed to fetch files from azure storage",
          description:
            err?.message || "An error occurred while fetching files.",
          status: "error",
          isClosable: true,
        });*/
    });
}
