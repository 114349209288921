import axios from "axios";
import {fetchRelations} from "./fetchRelations";

import {url} from "../../../../crudRequests";

export async function addRelation(
  e,
  newRelation,
  dashState,
  setRelations,
  patient,
  setIsLoading,
  toast
) {
  setIsLoading(true);
  e.preventDefault();
  axios({
    method: "POST",
    url: `${url}/addRelationship`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + dashState.jwt,
    },
    data: JSON.stringify({
      id: dashState.doctor?.did || dashState.admin?.id,
      addBody: newRelation,
      db: dashState.db,
      org: dashState.organization,
    }),
  })
    .then((res) => {
      setIsLoading(false);
      toast({
        title: "Relation Added",
        description: "Relation successfully added",
        status: "success",
        isClosable: true,
      });
      fetchRelations(setRelations, dashState, patient, toast);
    })
    .catch((err) => {
      setIsLoading(false);
      toast({
        title: "Relation Not Added",
        description:
          err?.response?.data || err?.message || "An error occurred.",
        status: "error",
        isClosable: true,
      });
      console.log(err);
    });
}
