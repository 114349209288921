import {useEffect, useState} from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  IconButton,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Tooltip,
  Icon,
  HStack,
  VStack,
} from "@chakra-ui/react";
import {addWeeks, addDays, lightFormat} from "date-fns";
import moment from "moment";
import {FaAnglesRight, FaAnglesLeft} from "react-icons/fa6";
import generateAvailability from "./helpers/generateAvailability";
import {Loader2} from "./Loader";
export default function AvailabilityListMultipleClinicians({
  state,
  clinician,
  duration,
  onSelectAvailability,
  service,
}) {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pointer, setPointer] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const count = 5;
  useEffect(() => {
    if (!clinician && service) {
      setIsLoading(true);
      setPointer(0);
      getMultipleClinicianList({
        start: new Date(),
        count,
        state,
        duration,
        service,
      }).then((e) => {
        setIsLoading(false);
        setList(e);
      });
    }
  }, [duration, service]);

  let pageItems = list.slice(pointer, pointer + count);

  return (
    <VStack w="full" gap="4" mt="6">
      <TableContainer w="full" overflowY="auto" minH={"100px"} maxH={"300px"}>
        <Table size="md" rounded={"md"}>
          <Thead position="sticky" bg="#9F9DFA" zIndex={10} top="0">
            <Tr color={"white"}>
              <Th color={"white"}>Clinician</Th>
              <Th color={"white"}>Date</Th>
              <Th color={"white"}>Time</Th>
            </Tr>
          </Thead>
          <Tbody border="1px solid gray.200" bg={"white"} rounded={"md"}>
            {isLoading ? (
              <Loader2 />
            ) : (
              pageItems?.map((e, i) => {
                return (
                  <Tr
                    p="0"
                    cursor={"pointer"}
                    _hover={{
                      backgroundColor: "gray.200",
                    }}
                    key={e}
                    onClick={() => {
                      try {
                        e.interval?.[0] &&
                          onSelectAvailability(e.interval?.[0], e.clinician);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    <Td p="2" fontSize={"15px"} fontWeight={600}>
                      {`${e.clinician?.lastName || ""}, ${
                        e.clinician?.firstName || ""
                      }`}
                    </Td>
                    <Td p="2" fontSize={"15px"} fontWeight={600}>
                      {e.interval?.[0]
                        ? moment(e.interval?.[0]).format("MMMM Do YYYY")
                        : ""}
                    </Td>
                    <Td p="2" fontSize={"15px"} fontWeight={600}>
                      {e.interval?.[0]
                        ? `${moment(e.interval?.[0]).format(
                            "h:mm a"
                          )} - ${moment(e.interval?.[1]).format("h:mm a")}`
                        : ""}
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <HStack w="full" justify={"space-between"}>
        <IconButton
          isDisabled={disabled}
          variant="outline"
          colorScheme="blue"
          aria-label="Previous"
          fontSize="20px"
          icon={<FaAnglesLeft />}
          onClick={async () => {
            if (disabled) return;
            let newPointer = pointer - count;
            if (list[newPointer]) {
              setPointer(newPointer);
            }
          }}
        />
        <IconButton
          isDisabled={disabled}
          variant="outline"
          colorScheme="blue"
          aria-label="Next"
          fontSize="20px"
          icon={<FaAnglesRight />}
          onClick={async () => {
            if (disabled) return;
            setDisabled(true);

            let newPointer = pointer + count;
            if (list[newPointer]) {
              setPointer(newPointer);
            }

            setDisabled(false);
            setIsLoading(false);
          }}
        />
      </HStack>
    </VStack>
  );
}

async function getList({start, count, state, clinician, duration}) {
  let list = [];

  try {
    let n = 1;
    while (list.length < count) {
      list = await generateAvailability({
        state,
        start,
        end: addWeeks(start, n),
        clinician,
        duration: duration.split(" ")[0],
      });
      n++;
    }
  } catch (error) {
    console.log(error);
  } finally {
    return list;
  }
}

async function getMultipleClinicianList({
  start,
  count,
  state,
  duration,
  service,
}) {
  let list = [];
  try {
    let clinicians = (state.doctors || []).filter((d) =>
      (d.services || []).includes(service?.serviceId)
    );
    list = await Promise.all(
      clinicians.map(
        (e) =>
          new Promise((res, _) => {
            getList({start, count, state, clinician: e, duration}).then((l) => {
              res(l.map((intv) => ({clinician: e, interval: intv})));
            });
          })
      )
    );
    list = list.flat();
    list.sort((a, b) => a.interval[0] - b.interval[0]);
  } catch (error) {
    console.log(error);
  } finally {
    return list;
  }
}
