import {MdOutlineStickyNote2} from "react-icons/md";
import React, { memo } from "react";

export const NotePanel = memo(({value, onChange, saveNoteData, edit, loading}) => (
  <div
    className="relative flex transition-all justify-center items-start flex-col h-full rounded-md w-full"
    htmlFor="time"
  >
    {edit && (
      <span
        className="flex absolute cursor-pointer hover:text-indigo-600 -top-[18px] right-3 justify-center items-center text-[#a6a3f8] text-[1.7rem] bg-white"
        onClick={(e) => {
          e.stopPropagation();
          if (!loading) {
            saveNoteData();
          }
        }}
      >
        {<MdOutlineStickyNote2 />}
      </span>
    )}
    <textarea
      value={value}
      onChange={onChange}
      width="100%"
      className="h-[4.5rem] bg-white w-full rounded-md block px-3 py-2.5 text-sm text-off font-normal border border-gray-200 focus:outline-none focus:border-[#D2D2FF]"
    />
  </div>
));

