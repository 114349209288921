import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";

export default function SummaryConsentModal({
  summaryConsent,
  setSummaryConsent,
  setConsentConfirmed,
}) {
  return (
    <Modal
      isOpen={summaryConsent}
      onClose={() => setSummaryConsent(null)}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Consent for Recording and Summarizing Clinical Appointments
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Before proceeding with the recording and summarization of clinical
          appointments, I, as the care provider or part of the clinical staff,
          hereby confirm that I have obtained explicit permission from the
          patient to do so. I understand and acknowledge that patient privacy
          and confidentiality are of utmost importance, and I am committed to
          complying with all applicable laws and ethical guidelines in this
          regard.
          <br />
          <br />I understand that the purpose of this recording and
          summarization is to improve the quality of care, facilitate accurate
          documentation, and potentially support research efforts. I am aware
          that all patient data will be handled with the highest level of
          confidentiality and that any data shared for research purposes will be
          anonymized to protect patient identity.
          <br />
          <br />
          By clicking confirm, I confirm that I have obtained the patient's
          consent for the recording and summarization of clinical appointments
          and that I will adhere to all legal and ethical requirements in
          handling patient data.
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              setSummaryConsent(null);
              setConsentConfirmed(true);
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
