import {View, Text, Image} from "@react-pdf/renderer";

export default function ServicesTable(data) {
  let headers = data.headers;
  let rowsData = data.rowsData;
  let {serviceSubTotal, serviceTax, serviceTaxAmount, serviceTotal} = data;
  let style2 = {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderBottom: "1px solid #000",
    paddingVertical: 10,
  };
  let style1 = {
    paddingHorizontal: 15,
    marginTop: "10px",
  };
  let style3 = {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderBottom: "1px solid #000",
  };

  let style4 = {
    flex: 1,
    fontSize: 12,
    paddingRight: 10,
  };

  let style5 = {
    padding: 10,
    fontSize: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
  };
  let style6 = {
    padding: 2,
    fontWeight: 500,
    fontSize: 12,
  };
  let style7 = {
    display: "flex",
    flexDirection: "row",
    padding: 2,

    justifyContent: "flex-end",
    alignItems: "center",
    gap: 3,
  };
  return (
    <View style={style1}>
      <Text style={{fontSize: 15, fontWeight: "bold", color: "#312e81"}}>
        Services
      </Text>

      <View>
        <View style={style2}>
          {headers.map((e, i) =>
            i === 1 ? (
              <Text key={i} style={{...style4, fontWeight: 900, minWidth: 90}}>
                {e}
              </Text>
            ) : (
              <Text key={i} style={{...style4, fontWeight: 900}}>
                {e}
              </Text>
            )
          )}
        </View>
        {rowsData.map((e, i) => (
          <View style={style2} key={i}>
            {e.map((d, j) =>
              j === 1 ? (
                <Text
                  style={{...style4, fontWeight: 500, minWidth: 90}}
                  key={j}
                >
                  {d}
                </Text>
              ) : (
                <Text style={{...style4, fontWeight: 500}} key={j}>
                  {d}
                </Text>
              )
            )}
          </View>
        ))}
      </View>
      <View
        style={{
          with: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          marginTop: 15,
          gap: 10,
          marginRight: 35,
        }}
      >
        <View style={style5}>
          <View style={style6}>
            <Text>Service Subtotal</Text>
          </View>
          <View style={style6}>
            <Text>{`Service Tax (${serviceTax}%)`}</Text>
          </View>
          <View style={style6}>
            <Text>Service Total</Text>
          </View>
        </View>

        <View style={style5}>
          <View style={style6}>
            <Text>{`$${parseFloat(serviceSubTotal).toFixed(2)}`}</Text>
          </View>
          <View style={style6}>
            <Text>{`$${parseFloat(serviceTaxAmount).toFixed(2)}`}</Text>
          </View>
          <View style={{...style6, fontWeight: 900}}>
            <Text>{`$${parseFloat(serviceTotal).toFixed(2)}`}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
