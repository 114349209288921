import {
  Box,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Button,
  Badge,
  Tooltip,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  HStack,
  Text,
  CheckboxGroup,
  Checkbox,
  VStack,
  Select,
  FormControl,
  Radio,
} from "@chakra-ui/react";
import {useState} from "react";
import {monthSecondCriteria, weekday} from "./helpers/criteria";

const dayOpt = ["first", "second", "third", "fourth", "last"];

export default function Monthly({date, recurrentCriteriaRef}) {
  let day = {
    every: 1,
    day: date ? new Date(date).getDate?.() || "" : new Date().getDate?.(),
  };
  let the = {
    ...monthSecondCriteria(date),
  };

  const [criteria1, setCriteria1] = useState(day);
  const [criteria2, setCriteria2] = useState(the);
  const [selected, setSelected] = useState("criteria1");

  recurrentCriteriaRef.current.frequency = "monthly";
  recurrentCriteriaRef.current.pattern =
    selected === "criteria1" ? criteria1 : criteria2;

  return (
    <VStack fontSize={"sm"} gap={3} align={"self-start"}>
      <HStack
        w="full"
        gap="2"
        align={"center"}
        fontWeight={600}
        color={selected === "criteria1" ? "gray.600" : "gray.400"}
        fontSize={"xs"}
      >
        <Radio
          size={"sm"}
          onChange={(e) => {
            setSelected("criteria1");
            setCriteria2(the);
          }}
          isChecked={selected === "criteria1"}
          value="day"
        >
          <Text fontSize={"xs"}>Day</Text>
        </Radio>
        <FormControl
          display={"flex"}
          gap={2}
          alignItems={"center"}
          flex={1}
          id="day"
          isDisabled={selected !== "criteria1"}
        >
          <NumberInput
            size="xs"
            w="10"
            max={31}
            min={1}
            clampValueOnBlur={false}
            value={criteria1.day}
            onBlur={() => {
              if (!Number(criteria1.day))
                setCriteria1((prev) => {
                  return {
                    ...prev,
                    day: day.day,
                  };
                });
            }}
            onChange={(e) =>
              setCriteria1((prev) => {
                if (e.match(/^\d{0,2}$/)) {
                  let v = e.replace(/[-+,.]/g, "");
                  if (Number(v) > 31) return prev;
                  return {
                    ...prev,
                    day: v,
                  };
                } else return prev;
              })
            }
          >
            <NumberInputField textAlign={"end"} p="1" />
          </NumberInput>

          <Text>of every</Text>
          <NumberInput
            size="xs"
            w="10"
            max={31}
            min={1}
            value={criteria1.every}
            onBlur={() => {
              if (!criteria1?.every)
                setCriteria1((prev) => {
                  return {
                    ...prev,
                    every: 1,
                  };
                });
            }}
            onChange={(e) =>
              setCriteria1((prev) => {
                if (e.match(/^\d{0,2}$/)) {
                  let v = e.replace(/[-+,.]/g, "");
                  if (Number(v) > 12) return prev;
                  return {
                    ...prev,
                    every: v,
                  };
                } else return prev;
              })
            }
          >
            <NumberInputField textAlign={"end"} p="1" />
          </NumberInput>
          <Text>month(s)</Text>
        </FormControl>
      </HStack>

      <HStack
        w="full"
        gap="2"
        align={"center"}
        fontWeight={600}
        color={selected !== "criteria1" ? "gray.600" : "gray.400"}
        fontSize={"xs"}
      >
        <Radio
          size={"sm"}
          onChange={(e) => {
            setSelected("criteria2");
            setCriteria1(day);
          }}
          isChecked={selected === "criteria2"}
          value="day"
        >
          <Text>The</Text>
        </Radio>
        <FormControl
          flex={1}
          display={"flex"}
          gap={2}
          alignItems="center"
          id="the"
          isDisabled={selected === "criteria1"}
        >
          <Select
            size="xs"
            variant="outline"
            value={criteria2.the}
            onChange={(e) => {
              setCriteria2((prev) => {
                return {...prev, the: e.target.value};
              });
            }}
          >
            {dayOpt.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </Select>
          <Select
            size="xs"
            variant="outline"
            value={criteria2.weekday}
            onChange={(e) => {
              setCriteria2((prev) => {
                return {...prev, weekday: e.target.value};
              });
            }}
          >
            {weekday.map((e, i) => (
              <option key={i} value={e}>
                {e.toLowerCase()}
              </option>
            ))}
          </Select>
          <Text>of every</Text>
          <NumberInput
            size="xs"
            w="10"
            max={12}
            min={1}
            flexShrink={0}
            value={criteria2.every}
            onBlur={() => {
              if (!criteria2?.every)
                setCriteria2((prev) => {
                  return {
                    ...prev,
                    every: 1,
                  };
                });
            }}
            onChange={(e) =>
              setCriteria2((prev) => {
                if (e.match(/^\d{0,2}$/)) {
                  let v = e.replace(/[-+,.]/g, "");
                  if (Number(v) > 12) return prev;
                  return {
                    ...prev,
                    every: v,
                  };
                } else return prev;
              })
            }
          >
            <NumberInputField textAlign={"end"} p="1" />
          </NumberInput>
          <Text>month(s)</Text>
        </FormControl>
      </HStack>
    </VStack>
  );
}
