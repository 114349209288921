import axios from "axios";
import {url} from "../../../../crudRequests";

export async function updatePreferences(state, pid, email, text) {
  let configService = {
    method: "POST",
    url: `${url}/updatePreferences`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: {
      pid,
      db: state.db,
      email,
      text,
    },
  };
  return axios(configService).then((res) => {
    return res;
  });
}
