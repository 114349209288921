import {useState, useRef, useContext, useEffect, useMemo} from "react";
import createPatient from "./helpers/createPatient";
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Input,
  PopoverFooter,
  Flex,
  ButtonGroup,
  useToast,
  FormControl,
  FormLabel,
  Select,
  Switch,
  VStack,
} from "@chakra-ui/react";
import {DashStateContext} from "../../pages/Patients";
import fetchReferralVia from "./Information/helpers/fetchReferralVia";
import {SearchableSelect} from "../SearchableSelect";
import {alphabeticalSort} from "../Tasks/helpers/alphabeticalSort";
export default function InvitePatient({dispatch, setPatient}) {
  const dashState = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [referralVia, setReferralVia] = useState([]);
  useEffect(() => {
    fetchReferralVia(dashState, setReferralVia);
  }, []);
  const [fields, setFields] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
    referralVia: "",
    welcome: true,
    did: "",
  });
  const createClose = useRef();
  const toast = useToast();
  const sortedDoctorOptions = useMemo(() => {
    return alphabeticalSort(dashState.doctors ?? [dashState.doctor], "lastName").map((option) => ({
      value: option.did,
      label: `${option.lastName}, ${option.firstName}`,
    }));
  }, [dashState.doctor?.did, dashState.doctors?.length]);

  return (
    <Popover placement="right-start">
      <PopoverTrigger>
        <Button px="12" colorScheme="blue">
          Add a new patient
        </Button>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Add Patient
        </PopoverHeader>
        <PopoverCloseButton ref={createClose} />
        <PopoverBody>
          <VStack>
            <Input
              variant="flushed"
              placeholder="First Name"
              my="2"
              value={fields.fName}
              onChange={(e) => setFields({...fields, fName: e.target.value})}
            />
            <Input
              variant="flushed"
              placeholder="Last Name"
              my="2"
              value={fields.lName}
              onChange={(e) => setFields({...fields, lName: e.target.value})}
            />
            <Input
              variant="flushed"
              placeholder="Email"
              my="2"
              value={fields.email}
              onChange={(e) => setFields({...fields, email: e.target.value})}
            />
            <Input
              variant="flushed"
              type="number"
              placeholder="Phone"
              my="2"
              value={fields.phone}
              onChange={(e) => setFields({...fields, phone: e.target.value})}
            />
            {dashState?.doctors?.length > 0 ? (
              <FormControl my="2">
                <SearchableSelect
                  label="Clinician"
                  placeholder="-"
                  options={sortedDoctorOptions}
                  onChange={(e) => {
                    setFields({...fields, did: e.value});
                  }}
                />
              </FormControl>
            ) : null}
            <FormControl id="referralVia" my="2">
              <FormLabel color={"gray.400"} mb="0" fontWeight={"normal"}>
                Referral Via
              </FormLabel>
              <Select
                variant="flushed"
                placeholder="-"
                value={fields.referralVia}
                onChange={(e) => {
                  setFields({...fields, referralVia: e.target.value});
                }}
              >
                {referralVia.map((rf, i) => (
                  <option p="1" key={i} value={rf.name}>
                    {rf.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="welcome-message" mb="0">
                Send welcome message?
              </FormLabel>
              <Switch
                isChecked={fields.welcome}
                onChange={(e) => {
                  setFields({...fields, welcome: e.target.checked});
                }}
                id="welcome-message"
              />
            </FormControl>
          </VStack>
        </PopoverBody>
        <PopoverFooter border="0">
          <Flex justifyContent="end">
            <ButtonGroup size="sm">
              <Button
                colorScheme="blue"
                variant="ghost"
                onClick={() => {
                  createClose.current.click();
                }}
              >
                Go Back
              </Button>
              <Button
                isLoading={isLoading}
                colorScheme="blue"
                onClick={() => {
                  createPatient(
                    dashState,
                    dispatch,
                    setPatient,
                    fields,
                    setFields,
                    createClose,
                    setIsLoading,
                    toast
                  );
                }}
              >
                Add Patient
              </Button>
            </ButtonGroup>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
