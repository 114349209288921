import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Icon,
  IconButton,
  Center,
  InputGroup,
  InputRightElement,
  VStack,
  StackDivider,
  Text,
  Textarea,
} from "@chakra-ui/react";
import {BsPlusCircleDotted} from "react-icons/bs";
import {AiFillDelete} from "react-icons/ai";
import {useEffect, useRef, useState} from "react";
import {drugAutocomplete} from "../../../../crudRequests";
import {SearchIcon} from "@chakra-ui/icons";

export default function UnlimitedItemFields({
  label,
  items,
  setItems,
  fields,
  dashState,
}) {
  const handleOnChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };
  const handleRemove = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };
  return (
    <FormControl id={label} isDisabled={!items} mt="5">
      <FormLabel textAlign={"center"}>{label}</FormLabel>
      {items &&
        items.map((item, index) => (
          <Box display="flex" key={index} mt={4}>
            <Box w={"80%"} mr={5}>
              <Box
                gap={2}
                display={{base: "block", sm: "flex"}}
                textAlign={"center"}
              >
                {fields.map(({name, placeholder, type}) => {
                  if (type === "medicationSelect") {
                    return (
                      <MedicationAutoComplete
                        dashState={dashState}
                        initialValue={item[name]}
                        onChange={(e) => {
                          handleOnChange(index, name, e.brand_name);
                        }}
                      />
                    );
                  }
                  return (
                    <Input
                      key={name}
                      variant="flushed"
                      type={type}
                      placeholder={placeholder}
                      value={item[name]}
                      onChange={(e) =>
                        handleOnChange(index, name, e.target.value)
                      }
                    />
                  );
                })}
              </Box>
              <Textarea
                key="note"
                placeholder='Note'
                resize="none"
                variant="flushed"
                value={item.note}
                onChange={(e) => handleOnChange(index, "note", e.target.value)}
              />
            </Box>
            <Center w={"20%"}>
              <IconButton
                icon={<Icon as={AiFillDelete} />}
                aria-label="Remove Entry"
                onClick={() => handleRemove(index)}
                size="xs"
              />
            </Center>
          </Box>
        ))}
      <Icon
        cursor="pointer"
        onClick={() => {
          const newItem = fields.reduce((acc, f) => {
            acc[f.name] = "";
            return acc;
          }, {});
          setItems(items ? [...items, newItem] : [newItem]);
        }}
        as={BsPlusCircleDotted}
        fontSize={"26px"}
        mt={"4"}
      ></Icon>
    </FormControl>
  );
}

function MedicationAutoComplete({dashState, onChange, initialValue}) {
  const [possibleDrugs, setPossibleDrugs] = useState([]);
  const timeout = useRef();
  const firstRender = useRef(true);
  const [drugName, setDrugName] = useState(initialValue);
  const [selectedDrug, setSelectedDrug] = useState(null);
  useEffect(() => {
    if (drugName && !selectedDrug && !firstRender.current) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        drugAutocomplete(dashState, drugName)
          .then((res) => {
            const uniqueDrugs = Array.from(
              new Set(res?.data?.map((drug) => drug.alt_name))
            ).map((alt_name) =>
              res?.data?.find((drug) => drug.alt_name === alt_name)
            );
            setPossibleDrugs(uniqueDrugs || []);
          })
          .catch((e) => {
            console.error(e);
          });
      }, 1000);
    } else {
      clearTimeout(timeout.current);
      setPossibleDrugs([]);
    }
  }, [drugName]);
  useEffect(() => {
    firstRender.current = false;
  }, []);
  return (
    <InputGroup>
      <VStack
        maxH="250px"
        w="100%"
        divider={<StackDivider borderColor="gray.200" />}
      >
        <Box w="100%">
          <Input
            value={drugName}
            placeholder="search..."
            w="100%"
            onChange={(e) => {
              setDrugName(e.target.value);
              if (selectedDrug) setSelectedDrug(null);
            }}
            variant="flushed"
          />
          <InputRightElement>
            <SearchIcon
              cursor="pointer"
              onClick={async () => {
                if (!drugName) return;
                setSelectedDrug({brand_name: drugName});
              }}
            />
          </InputRightElement>
          <Box width="240px">
            {possibleDrugs?.map((item, index) => (
              <Box
                cursor="pointer"
                onClick={async () => {
                  setSelectedDrug(item);
                  setDrugName(item.brand_name);
                  onChange(item);
                }}
                key={index}
              >
                <Text paddingLeft={4} fontSize="sm">
                  {item?.alt_name}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </VStack>
    </InputGroup>
  );
}
