import React, {useState, useEffect, useRef, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {crud} from "../crudRequests";
import {BiEdit, BiSearchAlt2} from "react-icons/bi";
import {BsPersonCircle} from "react-icons/bs";
import {GiModernCity} from "react-icons/gi";
import {
  Box,
  Center,
  useToast,
  Wrap,
  WrapItem,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {MdKeyboardDoubleArrowDown} from "react-icons/md";
export default function InstanceBar({dispatch, schState, style}) {
  const p = window.location.pathname;

  const adminRoutes = [
    "/admin",
    "/clinicians",
    "/services",
    "/billing",
    "/permissions",
    "/locations",
    "/insurers",
    "/createService",
    "/forms",
    /\/sleepTrackerByPatient/,
  ];

  function exclude(p) {
    return !adminRoutes.find((e) => p.match(e));
  }

  return (
    <div
      className="ml-36 p-2 flex items-center relative z-[29] h-[82px]  rounded-bl-3xl "
      style={style}
    >
      {!p.includes("/locations") && (
        <LocationsPicker dispatch={dispatch} schState={schState} />
      )}
      {p === "/" && <Selection schState={schState} />}
    </div>
  );
}

function Selection({schState}) {
  return (
    <div
      style={{width: "180px"}}
      className="mt-2 ml-6  flex justify-start items-center relative  h-[3.5rem]"
    >
      {schState.selectedDoctor || schState.doctor ? (
        <div className="flex items-center rounded-md h-[3.5rem] bg-[#7270FF] p-1  w-[20rem]">
          {schState.selectedDoctor?.photo ? (
            <img
              src={schState.selectedDoctor?.photo}
              alt=""
              className="relative  w-12 p-1 h-12 rounded-md drop-shadow-lg"
            />
          ) : (
            <BsPersonCircle className="text-[1.5rem] text-white" />
          )}
          <div className="text-white text-[12px] ml-2 mt-1 font-semibold">
            {schState.selectedDoctor?.name}
            <br />
            <p className="text-[9px] leading-[10px] font-normal">
              {schState.selectedDoctor?.title}
            </p>
          </div>
        </div>
      ) : (
        <div className="flex h-full  items-center rounded-md bg-[#7270FF]  p-2 pr-4 w-[20rem] ">
          <img src="/images/Doctor/doctors.png" className="h-8 mx-4 mr-1" />{" "}
          <p className="text-white text-[25px] ml-2 font-medium leading-[25px]">
            ALL
          </p>
        </div>
      )}
    </div>
  );
}

function LocationsPicker({dispatch, schState}) {
  const fileRef = useRef(null);
  let loc = useMemo(() => {
    return schState.locations?.find((l) => l.lid === schState.selectedLocation);
  }, [schState.selectedLocation, schState.locations]);

  const [showList, setShowList] = useState(false);
  const toast = useToast();

  function onUpdatePhoto(ele) {
    let files = ele.target.files;
    let file = files[0];
    if (!file?.type.match(/image.*/i)) return;
    var reader = new FileReader();
    reader.addEventListener("load", (e) => {
      crud({jwt: schState.jwt}, [
        {
          db: schState.db,
          collection: "locations",
          parameters: [{lid: loc.lid}, {$set: {logo: e.target.result}}],
          method: "updateOne",
        },
      ])
        .then((res) => {
          dispatch({
            type: "UPDATE_LOCATION",
            payload: {lid: loc.lid, logo: e.target.result},
          });
          toast({
            title: "The location logo has been successfully updated!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch(function (error) {
          toast({
            title: "An error occurred while updating the logo!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });

          console.log(error);
        });
    });
    reader.readAsDataURL(file);
  }

  return (
    <div className="mt-2 ml-3 relative   flex justify-center items-center w-[11rem] hover:cursor-pointer h-[3.5rem]">
      {!showList ? (
        <Box h="full" w="full">
          <input
            ref={fileRef}
            onChange={(e) => {
              onUpdatePhoto(e);
            }}
            className="absolute top-0 opacity-0 z-0 h-0 w-0"
            type="file"
            name="updatePhoto"
          />
          <div
            className="absolute  group transition text-transparent w-full h-full hover:text-white text-lg inset-0   z-20 flex justify-center items-center hover:bg-gray-400/50 hover:cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              fileRef.current.click();
            }}
          >
            <BiEdit className="" />
          </div>

          <div className="w-full h-full   overflow-clip z-10">
            {loc && loc.logo ? (
              <img
                src={loc.logo}
                alt=""
                className=" w-full h-full object-contain  z-20 p-1"
              />
            ) : (
              <span className="z-20 relative">
                <GiModernCity className="text-[2rem] text-white relative" />
              </span>
            )}
          </div>
        </Box>
      ) : (
        <ul className="h-full w-full   p-1  sbar3 border-b-2 overflow-y-scroll space-y-1 ">
          {schState.locations.map((l, i) => {
            let name = l.name;
            // name = name.length > 25 ? name.slice(0, 25) + "..." : name;

            return (
              <li
                key={i}
                className="text-slate-500   text-sm font-medium  hover:text-blue-500"
                onClick={() => {
                  setShowList(false);
                  if (schState.selectedLocation !== l.lid) {
                    dispatch({type: "CHANGE_LOCATION", payload: l.lid});
                  }
                }}
              >
                {name.length > 23 ? (
                  <Tooltip label={l.name} placement="right">
                    {name.slice(0, 23) + "..."}
                  </Tooltip>
                ) : (
                  name
                )}
              </li>
            );
          })}
        </ul>
      )}
      {!showList && (
        <Icon
          boxSize={6}
          as={MdKeyboardDoubleArrowDown}
          color={"gray.300"}
          _hover={{
            color: "gray.500",
          }}
          position={"absolute"}
          right="100%"
          top={"0%"}
          onClick={() => setShowList(true)}
        />
      )}
    </div>
  );
}

function SearchDoctor({searchValue, search, setSelectDoctor, selectDoctor}) {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const handleClick = (e) => {
      if (!hidden) setHidden((prev) => !prev);
    };

    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [hidden, selectDoctor]);

  return (
    <div
      className=" flex h-10 transition z-0 justify-center items-center rounded-r-lg  absolute left-[calc(100%-0px)] top-[6px]"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="overflow-hidden bg-[#7270FF] transition"
        style={{width: hidden ? "0rem" : "6rem", transition: "0.2s"}}
      >
        <div className="w-[6rem]  h-[2rem] flex justify-center items-center p-1">
          <input
            name="searchValue"
            id="searchValue"
            value={searchValue}
            className="w-full h-full block  px-3 text-sm text-off font-medium bg-white  rounded-md focus:border-white focus:outline-none focus:ring-2 focus:ring-blue-300 focus:shadow-inner focus:ring-opacity-40"
            onChange={(e) => {
              setSelectDoctor(true);
              search(e.target.value);
            }}
          />
        </div>
      </div>
      <div
        className="w-[1.5rem] flex justify-center items-center cursor-pointer h-[2rem] rounded-r-lg bg-[#7270FF]"
        onClick={(e) => {
          setHidden((prev) => !prev);
        }}
      >
        <span className="h-full text-[1rem] text-slate-100 flex justify-center items-center">
          <BiSearchAlt2 />
        </span>
      </div>
    </div>
  );
}
