import {isoToApptDate} from "../../../../additional_files/helpers";
export default function defaultEmail({emailTo, relatedData, state}) {
  let {clinician, patient, insurer, template, type, entity, appointment} =
    relatedData;

  let {date, time} = isoToApptDate(
    appointment.ISOdate || appointment.serviceDate
  );
  try {
    switch (emailTo) {
      case "patient": {
        let template = relatedData.template[0]?.emailContent;
        let smap = {
          "{{patientName}}": `${patient?.fName || ""} ${patient?.lName || ""}`,
          "{{entity}}": entity,
          "{{doctorName}}": clinician?.name,
          "{{date}}": date,
          "{{time}}": time,
          "{{sender}}": state?.admin
            ? state?.organization?.name
            : state?.doctor.name,
        };
        return template
          ? template
              .replace(/{{\w+}}/g, (s) => smap[s])
              .replace(/<br\s*\/?>/gi, "\n")
              .replace(/<[^>]*>/g, "")
          : "";
      }

      case "insurer": {
        let template = relatedData.template[1]?.emailContent;
        let smap = {
          "{{insurerName}}": insurer?.name,
          "{{entity}}": entity,
          "{{doctorName}}": clinician?.name,
          "{{policyID}}": patient?.policyNumber || "N/A",
          "{{insuranceNumber}}": patient?.insuranceNumber || "N/A",
          "{{date}}": date,
          "{{time}}": time,
          "{{sender}}": state?.admin
            ? state?.organization?.name
            : state?.doctor.name,
        };
        return template
          ? template
              .replace(/{{\w+}}/g, (s) => smap[s])
              .replace(/<br\s*\/?>/gi, "\n")
              .replace(/<[^>]*>/g, "")
          : "";
      }

      default: {
        return "";
      }
    }
  } catch (e) {
    return "";
  }
}
