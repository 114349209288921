import {
  Box,
  Flex,
  Button,
  useToast,
  Spacer,
  Spinner,
  Center,
} from "@chakra-ui/react";
import {useState, useEffect} from "react";
import {lightFormat} from "date-fns";
import {viewFiles} from "./helpers/viewFiles";
import {getFileData} from "./helpers/getFileData";
import PreviewFile from "./helpers/PreviewFile";
export function Records({
  appointment,
  type,
  state,
  setInvOrRecExist,
  invOrRecExist,
}) {
  const [preview, setPreview] = useState(false);
  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const [receiptFiles, setReceiptFiles] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [loadingInv, setLoadingInv] = useState(true);
  const [loadingRec, setLoadingRec] = useState(true);
  const toast = useToast();
  let invoicesPath = `${appointment?.pid || ""}/Invoices/${
    appointment?.nonSessionService ? "Non-Session-Services" : "Appointments"
  }/${appointment?.aid ? fileNameFormat(appointment) : ""}/${
    type === "patient" ? "Patient/" : "Insurer/"
  }`;
  let receiptsPath = `${appointment?.pid || ""}/Receipts/${
    appointment?.nonSessionService ? "Non-Session-Services" : "Appointments"
  }/${appointment?.aid ? fileNameFormat(appointment) : ""}/${
    type === "patient" ? "Patient/" : "Insurer/"
  }`;

  function fileNameFormat(appt) {
    return (
      lightFormat(new Date(appt.ISOdate), "dd-MM-yy HH-mm aaa") +
      ` (${appt.aid})`
    );
  }

  useEffect(() => {
    if (appointment) {
      setLoadingInv(true);
      setLoadingRec(true);
      setInvoiceFiles([]);
      setReceiptFiles([]);
      viewFiles(state, setInvoiceFiles, setLoadingInv, invoicesPath, toast);
      viewFiles(state, setReceiptFiles, setLoadingRec, receiptsPath, toast);
    }
  }, [appointment]);

  useEffect(() => {
    let exist = !!invoiceFiles.find((f) => f.name.slice(-1) !== "/");
    setInvOrRecExist((prev) => ({
      ...prev,
      [type === "patient" ? "ptInv" : "tppInv"]: exist,
    }));
  }, [invoiceFiles]);

  return (
    <div className="flex justify-between mt-2 space-x-1">
      {preview && (
        <PreviewFile
          preview={preview}
          setPreview={setPreview}
          appointment={appointment}
          state={state}
        />
      )}
      <div className="flex flex-1 items-start  min-h-[5rem] flex-col text-white text-sm space-y-1">
        <span>invoice(s)</span>
        {loadingInv ? (
          <Center h="3rem" w="3rem">
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#827FFF"
              size="sm"
            />
          </Center>
        ) : invoiceFiles.length > 0 ? (
          <div className="max-h-20  w-full overflow-hidden">
            <ul className="w-full flex flex-wrap items-start overflow-y-auto h-full sbar2">
              {invoiceFiles.map((file, i) => (
                <li
                  onClick={() => {
                    if (
                      !fetchingData &&
                      appointment &&
                      file.name.slice(-1) !== "/"
                    )
                      getFileData(
                        appointment,
                        state,
                        invoicesPath,
                        file.name,
                        setPreview,
                        toast,
                        setFetchingData,
                        type,
                        "invoice"
                      );
                  }}
                  key={i}
                  className="bg-[#827FFF] m-[2px]  rounded-lg p-[2px] px-[4px] cursor-pointer flex flex-col justify-center items-center"
                >
                  <img
                    alt=""
                    src="/images/Clinic/inv.png"
                    className="w-[2rem] -mt-[2px]"
                  />

                  <span className="text-[10px] -mt-[2px] leading-[11px]">
                    view
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <span>none to show</span>
        )}
      </div>

      <div className="flex flex-1 items-end flex-col text-white text-sm space-y-1">
        <span>receipt(s)</span>
        {loadingRec ? (
          <Center h="3rem" w="3rem">
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#827FFF"
              size="sm"
            />{" "}
          </Center>
        ) : receiptFiles.length > 0 ? (
          <div className="max-h-20  w-full overflow-hidden">
            <ul className="w-full flex flex-wrap justify-end items-start overflow-y-auto h-full sbar2">
              {receiptFiles.map((file, i) => (
                <li
                  key={i}
                  onClick={() => {
                    if (
                      !fetchingData &&
                      appointment &&
                      file.name.slice(-1) !== "/"
                    )
                      getFileData(
                        appointment,
                        state,
                        receiptsPath,
                        file.name,
                        setPreview,
                        toast,
                        setFetchingData,
                        type,
                        "receipt"
                      );
                  }}
                  className="bg-[#827FFF] m-[2px]  rounded-lg p-[2px] px-[4px] cursor-pointer flex flex-col justify-center items-center"
                >
                  <img
                    alt=""
                    src="/images/Clinic/inv.png"
                    className="w-[2rem] -mt-[2px]"
                  />

                  <span className="text-[10px] -mt-[2px] leading-[11px]">
                    view
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <span>none to show</span>
        )}
      </div>
    </div>
  );
}
