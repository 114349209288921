import {
  Box,
  Avatar,
  Text,
  Flex,
  Badge,
  Tooltip,
  Tag,
  Icon,
  Button,
  Spinner,
  useDisclosure,
  useToast,
  Tr,
  Td,
} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {IoVideocam} from "react-icons/io5";
import {MdOutlineAttachMoney} from "react-icons/md";
import {HiOutlineDocumentText} from "react-icons/hi";
import {getJitsiJWT} from "./helpers/jitsiFunctions";
import RelatedCharts from "./RelatedCharts";
import InvoicOrReceipt from "../../Billing/InvoiceOrReceipt";
export default function AppointmentListItem({
  appointment,
  summaries,
  transaction,
  setPreview,
  handleLinkToChart,
  setPreviewInvRec,
  setSelectedTr,
  dispatch,
  setApptNotes,
  apptDoctor,
  selectedSummary,
  setSelectedSummary,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();

  const [printInvoiceOrReciept, setPrintInvoiceOrReciept] = useState("");
  const [fetchingFileData, setFetchingFileData] = useState("");

  const [payment, setPayment] = useState(null);

  useEffect(() => {
    setPayment(transaction);
  }, [transaction]);

  const status = appointment.arrived
    ? {name: "Arrived", color: "green"}
    : appointment.noShow
    ? {name: "No Show", color: "black"}
    : appointment.cancelled
    ? {name: "Cancelled", color: "red"}
    : appointment.rescheduled
    ? {name: "Rescheduled", color: "orange"}
    : appointment.pConfirmed
    ? {name: "Confirmed", color: "blue"}
    : {name: "Unconfirmed", color: "gray"};

  let individualSummary = summaries?.find((p) => p.aid === appointment.aid);
  let summary = individualSummary?.briefSummary
    ? {text: "VIEW AUTO-GENERATED SUMMARY", color: "green", display: true}
    : individualSummary?.sid
    ? {
        text: (
          <Flex alignItems="center" justifyContent="center">
            SUMMARY AUTO-GENERATING <Spinner size={"xs"} ml="1.5" />
          </Flex>
        ),
        color: "orange",
        display: false,
      }
    : {
        text: "NO SUMMARY AVAILABLE",
        color: "blackAlpha",
        display: false,
      };

  return (
    <Tr
      key={appointment.aid}
      bg={"white"}
      boxShadow="md"
      borderRadius="md"
      p="6"
      _hover={{
        bg: "gray.100",
      }}
      onClick={() =>
        dispatch({type: "SELECT_APPOINTMENT", payload: appointment})
      }
    >
      <Td>
        <Flex align={'center'}>
          <Tooltip
            label={`${apptDoctor.lastName ? apptDoctor.lastName + ", " : ""}${
              apptDoctor.firstName || ""
            }`}
          >
            <Avatar
              size="lg"
              name={`${apptDoctor.lastName ? apptDoctor.lastName + ", " : ""}${
                apptDoctor.firstName || ""
              }`}
              src={apptDoctor?.photo}
            />
          </Tooltip>
          <Text fontSize="sm" ml="0.75rem" fontWeight="medium">
            {
              `${apptDoctor.lastName ? apptDoctor.lastName + ", " : ""}${apptDoctor.firstName || ""}`
            }
          </Text>
        </Flex>
      </Td>
      <Td whiteSpace="nowrap" fontSize="14px">
        <Text as="span" fontWeight={"medium"}>
          {appointment.date}
        </Text>
        <Text textAlign={"start"} fontWeight={"light"}>
          {appointment.teleconference
            ? "Virtual"
            : appointment.telephone
            ? "Telephone"
            : "In Person"}
        </Text>
        <Text fontWeight={"light"}>
          @ {appointment.time}
          <a
            className="cursor-pointer"
            onClick={async (e) => {
              e.stopPropagation();
              if (dashState?.userType === "admin")
                toast({
                  title: "You are not authorized to join this call.",
                  status: "info",
                  description:
                    "Only the patient and clinician associated with this appointment are allowed to go in the call.",
                  duration: 3000,
                  isClosable: true,
                  position: "top",
                });
              else {
                if (appointment?.clinicApptLink) {
                  // Will display the loading toast until the promise is either resolved
                  // or rejected.
                  // console.log(appointment);
                  // return;
                  toast.promise(
                    getJitsiJWT(
                      dashState,
                      appointment?.aid,
                      appointment?.pid,
                      dashState?.db,
                      dashState?.selectedDoctor,
                      appointment?.clinicApptLink
                    ),
                    {
                      success: {
                        title: "Authenticated",
                        description: "Successfully joined meeting!",
                      },
                      error: {
                        title: "Please try again",
                        description:
                          "Something wrong authenticating you to the meeting",
                      },
                      loading: {
                        title: "Authenticating...",
                        description: "Please wait",
                      },
                    }
                  );
                }
              }
            }}
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              as={IoVideocam}
              fontSize={"20px"}
              mt="-1"
              ml="2"
              color="gray.600"
            />
          </a>
        </Text>
      </Td>
      <Td>
        <Tag colorScheme="blackAlpha" my="3">
          {appointment.service}
        </Tag>
      </Td>
      <Td>
        <Box>
          <Badge
            fontSize="12px"
            rounded="md"
            p="1"
            px="2"
            mb="4"
            colorScheme={status.color}
          >
            {status.name}
          </Badge>
        </Box>
      </Td>
      <Td>
        <Text
          fontWeight={"light"}
          color={
            appointment.note || appointment.cancellationReason
              ? "blue.500"
              : "gray.500"
          }
          fontSize="12px"
          mb="1"
          cursor={
            appointment.note || appointment.cancellationReason
              ? "pointer"
              : "default"
          }
          onClick={(e) => {
            if (appointment.note || appointment.cancellationReason) {
              setApptNotes(appointment);
              e.stopPropagation();
            }
          }}
        >
          {appointment.note || appointment.cancellationReason
            ? "View Notes"
            : "No Notes Added"}
        </Text>
      </Td>
      <Td>
        <Box>
          <Tooltip
            label={
              !appointment.claim
                ? "Claim is unsumbitted"
                : appointment.received
                ? "Claim is submitted"
                : "Claim is recieved"
            }
          >
            <Button
              variant="ghost"
              p="0"
              colorScheme={
                !appointment.claim
                  ? "blackAlpha"
                  : appointment.received
                  ? "whatsapp"
                  : "blue"
              }
              cursor={"default"}
            >
              <Icon as={HiOutlineDocumentText} fontSize="24" />
            </Button>
          </Tooltip>
          <Tooltip
            label={
              parseFloat(payment?.amount) !== parseFloat(payment?.paid || 0)
                ? "Payment is pending"
                : "Payment is recieved"
            }
          >
            <Button
              variant="ghost"
              p="0"
              colorScheme={
                parseFloat(payment?.amount) !== parseFloat(payment?.paid || 0)
                  ? "blackAlpha"
                  : "whatsapp"
              }
              cursor={"default"}
            >
              <Icon as={MdOutlineAttachMoney} fontSize="24" />
            </Button>
          </Tooltip>

          <RelatedCharts
            {...{
              handleLinkToChart,
              setPreview,
              dashState,
              patient,
              appointment,
              toast,
            }}
          />
        </Box>
      </Td>
      <Td>
        <InvoicOrReceipt
          {...{
            transaction: payment,
            dashState,
            setPreview: setPreviewInvRec,
            fetchingFileData,
            setFetchingFileData,
            setSelectedTr,
            toast,
          }}
        />
      </Td>
      <Td>
        <Badge
          onClick={(e) => {
            if (summary.display) {
              e.stopPropagation();

              setSelectedSummary(
                summaries?.find((p) => p.aid === appointment.aid)
              );
            }
          }}
          rounded="md"
          variant="outline"
          colorScheme={summary.color}
          fontSize={"12px"}
          p="1"
          px="2"
          textAlign={"center"}
          w="full"
          cursor={summary.display ? "pointer" : "default"}
        >
          {summary.text}
        </Badge>
      </Td>
    </Tr>
  );
}
