import {useEffect, useState} from "react";
import {getNotificationFaxes} from "../crudRequests";
import {IoPrint} from "react-icons/io5";
import useFindPhaxioNumber from "../hooks/useFindPhaxioNumber";

export const FaxNotifications = ({schState}) => {
  const [faxes, setFaxes] = useState([]);
  const [faxNumber] = useFindPhaxioNumber(schState, () => {});

  useEffect(() => {
    let isCancelled = false;

    const fetchAndModifyFaxes = async () => {
      try {
        const fetchedFaxes = await getNotificationFaxes(schState, schState.db, faxNumber, null, null)
        const fetchedReceivedFaxes = fetchedFaxes?.data?.data?.received || [];
        const fetchedSentFaxes = fetchedFaxes?.data?.data?.sent || [];
        const allFaxes = [...fetchedReceivedFaxes, ...fetchedSentFaxes]

          if (!isCancelled) {
            setFaxes(allFaxes);
          }
      } catch (error) {
        console.error("Error fetching and modifying faxes:", error);
      }
    }

    fetchAndModifyFaxes()

    return () => {
      isCancelled = true
    };
  }, [faxNumber])

  return (
    <div className="absolute top-6 z-[33] right-[9rem] flex justify-center items-center">
      <div
        className="bg-light opacity-75 p-[5px] rounded-md cursor-pointer shrink-0"
      >
        <span className="flex justify-center items-center">
          <IoPrint className="text-dark text-[32px]" />
        </span>
        {faxes?.length > 0 && (
          <span className="absolute top-[2px] -right-[1px] h-[2px] w-[2px] p-[7px] rounded-full flex justify-center items-center leading-[8px] font-medium bg-[#FF0000] text-[8px] text-white">
            {faxes.length}
          </span>
        )}
      </div>
    </div>
  );
}
