import { crud } from "../../../../crudRequests";

export const deleteForm = (dashState, fsid, setForms, toast, setSelectedForm) => {
  return crud(dashState, [
    {
      db: dashState.db,
      collection: "form_submissions",
      parameters: [{ fsid: fsid }],
      method: "deleteOne",
    },
  ])
    .then((res) => {
      setForms(prevTasks => prevTasks.filter(form => form.fsid !== fsid));
      setSelectedForm(null)
      toast({
        title: "Form Deleted",
        description: "The form has been deleted successfully.",
        status: "success",
        isClosable: true,
      });
    })
    .catch(function (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        window.location.href = "../login";
      } else {
        toast({
          title: "An error occurred",
          description: error.response?.data || error.message,
          status: "error",
          isClosable: true,
        });
      }
    });
}
