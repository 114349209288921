import React, {useEffect, useState} from "react";
import {useToast, VStack, Text, Box, Spinner, Center} from "@chakra-ui/react";
import {Form, Formik} from "formik";
import {crud} from "../../crudRequests";
import {v4} from "uuid";
import {reportCollections as collections} from "./reportCollections";
import {DynamicReportFilters} from "./DynamicReportFilters";
import {DynamicReportTable} from "./DynamicReportTable";
import {formatQuery} from "react-querybuilder";
import jsonLogic from "json-logic-js";

const compareValues = (a, b, compareFunc) => {
  if (typeof a === "boolean") {
    const bBool = b === "True" ? true : b === "False" ? false : b;
    return compareFunc(a, bBool);
  }

  if (a instanceof Date) {
    const bDate = new Date(b);
    bDate.setHours(0);
    bDate.setMinutes(0);
    bDate.setSeconds(0);
    bDate.setMilliseconds(0);
    bDate.setDate(bDate.getDate() + 1);
    return compareFunc(a.getTime(), bDate.getTime());
  }
  const aFloat = parseFloat(a);
  const bFloat = parseFloat(b);

  if (!isNaN(aFloat) && !isNaN(bFloat)) {
    return compareFunc(aFloat, bFloat);
  } else if (typeof a === "string" && typeof b === "string") {
    return compareFunc(a.toLowerCase(), b.toLowerCase());
  }
  return compareFunc(a, b);
};

jsonLogic.add_operation("==", (a, b) =>
  compareValues(
    a,
    b,
    (x, y) =>
      x === y ||
      (typeof x === "string" &&
        typeof y === "string" &&
        x.toLowerCase() === (y || "").toLowerCase())
  )
);
jsonLogic.add_operation("!=", (a, b) =>
  compareValues(
    a,
    b,
    (x, y) => x !== y && (typeof x !== "string" || !x.includes(y || ""))
  )
);
jsonLogic.add_operation(">", (a, b) => compareValues(a, b, (x, y) => x > y));
jsonLogic.add_operation(">=", (a, b) => compareValues(a, b, (x, y) => x >= y));
jsonLogic.add_operation("<", (a, b) => compareValues(a, b, (x, y) => x < y));
jsonLogic.add_operation("<=", (a, b) => compareValues(a, b, (x, y) => x <= y));
jsonLogic.add_operation("in", (a, b) =>
  compareValues(a, b, (x, y) => {
    if (Array.isArray(y)) {
      return y.includes(x);
    } else if (typeof y === "string" && typeof x === "string") {
      return y.toLowerCase().includes(x.toLowerCase());
    }
    return false;
  })
);
export const DynamicReport = (props) => {
  const toast = useToast();
  const initialActiveColumns = collections[0].fields.reduce((acc, field) => {
    if (field.default) {
      acc[field.name] = true;
    } else {
      acc[field.name] = false;
    }
    return acc;
  }, {});

  const initialValues = {
    collection: collections[0].collectionName,
    query: {combinator: "and", rules: []},
    selectedSavedReport: "",
    activeColumns: initialActiveColumns,
    reportName: "",
  };
  const onSubmit = (values) => {};
  const [data, setData] = useState(null);
  const [savedReports, setSavedReports] = useState([]);
  useEffect(() => {
    fetchSavedReports();
  }, []);
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState(null);
  const fetchRawData = async (collection) => {
    let action;
    switch (collection) {
      case "patients":
        action = {
          parameters: [
            {
              $match: {},
            },
            {
              $lookup: {
                from: "refClinicians",
                localField: "refClinicianId",
                foreignField: "refId",
                as: "referringClinicianData",
              },
            },
            {
              $unwind: {
                path: "$referringClinicianData",
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $lookup: {
                from: "refClinicians",
                localField: "refGpId",
                foreignField: "refId",
                as: "referringGPData",
              },
            },
            {
              $unwind: {
                path: "$referringGPData",
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $addFields: {
                concatenatedMedicalConditions: {
                  $reduce: {
                    input: "$preDiagnosedMedicalConditions",
                    initialValue: "",
                    in: {
                      $concat: [
                        "$$value",
                        {$cond: [{$eq: ["$$value", ""]}, "", ", "]},
                        "$$this.name",
                      ],
                    },
                  },
                },
                concatenatedMentalHealthDisorders: {
                  $reduce: {
                    input: "$preDiagnosedMentalHealthDisorders",
                    initialValue: "",
                    in: {
                      $concat: [
                        "$$value",
                        {$cond: [{$eq: ["$$value", ""]}, "", ", "]},
                        "$$this.name",
                      ],
                    },
                  },
                },
              },
            },
          ],
          method: "aggregate",
        };
        break;
      case "doctors":
        action = {
          parameters: [
            {$match: {}},
            {
              $lookup: {
                from: "provider_types",
                localField: "providerType",
                foreignField: "typeId",
                as: "providerTypeData",
              },
            },
            {
              $unwind: {
                path: "$providerTypeData",
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $lookup: {
                from: "doctors",
                localField: "supervisor",
                foreignField: "did",
                as: "supervisorData",
              },
            },
            {
              $unwind: {
                path: "$supervisorData",
                preserveNullAndEmptyArrays: true,
              },
            },
          ],
          method: "aggregate",
        };

        break;
      case "services":
        action = {parameters: [{}], method: "find"};

        break;
      case "appointments":
        try {
          const appointmentsRes = await crud(props.dashState, [
            {
              db: props.dashState.db,
              collection: "appointments",
              parameters: [{}],
              method: "find",
            },
          ]);

          let appointments = appointmentsRes.data[0] || [];
          const [servicesRes, refCliniciansRes, patientsRes] =
            await Promise.all([
              crud(props.dashState, [
                {
                  db: props.dashState.db,
                  collection: "services",
                  parameters: [{}],
                  method: "find",
                },
              ]),
              crud(props.dashState, [
                {
                  db: props.dashState.db,
                  collection: "refClinicians",
                  parameters: [{}],
                  method: "find",
                },
              ]),
              crud(props.dashState, [
                {
                  db: props.dashState.db,
                  collection: "patients",
                  parameters: [{}],
                  method: "find",
                },
              ]),
            ]);

          const services = servicesRes.data[0] || [];
          const refClinicians = refCliniciansRes.data[0] || [];
          const patients = patientsRes.data[0] || [];

          const doctorsLookup = props.dashState.doctors.reduce(
            (acc, doc) => ({...acc, [doc.did]: doc}),
            {}
          );
          const patientsLookup = patients.reduce(
            (acc, patient) => ({...acc, [patient.pid]: patient}),
            {}
          );
          const servicesLookup = services.reduce(
            (acc, service) => ({...acc, [service.serviceId]: service}),
            {}
          );
          const refCliniciansLookup = refClinicians.reduce(
            (acc, clinician) => ({...acc, [clinician.refId]: clinician}),
            {}
          );
          const mappedData = appointments.map((appointment) => {
            const doctor = doctorsLookup[appointment.did] || {};
            const patient = patientsLookup[appointment.pid] || {};
            const service = servicesLookup[appointment.serviceId] || {};
            const refClinician =
              refCliniciansLookup[patient.refClinicianId] || {};
            const supervisor = doctorsLookup[appointment.supervisorId] || {};

            const fullDate = new Date(appointment.ISOdate);
            fullDate.setHours(0, 0, 0, 0);

            return {
              ...appointment,
              doctorData: doctor,
              patientData: patient,
              serviceData: service,
              refClinicianData: refClinician,
              supervisorData: supervisor,
              calcDate: new Date(
                fullDate.getFullYear(),
                fullDate.getMonth(),
                fullDate.getDate()
              ),
              concatenatedMedicalConditions:
                patient.preDiagnosedMedicalConditions
                  ? patient.preDiagnosedMedicalConditions
                      .map((condition) => condition.name)
                      .join(", ")
                  : "",
              concatenatedMentalHealthDisorders:
                patient.preDiagnosedMentalHealthDisorders
                  ? patient.preDiagnosedMentalHealthDisorders
                      .map((disorder) => disorder.name)
                      .join(", ")
                  : "",
            };
          });

          return setRawData(mappedData);
        } catch (error) {
          console.error("Error fetching appointment data:", error);
          toast({
            title: "Error fetching data",
            description: "There was an error fetching the appointment data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        break;
      default:
        throw new Error("Invalid collection");
    }
    const res = await crud(props.dashState, [
      {
        db: props.dashState.db,
        collection: collection,
        ...action,
      },
    ]);
    const mappedData = Array.isArray(res.data[0])
      ? res.data[0].map((entry) => {
          if (collection === "appointments") {
            const fullDate = new Date(entry.ISOdate);
            fullDate.setHours(0, 0, 0, 0);

            entry.calcDate = new Date(
              fullDate.getFullYear(),
              fullDate.getMonth(),
              fullDate.getDate()
            );
            if (entry.patientData) {
              entry.concatenatedMedicalConditions = entry.patientData
                .preDiagnosedMedicalConditions
                ? entry.patientData.preDiagnosedMedicalConditions
                    .map((condition) => condition.name)
                    .join(", ")
                : "";
              entry.concatenatedMentalHealthDisorders = entry.patientData
                .preDiagnosedMentalHealthDisorders
                ? entry.patientData.preDiagnosedMentalHealthDisorders
                    .map((disorder) => disorder.name)
                    .join(", ")
                : "";
            }
          }
          return entry;
        })
      : [];

    setRawData(mappedData);
  };
  useEffect(() => {
    if (!rawData) {
      fetchRawData("patients");
    }
  }, []);
  const filterData = (data, query) => {
    const transformedQuery = formatQuery(query, "jsonlogic");
    const filteredData = transformedQuery
      ? data.filter((item) => jsonLogic.apply(transformedQuery, item))
      : data;
    return filteredData;
  };

  const queryData = async (collection, query, groupBy = [], formikProps) => {
    try {
      setLoading(true);
      let action;
      if (formikProps) {
        const updatedActiveColumns = {...formikProps.values.activeColumns};
        query.rules.forEach((rule) => {
          updatedActiveColumns[rule.field] = true;
        });
        formikProps.setValues({
          ...formikProps.values,
          activeColumns: updatedActiveColumns,
          groupBy,
        });
      }

      switch (collection) {
        case "patients":
          action = {
            parameters: [
              {
                $match: {},
              },
              {
                $lookup: {
                  from: "refClinicians",
                  localField: "refClinicianId",
                  foreignField: "refId",
                  as: "referringClinicianData",
                },
              },
              {
                $unwind: {
                  path: "$referringClinicianData",
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $lookup: {
                  from: "refClinicians",
                  localField: "refGpId",
                  foreignField: "refId",
                  as: "referringGPData",
                },
              },
              {
                $unwind: {
                  path: "$referringGPData",
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $addFields: {
                  concatenatedMedicalConditions: {
                    $reduce: {
                      input: "$preDiagnosedMedicalConditions",
                      initialValue: "",
                      in: {
                        $concat: [
                          "$$value",
                          {$cond: [{$eq: ["$$value", ""]}, "", ", "]},
                          "$$this.name",
                        ],
                      },
                    },
                  },
                  concatenatedMentalHealthDisorders: {
                    $reduce: {
                      input: "$preDiagnosedMentalHealthDisorders",
                      initialValue: "",
                      in: {
                        $concat: [
                          "$$value",
                          {$cond: [{$eq: ["$$value", ""]}, "", ", "]},
                          "$$this.name",
                        ],
                      },
                    },
                  },
                },
              },
            ],
            method: "aggregate",
          };
          break;
        case "doctors":
          action = {
            parameters: [
              {$match: {}},
              {
                $lookup: {
                  from: "provider_types",
                  localField: "providerType",
                  foreignField: "typeId",
                  as: "providerTypeData",
                },
              },
              {
                $unwind: {
                  path: "$providerTypeData",
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $lookup: {
                  from: "doctors",
                  localField: "supervisor",
                  foreignField: "did",
                  as: "supervisorData",
                },
              },
              {
                $unwind: {
                  path: "$supervisorData",
                  preserveNullAndEmptyArrays: true,
                },
              },
            ],
            method: "aggregate",
          };

          break;
        case "services":
          action = {parameters: [{}], method: "find"};

          break;
        case "appointments":
          action = {
            parameters: [
              {$match: {}},

              {
                $lookup: {
                  from: "doctors",
                  localField: "did",
                  foreignField: "did",
                  as: "doctorData",
                },
              },
              {
                $unwind: {
                  path: "$doctorData",
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $lookup: {
                  from: "patients",
                  localField: "pid",
                  foreignField: "pid",
                  as: "patientData",
                },
              },
              {
                $unwind: {
                  path: "$patientData",
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $lookup: {
                  from: "refClinicians",
                  localField: "patientData.refClinicianId",
                  foreignField: "refId",
                  as: "refClinicianData",
                },
              },
              {
                $unwind: {
                  path: "$refClinicianData",
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $lookup: {
                  from: "services",
                  localField: "serviceId",
                  foreignField: "serviceId",
                  as: "serviceData",
                },
              },
              {
                $unwind: {
                  path: "$serviceData",
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $lookup: {
                  from: "doctors",
                  localField: "supervisorId",
                  foreignField: "did",
                  as: "supervisorData",
                },
              },
              {
                $unwind: {
                  path: "$supervisorData",
                  preserveNullAndEmptyArrays: true,
                },
              },
            ],
            method: "aggregate",
          };

          break;
        default:
          throw new Error("Invalid collection");
      }
      let groupedData = null;
      const groupByHelper = function (xs, keys) {
        if (!keys.length) return xs;

        const getNestedValue = (obj, path) => {
          return path.split(".").reduce((acc, part) => acc && acc[part], obj);
        };

        const group = (data, key) => {
          return data.reduce((result, item) => {
            const groupKey = getNestedValue(item, key);
            if (!result[groupKey]) {
              result[groupKey] = [];
            }
            result[groupKey].push(item);
            return result;
          }, {});
        };

        const recursiveGroup = (data, keys) => {
          if (keys.length === 0) return data;
          const [currentKey, ...remainingKeys] = keys;
          const groupedData = group(data, currentKey);
          for (const key in groupedData) {
            groupedData[key] = recursiveGroup(groupedData[key], remainingKeys);
          }
          return groupedData;
        };

        return recursiveGroup(xs, keys);
      };
      const filteredData = filterData(rawData, query);
      if (groupBy.length) {
        groupedData = groupByHelper(filteredData, groupBy);
      }
      setData({filteredData, groupedData, groupBy});
      setLoading(false);
      toast({
        title: "Report generated succesfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchSavedReports = async () => {
    try {
      const res = await crud(props.dashState, [
        {
          db: props.dashState.db,
          collection: "customReports",
          parameters: [{}],
          method: "find",
        },
      ]);
      setSavedReports(res.data[0] || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectSavedReports = (event, formikProps) => {
    formikProps.handleChange(event);
    const selectedId = event.target.value;
    const selectedSavedReport = savedReports.find(
      (selection) => selection.reportid === selectedId
    );
    const currentCollection = collections.find(
      (coll) => coll.collectionName === formikProps.values.collection
    );
    const defaultActiveColumns = currentCollection.fields.reduce(
      (acc, field) => {
        acc[field.name] = field.default || false;
        return acc;
      },
      {}
    );
    formikProps.setValues({
      ...formikProps.values,
      reportName: selectedSavedReport ? selectedSavedReport?.name : "",
      selectedSavedReport: selectedSavedReport?.reportid || "",
      activeColumns: selectedSavedReport
        ? selectedSavedReport.activeColumns
        : defaultActiveColumns,
      groupBy: selectedSavedReport?.groupBy || [],
      query: selectedSavedReport?.query || {combinator: "and", rules: []},
    });
    queryData(
      formikProps.values.collection,
      selectedSavedReport?.query || {combinator: "and", rules: []},
      selectedSavedReport?.groupBy || []
    );
  };

  const handleNewReport = async (formikProps, asCopy = false, reportName) => {
    const reportid = v4();
    if (asCopy) {
      let copyCount = 1;
      while (
        savedReports.some(
          (report) => report.name === `${reportName} copy ${copyCount}`
        )
      ) {
        copyCount++;
      }
      reportName = `${reportName} copy ${copyCount}`;
    }

    const reportData = {
      reportid,
      name: reportName,
      collectionName: formikProps.values.collection,
      activeColumns: formikProps.values.activeColumns,
      query: formikProps.values.query,
      groupBy: formikProps.values.groupBy,
    };
    await crud(props.dashState, [
      {
        db: props.dashState.db,
        collection: "customReports",
        parameters: [reportData],
        method: "insertOne",
      },
    ]);
    setSavedReports([...savedReports, reportData]);
    formikProps.setValues({
      ...formikProps.values,
      selectedSavedReport: reportid,
      reportName: "",
    });
    toast({
      title: "Report saved succesfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleUpdateReport = async (formikProps) => {
    const reportid = formikProps.values.selectedSavedReport;
    const reportData = {
      name: formikProps.values.reportName,
      collectionName: formikProps.values.collection,
      activeColumns: formikProps.values.activeColumns,
      query: formikProps.values.query,
    };
    await crud(props.dashState, [
      {
        db: props.dashState.db,
        collection: "customReports",
        parameters: [{reportid}, {$set: reportData}],
        method: "updateOne",
      },
    ]);
    setSavedReports(
      savedReports.map((myReport) =>
        myReport.reportid === reportid ? {...reportData, reportid} : myReport
      )
    );
    toast({
      title: "Report updated succesfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDeleteReport = async (formikProps) => {
    const reportid = formikProps.values.selectedSavedReport;
    await crud(props.dashState, [
      {
        db: props.dashState.db,
        collection: "customReports",
        parameters: [{reportid}],
        method: "deleteOne",
      },
    ]);
    formikProps.setValues({
      ...formikProps.values,
      selectedSavedReport: "",
    });
    setSavedReports(
      savedReports.filter((myReport) => myReport.reportid !== reportid)
    );
    toast({
      title: "Report deleted succesfully",
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
  };
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({values, handleChange, setValues}) => {
        const collection = collections.find(
          (col) => col.collectionName === values.collection
        );
        return (
          <Form style={{overflow: "auto"}}>
            <VStack>
              <DynamicReportFilters
                collection={collection}
                collections={collections}
                onCollectionChange={(evt) => {
                  const selectedCollection = collections.find(
                    (coll) => coll.collectionName === evt
                  );
                  const initialActiveColumns = selectedCollection.fields.reduce(
                    (acc, field) => {
                      acc[field.name] = field.default || false;
                      return acc;
                    },
                    {}
                  );
                  setValues({
                    ...values,
                    collection: evt,
                    activeColumns: initialActiveColumns,
                    query: {combinator: "and", rules: []},
                    groupBy: [],
                    selectedSavedReport: "",
                  });
                  setData(null);
                  setRawData(null);
                  fetchRawData(evt);
                }}
                savedReports={savedReports}
                selectedSavedReport={values.selectedSavedReport}
                onSelectSavedReports={(evt) =>
                  handleSelectSavedReports(evt, {
                    handleChange,
                    setValues,
                    values,
                  })
                }
                customReportName={values.reportName}
                onSaveReport={handleNewReport}
                onUpdateReport={handleUpdateReport}
                onDeleteReport={handleDeleteReport}
                formikProps={{
                  handleChange,
                  setValues,
                  values,
                }}
                queryData={queryData}
                resultingData={data}
                loading={rawData === null}
              />

              <Box width="100%">
                {rawData === null ? (
                  <Center>
                    <Spinner size="xl" />
                  </Center>
                ) : (
                  <DynamicReportTable
                    collection={collection}
                    collections={collections}
                    data={data}
                    activeColumns={values.activeColumns}
                    groupBy={values.groupBy}
                    loading={loading}
                  />
                )}
              </Box>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
