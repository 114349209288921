import verifyAccess from './verifyAccess';

function replaceWithRandomLetters(text, fixed) {
	const alphabet = 'abcdefghijklmnopqrstuvwxyz';
	let result = '';

	const textLength = fixed || text?.length;
	if (!textLength) return '';
	if (text.length === 0) {
		text = '1234567890';
	}

	for (let i = 0; i < textLength; i++) {
		const randomIndex = Math.floor(Math.random() * alphabet.length);
		result += alphabet[randomIndex];
	}
	return result;
}

export default function renderTextByAccess(
	state,
	patient,
	field,
	fixed,
	concatenate
) {
	if (state.userType === 'admin' && state.admin.super) return patient[field];

	if (verifyAccess(state, field)) {
		return patient[field];
	} else {
		
		return (
			<span className="blur-sm select-none">
				{replaceWithRandomLetters(patient[field], fixed)} {concatenate}
			</span>
		);
	}
}
