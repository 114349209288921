import {View, Text, Image} from "@react-pdf/renderer";
import Services from "./Services";
import Products from "./Products";
import React, {useState, useEffect, useRef} from "react";
export default function Table({transaction, type, receiptAmount, location}) {
  let obj = {};
  let showProducts = transaction.products?.length > 0;
  let serviceTax = parseFloat(transaction.serviceTax || 0);
  let productTax = parseFloat(transaction.productTax || 0);

  let patientAmount = parseFloat(transaction.patientAmount ?? 0);
  let tppAmount = parseFloat(transaction.tppAmount ?? 0);

  let amountPaidByPatient = parseFloat(transaction.amountPaidByPatient ?? 0);
  let amountPaidByTpp = parseFloat(transaction.amountPaidByTpp ?? 0);

  let serviceAmount = parseFloat(transaction.serviceAmount ?? 0);
  let productAmount = parseFloat(transaction.productAmount ?? 0);

  let serviceSubTotal = serviceAmount / (1 + serviceTax / 100);
  let productSubTotal = productAmount / (1 + productTax / 100);

  let otbp = patientAmount - amountPaidByPatient;
  let otbi = tppAmount - amountPaidByTpp;

  let totalCost = patientAmount + tppAmount;

  let productFraction = productAmount / (serviceAmount + productAmount);
  let serviceFraction = serviceAmount / (serviceAmount + productAmount);

  let patientProductTotal = patientAmount * productFraction;
  let patientServiceTotal = patientAmount * serviceFraction;

  let patientProductSubtotal = patientProductTotal / (1 + productTax / 100);
  let patientServiceSubtotal = patientServiceTotal / (1 + serviceTax / 100);

  let tppProductTotal = tppAmount * productFraction;
  let tppServiceTotal = tppAmount * serviceFraction;

  let tppProductSubtotal = tppProductTotal / (1 + productTax / 100);
  let tppServiceSubtotal = tppServiceTotal / (1 + serviceTax / 100);

  let patientSubtotalPaid = (
    (amountPaidByPatient * productFraction) / (1 + productTax / 100) +
    (amountPaidByPatient * serviceFraction) / (1 + serviceTax / 100)
  ).toFixed(2);

  let tppSubtotalPaid = (
    (amountPaidByTpp * productFraction) / (1 + productTax / 100) +
    (amountPaidByTpp * serviceFraction) / (1 + serviceTax / 100)
  ).toFixed(2);

  let productSuptotalAmount = productAmount / (1 + productTax / 100);
  let serviceSuptotalAmount = serviceAmount / (1 + serviceTax / 100);

  let receiptSubtotal = (
    ((receiptAmount || 0) * productFraction) / (1 + productTax / 100) +
    ((receiptAmount || 0) * serviceFraction) / (1 + serviceTax / 100)
  ).toFixed(2);

  let headers = ["Name", "Description", "Date", "Qty", "Unit Price"];
  return (
    <View
      style={{
        flexDirection: "col",
        justifyContent: "center",
        display: "flex",
        marginTop: 5,
      }}
    >
      <Services
        {...{
          headers,
          rowsData: [
            [
              transaction.serviceType,
              `${
                transaction.serviceDescription
                  ? transaction.serviceDescription + " "
                  : ""
              }(${transaction.time})`,
              new Date(transaction.serviceDate).toLocaleString().split(",")[0],
              1,
              transaction.paidByMembership
                ? "Covered by membership"
                : serviceSubTotal.toFixed(2),
            ],
          ],
          serviceSubTotal: serviceSubTotal.toFixed(2),
          serviceTaxAmount: serviceAmount - serviceSubTotal,
          serviceTotal: serviceAmount,
          serviceTax,
        }}
      />
      {transaction.products?.length > 0 && (
        <Products
          {...{
            headers,
            rowsData: transaction.products.map((p) => {
              return [
                p.name,
                p.description,
                new Date(transaction.serviceDate)
                  .toLocaleString()
                  .split(",")[0],
                p.quantity,
                p.price,
              ];
            }),
            productSubTotal: productSubTotal,
            productTaxAmount: productAmount - productSubTotal,
            productTotal: productAmount,
            productTax,
          }}
        />
      )}
      <Summary
        {...{
          totalCost,
          patientAmount,
          tppAmount,
          amountPaidByPatient,
          amountPaidByTpp,
          otbp,
          otbi,
          type,
          location,
          transaction,
        }}
      />
    </View>
  );
}

function Summary({
  totalCost,
  patientAmount,
  tppAmount,
  amountPaidByPatient,
  amountPaidByTpp,
  otbp,
  otbi,
  type,
  location,
  transaction,
}) {
  let tpp = transaction.tpp;
  let display;
  let style5 = {
    padding: 10,
    fontSize: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
    gap: 3,
  };
  let style6 = {
    padding: 2,
    fontWeight: 500,
    fontSize: 12,
    padding: 3,
  };
  let style1 = {
    with: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    marginTop: 12,
    gap: 3,
    marginRight: 35,
  };
  let styleAbolute = {
    position: "absolute",
    top: 20,
    left: 10,
    width: "50%",
  };

  return (
    <View
      style={{
        borderTop: "1px solid black",
        paddingHorizontal: 15,
        marginTop: "10px",
        position: "relative",
      }}
    >
      {location?.invRecNotes && (
        <View style={styleAbolute}>
          <Text style={{fontSize: 10, fontWeight: 600}}>{`Notes:`}</Text>
          <View>
            <Text style={{fontSize: 10}}>{location?.invRecNotes}</Text>
          </View>
        </View>
      )}
      {!type.match(/PATIENT_INVOICE/) && !type.match(/INSURER_INVOICE/) && (
        <View style={style1}>
          <View style={style5}>
            <View style={style6}>
              <Text>Total Amount</Text>
            </View>
          </View>
          <View style={style5}>
            <View style={{...style6, fontWeight: 900}}>
              <Text>{`$${parseFloat(totalCost).toFixed(2)}`}</Text>
            </View>
          </View>
        </View>
      )}
      {type.match(/PATIENT_INVOICE/) && (
        <View style={{...style1}}>
          <View style={style5}>
            <View style={style6}>
              <Text>Total Amount</Text>
            </View>
            <View style={style6}>
              <Text>Patient Share</Text>
            </View>
          </View>
          <View style={style5}>
            <View style={{...style6, fontWeight: 900}}>
              <Text>{`$${parseFloat(totalCost).toFixed(2)}`}</Text>
            </View>
            <View style={{...style6, fontWeight: 900}}>
              <Text>{`$${parseFloat(otbp).toFixed(2)}`}</Text>
            </View>
          </View>
        </View>
      )}
      {type.match(/INSURER_INVOICE/) && (
        <View style={{...style1}}>
          <View style={style5}>
            <View style={style6}>
              <Text>Total Amount</Text>
            </View>
            <View style={style6}>
              <Text>Insurer Share</Text>
            </View>
          </View>
          <View style={style5}>
            <View style={{...style6, fontWeight: 900}}>
              <Text>{`$${parseFloat(totalCost).toFixed(2)}`}</Text>
            </View>
            <View style={{...style6, fontWeight: 900}}>
              <Text>{`$${parseFloat(otbi).toFixed(2)}`}</Text>
            </View>
          </View>
        </View>
      )}
      {type.match(/FULL_INVOICE/) && (
        <View style={{...style1}}>
          <View style={style5}>
            <View style={style6}>
              <Text>Patient Share</Text>
            </View>
            {tpp && (
              <View style={style6}>
                <Text>Insurer Share</Text>
              </View>
            )}
          </View>

          <View style={style5}>
            <View style={{...style6, fontWeight: 900}}>
              <Text>{`$${parseFloat(patientAmount).toFixed(2)}`}</Text>
            </View>
            {tpp && (
              <View style={{...style6, fontWeight: 900}}>
                <Text>{`$${parseFloat(tppAmount).toFixed(2)}`}</Text>
              </View>
            )}
          </View>
        </View>
      )}

      {(type.match(/FULL_RECEIPT/) || type.match(/PATIENT_RECEIPT/)) && (
        <View style={style1}>
          <View style={style5}>
            <View style={style6}>
              <Text>Patient Share</Text>
            </View>
            <View style={style6}>
              <Text>{`Patient Amount Paid`}</Text>
            </View>
            <View style={style6}>
              <Text>Patient Amount Outstanding</Text>
            </View>
            <View style={style6}>
              <Text>Payment Method</Text>
            </View>
          </View>

          <View style={style5}>
            <View style={{...style6, fontWeight: 900}}>
              <Text>{`$${parseFloat(patientAmount).toFixed(2)}`}</Text>
            </View>
            <View
              style={{
                ...style6,
                borderRadius: 5,
                backgroundColor: "#d1fae5",
                fontWeight: 900,
              }}
            >
              <Text>{`$${parseFloat(amountPaidByPatient).toFixed(2)}`}</Text>
            </View>
            <View
              style={{
                ...style6,
                borderRadius: 5,
                backgroundColor: "#fee2e2",
                fontWeight: 900,
              }}
            >
              <Text>{`$${parseFloat(otbp).toFixed(2)}`}</Text>
            </View>
            <View
              style={{
                ...style6,
                borderRadius: 5,
                fontWeight: 900,
              }}
            >
              <Text>{transaction.paymentMethod}</Text>
            </View>
          </View>
        </View>
      )}

      {(type.match(/FULL_RECEIPT/) || type.match(/INSURER_RECEIPT/)) && tpp && (
        <View style={style1}>
          <View style={style5}>
            <View style={style6}>
              <Text>Insurer Share</Text>
            </View>
            <View style={style6}>
              <Text>{`Insurer Amount Paid`}</Text>
            </View>
            <View style={style6}>
              <Text>Insurer Amount Outstanding</Text>
            </View>
          </View>

          <View style={style5}>
            <View style={{...style6, fontWeight: 900}}>
              <Text>{`$${parseFloat(tppAmount).toFixed(2)}`}</Text>
            </View>
            <View
              style={{
                ...style6,
                borderRadius: 5,
                backgroundColor: "#d1fae5",
                fontWeight: 900,
              }}
            >
              <Text>{`$${parseFloat(amountPaidByTpp).toFixed(2)}`}</Text>
            </View>
            <View
              style={{
                ...style6,
                borderRadius: 5,
                backgroundColor: "#fee2e2",
                fontWeight: 900,
              }}
            >
              <Text>{`$${parseFloat(otbi).toFixed(2)}`}</Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}
