import axios from "axios";

import {url} from "../../../../crudRequests";

export async function processAudio(
  state,
  audio,
  selectedAppointment,
  onUploadProgress
) {
  const formData = new FormData();
  formData.append("audioData", audio, "audio.wav");
  formData.append("aid", selectedAppointment);
  formData.append("db", state.db);
  //formData.append("dName", state.doctor?.name || state.admin?.name);

  return axios({
    method: "POST",
    url: `${url}/processAudio`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    onUploadProgress,
  });
}
