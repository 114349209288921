import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  IconButton,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Tooltip,
  Icon,
  HStack,
} from "@chakra-ui/react";

export default function Loader() {
  return Array.from({length: 5}).map((_, i) => (
    <Tr key={i}>
      <Td py="2" px="1">
        <Skeleton height="20px" />
      </Td>
      <Td py="2" px="1">
        <Skeleton height="20px" />
      </Td>
    </Tr>
  ));
}
export function Loader2() {
  return Array.from({length: 5}).map((_, i) => (
    <Tr key={i}>
      <Td py="2" px="1">
        <Skeleton height="20px" />
      </Td>
      <Td py="2" px="1">
        <Skeleton height="20px" />
      </Td>
      <Td py="2" px="1">
        <Skeleton height="20px" />
      </Td>
    </Tr>
  ));
}
