import React, {useState, useEffect, useRef} from "react";

import {View, Text, Image} from "@react-pdf/renderer";

export default function ({transaction, type, receiptAmount}) {
  let obj = {};
  switch (type) {
    case "FULL_INVOICE":
    case "FULL_RECEIPT":
    case "PATIENT_INVOICE":
    case "PATIENT_RECEIPT":
      obj = {
        Id: (p) => p.id,
        "Product Name": (p) => p.name,
        Price: (p) => p.price,
        Quantity: (p) => p.quantity,
      };

      break;

    default:
      break;
  }

  return (
    <View
      style={{
        marginTop: 30,
        paddingHorizontal: 15,
        color: "#1f2937",
        fontSize: 11,
      }}
    >
      <View
        style={{
          flexDirection: "col",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            borderTop: "1px solid #1f2937",
            borderRight: "1px solid #1f2937",
          }}
        >
          {Object.keys(obj).map((key, i) => (
            <View
              style={{
                flex: 1,
                padding: 5,
                flexWrap: "wrap",
                flexDirection: "row",
                display: "flex",
                fontWeight: 600,
                alignItems: "flex-start",
                justifyContent: "center",
                borderLeft: "1px solid #1f2937",
                backgroundColor: "#e2e8f0",
              }}
            >
              {key.split(" ").map((e, i) => (
                <Text style={{margin: 1}} key={i}>
                  {e}
                </Text>
              ))}
            </View>
          ))}
        </View>
        {(transaction.products || []).map((p, i) => (
          <View
            key={i}
            style={{
              flexDirection: "row",
              justifyContent: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              borderTop: "1px solid #1f2937",
              borderRight: "1px solid #1f2937",
              borderBottom: "1px solid #1f2937",
              marginTop: "-1px",
            }}
          >
            {Object.values(obj).map((val, i) => (
              <Text
                key={i}
                style={{
                  flex: 1,
                  padding: 6,
                  paddingHorizontal: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  fontWeight: 500,
                  textAlign: "center",
                  borderLeft: "1px solid #1f2937",
                }}
              >
                {val(p)}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
}
