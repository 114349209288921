import {crud} from "../../../../crudRequests";

export async function saveNote(
  newNote,
  payload,
  selectedNote,
  dashState,
  setIsLoading,
  toast,
  setNotes,
  toastText = null,
  keyNote
) {
  try {
    setIsLoading(true);
    if (selectedNote) {
      const updatePromises = [
        crud(dashState, [
          {
            db: dashState.db,
            collection: "patientNotes",
            parameters: [{noteId: selectedNote.noteId}, {$set: payload}],
            method: "updateOne",
          },
        ]),
      ];
    
      if (keyNote && keyNote.noteId !== selectedNote.noteId) {
        updatePromises.push(
          crud(dashState, [
            {
              db: dashState.db,
              collection: "patientNotes",
              parameters: [{noteId: keyNote.noteId}, {$set: {keyNote: false}}],
              method: "updateOne",
            },
          ])
        );
      }

      await Promise.all(updatePromises);
    
      setNotes((prevNotes) =>
        prevNotes.map((note) => {
          if (note.noteId === selectedNote.noteId) {
            return { ...selectedNote, ...payload }
          }
          if (keyNote && note.noteId === keyNote.noteId) {
            return { ...keyNote, keyNote: false }
          }
          return note
        })
      );
      toast({
        title: "Note Updated",
        description: toastText ?? "Note information successfully updated",
        status: "success",
        isClosable: true,
        duration: 2000,
      });
    } else {
      let res = await crud(dashState, [
        {
          db: dashState.db,
          collection: "patientNotes",
          parameters: [newNote],
          method: "insertOne",
        },
      ]);
      setNotes((prev) => [newNote].concat(prev));
      toast({
        title: "Note Updated",
        description: "Note information successfully saved",
        status: "success",
        isClosable: true,
        duration: 2000,
      });
    }
  } catch (error) {
    toast({
      title: "Note Not Saved",
      description: error?.message || "An error occurred.",
      status: "error",
      isClosable: true,
      duration: 2000,
    });
  } finally {
    setIsLoading(false);
  }
}
