import {lightFormat} from "date-fns";

const criteria = ["first", "second", "third", "fourth", "last"];
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export function monthSecondCriteria(date) {
  let d =
    date && new Date(date) !== "Invalid Date" ? new Date(date) : new Date();

  let n = d.getDate();
  let the = Math.ceil(n / 7);
  the = criteria[the - 1];

  return {the, weekday: weekday[d.getDay()], every: 1};
}

export function yearFirstCriteria(date) {
  let d =
    date && new Date(date) !== "Invalid Date" ? new Date(date) : new Date();

  let day = d.getDate();

  return {day, month: d.getMonth()};
}

export function yearSecondCriteria(date) {
  let d =
    date && new Date(date) !== "Invalid Date" ? new Date(date) : new Date();

  let n = d.getDate();
  let the = Math.ceil(n / 7);
  the = criteria[the - 1];

  return {the, weekday: d.getDay(), month: d.getMonth()};
}
