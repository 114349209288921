import axios from "axios";
import {crudStorage} from "./crudStorage";
import {apiAction, crud, url} from "../../../../crudRequests";

export async function faxFile(dashState, toNumber, path, fileName, toast) {
  const faxingToast = toast({
    title: "Preparing Fax",
    description: "Please wait a few seconds for the fax to process.",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });

  const fromNumber = dashState.locations?.filter(
    (loc) => loc.lid === dashState.selectedLocation
  )?.[0].phaxioNumber;

  if (fromNumber) {
    crudStorage(dashState, "download", path + fileName, "brightlighthealth", "")
      .then(async (res) => {
        return axios({
          method: "POST",
          url: `${url}/fax`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + dashState.jwt,
          },
          data: JSON.stringify({
            toNumber: faxNumbreFormat(toNumber),
            fromNumber: faxNumbreFormat(fromNumber),
            fileUrl: res?.data,
          }),
        })
          .then((res) => {
            toast.close(faxingToast);
            const pid = path.split("/")[0];
            crud(dashState, [
              {
                db: dashState.db || "blh",
                collection: "auditLogs",
                parameters: [
                  {
                    collection: "files",
                    action: "FAX-FILE",
                    triggeredBy: dashState.admin.id,
                    patientId: pid,
                    payload: {
                      fileName: fileName,
                      path: path,
                      toNumber: faxNumbreFormat(toNumber),
                      fromNumber: faxNumbreFormat(fromNumber),
                      timestamp: new Date().getTime(),
                    },
                  },
                ],
                method: "insertOne",
              },
            ]);
            if (path.includes("Requisition")) {
              apiAction(dashState, {
                action: "FAX-REQUISITION",
                payload: {
                  fileName,
                  path,
                  toNumber: faxNumbreFormat(toNumber),
                  fromNumber: faxNumbreFormat(fromNumber),
                },
              });
            }
            toast({
              title: "Successfully faxed file",
              description:
                "The selected file was successfully faxed to " + toNumber + ".",
              status: "success",
              isClosable: true,
            });
          })
          .catch((err) => {
            toast.close(faxingToast);
            toast({
              title: "Failed to fax file",
              description:
                err?.message || "An error occurred while faxing file.",
              status: "error",
              isClosable: true,
            });
          });
      })
      .catch((err) => {
        toast.close(faxingToast);
        toast({
          title: "Failed to fetch file",
          description:
            err?.message || "An error occurred while fetching file for faxing.",
          status: "error",
          isClosable: true,
        });
      });
  } else {
    toast.close(faxingToast);
    toast({
      title: "Failed to fax file",
      description:
        "No fax number is set up for this location. Please ensure your clinic has provisioned a fax number. If you are an administrator, go to Admin > Faxes to provision a fax number, otherwise contact your administrator to do so for you.",
      status: "error",
      isClosable: true,
    });
  }
}
function faxNumbreFormat(n) {
  let f = String(n ?? "");
  f = f.replace(/[^0-9+]+/g, "");
  if (!f.startsWith("+")) f = `+1${f}`;

  return f;
}
