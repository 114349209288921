import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
  Text,
  Button,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import {stringifyValue} from "./helpers/stringifyValue";
import {useEffect, useState} from "react";
import {lightFormat} from "date-fns";
import AidClaimModal from "../appointments/clinicAidClaimModal";
import {Stack} from "react-bootstrap";
import {getFileData} from "../appointments/payment/helpers/getFileData"; //"./helpers/getFileData";
import PreviewFile from "../appointments/payment/helpers/PreviewFile";
import {crudStorage} from "../appointments/payment/helpers/crudStorage";
import PrintInvRec from "./PrintInvRec";
import {membershipFileNameFormat} from "../appointments/payment/uploads";

const getTransactionTypePath = (type) => {
  switch (type) {
    case "service":
      return "Non-Session-Services";
    case "appointment":
      return "Appointments";
    case "product":
      return "Products";
    case "membership":
      return "Memberships";
    default:
      return "";
  }
};
const getId = (transaction) => {
  switch (transaction?.type) {
    case "product":
      return transaction?.tid;
    case "membership":
      return transaction?.tid;
    default:
      return transaction?.aid;
  }
};

export default function InvoicOrReceipt({
  transaction,
  dashState,
  setPreview,
  fetchingFileData,
  setFetchingFileData,
  setSelectedTr,
  toast,
}) {
  return (
    <Stack
      onClick={async (e) => {
        e.stopPropagation();
      }}
    >
      {
        <>
          <Badge
            colorScheme="orange"
            rounded="md"
            px="2"
            py="0.5"
            cursor={!fetchingFileData ? "pointer" : "not-allowed"}
            onClick={async (e) => {
              e.stopPropagation();
              try {
                if (!fetchingFileData) {
                  setFetchingFileData(true);
                  setSelectedTr(transaction);

                  const formatFileName = (transaction) => {
                    return transaction?.type === "membership"
                      ? membershipFileNameFormat(transaction)
                      : fileNameFormat(transaction, getId(transaction));
                  };

                  const invoicesPath = `${
                    transaction?.pid || ""
                  }/Invoices/${getTransactionTypePath(
                    transaction?.type
                  )}/${formatFileName(transaction)}`;

                  let fullInvoiceFile = await crudStorage(
                    dashState,
                    "view",
                    "",
                    "brightlighthealth",
                    invoicesPath
                  );

                  let file = fullInvoiceFile.data?.find(
                    (f) =>
                      f.name.slice(-1) !== "/" &&
                      f.name.includes("Consolidated_Invoice")
                  );
                  if (transaction && file) {
                    getFileData(
                      transaction,
                      dashState,
                      invoicesPath,
                      file.name,
                      setPreview,
                      toast,
                      setFetchingFileData,
                      "full",
                      "invoice"
                    );
                  } else {
                    toast({
                      title: "This file cannot be previewed.",
                      description: "Invoice could not be found!",
                      status: "warning",
                      isClosable: true,
                    });
                    setFetchingFileData(false);
                  }
                }
              } catch (error) {
                toast({
                  title: "This file cannot be previewed.",
                  description: "Invoice could not be found!",
                  status: "warning",
                  isClosable: true,
                });
                setFetchingFileData(false);
              }
            }}
          >
            Invoice
          </Badge>{" "}
          {(transaction?.amountPaidByPatient > 0 ||
            transaction?.amountPaidByTpp > 0) && (
            <>
              <Badge
                colorScheme="green"
                rounded="md"
                px="2"
                py="0.5"
                cursor={!fetchingFileData ? "pointer" : "not-allowed"}
                onClick={async (e) => {
                  e.stopPropagation();
                  try {
                    if (!fetchingFileData) {
                      setFetchingFileData(true);
                      setSelectedTr(transaction);
                      const formatFileName = (transaction) => {
                        return transaction?.type === "membership"
                          ? membershipFileNameFormat(transaction)
                          : fileNameFormat(transaction, getId(transaction));
                      };
                      const receiptsPath = `${
                        transaction?.pid || ""
                      }/Receipts/Appointments/${getTransactionTypePath(
                        transaction?.type
                      )}/${formatFileName(transaction)}`;
                      let fullReceiptFile = await crudStorage(
                        dashState,
                        "view",
                        "",
                        "brightlighthealth",
                        receiptsPath
                      );

                      let file = fullReceiptFile.data?.find(
                        (f) =>
                          f.name.slice(-1) !== "/" &&
                          f.name.includes("Consolidated_Receipt")
                      );
                      if (transaction && file) {
                        getFileData(
                          transaction,
                          dashState,
                          receiptsPath,
                          file.name,
                          setPreview,
                          toast,
                          setFetchingFileData,
                          "full",
                          "receipt"
                        );
                      } else {
                        toast({
                          title: "This file cannot be previewed.",
                          description: "Receipt could not be found!",
                          status: "warning",
                          isClosable: true,
                        });
                        setFetchingFileData(false);
                      }
                    }
                  } catch (error) {
                    console.log(error);
                    toast({
                      title: "This file cannot be previewed.",
                      description: "Receipt could not be found!",
                      status: "warning",
                      isClosable: true,
                    });
                    setFetchingFileData(false);
                  }
                }}
              >
                Receipt
              </Badge>
            </>
          )}
        </>
      }
    </Stack>
  );
}

function fileNameFormat(transaction, id) {
  return (
    lightFormat(new Date(transaction.serviceDate), "dd-MM-yy HH-mm aaa") +
    ` (${id})/`
  );
}
//<PrintInvRec transaction={transaction} state={dashState} />
