import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

export default function Risk({intake}) {
  const highestRiskLevel = intake?.symptoms?.reduce((max, current) => {
    let currentRiskLevel = parseInt(current?.symptom?.riskLevel, 10);
    return currentRiskLevel > max ? currentRiskLevel : max;
  }, 1);

  const riskText =
    highestRiskLevel == 5
      ? "Very High Risk"
      : highestRiskLevel == 4
      ? "High Risk"
      : highestRiskLevel == 3
      ? "Intermediate Risk"
      : highestRiskLevel == 2
      ? "Low Risk"
      : highestRiskLevel == 1
      ? "Very Low Risk"
      : "Unknown Risk";

  return (
    <Alert
      status={
        highestRiskLevel > 3
          ? "error"
          : highestRiskLevel > 2
          ? "warning"
          : "info"
      }
      my="2"
      rounded="lg"
    >
      <AlertIcon />
      <Box>
        <AlertTitle>{riskText} Indication</AlertTitle>
        <AlertDescription>
          Based on the intake assessment, the patient appears to be at a risk
          level of{" "}
          <b>
            {highestRiskLevel || "0"} - {riskText}
          </b>
          .{" "}
          {highestRiskLevel > 3 &&
            "Please treat the case with caution and consult the patient immediately."}
        </AlertDescription>
      </Box>
    </Alert>
  );
}
