import {updateDoctor, crud} from "../crudRequests";
import {mergeIntervals} from "./helpers";

import {
  startOfWeek,
  endOfWeek,
  lightFormat,
  isWithinInterval,
  startOfMonth,
  endOfMonth,
  add,
  sub,
  differenceInMinutes,
  startOfDay,
  isSameWeek,
  isSameMonth,
  isSameDay,
  setDay,
  getDay,
} from "date-fns";

const monthsNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const shtortWeekday = ["M", "T", "W", "Th", "F", "Sat", "Sun"];

export function currentWeekOrMonth(
  date,
  daysInInterval,
  weekdays,
  doctorsList,
  range,
  state,
  dispatch
) {
  let intervals = [];
  intervals = daysInInterval
    .filter((e) => weekdays.has((e.getDay() + 6) % 7))
    .map((d) => {
      let start = new Date(d);
      let end = new Date(d);
      return [
        start.setHours(...range[0].split(":"), 0, 0),
        end.setHours(...range[1].split(":"), 0, 0),
      ];
    });

  let arr = [];

  [...doctorsList.keys()].forEach((did) => {
    arr.push(
      updateDoctorUnavailableTimes(
        state.jwt,
        did,
        intervals,
        state.doctorsPayload?.[did].unavailable || state.doctor.unavailable,
        dispatch,
        undefined,
        null,
        state.db
      )
    );
  });
  return Promise.all(arr);
}

export function untilRemoved(
  date,
  daysInInterval,
  weekdays,
  doctorsList,
  range,
  state,
  dispatch
) {
  let intervals = [];

  intervals = daysInInterval
    .filter((e) => weekdays.has((e.getDay() + 6) % 7))
    .map((d) => {
      let start = new Date(d);
      let end = new Date(d);
      return [
        start.setHours(...range[0].split(":"), 0, 0),
        end.setHours(...range[1].split(":"), 0, 0),
      ];
    });

  let arr = [];

  [...doctorsList.keys()].forEach((did) => {
    arr.push(
      updateDoctorUnavailableTimes(
        state.jwt,
        did,
        intervals,
        state.doctorsPayload?.[did].unavailable || state.doctor.unavailable,
        dispatch,
        true,
        date,
        state.db
      )
    );
  });
  return Promise.all(arr);
}

async function updateDoctorUnavailableTimes(
  jwt,
  did,
  intervals,
  unavailable,
  dispatch,
  untilRemoved,
  date,
  db
) {
  let mergedIntervals, newUnavailable;

  if (untilRemoved) {
    let u = (unavailable.untilRemoved || []).map(([start, end]) => {
      return [setToCurrentWeek(date, start), setToCurrentWeek(date, end)];
    });

    mergedIntervals = mergeIntervals(intervals.concat(u));
    newUnavailable = {...unavailable, untilRemoved: mergedIntervals};
  } else {
    mergedIntervals = mergeIntervals(
      intervals.concat(unavailable.intervals || [])
    );
    newUnavailable = {...unavailable, intervals: mergedIntervals};
  }

  return crud({jwt}, [
    {
      db: db,
      collection: "doctors",
      parameters: [{did}, {$set: {unavailable: newUnavailable}}],
      method: "updateOne",
    },
  ])
    .then((res) => {
      dispatch({
        type: "ADD_UNAVAILABLE",
        payload: {
          did,
          unavailable: newUnavailable,
        },
      });
    })
    .catch(function (error) {
      window.location.href = "../login";
      throw error;
    });
}

export function validateValues(doctorsList, weekdays, range) {
  let doctorsListError = "Please select any doctor(s)!";
  let weekdaysError = "Please select any Day(s) of the week!";
  let rangeError = "Please select a valid unavailable range!";
  let startRange = new Date(0);
  let endRange = new Date(0);
  let dif =
    endRange.setHours(...range[1].split(":"), 0, 0) -
    startRange.setHours(...range[0].split(":"), 0, 0);

  if (doctorsList.size === 0) return {error: doctorsListError};
  if (weekdays.size === 0) return {error: weekdaysError};
  if (!(dif > 0)) return {error: rangeError};

  return {};
}

export function setToCurrentWeek(currentDate, oldDate) {
  let old = new Date(oldDate);
  let diff = differenceInMinutes(oldDate, startOfDay(oldDate));
  let newDate = setDay(currentDate, old.getDay(), {weekStartsOn: 1});
  newDate = startOfDay(newDate);
  newDate.setMinutes(diff);
  return newDate.getTime();
}

export function slotsToString({
  date,
  schState,
  selectedDids,
  selectedCriteria,
  doctorsRef,
}) {
  let doctor =
    schState.userType === "admin"
      ? schState.doctors[[...doctorsRef.current][0]]
      : schState.selectedDoctor;
  switch (selectedCriteria) {
    case "current week": {
      let timesMap = {};

      let intervals = doctor.unavailable.intervals || [];
      intervals.forEach(([start, end], i) => {
        if (isSameWeek(date, start, {weekStartsOn: 1})) {
          let f = `${lightFormat(start, "HH:mm")}-${lightFormat(end, "HH:mm")}`;
          timesMap[f] ??= {weekdaysSet: new Set(), indexSet: new Set()};
          timesMap[f].weekdaysSet.add((getDay(start) + 6) % 7);
          timesMap[f].indexSet.add(i);
        }
      });

      return Object.entries(timesMap).map(([key, value]) => {
        let timeString = key;
        let wString = [...value.weekdaysSet]
          .sort()
          .map((e) => shtortWeekday[e])
          .join(", ");
        let name = "Dr " + doctor.name;
        return {
          format: `${timeString}; ${wString}; ${name}`,
          indexSet: value.indexSet,
          doctor,
        };
      });
    }

    case "current month": {
      let timesMap = {};

      let intervals = doctor.unavailable.intervals || [];
      intervals.forEach(([start, end], i) => {
        if (isSameMonth(date, start)) {
          let f = `${lightFormat(start, "HH:mm")}-${lightFormat(end, "HH:mm")}`;
          timesMap[f] ??= {weekdaysSet: new Set(), indexSet: new Set()};
          timesMap[f].weekdaysSet.add((getDay(start) + 6) % 7);
          timesMap[f].indexSet.add(i);
        }
      });

      return Object.entries(timesMap).map(([key, value]) => {
        let timeString = key;
        let wString = [...value.weekdaysSet]
          .sort()
          .map((e) => shtortWeekday[e])
          .join(", ");
        let month = monthsNames[date.getMonth()];
        let name = "Dr " + doctor.name;
        return {
          format: `${timeString}; ${wString}; ${month}; ${name}`,
          indexSet: value.indexSet,
          doctor,
        };
      });
    }

    case "until removed": {
      let timesMap = {};

      let intervals = doctor.unavailable.untilRemoved || [];
      intervals.forEach(([start, end], i) => {
        let f = `${lightFormat(start, "HH:mm")}-${lightFormat(end, "HH:mm")}`;
        timesMap[f] ??= {weekdaysSet: new Set(), indexSet: new Set()};
        timesMap[f].weekdaysSet.add((getDay(start) + 6) % 7);
        timesMap[f].indexSet.add(i);
      });

      return Object.entries(timesMap).map(([key, value]) => {
        let timeString = key;
        let wString = [...value.weekdaysSet]
          .sort()
          .map((e) => shtortWeekday[e])
          .join(", ");
        let name = "Dr " + doctor.name;
        return {
          format: `${timeString}; ${wString}; ${name}`,
          indexSet: value.indexSet,
          doctor,
        };
      });
    }

    default:
      break;
  }
}
