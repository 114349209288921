import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
} from "react";
import InvRecpDF from "../../Invoice";
import {pdf} from "@react-pdf/renderer";
import {nanoid} from "nanoid";
import {lightFormat} from "date-fns";
import Swal from "sweetalert2";
import {
  fullInvoice,
  fullReceipt,
  patientInvoice,
  patientReceipt,
  insurerInvoice,
  insurerReceipt,
} from "./uploads";
import {
  generatePaymentLink,
  createCharge,
  crud,
  sendCardReqLink,
  sendInvRecEmail,
  getPaymentMethods,
  executePayment,
} from "../../../crudRequests";
import createPaymentRecord from "./helpers/createPaymentRecord";
import {isoToApptDate} from "../../../additional_files/helpers";
import {loadStripe} from "@stripe/stripe-js";
import jsPDFInvoice from "../../../additional_files/jsPDFInvoice";
import {BiChevronDown, BiLink} from "react-icons/bi";
import {crudStorageUpload} from "../../Patients/Files/helpers/crudStorageUpload";
import deleteFiles from "./helpers/deleteFiles";
import {Button, useToast} from "@chakra-ui/react";
let durations = [
  "15 min",
  "30 min",
  "45 min",
  "60 min",
  "75 min",
  "90 min",
  "105 min",
  "120 min",
];
let durationLabels = [
  "15 min",
  "30 min",
  "45 min",
  "1 hr",
  "1.25 h",
  "1.5 hr",
  "1.75 hr",
  "2 hr",
];

export default function CreateCharge({
  state,
  patient,
  stateChanger,
  appt,
  dispatch,
  socket,
  setErrorMessage,
  setSuccess,
}) {
  //console.log(state);
  const [sendingLink, setSendingLink] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(function () {
    if (state.db) {
      getPaymentMethods(state, patient.pid)
        .then((paymentMethods) => {
          setCards(paymentMethods.data);
          setSelectedCard(paymentMethods.data.find((c) => c?.default));
        })
        .catch((error) => {
          setErrorMessage(error.response?.data || error.message);
          console.log(error);
        });
    }
  }, []);

  return (
    <div className="relative   rounded-[10px] py-4  flex flex-col items-start space-y-4">
      <div className="flex justify-start items-center  px-8">
        <h1 className="text-[rgba(87,84,255,0.75)] font-semibold text-2xl leading-[25px]">
          Payment
        </h1>
      </div>
      <CardsList {...{cards, selectedCard, setSelectedCard}} />
      <div
        className="hover:text-yellow-400 cursor-pointer self-end text-[rgba(87,84,255,0.75)] mr-9 flex justify-end items-center"
        onClick={() => {
          setSendingLink(true);
          setErrorMessage(false);
          setSuccess(false);
          sendCardReqLink(state, patient)
            .then(() => {
              setSendingLink(false);
              setSuccess("The card request link has been sent!");
            })
            .catch((error) => {
              setSendingLink(false);
              setErrorMessage(error.response?.data?.toString());
              setSuccess(false);
            });
        }}
      >
        <span>
          <BiLink />
        </span>
        <span className="text-xs">Send Card Request</span>
        {sendingLink && (
          <span className="">
            <svg
              className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25 stroke-[4px]"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        )}
      </div>
      <SendForm
        state={state}
        patient={patient}
        appt={appt}
        stateChanger={stateChanger}
        dispatch={dispatch}
        socket={socket}
        setErrorMessage={setErrorMessage}
        setSuccess={setSuccess}
        selectedCard={selectedCard}
      />
    </div>
  );
}

function SendForm({
  state,
  patient,
  appt,
  stateChanger,
  dispatch,
  socket,
  setErrorMessage,
  selectedCard,
  setSuccess,
}) {
  const [sending, setSending] = useState(false);
  const toast = useToast();
  async function generateCharge() {
    try {
      //console.log(state.organization)

      const {value: description} = await Swal.fire({
        input: "textarea",
        title: "Payment Description",
        inputPlaceholder: "Type the description...",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value.match(/\w+/)) {
            return "You must write a description!";
          }
        },
      });
      if (description) {
        const stripe = await loadStripe(
          state.organization?.stpPublicKey || "Invalid key"
        );
        // const stripe = await loadStripe('pk_live_51JQK47EPLWeDUJKNV11VBcgI1FOqTT6vCvSiOuOSUJICouYfMxDh5H9KGLKD2M5IEdcSs3bBGesgPNoVru2Emnjt00jQxeXkj4');
        if (patient && stripe) {
          setSending(true);
          setSuccess(false);
          setErrorMessage(false);

          let sendInvEmail = async (schState, transaction, amountPaid) => {
            try {
              let doctor = null;
              if (schState.userType === "admin") {
                let index = schState.doctorsPayload[transaction["did"]].index;
                doctor = schState.doctors[index];
              } else {
                doctor = schState.selectedDoctor;
              }

              const response = await crud(schState, [
                {
                  db: schState.db,
                  collection: "insurers",
                  parameters: [{iid: transaction?.tpp || ""}],
                  method: "findOne",
                },
                {
                  db: schState.db,
                  collection: "doctors",
                  parameters: [{did: transaction.supervisorId || ""}],
                  method: "findOne",
                },
              ]);
              let res = response.data;

              let invData = {
                state: schState,
                transaction,
                doctor,
                patient,
                tpp: res[0],
                supervisor: res[1],
                receiptAmount:
                  amountPaid ||
                  (appt.patientAmount ?? appt.amount) -
                    (appt.amountPaidByPatient ?? 0),
                nonSessionService: appt.nonSessionService,
              };

              let blob = await patientReceipt(invData);
              await fullReceipt(invData);

              let dataURI = await new Promise((resolve, reject) => {
                try {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = function () {
                    resolve(reader.result);
                  };
                } catch (error) {
                  reject(error);
                }
              });
              let {date, time} = isoToApptDate(transaction.serviceDate);
              let location = await crud(schState, [
                {
                  db: schState.db,
                  collection: "locations",
                  parameters: [{lid: schState.selectedLocation}],
                  method: "findOne",
                },
              ]);
              location = location.data[0];
              let sender = location?.name;
              sendInvRecEmail(schState, {
                invoice: false,
                date,
                time,
                pEmail: transaction.pEmail,
                pName: transaction.pName,
                dName: transaction.dName,
                sender: sender || schState?.organization?.name,
                attachments: [
                  {
                    filename: "Receipt.pdf",
                    path: dataURI,
                  },
                ],
              });
            } catch (error) {
              console.log(error);
            }
          };

          const handleServerResponse = async (response) => {
            try {
              if (response.error) {
                setSending(false);
                setErrorMessage(response.error);
              } else if (response.requires_action) {
                const {error: errorAction, paymentIntent} =
                  await stripe.handleCardAction(
                    response.payment_intent_client_secret
                  );
                /// console.log(paymentIntent)
                if (errorAction) {
                  setSending(false);
                  //  console.log(errorAction)
                  setErrorMessage(errorAction.message);
                } else {
                  const paymentResponset = await executePayment(
                    state,
                    appt,
                    patient.pid,
                    null,
                    paymentIntent.id
                  );

                  handleServerResponse(paymentResponset.data);
                }
              } else {
                if (response?.billing && response?.success) {
                  await sendInvEmail(
                    state,
                    response?.billing,
                    response?.amountPaid
                  );
                  await createPaymentRecord({
                    state,
                    transaction: response?.billing,
                    description,
                    date: Date.now(),
                    amount: response?.amountPaid,
                    payer: "patient",
                  });
                  if (response?.appointment)
                    dispatch({
                      type: "UPDATE_APPOINTMENT",
                      payload: response?.appointment,
                    });
                }

                //socket?.emit?.("update_appt", updateBody, {});
                // stateChanger(true);

                setSuccess("Successful payment!");
                toast({
                  title: "Successful payment!",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                stateChanger(false);
                setSending(false);
              }
            } catch (error) {
              setErrorMessage(error.response?.data || error.message);
            }
          };

          const paymentResponset = await executePayment(
            state,
            appt,
            patient.pid,
            selectedCard
          );
          handleServerResponse(paymentResponset.data);
        }
      }
    } catch (error) {
      setErrorMessage(error.response?.data || error.message);

      console.log(error);
      setSending(false);
    }
  }

  return (
    <form
      className="w-full flex justify-start items-center px-8"
      onSubmit={async (e) => {
        e.preventDefault();
      }}
    >
      <div className="bg-[#E7E6FF] rounded-2xl border flex flex-col w-full justify-between items-center space-y-7 p-7 pt-10">
        <div className="space-y-4 w-full">
          <button
            className={`${
              sending || !selectedCard ? "cursor-not-allowed" : ""
            } text-white relative p-4 w-full py-2 rounded-xl text-[18px] bg-[#5754FF] font-medium  focus:outline-none border-none flex justify-center items-center`}
            onClick={generateCharge}
            disabled={sending || !selectedCard}
          >
            <span>{`Generate charge (${(
              (appt.patientAmount ?? appt.amount) -
              (appt.amountPaidByPatient ?? 0)
            ).toFixed(2)} CAD)`}</span>

            {sending && (
              <span className="ml-1">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

function CardsList({cards, selectedCard, setSelectedCard}) {
  return (
    <div className="space-y-2 mt-4 w-full px-8">
      <div
        className="rounded-[10px] py-2 text-background font-semibold text-center mr-2"
        style={{backgroundColor: "#8F8CFF"}}
      >
        <ul className="flex justify-center items-center  lg:text-base">
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Card
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Exp Date
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Selected Card
          </li>
          {/*<li className="flex justify-center items-center flex-1 text-[16px]">Action</li>*/}
        </ul>
      </div>
      <div className="overflow-hidden flex justify-center max-h-[6rem]">
        <div className="overflow-y-scroll  w-full space-y-2 pr-2 pb-2 sbar2">
          {cards.length > 0 ? (
            cards.map((c, i) => {
              return (
                <div
                  key={i}
                  className=" rounded-[10px] mt-0 py-2 bg-[#DFDEFF] text-sm text-dark text-opacity-75 font-semibold text-center h-fit"
                  style={{
                    backgroundColor: c === selectedCard ? "#c4c2fb" : "#DFDEFF",
                  }}
                >
                  <ul className="flex justify-center items-center md:text-sm w-full">
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {"****" + c.card.last4}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {`${c.card.exp_month}/${c.card.exp_year}`}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      <div
                        className="bg-[#5754FF] rounded-full p-2 w-20 cursor-pointer transition duration-500"
                        onClick={() => {
                          setSelectedCard(c);
                        }}
                        style={{
                          backgroundColor:
                            c === selectedCard ? "#5754FF" : "#FFFFFF",
                          boxShadow:
                            c !== selectedCard && "0 0 0 2px inset #5754FF",
                        }}
                      >
                        <p
                          className="w-3 h-3 rounded-full bg-white transition duration-500"
                          style={{
                            transform:
                              c === selectedCard
                                ? "translateX(55px)"
                                : "translateX(0)",
                            backgroundColor:
                              c === selectedCard ? "#FFFFFF" : "#5754FF",
                          }}
                        ></p>
                      </div>
                    </li>
                  </ul>
                </div>
              );
            })
          ) : (
            <div
              className=" rounded-[10px] mt-0 py-2 bg-[#DFDEFF] text-sm text-dark text-opacity-75 font-semibold text-center h-fit"
              style={{
                backgroundColor: "#DFDEFF",
              }}
            >
              <span>No registered card</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
