import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  useToast,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {useContext, useState} from "react";
import {updatePatient} from "./helpers/updatePatient";
import UnlimitedInsurers from "./Fields/UnlimitedInsurers";
import {getPatientAidEligibility} from "../../../crudRequests";
import {checkResetParams} from "../../../authRequests";
export default function Insurance() {
  const [patient, setPatient] = useContext(PatientContext);
  const dispatch = useContext(DispatchContext);
  const dashState = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [lastInputLength, setLastInputLength] = useState(0);
  const [isValid, setIsValid] = useState({
    healthCardVerCode: true,
    healthCardExpiry: true,
  });

  const validateField = (field, value) => {
    let regex = "";
    switch (field) {
      case "healthCardVerCode":
        regex = /^([A-Z]{1,2})?$/;
        break;
      case 'healthCardExpiry':
        regex = /^(\d{0,4})(\/(0[1-9]?|1[0-2]?)?)?(\/(0[1-9]?|[12][0-9]?|3[01]?)?)?$/
        break;
      default:
        regex = "";
    }
    return regex.test(value);
  };

  return (
    <form
      onSubmit={async (e) => {
        try {
          setIsLoading(true);
          e.preventDefault();
          let hcn = patient?.healthCard?.healthCardNo;
          let hcvc = patient?.healthCard?.healthCardVerCode;
          if ((hcn && !hcvc) || (hcvc && !hcn)) {
            toast({
              title: "Information Not Updated",
              description:
                "Both the Health Card Number and the Health Card Ver. Code fields must be either filled in or left empty. Please ensure that both fields are completed or both are left blank.",
              status: "error",
              isClosable: true,
            });
            return;
          }
          if (patient?.healthCard?.healthCardNo) {
            let res = await getPatientAidEligibility({
              state: dashState,
              health_number: patient?.healthCard?.healthCardNo || "",
              ontario_version_code:
                patient?.healthCard?.healthCardVerCode || "",
              //signal,
            });
            console.log(res.data);
            /*toast({
              title: "Information Not Updated",
              description: res.data,
              status: "success",
              isClosable: true,
            });*/
          }

          if (Object.values(isValid).every((value) => value === true)) {
            await updatePatient(
              e,
              patient,
              dashState,
              setIsLoading,
              toast,
              dispatch
            );
          } else {
            toast({
              title: "Information Not Updated",
              description: "Invalid Fields",
              status: "error",
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: "Information Not Updated",
            description: error?.response?.data || error.message,
            status: "error",
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <VStack spacing={4}>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={6}
          mb="3"
        >
          <FormControl id="healthCardNo">
            <FormLabel>Health Card Number</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="999-999-999"
              value={patient.healthCard?.healthCardNo}
              onChange={(e) => {
                setPatient({
                  ...patient,
                  healthCard: {
                    ...patient?.healthCard,
                    healthCardNo: e.target.value,
                  },
                });
              }}
            />
          </FormControl>
          <FormControl
            id="healthCardVerCode"
            isInvalid={!isValid.healthCardVerCode}
          >
            <FormLabel>Health Card Ver. Code</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="YM"
              value={patient.healthCard?.healthCardVerCode}
              onChange={(e) => {
                const value = e.target.value.toUpperCase();
                const valid = validateField("healthCardVerCode", value);
                setPatient({
                  ...patient,
                  healthCard: {
                    ...patient?.healthCard,
                    healthCardVerCode: valid
                      ? value
                      : patient.healthCard?.healthCardVerCode
                      ? patient.healthCard.healthCardVerCode
                      : "",
                  },
                });
              }}
              onBlur={(e) => {
                if (patient.healthCard?.healthCardNo) {
                  setIsValid({
                    ...isValid,
                    healthCardVerCode: e.target.value.length < 2 ? false : true,
                  });
                } else {
                  setIsValid({...isValid, healthCardVerCode: true});
                }
              }}
            />
          </FormControl>

          <FormControl
            id="healthCardExpiry"
            isInvalid={!isValid.healthCardExpiry}
          >
            <FormLabel>Health Card Expiry</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="yyyy/mm/dd"
              value={patient.healthCard?.healthCardExpiry}
              onChange={(e) => {
                let value = e.target.value;
                if (value.length === 4 && value.length >= lastInputLength) {
                  value += '/';
                } else if (value.length === 7 && value.length >= lastInputLength) {
                  value += '/';
                }
                const valid = validateField("healthCardExpiry", value);
                setPatient({
                  ...patient,
                  healthCard: {
                    ...patient?.healthCard,
                    healthCardExpiry: valid
                      ? value
                      : patient.healthCard?.healthCardExpiry
                      ? patient.healthCard.healthCardExpiry
                      : "",
                  },
                });
                setLastInputLength(value.length);
              }}
              onBlur={(e) => {
                if (patient.healthCard?.healthCardNo){
                  setIsValid({...isValid, healthCardExpiry: e.target.value.length < 10 ? false : true})
                } else {
                  setIsValid({...isValid, healthCardExpiry: true});
                }
              }}
            />
          </FormControl>

          <FormControl id="healthCardState">
            <FormLabel>Health Card State</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="Ontario"
              value={patient.healthCard?.healthCardState || patient.state}
              onChange={(e) => {
                setPatient({
                  ...patient,
                  healthCard: {
                    ...patient?.healthCard,
                    healthCardState: e.target.value,
                  },
                });
              }}
            />
          </FormControl>
        </Grid>

        <UnlimitedInsurers />

        <Button
          isLoading={isLoading}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Save
        </Button>
      </VStack>
    </form>
  );
}
