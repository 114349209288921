import React from "react";
import {Box, Flex, Icon, Text, Spacer} from "@chakra-ui/react";

export const NavItem = ({
  selectedPatient,
  setPatient,
  isActive,
  link,
  icon,
  children,
  ...rest
}) => (
  <Box style={{textDecoration: "none"}} _focus={{boxShadow: "none"}}>
    <Flex
      align="center"
      p={selectedPatient ? "1" : "3"}
      mx="4"
      my="1"
      fontWeight={selectedPatient ? "regular" : "medium"}
      fontSize={selectedPatient && "12px"}
      borderRadius="lg"
      role="group"
      cursor={selectedPatient ? "" : "pointer"}
      bg={
        isActive(link.route, link.routes, link.name)
          ? selectedPatient
            ? "blue.600"
            : "blue.700"
          : "transparent"
      }
      color={"white"}
      _hover={
        !selectedPatient && {
          bg: "gray.700",
          color: "white",
        }
      }
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="18"
          color={"white"}
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
      <Spacer />
      {selectedPatient && (
        <Text fontWeight={"extrabold"} mx="3" cursor="pointer">
          &mdash;
        </Text>
      )}
    </Flex>
  </Box>
);
