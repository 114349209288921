import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  FormControl,
  Switch,
  Icon,
  Text,
  VStack,
  Textarea,
  Button,
  Center,
  Box,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import React, {useState, useRef} from "react";
import {MdInfoOutline} from "react-icons/md";
export default function CancelAppointment({
  onConfirm,
  onClose,
  isOpen,
  status,
  title,
  appt,
  noShowFee = null,
}) {
  const [allowAction, setAllowAction] = useState(false);
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [noShowFeeOverride, setNoShowFeeOverride] = useState(noShowFee);
  const initialRef = useRef(null);
  const actionLabel = {
    "cancel-appt": "Allow payment of this appointment?",
    "cancel-billing": "Cancel the appointment?",
    "no-show": "Charge No Show fee?",
  };
  const placeholder = {
    "cancel-appt": "Write the reason for the cancellation...",
    "no-show": "Write the reason for the change of status...",
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb="0">
          <Center>
            <Icon
              className="show-icon"
              boxSize={20}
              color="yellow.400"
              as={MdInfoOutline}
            />
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="0">
          <form
            id="my-form"
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoading(true);
              await onConfirm({reason, allowAction, status, noShowFeeOverride});
              onClose(false);
              setIsLoading(false);
            }}
          >
            <VStack gap={2}>
              <Text fontSize={"2xl"} textAlign={"center"} fontWeight={600}>
                {title}
              </Text>
              <Textarea
                ref={initialRef}
                required
                isInvalid={reason === ""}
                resize={"vertical"}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder={placeholder[status]}
                size="sm"
              />
            </VStack>
            <Box display="flex" w="100%" mt={4}>
              <FormControl display="flex" alignItems="center" w="60%">
                <FormLabel
                  color={allowAction ? "blue.500" : "gray.400"}
                  htmlFor="allowAction"
                  mb="0"
                  cursor={"pointer"}
                >
                  {actionLabel[status]}
                </FormLabel>
                <Switch
                  defaultChecked={status === "no-show"}
                  onChange={(e) => {
                    setAllowAction((prev) => !prev);
                  }}
                  id="allowAction"
                />
              </FormControl>
              {status === "no-show" && (
                <FormControl w="40%">
                  <InputGroup size="xs" w="8rem" ml={2}>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.500"
                      fontSize="12px"
                      children="$"
                      h="100%"
                    />
                    <Input
                      id="noShowFee"
                      isDisabled={allowAction}
                      type="text"
                      value={noShowFeeOverride ?? "0"}
                      onChange={(e) => {
                        const validNumber = /^\d+(\.\d{0,2})?$/;
                        if (
                          validNumber.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setNoShowFeeOverride(e.target.value);
                        }
                      }}
                    />
                  </InputGroup>
                </FormControl>
              )}
            </Box>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            type="submit"
            form="my-form"
            colorScheme="blue"
            mr={3}
          >
            Confirm
          </Button>
          <Button onClick={() => onClose(false)} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
