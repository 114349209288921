import React from "react";
import {Box, Drawer, DrawerContent, useDisclosure} from "@chakra-ui/react";
import SidebarContent from "./SidebarContent";
import TopBar from "./TopBar";
import TokenNotification from "../TokenNotification";
export default function Navigation({
  state,
  dispatch,
  patient,
  setPatient,
  children,
}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  return (
    <Box h="100vh" bg={"white"} overflowY={"hidden"}>
      <TokenNotification token={state?.jwt} />
      <SidebarContent
        state={state}
        patient={patient}
        setPatient={setPatient}
        onClose={() => onClose}
        display={{base: "none", md: "block"}}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            patient={patient}
            setPatient={setPatient}
            state={state}
            onClose={onClose}
          />
        </DrawerContent>
      </Drawer>
      <TopBar state={state} onOpen={onOpen} dispatch={dispatch} />
      <Box
        ml={{base: 0, md: 60}}
        p={5}
        pb="2"
        height="calc(100% - 96px)"
        display="flex"
        flexDirection="column"
        overflow={"hidden"}
      >
        {children}
      </Box>
    </Box>
  );
}
