import {crud} from "../../../crudRequests";
import {notificationsData} from "../../../additional_files/notifications";
import {v4 as uuidv4} from "uuid";
import {fileNameFormat, initialInvoices} from "../payment/uploads";
import {lightFormat} from "date-fns";
import {crudStorage} from "../../../storageRequests";

export default async function noShowConfirmation({
  toast,
  allowAction,
  reason,
  appt,
  state,
  dispatch,
  socket,
  noShowFeeOverride = null
}) {
  const chargeFee = !allowAction
  try {
    const updateBody = {
      ...appt,
      noShow: true,
      cancelled: false,
      arrived: false,
      changeStatusReadon: reason.trim(),
      noShowFee: noShowFeeOverride,
      amount: chargeFee ? noShowFeeOverride : 0,
      serviceAmount: chargeFee ? noShowFeeOverride : 0,
      patientAmount: chargeFee ? noShowFeeOverride : 0,
    };

    const [transactionRes, locationRes] = await Promise.all([
      crud(state, [
        {
          db: state.db,
          collection: "billing",
          parameters: [{ aid: appt.aid }],
          method: "findOne",
        },
      ]),
      crud(state, [
        {
          db: state.db,
          collection: "locations",
          parameters: [{ lid: state.selectedLocation }],
          method: "findOne",
        },
      ]),
    ]);

    const transaction = transactionRes.data[0]
    let location = locationRes.data[0]
    let sender = location?.name;
    let {ntf, ntfList} = notificationsData(
      state.userType,
      "Updated appointment",
      updateBody,
      state
    );
    let reqObj = [
      {
        db: state.db,
        collection: "appointments",
        parameters: [{aid: updateBody.aid}, {$set: updateBody}],
        method: "updateOne",
      },
      {
        db: state.db,
        collection: "notifications",
        parameters: [
          {userType: ntfList[0].userType},
          {$push: {[ntfList[0].id]: ntfList[0].notification}},
          {upsert: true},
        ],
        method: "findOneAndUpdate",
      }
    ];

    if ((!appt.paid && !chargeFee) || chargeFee) {
      reqObj.push({
        db: state.db,
        collection: "billing",
        parameters: [
          { aid: appt.aid },
          {
            $set: {
              cancelled: true,
              cancellationReason: updateBody.cancellationReason,
            },
          },
        ],
        method: "findOneAndUpdate",
      });

      const path = transaction?.pid + `/Invoices/${"Appointments"}/${fileNameFormat(transaction)}/`;

      const [mainRes, patientRes] = await Promise.all([
        crudStorage(state, "view", "", "brightlighthealth", path),
        crudStorage(state, "view", "", "brightlighthealth", path + "Patient/"),
      ]);

      const mainInvoiceToDelete = mainRes.data.find((file) =>
        file.name.includes("Consolidated_Invoice")
      );
      const patientFileToDelete = patientRes.data.length > 0 ? patientRes.data[0] : null;

      if (patientFileToDelete) {
        await Promise.all([
          crudStorage(state, "deleteFile", path + mainInvoiceToDelete.name, "brightlighthealth", ""),
          crudStorage(
            state,
            "deleteFile",
            path + "Patient/" + patientFileToDelete.name,
            "brightlighthealth",
            ""
          ),
        ]);
      }
    }

    if (chargeFee) {
      const newTransaction = {
        ...transaction,
        tid: uuidv4(),
        amount: noShowFeeOverride,
        serviceAmount: noShowFeeOverride,
        patientAmount: noShowFeeOverride,
        cancelled: false,
        date: `${lightFormat(new Date(), "dd/MM/yy HH:mm")}`,
      };

      await Promise.all([
        initialInvoices(appt, state, { ...transaction, cancelled: true }, false),
        initialInvoices(appt, state, newTransaction, true),
      ]);

      reqObj.push({
        db: state.db,
        collection: "billing",
        parameters: [newTransaction],
        method: "insertOne",
      },)
    }

    await crud(state, reqObj, {
      email: {
        type: "update_appointment",
        content: updateBody,
        options: { status: "No Show" },
        sender,
        org: state?.organization,
        location,
      },
    });

    dispatch({
      type: "UPDATE_APPOINTMENT",
      payload: updateBody,
    });
    socket?.emit?.("update_appt", updateBody, {ntf});


  } catch (e) {
    toast({
      title: "Update Error!",
      description: e.message,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    console.log(e);
  }
}
