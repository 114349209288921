function replaceWithRandomLetters(text) {
	const alphabet = 'abcdefghijklmnopqrstuvwxyz';
	let result = '';

	if (!text?.length || text?.length === 0) {
		return '';
	}
	for (let i = 0; i < text.length; i++) {
		const randomIndex = Math.floor(Math.random() * alphabet.length);
		result += alphabet[randomIndex];
	}
	return result;
}

export default function renderTextByAccess(text, clone) {
	return (
		<span className="blur-sm select-none">
			{clone ? text : replaceWithRandomLetters(text)}
		</span>
	);
}
