import React, {useState} from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
} from "@chakra-ui/react";
import {SearchableSelect} from "../SearchableSelect";

export const AssignClinicianPopover = ({
  clinicians,
  onAssign,
  assignedClinicians,
}) => {
  const [selectedClinician, setSelectedClinician] = useState(null);

  const handleAssign = (onClose) => {
    onAssign(selectedClinician);
    onClose();
  };
  return (
    <Popover placement="top">
      {({onClose}) => (
        <>
          <PopoverTrigger>
            <Button
              variant="ghost"
              fontWeight="light"
              width="100%"
              backgroundColor="blue.400"
              textColor="white"
            >
              Assign New Clinician
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader backgroundColor="gray.200">
              Assign Clinician
            </PopoverHeader>
            <PopoverBody>
              <SearchableSelect
                label="Assign to"
                options={clinicians
                  ?.filter(
                    (clinician) =>
                      !assignedClinicians.some(
                        (assigned) => assigned.did === clinician.did
                      )
                  )
                  .map((clinician) => ({
                    label: `${clinician.lastName}, ${clinician.firstName}`,
                    value: clinician.did,
                  }))
                  .sort((a, b) =>
                    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                  )}
                onChange={(value) => setSelectedClinician(value)}
              />
              <Flex mt={4} justifyContent="right" gap={2}>
                <Button
                  variant="ghost"
                  colorScheme="blue"
                  bg="white"
                  color="blue.500"
                  onClick={onClose}
                >
                  Go Back
                </Button>
                <Button
                  colorScheme="blue"
                  bg="blue.500"
                  color="white"
                  fontWeight="light"
                  onClick={() => handleAssign(onClose)}
                >
                  Assign Clinician
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
