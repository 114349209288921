import {crud} from "../../../../crudRequests";
import Swal from "sweetalert2";
export async function deleteNote(selectedNote, dashState, setNotes, toast) {
  try {
    if (selectedNote) {
      let result = await Swal.fire({
        title: "Are you sure you want to delete this note?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        await crud(dashState, [
          {
            db: dashState.db,
            collection: "patientNotes",
            parameters: [{noteId: selectedNote.noteId}],
            method: "deleteOne",
          },
        ]);
        setNotes((prev) =>
          prev.filter((nt) => {
            return nt.noteId !== selectedNote.noteId;
          })
        );
      }
    }
  } catch (error) {
    toast({
      title: "Note Not Deleted",
      description: error?.message || "An error occurred.",
      status: "error",
      isClosable: true,
    });
  } finally {
  }
}
