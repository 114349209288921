import {useState, useContext} from "react";
import {
  Button,
  Text,
  Textarea,
  VStack,
  HStack,
  Icon,
  useToast,
  Spacer,
  Badge,
  Tooltip,
} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {updateSummary} from "./helpers/updateSummary";
import {regenerateSummary} from "./helpers/regenerateSummary";
import {BiCopy} from "react-icons/bi";
import {RiInformationFill, RiDatabase2Line} from "react-icons/ri";
import SummaryMetadataModal from "./SummaryMetadataModal";

export default function IndividualSummary({
  unchangingType,
  type,
  setType,
  summary,
  setSummary,
}) {
  const dashState = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [summaryMetadata, setSummaryMetadata] = useState(false);
  const toast = useToast();

  return (
    summary && (
      <form
        onSubmit={(e) =>
          updateSummary(e, summary, dashState, setIsLoading, toast)
        }
      >
        <HStack my="1" spacing="0">
          <Badge
            onClick={() => {
              setType((prevType) => {
                return prevType.includes("Transcript")
                  ? unchangingType
                  : summary.diarizedTranscript
                  ? "diarizedTranscript"
                  : "nonDiarizedTranscript";
              });
            }}
            colorScheme={summary.diarizedTranscript ? "green" : "orange"}
            variant={type.includes("Transcript") ? "solid" : "subtle"}
            cursor="pointer"
            fontSize="12px"
            rounded="md"
            p="1"
            px="2"
          >
            {type.includes("Transcript") ? (
              "Hide Transcript"
            ) : summary.diarizedTranscript ? (
              "View Diarized Transcript"
            ) : (
              <Tooltip
                label="
                A diarized version of this transcript is still processing, and can take up to an hour to process."
              >
                <Text as="span">
                  <Icon as={RiInformationFill} mt="-0.5" fontSize={"15px"} />{" "}
                  View Non-Diarized Transcript
                </Text>
              </Tooltip>
            )}
          </Badge>
          <Spacer />
          <Button
            onClick={() => setSummaryMetadata(summary)}
            colorScheme="blue"
            variant="ghost"
          >
            View Metadata <Icon as={RiDatabase2Line} ml="1" />
          </Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(summary[type]);
              toast({
                title: "Copied to clipboard",
                status: "info",
                duration: 2000,
              });
            }}
            colorScheme="blue"
            variant="ghost"
          >
            Copy <Icon as={BiCopy} ml="1" />
          </Button>
        </HStack>
        <VStack spacing={4}>
          <Textarea
            id="textarea"
            value={summary[type]}
            onChange={(e) => setSummary({...summary, [type]: e.target.value})}
            whiteSpace="pre-wrap"
            overflowWrap="break-word"
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius="md"
            padding={2}
            width="100%"
            height="66.6vh"
            resize="none"
          />
          <HStack spacing={2}>
            <Button
              isLoading={isRegenerating && true}
              onClick={() => {
                regenerateSummary(
                  type,
                  summary,
                  setSummary,
                  dashState,
                  setIsRegenerating,
                  toast
                );
              }}
              colorScheme="blue"
              variant="outline"
              isDisabled={type.includes("Transcript")}
              px="10"
            >
              Regenerate
            </Button>
            <Button
              isLoading={isLoading && true}
              colorScheme="blue"
              type="submit"
              px="10"
            >
              Save
            </Button>
          </HStack>
        </VStack>
        <SummaryMetadataModal
          summaryMetadata={summaryMetadata}
          setSummaryMetadata={setSummaryMetadata}
        />
      </form>
    )
  );
}
