import axios from "axios";
import {url} from "./crudRequests";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch {
    window.location.href = "../login";
  }
}

export async function loginUser(email, password, userType, db) {
  clearTimeout(window.tmId || 0);
  window.sessionExpired = false;
  let config = {
    method: "POST",
    url: `${url}/loginUser`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({
      email: email,
      password: password,
      userType: userType,
      db,
    }),
  };
  return await axios(config);
}

export async function verifyUser(jwt, fadeInContent) {
  let config = {
    method: "POST",
    url: `${url}/verifyUser`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({id: jwt ? jwtDecode(jwt)?.id : ""}),
  };
  axios(config)
    .then(() => {
      var fc = 0;
      fadeInContent?.current &&
        (function fade() {
          if (fadeInContent?.current) {
            fadeInContent.current.style.opacity = fc = fc + 0.1;
            fadeInContent.current.style.opacity < 1 && setTimeout(fade, 30);
          }
        })();
      return;
    })
    .catch((e) => {
      console.log(e.message);
      window.location.href = "../login";
    });
}

export async function verifyLoginCode({jwt, code, userType, user, db}) {
  let config = {
    method: "POST",
    url: `${url}/verifyLoginCode`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({id: jwtDecode(jwt).id, code, userType, user, db}),
  };
  return axios(config);
}

export function setAuthCode({jwt, userType, user, db, organization}) {
  let config = {
    method: "POST",
    url: `${url}/setAuthCode`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(jwt).id,
      userType,
      user,
      db,
      organization,
    }),
  };
  return axios(config);
}

export function logoutUser() {
  window.location.href = "../login";
}

export async function resetUserEmail(email, userType, org) {
  let config = {
    method: "POST",
    url: `${url}/resetUser`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({email: email, userType: userType, org}),
  };
  return await axios(config);
}

export async function checkResetParams(reset, userType, db) {
  let config = {
    method: "POST",
    url: `${url}/checkResetParams`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({reset: reset, userType: userType, db}),
  };
  return await axios(config);
}
export async function checkApptConfirmation(params, date) {
  let config = {
    method: "POST",
    url: `${url}/appointmentConfirmation`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({params, date}),
  };
  return await axios(config);
}

export async function resetPassword(reset, userId, password, userType, db) {
  let config = {
    method: "POST",
    url: `${url}/resetPassword`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({
      reset: reset,
      userId,
      password: password,
      userType: userType,
      db,
    }),
  };
  return await axios(config);
}

export async function setCardInfo(params, pid, cardInfo, db) {
  let config = {
    method: "POST",
    url: `${url}/setCardInfo`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({
      params,
      userId: pid,
      cardInfo,
      db,
    }),
  };
  return await axios(config);
}
