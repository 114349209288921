import {
  Grid,
  Box,
  Text,
  useToast,
  Square,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import Swal from "sweetalert2";

import {crud} from "../../../crudRequests";

import {SlOptionsVertical} from "react-icons/sl";

import {useState} from "react";
export default function DeleteRecurrence({appt, state, dispatch}) {
  const [isLoading, setIsloading] = useState(false);
  const toast = useToast();
  return (
    <Flex mt="5" borderRadius="4px" justify={"flex-end"} align={"center"}>
      <Menu>
        <MenuButton
          px="2"
          as={IconButton}
          aria-label="Options"
          rightIcon={<SlOptionsVertical />}
          variant="outline"
          colorScheme="blue"
          fontSize={"xs"}
        >
          Recurrence
        </MenuButton>
        <MenuList>
          <MenuGroup
            title="Delete recurring event"
            fontSize={"sm"}
            textAlign={"start"}
            color={"blue.500"}
          >
            <Divider />
            <MenuItem
              onClick={async () => {
                if (!isLoading) {
                  setIsloading(true);

                  await thisEvent({state, appt, dispatch, toast});
                  setIsloading(false);
                }
              }}
              fontSize={"sm"}
              color={"gray.600"}
            >
              This event
            </MenuItem>
            <MenuItem
              fontSize={"sm"}
              onClick={async () => {
                if (!isLoading) {
                  setIsloading(true);

                  await thisAndSub({state, appt, dispatch, toast});
                  setIsloading(false);
                }
              }}
              color={"gray.600"}
            >
              This event and all subsequent ones
            </MenuItem>
            <MenuItem
              fontSize={"sm"}
              onClick={async () => {
                if (!isLoading) {
                  setIsloading(true);

                  await allEvents({state, appt, dispatch, toast});
                  setIsloading(false);
                }
              }}
              color={"gray.600"}
            >
              All events in the recurrence
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
}

async function thisEvent({state, appt, dispatch, toast}) {
  try {
    let result = await Swal.fire({
      title: "Delete this event",
      text: "Are you sure you would like to permanently remove this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it.",
    });
    if (result.isConfirmed) {
      const generatingToast = toast({
        title: "Deleting appointment",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });
      await crud(state, [
        {
          db: state.db,
          collection: "appointments",
          parameters: [{aid: appt.aid}],
          method: "deleteOne",
        },
        {
          db: state.db,
          collection: "billing",
          parameters: [{aid: appt.aid}],
          method: "deleteOne",
        },
      ]);
      dispatch({
        type: "DELETE_RECURRENCE",
        criteria: (a) => a.aid !== appt.aid,
        did: appt.did,
        lid: appt.lid,
      });
      toast.close(generatingToast);
    }
  } catch (error) {
    console.log(error);
  }
}
async function thisAndSub({state, appt, dispatch, toast}) {
  try {
    let result = await Swal.fire({
      title: "Delete this event and all subsequent ones",
      text: "Are you sure you would like to permanently remove this event and all subsequent ones?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it.",
    });
    if (result.isConfirmed) {
      const generatingToast = toast({
        title: "Deleting appointments",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });
      await crud(state, [
        {
          db: state.db,
          collection: "appointments",
          parameters: [
            {
              ISOdate: {
                $gte: appt.ISOdate,
              },
              recurrenceId: appt.recurrenceId,
            },
          ],
          method: "deleteMany",
        },
        {
          db: state.db,
          collection: "billing",
          parameters: [
            {
              serviceDate: {
                $gte: appt.ISOdate,
              },
              recurrenceId: appt.recurrenceId,
            },
          ],
          method: "deleteMany",
        },
      ]);
      dispatch({
        type: "DELETE_RECURRENCE",
        criteria: (a) =>
          !(a.recurrenceId === appt.recurrenceId && a.ISOdate >= appt.ISOdate),
        did: appt.did,
        lid: appt.lid,
      });
      toast.close(generatingToast);
    }
  } catch (error) {
    console.log(error);
  }
}
async function allEvents({state, appt, dispatch, toast}) {
  try {
    let result = await Swal.fire({
      title: "Delete all events",
      text: "Are you sure you would like to permanently remove all events?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it.",
    });
    if (result.isConfirmed) {
      const generatingToast = toast({
        title: "Deleting appointments",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });
      await crud(state, [
        {
          db: state.db,
          collection: "appointments",
          parameters: [
            {
              recurrenceId: appt.recurrenceId,
            },
          ],
          method: "deleteMany",
        },
        {
          db: state.db,
          collection: "billing",
          parameters: [
            {
              recurrenceId: appt.recurrenceId,
            },
          ],
          method: "deleteMany",
        },
      ]);
      dispatch({
        type: "DELETE_RECURRENCE",
        criteria: (a) => a.recurrenceId !== appt.recurrenceId,
        did: appt.did,
        lid: appt.lid,
      });
      toast.close(generatingToast);
    }
  } catch (error) {
    console.log(error);
  }
}
