import {
  VStack,
  useToast,
  Tbody,
  Tr,
  Td,
  Heading,
  Table,
  Thead,
  Th,
} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {fetchRelations} from "./helpers/fetchRelations";
import AddRelation from "./Relations/AddRelation";

export default function Relations() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [relations, setRelations] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchRelations(setRelations, dashState, patient, toast);
  }, []);

  return (
    <VStack justifyContent="center" alignItems="center">
      {" "}
      <Heading size="md" mb="4">
        Relations' Accounts
      </Heading>
      <Table borderRadius="lg" size="sm" maxW="md">
        <Thead>
          <Tr>
            <Th textAlign="center">Name</Th>
            <Th textAlign="center">Relation</Th>
            <Th textAlign="center">Email</Th>
            <Th textAlign="center">Phone</Th>
          </Tr>
        </Thead>
        <Tbody>
          {relations?.length > 0 ? (
            relations.map((r, i) => (
              <Tr key={i}>
                <Td textAlign="center">{r.name}</Td>
                <Td textAlign="center">{r.relation}</Td>
                <Td textAlign="center">{r.email}</Td>
                <Td textAlign="center">{r.phone}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={6} textAlign="center">
                No relations' accounts created
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <AddRelation relations={relations} setRelations={setRelations} />
    </VStack>
  );
}
