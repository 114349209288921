import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Button,
  ModalCloseButton,
  useToast,
  Textarea,
  Text,
} from "@chakra-ui/react";
import React, {useEffect, useRef} from "react";
import {crud, sendInvRecEmail} from "../../../../crudRequests";
import {BsFillFolderFill} from "react-icons/bs";
import defaultEmail from "./defaultEmail";

export default function SendEmail({state, preview, emailTo, setEmailTo}) {
  let [value, setValue] = React.useState(() =>
    defaultEmail({emailTo, relatedData: preview.relatedData, state})
  );

  const [disabledButton, setDisabledButton] = React.useState(false);
  let [prevEmailTo, setPrevEmailTo] = React.useState(emailTo);
  if (prevEmailTo !== emailTo) {
    setPrevEmailTo(emailTo);
    setValue(defaultEmail({emailTo, relatedData: preview.relatedData, state}));
  }

  const toast = useToast();

  return (
    <Modal
      isOpen={emailTo}
      onClose={() => {
        setEmailTo(false);
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Email to ${emailTo}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            minHeight={"150px"}
            placeholder="Write the message..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={async () => {
              try {
                if (!disabledButton) {
                  let email =
                    emailTo === "insurer"
                      ? preview.relatedData?.insurer?.email
                      : preview.relatedData?.patient?.email;
                  if (!email) {
                    toast({
                      title: "Email error.",
                      description: `The ${emailTo} does not have a registered email!.`,
                      status: "error",
                      isClosable: true,
                    });
                    return;
                  }
                  setDisabledButton(true);
                  var dataURI = `data:application/pdf;base64,${preview.data}`;

                  await sendInvRecEmail(state, {
                    invoice: "invoice" === preview.relatedData.entity,
                    templateType:
                      emailTo === "patient"
                        ? "invioce_receipt"
                        : "invioce_receipt_insurers",
                    text: value.replace(/\n/g, "<br/>"),
                    email,
                    sender: state?.organization?.name,
                    attachments: [
                      {
                        filename: `Appointment ${preview.relatedData.entity}.pdf`,
                        path: dataURI,
                      },
                    ],
                  });
                }
                setDisabledButton(false);
                setEmailTo(false);
              } catch (error) {
                setDisabledButton(false);
                toast({
                  title: "Email error.",
                  description: error.message,
                  status: "error",
                  isClosable: true,
                });
              }
            }}
            colorScheme="blue"
            isLoading={disabledButton}
            loadingText="Sending Email..."
            spinnerPlacement="start"
          >
            {`Send ${preview.relatedData.entity}`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
