import {
  Input,
  Box,
  Icon,
  VStack,
  Flex,
  Text,
  InputLeftElement,
  InputGroup,
  useToast
} from "@chakra-ui/react";

import {BsPlusCircleDotted} from "react-icons/bs";
import {useCallback} from "react";
import {debounce} from 'lodash'

export default function CodesList({
  setCList,
  setCodesType,
  getCodes,
  calculations,
  setCalculations,
  total,
  updateCalculations
}) {
  const toast = useToast()

  const debouncedGetCodes = useCallback(
    debounce((type, search_string) => {
      getCodes(type, search_string)
    }, 500),
    []
  )

  const valid = calculations.serviceCode && calculations.diagnosticCode && calculations.amount

  return (
    <Flex direction="column" align="start">
      <VStack maxH="150px" align="start" overflowY="scroll" p={1} w="full">
        <Flex justify="space-evenly" w="full" align="end" mb={2}>
          <Box>
            <Text fontSize="11px" color="gray.600">
              Service Codes <sup className="text-red-500 text-[10px]">*</sup>
            </Text>
            <Input
              size="xs"
              w="90px"
              value={calculations.serviceCode ?? ""}
              onFocus={() => {
                setCodesType("service");
              }}
              onChange={(e) => {
                debouncedGetCodes("service", e.target.value)
                updateCalculations("serviceCode", e.target.value)
              }}
            />
          </Box>
          <Box>
            <Text fontSize="11px" color="gray.600">
              Diagnostic Codes <sup className="text-red-500 text-[10px]">*</sup>
            </Text>
            <Input
              size="xs"
              w="90px"
              value={calculations.diagnosticCode ?? ""}
              type="number"
              onFocus={() => {
                setCodesType("diagnostic");
              }}
              onChange={(e) => {
                debouncedGetCodes("diagnostic", e.target.value)
                updateCalculations("diagnosticCode", e.target.value)
              }}
            />
          </Box>
          <Box>
            <Text fontSize="11px" color="gray.600">
              Amount <sup className="text-red-500 text-[10px]">*</sup>
            </Text>
            <InputGroup size="xs" w="90px">
              {calculations.amount && (
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.500"
                  fontSize="12px"
                  children="$"
                />
              )}
              <Input
                type="number"
                value={calculations.amount ?? ""}
                readOnly
              />
            </InputGroup>
          </Box>
          <Text>x</Text>
          <Box>
            <Text fontSize="11px" color="gray.600">
              Unit
            </Text>
            <Input
              size="xs"
              w="90px"
              type="number"
              value={calculations.unit ?? ""}
              onChange={(e) => {
                updateCalculations("unit", e.target.value)
              }}
              isDisabled={!valid}
            />
          </Box>
          <Text>+</Text>
          <Box>
            <Text fontSize="11px" color="gray.600">
              %
            </Text>
            <Input
              size="xs"
              w="90px"
              type="number"
              value={calculations.percentage ?? ""}
              onChange={(e) => {
                updateCalculations("percentage", e.target.value)
              }}
              isDisabled={!valid}
            />
          </Box>
          <Box>
            <Text fontSize="11px" color="gray.600">
              Total
            </Text>
            <InputGroup size="xs" w="90px">
              {calculations.total && (
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.500"
                  fontSize="12px"
                  children="$"
                />
              )}
              <Input
                size="xs"
                w="90px"
                value={calculations.total ?? ""}
                readOnly
              />
            </InputGroup>
          </Box>
          <Box>
            <Text fontSize="11px" color="gray.600">
              Override Fee
            </Text>
            <InputGroup size="xs" w="90px">
              {calculations.overrideFee && (
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.500"
                    fontSize="12px"
                    children="$"
                  />
                )}
              <Input
                size="xs"
                w="90px"
                type="number"
                value={calculations.overrideFee ?? ""}
                onChange={(e) => {
                  updateCalculations("overrideFee", e.target.value)
                }}
                isDisabled={!valid}
              />
            </InputGroup>
          </Box>
          <Box>
            <Icon
              cursor="pointer"
              onClick={() => {
                if (valid) {
                  total.current += parseFloat(calculations.total)
                  setCList((prev) => [...prev, calculations])
                  setCalculations({
                    serviceCode: null,
                    diagnosticCode: null,
                    amount: null,
                    unit: null,
                    percentage: null,
                    total: null,
                    overrideFee: null
                  })
                  setCodesType("service")
                  getCodes(null)
                } else {
                  toast({
                    title: "Incomplete Field",
                    description: "Please complete all the required fields.",
                    status: "warning",
                    duration: 2000,
                  });
                }
              }}
              as={BsPlusCircleDotted}
              w={6}
              h={6}
              mr={'0.5rem'}
            />
          </Box>
        </Flex>
      </VStack>
    </Flex>
  );
}
