export async function patientsFilter({
  searchText,
  limit,
  skip,
  patients,
  selectedDoctor,
}) {
  try {
    if (selectedDoctor) {
      patients = patients.filter((p) => p.did.includes(selectedDoctor.did));
    }

    patients = patients.filter((p) => {
      if (searchText.trim()) {
        // console.log(searchText);
        let fieds = ["fName", "lName", "email", "healthCard", "phone"];
        for (let f of fieds) {
          if (p[f]?.toString()?.toLowerCase().includes(searchText.trim()))
            return true;
        }
        if (
          ((p["fName"] || "") + " " + (p["lName"] || ""))
            .toLowerCase()
            .includes(searchText.trim())
        )
          return true;
        return false;
      } else return true;
    });
    return {
      data: patients.slice(
        skip * (limit || 30),
        skip * (limit || 30) + limit || 30
      ),
      count: patients.length,
    };
  } catch (e) {
    throw new Error(e);
  }
}
