import {supplementaryCrud} from "../../../../crudRequests";

export default async function fetchReferralVia(state, setReferralVia) {
  try {
    let req = [
      {
        db: "autocomplete",
        collection: "referral_via",
        parameters: [{}],
        method: "find",
      },
    ];
    //console.log("Fetch Suplemetary");
    let res = await supplementaryCrud(state, req);
    res = res.data[0];
    setReferralVia(res);
  } catch (error) {
    console.log(error);
  }
}
