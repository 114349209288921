import {FormControl, FormLabel, Select} from "@chakra-ui/react";

export default function CustomSelectField({
  id,
  label,
  value,
  onChange,
  options,
}) {
  return (
    <FormControl id={id}>
      <FormLabel>{label}</FormLabel>
      <Select
        variant="flushed"
        placeholder="-"
        value={value}
        onChange={onChange}
      >
        {options.map((option, key) => (
          <option key={key} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
