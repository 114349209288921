import {previewFile} from "../../Patients/Files/helpers/previewFile"

export const ClinicAidPDFButton = ({
  dashState,
  selectedFile,
  toast,
  setPreview,
  invoicesPath
}) => {

  const handleClick = async () => {
    await previewFile(
      dashState,
      invoicesPath,
      selectedFile,
      setPreview,
      toast
    )
  }

  return (
    <div className="max-h-20  w-full overflow-hidden text-white text-sm">
      <button
        onClick={handleClick}
        className="bg-[#827FFF] m-[2px]  rounded-lg p-[2px] px-[4px] cursor-pointer flex flex-col justify-center items-center"
      >
        <img
          alt=""
          src="/images/Clinic/inv.png"
          className="w-[2rem] -mt-[2px]"
        />

        <span className="text-[10px] -mt-[2px] leading-[11px]">
          view
        </span>
      </button>
    </div>
  )
}
