import React, {useState} from "react";
import {
  InputGroup,
  Text,
  Flex,
  Button,
  Input,
  Spacer,
  Box,
  InputLeftElement,
} from "@chakra-ui/react";

export default function PaginationBar({
  skip,
  setSkip,
  count,
  searching,
  reset,
}) {
  const [inputValue, setInputValue] = useState(1);
  const [prevReset, setPrevReset] = useState(1);

  if (prevReset !== reset) {
    setPrevReset(reset);
    setInputValue(1);
  }

  return (
    <Flex p="8">
      <Text color="gray.600" fontSize="14px" mt="2.5">
        {"Showing "}
        {`${(1 + skip) * 30 < count ? skip * 30 + " to " : ""}${
          (1 + skip) * 30 < count ? (1 + skip) * 30 : count
        } of ${count} patients`}
      </Text>
      <Spacer />
      <Button
        variant="outline"
        px="8"
        mx="2"
        onClick={(e) => {
          if (skip > 0 && !searching) {
            setSkip((prev) => prev - 1);
            setInputValue(skip);
          }
        }}
      >
        Previous
      </Button>
      <Button
        variant="outline"
        px="8"
        mx="2"
        onClick={(e) => {
          if (!searching) {
            let max = Math.ceil(count / 30);
            if (skip < max - 1) {
              setSkip((prev) => prev + 1);
              setInputValue(skip + 2);
            }
          }
        }}
      >
        Next
      </Button>
      <Spacer />
      <Box>
        <InputGroup>
          <InputLeftElement fontSize="12px">pg.</InputLeftElement>
          <Input
            w="5.5rem"
            ml="-1"
            type="number"
            onChange={(e) => {
              if (!isNaN(e.target.value) && !searching) {
                if (e.target.value === "") setInputValue(0);
                setInputValue(parseInt(e.target.value));
              }
            }}
            value={inputValue}
          />
          <Button
            ml="1"
            variant="ghost"
            onClick={(e) => {
              if (inputValue && !searching) {
                setSkip(inputValue - 1);
                setInputValue(inputValue);
              }
            }}
          >
            Go
          </Button>
        </InputGroup>
      </Box>
    </Flex>
  );
}
