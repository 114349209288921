import axios from "axios";

import {url} from "../../../../crudRequests";

export async function fileProxy(state, orgShareName, directory, fileName) {
  return axios({
    method: "GET",
    url: `${url}/fileProxy`,
    params: {
      s3BucketName: state.organization?.s3BucketName,
      fileName: fileName,
      directoryName: directory,
      orgShareName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  });
}
