import {useEffect, useState} from "react";
import {
  Button,
  Flex,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import RequistionForm from "./RequistionForm";
import {crud, apiAction} from "../../../crudRequests";
import Loader from "../../appointments/clinicAidClaimModal/Loader";

const useGetRequisitionTypes = (state) => {
  const [requisitionList, setRequisitionList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await crud(state, [
          {
            db: state.db,
            collection: "requisitionTemplates",
            parameters: [],
            method: "find",
          },
        ]);
        setRequisitionList(data.data[0] || []);
        setError(null);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return {data: requisitionList, loading, error};
};

export default function CreateRequisitionDialog(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requistionType, setRequisitionType] = useState("");
  const isDoctor = Boolean(props.dashState?.doctor?.did);
  const [formData, setFormData] = useState({});
  const toast = useToast();

  const {
    data,
    loading: dataLoading,
    error,
  } = useGetRequisitionTypes(props.dashState);
  const selectedLocation = props.dashState.locations.find(
    (loc) => loc.lid === props.dashState.selectedLocation
  );
  const selectedReq = data?.find((reqDoc) => reqDoc.type === requistionType);
  const setFormSystemData = () => {
    if (selectedReq) {
      const patientDob = props.patient.dob?.split("-");
      let updatedFormData = {
        patienFname: props.patient.fName,
        patienLname: props.patient.lName,
        y_birth2: patientDob ? patientDob[0] : null,
        m_birth2: patientDob ? patientDob[1] : null,
        d_birth2: patientDob ? patientDob[2] : null,
        sex: props.patient.gender === "Male" ? true : false,
        sexF: props.patient.gender === "Male" ? false : true,
        patientTelephone: props.patient.phone,
        "Patient’s Address including Postal Code": `${
          props.patient.address1 || ""
        } ${props.patient.address2 || ""} ${props.patient.address3 || ""}, ${
          props.patient.city || ""
        }, ${props.patient.province || ""} ${
          props.patient.postalCode || ""
        }`.trim(),
        "Health Number": props.patient?.healthCard?.healthCardNo,
        version: props.patient?.healthCard?.healthCardVerCode,
        "Patient’s Last Name as per OHIP Card": props.patient.lName,
        Province: props.patient.province,
      };

      if (formData.did) {
        const clinician = isDoctor
          ? props.dashState.doctor
          : props.dashState.doctors.find((dr) => dr.did === formData.did);
        updatedFormData = {
          ...updatedFormData,
          name: `${clinician.firstName} ${clinician.lastName}`,
          address: selectedLocation.address,
          "ClinicianPractitioner Number": clinician.practitionerNumber,
          contactNumber1: clinician.phone,
          urgentPhone: selectedLocation.phone,
          clinicianSignature: clinician.signature,
          "CPSO  Registration No": clinician.registrationId,
        };
      }
      if (formData.copyToDid) {
        const additionalClinician = props.dashState.doctors.find(
          (dr) => dr.did === formData.copyToDid
        );
        updatedFormData.clinician = true;
        updatedFormData.lname = additionalClinician.lastName;
        updatedFormData.fname = additionalClinician.firstName;
        updatedFormData.address2a = selectedLocation.address;
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        ...updatedFormData,
      }));
    }
  };

  useEffect(() => {
    setFormSystemData();
  }, [selectedReq, formData.did, formData.copyToDid, props.patient]);

  useEffect(() => {
    if (isDoctor) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        did: props.dashState.doctor.did,
      }));
    }
  }, [isDoctor, props.dashState.doctor]);

  if (dataLoading) {
    return <Loader />;
  }

  return (
    <>
      <Flex justify="center" align="center">
        <Select
          value={requistionType}
          w="25%"
          onChange={(evt) => {
            setRequisitionType(evt.target.value);
          }}
        >
          <option value="">Select a requistion type</option>
          {data.map((reqTemplate) => {
            return <option value={reqTemplate.type}>{reqTemplate.name}</option>;
          })}
        </Select>
        <Button
          ml="2"
          onClick={() => onOpen()}
          isDisabled={dataLoading || !selectedReq}
        >
          Create
        </Button>
      </Flex>
      {selectedReq && (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedReq.name} </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RequistionForm
                state={props.dashState}
                requisitionData={selectedReq}
                formData={formData}
                setFormData={setFormData}
                isDoctor={isDoctor}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                variant="ghost"
                isDisabled={
                  requestLoading ||
                  !formData.did ||
                  !formData.clinicianSignature?.length
                }
                onClick={async () => {
                  setRequestLoading(true);
                  await apiAction(props.dashState, {
                    action: "CREATE-REQUISITION",
                    payload: {
                      type: requistionType,
                      patientId: props.patient.pid,
                      input: formData,
                    },
                  });
                  setRequestLoading(false);
                  setFormData({
                    ...(isDoctor && {did: props.dashState.doctor.did}),
                  });
                  setFormSystemData();
                  props.refetch();
                  toast({
                    title: "Requisiton created succesfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                  onClose();
                }}
              >
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
