import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Badge,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import {
  formatDateTime,
  getTimeDifference,
  elapseFormatterClean,
} from "../helpers/timeFormatters";

export default function SummaryMetadataModal({
  summaryMetadata,
  setSummaryMetadata,
}) {
  const metadata = [
    {
      name: "Total Appointment Time",
      value: elapseFormatterClean(summaryMetadata?.elapsedTime) || "N/A",
    },
    {
      name: "Appointment Concluded",
      value: formatDateTime(summaryMetadata?.initiatedDatetime) || "N/A",
    },
    {
      name: "Summary Generated",
      value: formatDateTime(summaryMetadata?.processedDatetime) || "N/A",
    },
    {
      name: "Summary Processing Time",
      value:
        getTimeDifference(
          summaryMetadata?.initiatedDatetime,
          summaryMetadata?.processedDatetime
        ) || "Still Processing",
    },
    {
      name: "Diarization Processing Time",
      value:
        getTimeDifference(
          summaryMetadata?.initiatedDatetime,
          summaryMetadata?.diarizationProcessedDatetime
        ) || "Still Processing",
    },
    {
      name: "Session Administered By",
      value: summaryMetadata?.administeredBy,
    },
  ];

  return (
    <Modal
      isOpen={summaryMetadata}
      onClose={() => setSummaryMetadata(null)}
      size={"md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Appointment Summary Metadata</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {metadata.map((m, key) => (
            <Flex my="2" w="full">
              <Text fontSize={"14px"} fontWeight={"medium"} as="span">
                {m.name}
              </Text>
              <Spacer />
              <Badge
                colorScheme={"blackAlpha"}
                variant="subtle"
                fontSize="12px"
                rounded="md"
                p="1"
                px="2"
              >
                {m.value}
              </Badge>
            </Flex>
          ))}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
