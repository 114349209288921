import {useContext, useState} from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import {createPaymentMethod} from "../helpers/createPaymentMethod";
import {DashStateContext, PatientContext} from "../../../../pages/Patients";
import {Button} from "@chakra-ui/react";

export default function StripeAddCCForm({
  setErrorMessage,
  setSuccessMessage,
  setCards,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      var addressElementRef = elements.getElement("address");
      var paymentElement = elements.getElement("payment");
      // console.log(elements);
      setIsLoading(true);
      setSuccessMessage("");
      setErrorMessage("");
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      await elements.submit();

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        elements,
      });

      if (paymentMethod) {
        createPaymentMethod(dashState, patient.pid, paymentMethod.id)
          .then((res) => {
            setErrorMessage("");
            setCards((prev) => {
              //console.log(res.data);
              return [{...paymentMethod, default: true}].concat(
                prev.map((card) => ({...card, default: false}))
              );
            });
            setIsLoading(false);
            setSuccessMessage("Successfully added new credit card.");
            addressElementRef.clear();
            paymentElement.clear();
          })
          .catch((e) => {
            setErrorMessage(e.response?.data || e.message);
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement options={{terms: {card: "never"}}} />
        {elements?.getElement("payment") && (
          <AddressElement
            options={{mode: "billing", autocomplete: {mode: "automatic"}}}
          />
        )}
        {elements?.getElement("payment") && (
          <Button
            isLoading={isLoading && true}
            my="5"
            colorScheme="blue"
            type="submit"
            isDisabled={
              isLoading ||
              !stripe ||
              !elements ||
              !elements?.getElement("payment")
            }
            px="10"
          >
            Save
          </Button>
        )}
      </form>
    </>
  );
}
