import {Button, Icon, useToast} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect, useRef} from "react";
import SummaryConsentModal from "./SummaryConsentModal";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import TranscriptModal from "./TranscriptModal";
import {addSummary} from "./helpers/addSummary";
import {AiFillAudio} from "react-icons/ai";
import startRecording from "./helpers/startRecording";
import RecordingControls from "./RecordingControls";

export default function Recording({summaries, setSummaries}) {
  const [recordAppointment, setRecordAppointment] = useState(false);
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [transcriptModal, setTranscriptModal] = useState(false);

  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [summaryConsent, setSummaryConsent] = useState(false);
  const [consentConfirmed, setConsentConfirmed] = useState(null);

  const toast = useToast();

  const [elapsedTime, setElapsedTime] = useState(0);
  const [openMic, setOpenMic] = useState(false);
  const [progress, setProgress] = useState(0);

  const progressId = useRef();
  const initialTimestamp = useRef(0);
  const previousElapsedTime = useRef(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const abort = useRef(false);

  elapsedTime > 3 * 60 * 60 * 1000 && endRecording();

  function endRecording() {
    addSummary(
      transcript,
      selectedAppointment,
      elapsedTime,
      patient,
      dashState,
      setSummaries,
      toast,
      discardTimer
    );
    clearInterval(progressId.current);
    mediaRecorderRef.current && mediaRecorderRef.current.stop();
    previousElapsedTime.current = 0;
    setElapsedTime(0);
  }

  function discardTimer() {
    if (mediaRecorderRef.current) {
      abort.current = true;
      mediaRecorderRef.current.stop();
    }
    clearInterval(progressId.current);
    previousElapsedTime.current = 0;
    setElapsedTime(0);

    setRecordAppointment(false);
    setConsentConfirmed(false);
    resetTranscript();
    SpeechRecognition.stopListening();
  }

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (consentConfirmed) {
      if (browserSupportsSpeechRecognition) {
        SpeechRecognition.startListening({continuous: true});
        if (!openMic) {
          setOpenMic(true);
          startRecording(
            selectedAppointment,
            setSummaries,
            dashState,
            setOpenMic,
            setProgress,
            setElapsedTime,
            audioChunksRef,
            mediaRecorderRef,
            abort,
            progressId,
            initialTimestamp,
            previousElapsedTime
          );
        }
      } else {
        toast({
          title: "Browser doesn't support speech recognition",
          description:
            "Presently, Brightlight's appointment transcription and summarization feature is only supported on Google Chrome and Safari.",
          status: "error",
          isClosable: true,
        });
        discardTimer();
      }
    }
  }, [consentConfirmed]);

  return (
    <>
      {!recordAppointment && (
        <Button
          colorScheme="blue"
          variant="outline"
          rounded="md"
          px="8"
          py="6"
          onClick={() => setRecordAppointment(true)}
        >
          <Icon as={AiFillAudio} mr="2" /> Record Appointment
        </Button>
      )}
      {recordAppointment && (
        <RecordingControls
          summaries={summaries}
          consentConfirmed={consentConfirmed}
          setSummaryConsent={setSummaryConsent}
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          setTranscriptModal={setTranscriptModal}
          elapsedTime={elapsedTime}
          endRecording={endRecording}
          progress={progress}
          discardTimer={discardTimer}
          transcript={transcript}
          toast={toast}
        />
      )}
      <SummaryConsentModal
        summaryConsent={summaryConsent}
        setSummaryConsent={setSummaryConsent}
        setConsentConfirmed={setConsentConfirmed}
      />
      <TranscriptModal
        transcriptModal={transcriptModal}
        setTranscriptModal={setTranscriptModal}
        transcript={transcript}
      />
    </>
  );
}
