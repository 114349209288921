export const getAssignedClinicians = (patient, cliniciansMap) => {
  const assignedClinicians = []
    .concat(patient.did)
    .filter((e) => cliniciansMap[e])
    .map((e) => cliniciansMap[e]);

  const preferredClinicianIndex = assignedClinicians.findIndex(
    (clinician) => clinician.did === patient.preferredClinicianId
  );

  if (preferredClinicianIndex > -1) {
    const [preferredClinician] = assignedClinicians.splice(preferredClinicianIndex, 1);
    assignedClinicians.unshift(preferredClinician);
  }

  return assignedClinicians
}
