export default function searchMatch(_data = [], entri, fields) {
  if (entri === "") return [];
  try {
    let sanitizedEntri = entri.replace(
      /[$^.*+?=!:|/()[\]{}]/g,
      (e) => "\\" + e
    );
    let regex = new RegExp(`${sanitizedEntri}`, "i");
    let d = _data.filter((e, i) => {
      for (let field of fields) {
        if (typeof e[field] === "string" && e[field]?.match(regex)) return true;
      }
      return false;
    });
    return d;
  } catch (e) {
    return [];
  }
}
