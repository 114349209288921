import {crud} from "../../../../crudRequests";
import {
  lightFormat,
  differenceInMinutes,
  startOfDay,
  endOfDay,
  isSameDay,
  setDay,
  eachDayOfInterval,
} from "date-fns";

export default async function generateAvailability({
  state,
  start,
  end,
  clinician,
  duration,
}) {
  try {
    let res = await getData({
      start: start.getTime(),
      did: clinician?.did,
      end: end.getTime(),
      state,
    });

    let daysInInterval = eachDayOfInterval({
      start: new Date(start),
      end: new Date(end),
    });
    let arr = [];

    for (let d of daysInInterval) {
      let a = getAvailable(d, {clinician, apptBydate: res.data[0]}, duration);
      arr = arr.concat(a);
    }

    return arr.filter((i) => {
      try {
        let st = i[0];
        let calendarSartsAt = clinician.calendarSartsAt || "08:00";
        let [hours, min] = calendarSartsAt.split(":");
        let d = new Date(st);
        d.setHours(hours, min);
        return st >= Date.now() && st >= d;
      } catch (error) {
        return i[0] >= Date.now();
      }
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

let getData = async ({start, did, end, state}) => {
  let requestObjs = [
    {
      db: state.db,
      collection: "appointments",
      parameters: [
        {
          did: did,
          ISOdate: {
            $gte: start,
            $lte: end,
          },
        },
      ],
      method: "find",
    },
  ];
  return crud(state, requestObjs, null);
};

export function getAvailable(selectedDate, data, elapse = 45) {
  let date = new Date(selectedDate);
  let clinician = data?.clinician || {};
  let apptBydate = data?.apptBydate || [];

  elapse = isNaN(elapse) ? 45 : parseFloat(elapse);

  let apptIntervals = [];

  if (apptBydate.length > 0) {
    apptIntervals = generateAppointmentsIntervals(
      filterByDay(date, apptBydate)
    );
  }

  let a = [];
  let u = (clinician.unavailable?.untilRemoved || []).map(([start, end]) => {
    return [setToCurrentWeek(date, start), setToCurrentWeek(date, end)];
  });

  (clinician.unavailable?.intervals || []).concat(u).forEach((interval) => {
    if (isSameDay(interval[0], interval[1])) a.push(interval);
    else {
      a.push([interval[0], endOfDay(interval[0]).getTime()]);
      a.push([startOfDay(interval[1]).getTime(), interval[1]]);
    }
  });

  a = filterByDay(date, a);
  let resp = [];
  let unavailableIntervals = mergeIntervals(a.concat(apptIntervals));

  unavailableIntervals.unshift([0, startOfDay(date).getTime()]);
  unavailableIntervals.push([endOfDay(date).getTime() + 1000, 0]);

  let min = 60000 * elapse; // 45 min

  for (let i = 0; i < unavailableIntervals.length; i++) {
    let current = unavailableIntervals[i];
    let next = unavailableIntervals[i + 1];
    if (next) {
      let range = next[0] - current[1];
      if (range >= min) {
        let n = Math.floor(range / min);
        let left = current[1];

        /*for (let j = 0; j < n; j++) {
            resp.push([left, left + min]);
            left += min;
          }*/

        while (left + min <= next[0]) {
          resp.push([left, left + min]);
          left += 15 * 60 * 1000;
        }
      }
    }
  }

  return resp;
}

function setToCurrentWeek(currentDate, oldDate) {
  let old = new Date(oldDate);
  let diff = differenceInMinutes(oldDate, startOfDay(oldDate));
  let newDate = setDay(currentDate, old.getDay(), {weekStartsOn: 1});
  newDate = startOfDay(newDate);
  newDate.setMinutes(diff);
  return newDate.getTime();
}

function filterByDay(d, appointments) {
  return appointments.filter((appt) => {
    let apptDate = new Date(appt.ISOdate || appt[0]);
    return isSameDay(apptDate, d);
  });
}

function generateAppointmentsIntervals(appointments) {
  let intervals = [];

  appointments.forEach((appt) => {
    let start = new Date(appt.ISOdate).getTime();
    let end = start + parseInt(appt.duration.split(" ")[0]) * 60000;
    intervals.push([start, end]);
  });

  return intervals;
}

function mergeIntervals(intervals) {
  if (intervals.length === 0) return intervals;
  intervals.sort((a, b) => {
    return a[0] - b[0];
  });
  let merged = [];
  let current = intervals[0];
  for (let i of intervals) {
    if (current[1] >= i[0]) {
      current[1] = i[1] <= current[1] ? current[1] : i[1];
    } else {
      merged.push(current);
      current = i;
    }
  }
  merged.push(current);
  return merged;
}

function stdate(inv) {
  return inv.map((e) => [new Date(e[0]), new Date(e[1])]);
}
