import {processSummary} from "./processSummary";

export async function regenerateSummary(
  type,
  summary,
  setSummary,
  dashState,
  setIsRegenerating,
  toast
) {
  setIsRegenerating(true);
  const regenerationToast = toast({
    title: "Summary Regenerating",
    description: "Please wait up to 2 minutes for the summary to regenerate.",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });

  try {
    const regeneratedSummary = await processSummary(
      dashState,
      summary.sid,
      type
    );
    setSummary({...summary, [type]: regeneratedSummary.data});
    setIsRegenerating(false);
    toast({
      title: "Summary Regenerated",
      description: "Summary has been successfully regenerated",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    setIsRegenerating(false);
    toast({
      title: "Summary Not Regenerated",
      description: err?.message || "An error occurred.",
      status: "error",
      isClosable: true,
    });
  }

  toast.close(regenerationToast);
}
