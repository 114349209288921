import {crud} from "../../../crudRequests";

export default async function sendNotification({
  dashState,
  card,
  toast,
  action,
  isResending,
}) {
  try {
    const generatingToast = isResending
      ? toast({
          title: "Sending email...",
          status: "loading",
          variant: "subtle",
          duration: null,
          isClosable: true,
        })
      : 0;
    const locationData = await crud(dashState, [
      {
        db: dashState.db,
        collection: "locations",
        parameters: [{lid: dashState.selectedLocation}],
        method: "findOne",
      },
    ]);
    let location = locationData.data?.[0];
    const emailOption = {
      type: "gift_card_purchase",
      content: card,
      sender: location?.name,
      org: dashState.organization,
      location,
    };
    const res = await crud(
      dashState,
      [
        {
          db: dashState.db,
          collection: "giftCards",
          parameters: [{gcId: card.gcId}, {$set: {}}],
          method: "findOneAndUpdate",
        },
      ],
      {email: emailOption}
    );
    if (isResending) {
      toast({
        title: "Email has been sent!",
        status: "success",
        isClosable: true,
        duration: 3000,
        isClosable: true,
      });
    }
    isResending && toast.close(generatingToast);
    action?.();
  } catch (error) {
    toast({
      title: "Email has been rejected!",
      description: error?.message,
      status: "error",
      isClosable: true,
    });
  }
}
