import {crudStorage} from "./crudStorage";

export default async function deleteFiles(state, path) {
  try {
    let res = await crudStorage(state, "view", "", "brightlighthealth", path);
    res = res.data;
    let arr = res?.filter((f) => f.name.slice(-1) !== "/");
    await Promise.all(
      arr.map((f) =>
        crudStorage(
          state,
          "deleteFile",
          path + "/" + f.name,
          "brightlighthealth",
          ""
        )
      )
    );
  } catch (error) {
    console.log(error);
  }
}
