import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Box,
  Text,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import {EditIcon, DeleteIcon, CheckIcon} from "@chakra-ui/icons";
import {AssignClinicianPopover} from "./AssignClinicianPopover";
import {crud} from "../../crudRequests";
import {useEffect, useRef, useState} from "react";

export const PatientCliniciansDialog = ({
  patient,
  clinicians,
  assignedClinicians,
  schState,
  dispatch,
  setPatient
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [clinicianToDelete, setClinicianToDelete] = useState(null);
  const [preferredClinician, setPreferredClinician] = useState(null)
  const [loadingClinician, setLoadingClinician] = useState(null);
  const cancelRef = useRef();

  const updatePatientFields = async (pid, updatedFields) => {
    dispatch({
      type: "UPDATE_PATIENT",
      pid,
      payload: {
        ...schState.patients.find((p) => p.pid === pid),
        ...updatedFields,
      },
    });

    if (setPatient) {
      setPatient((prevPatient) => {
        return {
          ...prevPatient,
          ...updatedFields,
        };
      });
    }
  
    await crud(schState, [
      {
        db: schState.db,
        collection: "patients",
        parameters: [
          {pid},
          {$set: updatedFields},
        ],
        method: "updateOne",
      },
    ]);
  };

  const handleAssignClinician = async (clinician) => {
    try {
      const currentClinicians = patient.did ? [...patient.did] : [];
      const newClinicians = [...currentClinicians, clinician.value];
      await updatePatientFields(patient.pid, {did: newClinicians});

      onClose();
      toast({
        title: "Clinician Assigned",
        description:
          "The clinician has been successfully assigned to the patient.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to assign clinician. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClinician = async (clinician) => {
    try {
      const newClinicians = patient.did.filter((did) => did && did !== clinician.did);
      await updatePatientFields(patient.pid, {did: newClinicians});

      toast({
        title: "Clinician Removed",
        description:
          "The clinician has been successfully unassigned from the patient.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to unassign clinician. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onAlertClose = () => {
    setIsAlertOpen(false);
    setClinicianToDelete(null);
  };

  const confirmDelete = () => {
    if (clinicianToDelete) {
      handleDeleteClinician(clinicianToDelete);
    }
    onAlertClose();
  };

  const makePreferredClinician = async (clinician) => {
    try {
      const isPreferredClinician = patient.preferredClinicianId === clinician.did
      let toastText = ""
      if (isPreferredClinician) {
        await updatePatientFields(patient.pid, {preferredClinicianId: null});
        toastText = "Preferred Clinician removed"
      } else {
        await updatePatientFields(patient.pid, {preferredClinicianId: clinician.did});
        toastText = "Preferred Clinician added"
      }
      toast({
        description: toastText,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      if (isPreferredClinician) {
        setPreferredClinician(null)
      } else {
        setPreferredClinician(clinician.did)
      }
    } catch {
      toast({
        title: "Error",
        description: "Failed to update preferred clinician. Please try again.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    setPreferredClinician(patient.preferredClinicianId)
  },[patient.preferredClinicianId])

  return (
    <>
      <IconButton
        icon={<EditIcon />}
        aria-label="Edit patient clinicians"
        onClick={onOpen}
        variant="outline"
        bg="blue.400"
        opacity="75%"
        boxShadow="lg"
        size="xs"
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxHeight="80vh" display="flex" flexDirection="column">
          <ModalHeader bg="gray.100" textColor="gray.600" fontSize="md">
            ASSIGNED
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" flex="1">
            <VStack
              spacing={2}
              align="stretch"
              divider={<Box height="1px" bg="gray.200" />}
            >
              {assignedClinicians?.map((clinician, index) => (
                <Box
                  key={clinician.id || index}
                  p={2}
                  bg="white"
                  borderRadius="md"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontWeight="light">
                    {clinician.lastName}, {clinician.firstName}
                  </Text>
                  <Box>
                    <Tooltip label="Select preferred clinician" aria-label="A tooltip" openDelay={500}>
                      <IconButton
                        isLoading={loadingClinician === clinician.did}
                        icon={<CheckIcon />}
                        aria-label="Select preferred clinican"
                        size="xs"
                        variant={preferredClinician === clinician.did ? "solid" : "outline"}
                        colorScheme="green"
                        onClick={async () => {
                          setLoadingClinician(clinician.did)
                          await makePreferredClinician(clinician)
                          setLoadingClinician(null)
                        }}
                        mr={2}
                      />
                    </Tooltip>
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Unassign clinician"
                      size="xs"
                      variant="solid"
                      colorScheme="red"
                      onClick={() => {
                        setClinicianToDelete(clinician);
                        setIsAlertOpen(true);
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <AssignClinicianPopover
              clinicians={clinicians}
              assignedClinicians={assignedClinicians}
              onAssign={handleAssignClinician}
              onDelete={handleDeleteClinician}
              schState={schState}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unassign Clinician
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to unassign this clinician from the patient?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Unassign
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
