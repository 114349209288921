import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import {useState} from "react";
import IndividualSummary from "./IndividualSummary";

export default function Summaries({selectedSummary, setSelectedSummary}) {
  const summaryTypes = [
    {name: "Brief", field: "briefSummary"},
    {name: "Narrative", field: "narrativeSummary"},
    {name: "Bullet-Point", field: "bulletptSummary"},
    {name: "SOAP", field: "soapSummary"},
    {name: "Comprehensive", field: "comprehensiveSummary"},
  ];
  const [type, setType] = useState(summaryTypes[0].field);

  return (
    <Drawer
      onClose={() => setSelectedSummary(null)}
      isOpen={!!selectedSummary}
      size={"lg"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader></DrawerHeader>
        <DrawerBody>
          <Tabs
            variant="soft-rounded"
            colorScheme="blue"
            onChange={(i) => {
              setType(summaryTypes[i].field);
            }}
          >
            <TabList>
              {summaryTypes.map((tab, i) => (
                <Tab key={i}>{tab.name}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {summaryTypes.map((tab, i) => (
                <TabPanel key={i}>
                  <IndividualSummary
                    unchangingType={tab.field}
                    type={type}
                    setType={setType}
                    summary={selectedSummary}
                    setSummary={setSelectedSummary}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
