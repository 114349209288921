import axios from "axios";
import {url} from "../../../../crudRequests";

export async function pharmAutocomplete(state, query, favPharmacies, skip, phid = null) {
  let configService = {
    method: "POST",
    url: `${url}/pharmAutocomplete`,
    data: {
      query,
      lid: state.selectedLocation,
      oid: state?.organization?.oid,
      favPharmacies,
      skip,
      phid
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    // console.log({res});
    return res;
  });
}

export default async function pAutocomplete(
  state,
  query,
  setPharmacies,
  toast,
  onComplete = null,
  favPharmacies = [],
  page = 1,
  setHasMoreData = null
) {
  const skip = (page - 1) * 25
  try {
    const res = await pharmAutocomplete(state, query, favPharmacies, skip)
    if (page === 1) {
      setPharmacies(res.data)
    } else {
      setPharmacies(prev => [...prev, ...res.data])
    }
    if (setHasMoreData) {
      if (res.data.length < 25) {
        setHasMoreData(false)
      } else {
        setHasMoreData(true)
      }
    }
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
  if (onComplete) {
    onComplete();
  }
}
