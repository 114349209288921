import {
  Grid,
  Text,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Checkbox,
  Flex,
  Box,
} from "@chakra-ui/react";
import SelectSingleClinician from "./SelectSingleClinician";
import SignaturePad from "../../SignaturePad";
import React, {useEffect} from "react";

function ClinicianSignatureField({signature, onSignatureChange}) {
  return (
    <Box mt={4}>
      <Text fontWeight="bold" mb={2}>
        Clinician Signature:
      </Text>
      <SignaturePad
        onCreate={onSignatureChange}
        blobValue={signature}
        onDelete={() => {
          onSignatureChange("");
        }}
      />
    </Box>
  );
}

export default function RequistionForm(props) {
  const handleStateUpdate = (fieldName, value) => {
    props.setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  useEffect(() => {
    props.requisitionData.schema
      .filter(
        (inputConfig) =>
          inputConfig.source === "system" && inputConfig.type === "date"
      )
      .forEach((inputConfig) => {
        if (!props.formData[inputConfig.pdfMapping]) {
          const today = new Date().toLocaleString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          handleStateUpdate(inputConfig.pdfMapping, today);
        }
      });
  }, []);

  return (
    <FormControl>
      <Flex>
        {!props.isDoctor && (
          <>
            <SelectSingleClinician
              label="Clinician"
              schState={props.state}
              dispatch={(evt) => {
                if (evt.type === "SELECT_DOCTOR") {
                  handleStateUpdate("did", evt.payload.did);
                }
              }}
              formData={props.formData}
              handleStateUpdate={handleStateUpdate}
              source="did"
            />
            <SelectSingleClinician
              label="Copy to"
              schState={props.state}
              dispatch={(evt) => {
                if (evt.type === "SELECT_DOCTOR") {
                  handleStateUpdate("copyToDid", evt.payload.did);
                }
              }}
              formData={props.formData}
              handleStateUpdate={handleStateUpdate}
              source="copyToDid"
            />
          </>
        )}
      </Flex>
      <Grid
        templateRows="repeat(auto-fill, minmax(40px, auto))"
        templateColumns="repeat(16, 1fr)"
        gap={2}
        w="100%"
        my="6"
        border="1px solid"
        borderColor="cyan.100"
        p={4}
      >
        {props?.requisitionData?.schema
          .filter((inputConfig) => inputConfig.source === "system")
          .map((inputConfig, i) => {
            switch (inputConfig.type) {
              case "boolean":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan || 4}
                    key={inputConfig.pdfMapping}
                  >
                    <Flex direction="column">
                      <FormLabel>
                        {inputConfig.label || inputConfig.pdfMapping}
                      </FormLabel>
                      <Checkbox
                        isChecked={props.formData[inputConfig.pdfMapping]}
                        onChange={(evt) =>
                          handleStateUpdate(
                            inputConfig.pdfMapping,
                            evt.target.checked
                          )
                        }
                        size="lg"
                      />
                    </Flex>
                  </GridItem>
                );
              case "text":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan || 4}
                    key={inputConfig.pdfMapping}
                  >
                    <FormControl>
                      <FormLabel>
                        {inputConfig.label || inputConfig.pdfMapping}
                      </FormLabel>
                      <Input
                        value={props.formData[inputConfig.pdfMapping] || ""}
                        onChange={(evt) => {
                          if (
                            !inputConfig.maxLength ||
                            evt.target.value.length <= inputConfig.maxLength
                          ) {
                            handleStateUpdate(
                              inputConfig.pdfMapping,
                              evt.target.value
                            );
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>
                );
              case "filler":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan || 4}
                    bg="gray.100"
                    key={i}
                    p={2}
                  >
                    <Text fontWeight="bold">{inputConfig.label}</Text>
                  </GridItem>
                );
              case "date":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan || 4}
                    key={inputConfig.pdfMapping}
                  >
                    <FormControl>
                      <FormLabel>
                        {inputConfig.label || inputConfig.pdfMapping}
                      </FormLabel>
                      <Input
                        type="date"
                        value={props.formData[inputConfig.pdfMapping] || ""}
                        onChange={(evt) => {
                          const selectedDate = new Date(
                            evt.target.value + "T00:00:00"
                          );
                          const formattedDate = selectedDate.toLocaleString(
                            "en-CA",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              timeZone: "UTC",
                            }
                          );
                          handleStateUpdate(
                            inputConfig.pdfMapping,
                            formattedDate
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                );
              default:
                return null;
            }
          })}
      </Grid>
      <Grid
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(16, 1fr)"
        w="100%"
        my="6"
        border="solid"
        borderColor="cyan.100"
      >
        {props?.requisitionData?.schema
          .filter((inputConfig) => inputConfig.source === "form")
          .map((inputConfig, i) => {
            switch (inputConfig.type) {
              case "boolean":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan}
                    rowSpan={inputConfig.rowSpan}
                    key={inputConfig.pdfMapping}
                  >
                    <Flex alignItems="center" p="4px">
                      <Checkbox
                        checked={props.formData[inputConfig.pdfMapping]}
                        onChange={(evt) =>
                          handleStateUpdate(
                            inputConfig.pdfMapping,
                            evt.target.checked
                          )
                        }
                        spacing="1rem"
                      >
                        {inputConfig.label}
                      </Checkbox>
                    </Flex>
                  </GridItem>
                );
              case "text":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan}
                    rowSpan={inputConfig.rowSpan}
                    key={inputConfig.pdfMapping}
                  >
                    <Flex alignItems="center" p="4px">
                      <FormLabel>{inputConfig.label}</FormLabel>
                      <Input
                        type="string"
                        value={props.formData[inputConfig.pdfMapping]}
                        onChange={(evt) => {
                          if (
                            inputConfig.maxLength &&
                            evt.target.value.length <= inputConfig.maxLength
                          ) {
                            handleStateUpdate(
                              inputConfig.pdfMapping,
                              evt.target.value
                            );
                          }
                        }}
                      />
                    </Flex>
                  </GridItem>
                );
              case "filler":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan}
                    rowSpan={inputConfig.rowSpan}
                    bg="gray.100"
                    key={i}
                    p="4px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    textAlign="center"
                  >
                    <Text fontWeight="bold">{inputConfig.label}</Text>
                  </GridItem>
                );
            }
            return <Text>{inputConfig.label}</Text>;
          })}
      </Grid>
      <ClinicianSignatureField
        signature={props.formData.clinicianSignature}
        onSignatureChange={(newSignature) => {
          return handleStateUpdate("clinicianSignature", newSignature);
        }}
      />
    </FormControl>
  );
}
