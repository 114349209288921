import {crud} from "../../../../crudRequests";

export const getKeyNote = async (dashState, pid) => {
  let note = null;
  let admin = null;
  const res = await crud(dashState, [
    {
      db: dashState.db,
      collection: "patientNotes",
      parameters: [{pid: pid, keyNote: true}],
      method: "findOne",
    },
  ]);
  note = res.data?.[0] || null;
  if (note) {
    if (note.userType === "admin") {
      const res = await crud(dashState, [
        {
          db: dashState.db,
          collection: "admins",
          parameters: [{id: note.userId}],
          method: "find",
        },
      ]);
      admin = res.data[0] || [];
      admin = {[note.userId]: admin[0]};
    }
  }
  return [note, admin];
};
