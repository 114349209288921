import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
  Input,
} from "@chakra-ui/react";
import {v4 as uuidv4} from "uuid";
import {useContext, useState, useEffect, useMemo, useRef} from "react";
import {saveNote} from "./helpers/saveNote";
export default function AddOrEdit({
  selectedNote,
  setSelectedNote,
  isOpen,
  onClose,
  patient,
  dashState,
  setNotes,
}) {
  const [description, setDescription] = useState(
    selectedNote?.description || ""
  );

  const [title, setTitle] = useState(selectedNote?.title || "");
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = useRef(null);
  const toast = useToast();
  const save = async () => {
    let newNote = null;
    if (!selectedNote) {
      let createdAt = Date.now();
      newNote = {
        noteId: uuidv4(),
        pid: patient.pid,
        createdAt,
        updatedAt: createdAt,
        userType: dashState.userType,
        userId:
          dashState.userType === "doctor"
            ? dashState?.doctor?.did
            : dashState?.admin?.id,
        description,
        title,
      };
    }
    await saveNote(
      newNote,
      {title, description},
      selectedNote,
      dashState,
      setIsLoading,
      toast,
      setNotes
    );
    onClose();
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setSelectedNote(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="blue.600">{`${
            selectedNote ? "Edit" : "Create"
          } note`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form
              id="my-form"
              onSubmit={(e) => {
                e.preventDefault();
                save();
              }}
            >
              <FormControl isRequired>
                <FormLabel color="gray.600" fontSize="sm" mb="0.5">
                  Note title
                </FormLabel>
                <Input
                  ref={initialRef}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel color="gray.600" fontSize="sm" mb="0.5">
                  Description
                </FormLabel>
                <Textarea
                  placeholder="write note..."
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  resize={"vertical"}
                />
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              form="my-form"
              type="submit"
              colorScheme="blue"
              mr={3}
              isLoading={isLoading}
              loadingText={"Saving..."}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                onClose();
                setSelectedNote(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
