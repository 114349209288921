import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState} from "react";
import {Box, Button, FormControl, FormLabel, useToast} from "@chakra-ui/react";
import {assignForm} from "./helpers/assignForm";
import {SearchableSelect} from "../../SearchableSelect";

export default function AssignForm({forms, setAssignedForms}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState();

  return (
    <form
      onSubmit={(e) =>
        assignForm(
          e,
          forms,
          selectedForm,
          setAssignedForms,
          patient,
          dashState,
          setIsLoading,
          toast
        )
      }
    >
      <Box maxW={"20rem"} mt="12">
        <FormControl id={"form"}>
          <FormLabel>Assign New Form</FormLabel>
          <SearchableSelect
            label="Select form"
            placeholder="-"
            options={[
              {value: "", label: "-"},
              ...forms
                .sort((a, b) =>
                  a.formTitle
                    .toLowerCase()
                    .trim()
                    .localeCompare(b.formTitle.toLowerCase().trim())
                )
                .map((form) => ({
                  value: form.fid,
                  label: form.formTitle,
                })),
            ]}
            onChange={(e) => {
              setSelectedForm(e.value);
            }}
            value={selectedForm}
            isDisabled={false}
          />
        </FormControl>
        <Button
          isLoading={isLoading}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Assign
        </Button>
      </Box>
    </form>
  );
}
