import React, {useState, useRef, useEffect, useContext} from "react";
import {PiListMagnifyingGlass} from "react-icons/pi";
export default function DefineApptServiceCriteria({
  criteria,
  initialCriteria,
  criteriaKeyRef,
  creating,
  setServiceCriteriaKey,
  action,
  service,
}) {
  const [selectedCriteria, setSelectedCriteria] = useState(
    new Set(initialCriteria.initialSelectedCriteria)
  );

  let isfixedCost = service?.isfixedCost;

  const [prevInitialCriteria, setPrevInitialCriteria] =
    useState(selectedCriteria);

  if (prevInitialCriteria !== initialCriteria) {
    setPrevInitialCriteria(initialCriteria);
    setSelectedCriteria(new Set(initialCriteria.initialSelectedCriteria));
  }

  //flex flex-col justify-center items-center

  return (
    <div className="w-full px-3 mt-6 space-y-4">
      <div className="pr-0 w-full pb-1  flex flex-col text-left items-start">
        <span className="text-[13px]   text-off ml-1 ">Service Criteria</span>
        <div className="flex-1 w-full  flex space-y-2  shadow-inner border overflow-hidden border-white rounded-xl p-1 pb-2">
          <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  min-h-[100px] max-h-[160px]  w-full sbar2   p-2">
            {criteria.map((crit, i) => (
              <li
                onClick={() => {
                  if (!creating) {
                    !selectedCriteria.has(crit)
                      ? setSelectedCriteria((prev) => {
                          prev.add(crit);
                          let n = new Set(prev);
                          return n;
                        })
                      : setSelectedCriteria((prev) => {
                          prev.delete(crit);
                          let n = new Set(prev);
                          return n;
                        });
                  }
                }}
                key={i}
                className={`cursor-pointer m-1 flex items-center  p-1 py-0 px-0 space-x-1`}
              >
                <span
                  className={`h-5 w-5 rounded-md shadow-ntf bg-white`}
                  style={{
                    backgroundColor: selectedCriteria.has(crit)
                      ? "#9F9DFA"
                      : "#FFFFFF",
                  }}
                ></span>

                <span className="font-medium text-[0.9rem] text-[#8e8df9]">
                  {crit.type}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <DefinedCriteria
        {...{
          selectedCriteria,
          initialCriteria,
          criteriaKeyRef,
          setServiceCriteriaKey,
          action,
        }}
      />
    </div>
  );
}

function DefinedCriteria({
  selectedCriteria,
  criteriaKeyRef,
  initialCriteria,
  setServiceCriteriaKey,
  action,
}) {
  const [selectedValues, setSelectedValues] = useState(
    initialCriteria.initialCriteriaValues
  );
  const [selectedIdValues, setSelectedIdValues] = useState(
    initialCriteria.initialCriteriaIdValues
  );
  const [prevSelectedCriteria, setPrevSelectedCriteria] =
    useState(selectedCriteria);

  const [showList, setShowList] = useState({});
  criteriaKeyRef.current = selectedIdValues;

  const [prevInitialCriteria, setPrevInitialCriteria] =
    useState(selectedCriteria);

  if (prevInitialCriteria !== initialCriteria) {
    setPrevInitialCriteria(initialCriteria);
    setSelectedValues(initialCriteria.initialCriteriaValues);
    setSelectedIdValues(initialCriteria.initialCriteriaIdValues);
  }

  useEffect(() => {
    if (setServiceCriteriaKey || action) {
      if (setServiceCriteriaKey) {
        setServiceCriteriaKey(criteriaFormatKey(selectedIdValues));
      }

      if (action) {
        action(selectedIdValues);
      }
    }
  }, [selectedIdValues]);

  if (prevSelectedCriteria !== selectedCriteria) {
    setPrevSelectedCriteria(selectedCriteria);

    setSelectedValues((prev) => {
      return [...selectedCriteria].reduce((ac, el) => {
        ac[el.type] = prev[el.type];
        return ac;
      }, {});
    });

    setSelectedIdValues((prev) => {
      return [...selectedCriteria].reduce((ac, el) => {
        ac[el.type] = prev[el.type];
        return ac;
      }, {});
    });

    setShowList((prev) => {
      return [...selectedCriteria].reduce((ac, el) => {
        ac[el.type] = prev[el.type];
        return ac;
      }, {});
    });
  }

  return (
    <div className="h-full w-full  flex-1 overflow-hidden  flex flex-col text-left justify-between items-center pb-2">
      <span className="text-[13px] ml-1  text-off self-start">
        Selected Criteria
      </span>
      <div
        className="flex-1 w-full  flex space-y-2 shadow-inner min-h-[100px] overflow-hidden border-white  border rounded-xl pr-1"
        onClick={() => setShowList({})}
      >
        <ul className="overflow-y-scroll rounded-xl  max-h-[130px] content-start flex items-start flex-wrap w-full sbar2 pb-2  p-1 px-2">
          {[...selectedCriteria].map((crit, i) => (
            <li
              onClick={(e) => {
                e.stopPropagation();

                setShowList((prev) => ({[crit.type]: !prev[crit.type]}));
              }}
              key={i}
              className={`cursor-pointer relative [box-shadow:_0px_4px_4px_rgba(0,0,0,0.25)] mt-2 mr-2  bg-[#C1BFFF] rounded-md max-w-[8rem] min-w-[6rem]  flex-1`}
            >
              <div className=" flex justify-between items-center w-full h-full p-1  space-x-2">
                <p className="text-[#5754FF] text-[12px] leading-4  overflow-hidden  font-semibold flex flex-wrap">
                  {crit.type}
                </p>
                <span className="text-white text-lg">
                  <PiListMagnifyingGlass />
                </span>
              </div>
              <div className="text-white text-[12px] leading-4  overflow-hidden  font-semibold flex flex-wrap">
                {selectedValues[crit.type] && (
                  <span className="p-1 ">{selectedValues[crit.type]}</span>
                )}
              </div>
              {showList[crit.type] && (
                <OptionsList
                  criteria={crit}
                  setSelectedIdValues={setSelectedIdValues}
                  setSelectedValues={setSelectedValues}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
function OptionsList({criteria, setSelectedValues, setSelectedIdValues}) {
  let list = [];
  let idList = [];
  switch (criteria.type) {
    case "Insurers":
      list = criteria.list?.map((d) => d.name);
      idList = criteria.list?.map((d) => d.iid);
      break;
    case "Clinicians":
      list = criteria.list?.map((d) => d.name);
      idList = criteria.list?.map((d) => d.did);
      break;
    case "Provider Types":
      list = criteria.list?.map((d) => d.name);
      idList = criteria.list?.map((d) => d.typeId);
      break;

    default:
      list = criteria.list;
      idList = criteria.list;
      break;
  }

  return (
    <div className="shadow-lg z-10 overflow-hidden absolute bg-white/80 rounded-xl p-1 h-20 w-full left-0  top-0 max-h-[12.5rem]">
      <ul className="overflow-y-scroll w-full sbar h-full divide-y-[1px] p-1 space-y-[1px]">
        {list.map((l, i) => (
          <li
            className="text=[#8F8CFF]  flex-wrap flex text-[#8F8CFF] font-semibold  pl-1 text-xs hover:bg-[#aaa4f3] shadow-sm hover:text-white cursor-pointer"
            onClick={(ev) => {
              // ev.stopPropagation();
              setSelectedValues((prev) => ({...prev, [criteria.type]: l}));
              setSelectedIdValues((prev) => ({
                ...prev,
                [criteria.type]: idList[i],
              }));
            }}
            key={i}
          >
            {l}
          </li>
        ))}
      </ul>
    </div>
  );
}

function criteriaFormatKey(data) {
  let en = Object.entries(data).filter(([k, v]) => v !== undefined);
  if (en.length > 0) {
    en = en.map((el) => el.join("|"));
    en.sort();
    return en.join("||");
  } else return "";
}
