let toastInstance;

export function setToastInstance(toast) {
  toastInstance = toast;
}

export function showToast(config) {
  if (toastInstance) {
    return toastInstance(config);
  } else {
    console.error("Toast instance is not set. Make sure to initialize it.");
  }
}
