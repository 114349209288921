import {crud} from "../../../../crudRequests";

export async function fetchBilling(setBilling, dashState, patient, toast) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "billing",
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ]);
    setBilling(res.data[0]);
  } catch (err) {
    toast({
      title: "Failed to fetch billing data",
      description:
        err?.message || "An error occurred while fetching billing data.",
      status: "error",
      isClosable: true,
    });
  }
}
