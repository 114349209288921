import Swal from "sweetalert2";
import deleteFiles from "./helpers/deleteFiles";
import {crudStorageUpload} from "../../Patients/Files/helpers/crudStorageUpload";
import {lightFormat} from "date-fns";
import InvRecpDF from "../../Invoice";
import InvMembPDF from "../../Memberships/Invoice";

import {pdf} from "@react-pdf/renderer";
import {crud} from "../../../crudRequests";
import {ClinicAidPdf} from "../clinicAidClaimPdf/ClinicAidPDF";
export async function fullInvoice({
  state,
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  nonSessionService,
  cancelled,
}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          doctor,
          patient,
          tpp,
          supervisor,
          nonSessionService,
          cancelled,
        }}
        returnDoc
        type="FULL_INVOICE"
      />
    ).toBlob();
    let d = new Date();
    await deleteFiles(
      state,
      transaction?.pid +
        `/Invoices/${
          nonSessionService ? "Non-Session-Services" : "Appointments"
        }/${fileNameFormat(transaction)}`
    );
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Consolidated_Invoice" +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Invoices/${
          nonSessionService ? "Non-Session-Services" : "Appointments"
        }/${fileNameFormat(transaction)}/`
    );
  } catch (e) {
    console.log(e);
  }
}
export async function fullReceipt({
  state,
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  nonSessionService,
  cancelled,
}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          doctor,
          patient,
          tpp,
          supervisor,
          nonSessionService,
          cancelled,
        }}
        returnDoc
        type="FULL_RECEIPT"
      />
    ).toBlob();
    let d = new Date();
    await deleteFiles(
      state,
      transaction?.pid +
        `/Receipts/${
          nonSessionService ? "Non-Session-Services" : "Appointments"
        }/${fileNameFormat(transaction)}`
    );
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Consolidated_Receipt" +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Receipts/${
          nonSessionService ? "Non-Session-Services" : "Appointments"
        }/${fileNameFormat(transaction)}/`
    );
  } catch (e) {
    console.log(e);
  }
}
export async function patientInvoice({
  state,
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  nonSessionService,
  cancelled,
}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          doctor,
          patient,
          tpp,
          supervisor,
          nonSessionService,
          cancelled,
        }}
        returnDoc
        type="PATIENT_INVOICE"
      />
    ).toBlob();
    let d = new Date();

    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Invoice " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Invoices/${
          nonSessionService ? "Non-Session-Services" : "Appointments"
        }/${fileNameFormat(transaction)}/Patient/`
    );
  } catch (e) {
    console.log(e);
  }
}
export async function patientReceipt({
  state,
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  receiptAmount,
  nonSessionService,
  cancelled,
}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          doctor,
          patient,
          tpp,
          supervisor,
          receiptAmount,
          cancelled,
          nonSessionService,
        }}
        returnDoc
        type="PATIENT_RECEIPT"
      />
    ).toBlob();
    let d = new Date();
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Receipt " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Receipts/${
          nonSessionService ? "Non-Session-Services" : "Appointments"
        }/${fileNameFormat(transaction)}/Patient/`
    );

    return blob;
  } catch (e) {
    console.log(e);
  }
}

export async function insurerInvoice({
  state,
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  nonSessionService,
  cancelled,
}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          doctor,
          patient,
          tpp,
          supervisor,
          nonSessionService,
          cancelled,
        }}
        returnDoc
        type="INSURER_INVOICE"
      />
    ).toBlob();
    let d = new Date();
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Invoice " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Invoices/${
          nonSessionService ? "Non-Session-Services" : "Appointments"
        }/${fileNameFormat(transaction)}/Insurer/`
    );
  } catch (e) {
    console.log(e);
  }
}

export async function insurerReceipt({
  state,
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  receiptAmount,
  nonSessionService,
  cancelled,
}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          doctor,
          patient,
          tpp,
          supervisor,
          receiptAmount,
          nonSessionService,
          cancelled,
        }}
        returnDoc
        type="INSURER_RECEIPT"
      />
    ).toBlob();
    let d = new Date();
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Receipt " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Receipts/${
          nonSessionService ? "Non-Session-Services" : "Appointments"
        }/${fileNameFormat(transaction)}/Insurer/`
    );
    return blob;
  } catch (e) {
    console.log(e);
  }
}

export function fileNameFormat(transaction) {
  return (
    lightFormat(new Date(transaction.serviceDate), "dd-MM-yy HH-mm aaa") +
    ` (${transaction.aid})`
  );
}
export function membershipFileNameFormat(transaction) {
  return (
    lightFormat(new Date(transaction.serviceDate), "dd-MM-yy HH-mm aaa") +
    ` (${transaction.tid})/`
  );
}
export async function initialInvoices(appt, state, newTransaction = null, newInvoice = true) {
  try {
    if (!appt || !state) return;
    let res = await crud(state, [
      {
        db: state.db,
        collection: "billing",
        parameters: [{aid: appt.aid}],
        method: "findOne",
      },
      {
        db: state.db,
        collection: "doctors",
        parameters: [{did: appt.did}],
        method: "findOne",
      },
      {
        db: state.db,
        collection: "patients",
        parameters: [{pid: appt?.pid}],
        method: "findOne",
      },
      {
        db: state.db,
        collection: "insurers",
        parameters: [{iid: appt?.tpp || ""}],
        method: "findOne",
      },
      {
        db: state.db,
        collection: "doctors",
        parameters: [{did: appt.supervisorId || ""}],
        method: "findOne",
      },
    ]);

    res = res.data;

    let transaction = res[0];

    let invData = {
      state,
      transaction: newTransaction ?? transaction,
      doctor: res[1],
      patient: res[2],
      tpp: res[3],
      supervisor: res[4],
      cancelled: newTransaction?.cancelled ?? transaction.cancelled,
    };

    await patientInvoice(invData);
    newInvoice && await fullInvoice(invData);
    if (transaction.tpp) await insurerInvoice(invData);
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function membershipInvoice({state, transaction, patient}) {
  try {
    let blob = await pdf(
      <InvMembPDF
        {...{
          state,
          transaction,
          patient,
          doctor: {},
        }}
        returnDoc
        type="MEMBERSHIP_INVOICE"
      />
    ).toBlob();
    let d = new Date();
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Consolidated_Invoice " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Invoices/Memberships/${membershipFileNameFormat(transaction)}/`
    );
  } catch (e) {
    console.log(e);
  }
}
export async function membershipReceipt({state, transaction, patient}) {
  try {
    let blob = await pdf(
      <InvMembPDF
        {...{
          state,
          transaction,
          patient,
          doctor: {},
        }}
        returnDoc
        type="MEMBERSHIP_RECEIPT"
      />
    ).toBlob();
    let d = new Date();
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Consolidated_Receipt " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Receipts/Memberships/${membershipFileNameFormat(transaction)}/`
    );
  } catch (e) {
    console.log(e);
  }
}

export async function generateClinicAidClaimPdf({invoiceData, state}) {
  try {
    let blob = await pdf(
      <ClinicAidPdf
        invoiceData={invoiceData}
      />
    ).toBlob();
    let d = new Date();

    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Claim " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      invoiceData.pid +
      `/ClinicAidClaims/${lightFormat(new Date(invoiceData.serviceDate), "dd-MM-yy HH-mm aaa") +
        ` (${invoiceData.aid})`}/`
    );
  } catch (e) {
    console.log(e);
  }
}
