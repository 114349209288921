import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useReducer,
  useMemo,
} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {
  loadDataForAdminLogin,
  loadDataForDoctorLogin,
  url,
} from "../crudRequests";

import Navbar from "../components/Navbar";
import InstanceBar from "../components/InstanceBar";
import Calendar from "../components/Calendar";
import Notifications from "../components/Notifications";
import {scheduleReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import AppointmentModal from "../components/AppointmentModal";
import ToastNotification from "../components/toastNotification/ToastNotification";
import {GoTab} from "react-icons/go";
import {FaxNotifications} from "../components/FaxNotifications";

export default function Schedule(props) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [prevState, setPrevstate] = useState(state);
  const loadedRef = useRef(null);
  const reloadingDataRef = useRef(null);
  const selectedAppointment = useRef(null);
  const [closeModals, setCloseModals] = useState(null);
  const [schState, dispatch] = useReducer(scheduleReducer, state, init);

  selectedAppointment.current = schState.selectedAppointment;

  let room =
    schState.userType === "admin"
      ? schState.selectedLocation + schState.admin?.oid
      : schState.doctor?.did;

  useEffect(
    function () {
      verifyUser(state ? state.jwt : "", loadedRef);
    },
    [state]
  );

  const InsurersMap = useMemo(() => {
    return (schState.insurers || []).reduce((acc, el) => {
      acc[el.iid] = el;
      return acc;
    }, {});
  }, [schState.insurers]);

  const socket = useSocket(url, room, dispatch);

  //const socket = useSocket("http://localhost:3000", room, dispatch);

  //"https://webserver.brightlight.ai/"

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }

  function reloadState(destination, abortController, action) {
    action?.();

    if (schState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
    else
      loadDataForDoctorLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
  }

  useLayoutEffect(() => {
    //console.log(schState.changingLocation, 1);
    const abortController = new AbortController();
    if (!schState?.firstLoading) {
      //console.log(schState);
      reloadingDataRef.current = true;
      reloadState("../", abortController, () => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      });

      return () => {
        abortController.abort();
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        reloadingDataRef.current = false;
      };
    }
  }, [schState.selectedLocation]);

  useEffect(() => {
    let f = (e) => {
      if (selectedAppointment.current)
        dispatch({type: "SELECT_APPOINTMENT", payload: null});
    };
    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, []);

  useEffect(() => {
    // console.log(schState.changingLocation, 2);
    if (schState?.firstLoading)
      navigate("../", {
        state: {
          ...schState,
          changingLocation: reloadingDataRef.current,
          firstLoading: false,
        },
      });
  }, []);

  function selectAppointment(appt) {
    dispatch({type: "SELECT_APPOINTMENT", payload: appt});
  }

  const filteredClinicians = useMemo(() => {
    if (schState.doctors)
      return schState.doctors.filter((d) =>
        schState.selectedClinicians.has(d.did)
      );
    else return schState.doctor || [];
  }, [schState.selectedClinicians]);

  function calendarProps() {
    switch (schState.userType) {
      case "admin": {
        if (schState.selectedDoctor) {
          let props = schState.doctorsPayload[schState.selectedDoctor.did];
          return {
            ...props,
            dispatch: dispatch,
            selectAppointment,
            closeModals,
            userType: "admin",
            doctors: filteredClinicians,
            selectedDoctor: schState.selectedDoctor,
            jwt: schState.jwt,
            schState,
          };
        } else {
          return {
            dispatch: dispatch,
            doctorsPayload: schState.doctorsPayload,
            doctors: filteredClinicians,
            selectAppointment,
            selectedDoctor: schState.selectedDoctor,
            closeModals,
            userType: "admin",
            jwt: schState.jwt,
            schState,
          };
        }
      }
      case "doctor": {
        return {
          dispatch: dispatch,
          appointments: schState.appointments,
          patients: schState.patients,
          selectedDoctor: schState.doctor,
          selectAppointment,
          closeModals,
          userType: "doctor",
          jwt: schState.jwt,
          schState,
        };
      }

      default:
        return {schState};
    }
  }

  return (
    <div
      className="relative w-full h-full flex flex-col"
      ref={loadedRef}
      style={{opacity: 0}}
    >
      <ToastNotification appointments={schState.appointments || []} />
      <div
        className="absolute top-6 z-[30] right-12 bg-light opacity-75 py-2 px-[9px] rounded-md cursor-pointer"
        onClick={logoutUser}
      >
        <img src="/images/Doctor/login.png" alt="" className="w-6" />
      </div>
      <FaxNotifications
        {...{
          dispatch,
          schState,
        }}
      />

      <Notifications
        {...{
          dispatch,
          schState,
        }}
      />

      <Navbar state={{...schState}} />
      <InstanceBar
        {...{
          dispatch,
          schState,
        }}
      />
      <SocketContext.Provider value={socket.current}>
        <AppointmentModal
          {...{
            dispatch,
            schState,
            InsurersMap,
          }}
        />
      </SocketContext.Provider>
      <div className="relative pb-3 flex-1 ml-28  w-[calc(100%-7rem)]    z-[32]">
        {schState.changingLocation && (
          <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
          </div>
        )}
        <div className="max-w-[85rem] relative  clinic-calendar-container text-center ml-12 mr-12">
          <SocketContext.Provider value={socket}>
            <Calendar {...calendarProps()} />
          </SocketContext.Provider>
          <div className="p-2 flex justify-center items-center">
            {/* <span
              className="border-b border-slate-400 flex justify-center items-center space-x-1 hover:border-blue-500 px-3 hover:text-blue-500 text-[13px] text-slate-400  cursor-pointer"
              onClick={() => {
                let url = window.location.href.replace("www.", "");
                window.location.href =
                  url + `onlineBooking/${schState.selectedLocation}`;
              }}
            >
              <span>{`Book online`}</span>
              <GoTab className="text-[1rem] -mb-[1px]" />
            </span>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

/*
function ClinicsPicker(props) {
  return (
    <div className="">
      <div className="flex flex-col  space-y-2 items-center">
        <span className=" hover:cursor-pointer flex justify-center items-center rounded-2xl  drop-shadow-lg w-[5.5rem] h-[4rem] bg-[#7E7BFF]">
          <img src="/images/Doctor/halifax.png" alt="" className="hover:cursor-pointer h-[5rem]  drop-shadow-lg " />
        </span>
        {props.userType === "admin" && (
          <button className="flex justify-center relative h-6 items-center focus:outline-none">
            <span className="absolute -top-[2px] bg-[#5754FF]/50 rounded-lg w-full h-full"></span>
            <span className="relative z-10 text-white text-[10px] px-2">CHANGE</span>
            <span className="absolute top-[2px] bg-[#5754FF]/50 rounded-lg w-full h-full"></span>
          </button>
        )}
      </div>
    </div>
  );
}
function ServiceCostList() {
  return (
    <div className="w-60 overflow-hidden h-[12rem] p-1 ">
      <ul className="overflow-y-scroll sbar2 h-full text-white space-y-1 pb-1 mt-2 px-2">
        <li className="py-1 pb-[6px] px-5 bg-[#ABA8FF] rounded-full flex flex-col shadow01">
          <span className="font-[400] text-xs">Initial Visit</span>
          <span className="text-[10px] font-light">
            60 minutes <b className="font-[500]">$300</b>{" "}
          </span>
        </li>
        <li className="py-1 pb-[6px] px-5 bg-[#ABA8FF] rounded-full flex flex-col shadow01">
          <span className="font-[400] text-xs">Followup Visit</span>
          <span className="text-[10px] font-light">
            60 minutes <b className="font-[500]">$200</b>{" "}
          </span>
        </li>
        <li className="py-1 pb-[6px] px-5 bg-[#ABA8FF] rounded-full flex flex-col shadow01">
          <span className="font-[400] text-xs">Counselling Session</span>
          <span className="text-[10px] font-light">
            60 minutes <b className="font-[500]">$250</b>{" "}
          </span>
        </li>
        <li className="py-1 pb-[6px] px-5 bg-[#ABA8FF] rounded-full flex flex-col shadow01">
          <span className="font-[400] text-xs">PP1M Injection</span>
          <span className="text-[10px] font-light">
            60 minutes <b className="font-[500]">$450</b>{" "}
          </span>
        </li>
        <li className="py-1 pb-[6px] px-5 bg-[#ABA8FF] rounded-full flex flex-col shadow01">
          <span className="font-[400] text-xs">Disease Diagnosis</span>
          <span className="text-[10px] font-light">
            60 minutes <b className="font-[500]">$300</b>{" "}
          </span>
        </li>
      </ul>
    </div>
  );
}
*/
