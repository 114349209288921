import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Badge,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import {AiOutlineDelete} from "react-icons/ai";
import React, {useState, useEffect, useRef, useMemo} from "react";
import {crud} from "../../../crudRequests";

import {v4 as uuidv4} from "uuid";

export default function ExternalSales({
  state,
  products = [],
  closeSalesModal,
  callback,
  productSalesInformation,
}) {
  const toast = useToast();
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  const [searchText, setSearchText] = useState("");
  const [selectedProducts, setSelectedProducts] = useState(
    productSalesInformation?.products || []
  );
  const [pdtIds, setpdtIds] = useState(
    new Set(selectedProducts.map((p) => p.id))
  );

  const [productTax, setProductTax] = useState(() => {
    let location = state.locations?.find(
      (l) => l.lid === state.selectedLocation
    );
    return location.productsTaxRate || 0;
  });

  const {tableEntries, selectionsEntries} = useMemo(() => {
    let selectionsEntries = {
      "Selected Product": (p) => p.name,

      Price: (p) => p.price,
      Quantity: (p, key) => (
        <NumberInput
          key={key}
          size="sm"
          maxW={20}
          min={1}
          value={p.quantity}
          max={p.stock}
          onChange={(ele) => {
            setSelectedProducts((prev) =>
              prev.map((e) =>
                e.id === p.id
                  ? {...p, quantity: ele === "" ? 0 : parseInt(ele)}
                  : e
              )
            );
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      ),
    };

    let tableEntries = {
      "Product Name": (p) => p.name,

      "In-Stock": (p) => p.stock,
      Price: (p) => p.price,
    };
    return {tableEntries, selectionsEntries};
  }, []);

  let filteredList = useMemo(() => {
    function filterText(product, text) {
      let regex = new RegExp(text, "i");
      if (text.length === 0) {
        return product;
      }
      return (
        product["name"]?.match(regex) ||
        String(product["price"]).match(regex) ||
        String(product["stock"]).match(regex)
      );
    }

    return products.filter((p) => filterText(p, searchText));
  }, [searchText, products]);

  let amount = selectedProducts.reduce((acc, el) => {
    return acc + parseFloat(el.price) * parseFloat(el.quantity);
  }, 0);

  amount = (amount * (1 + productTax / 100)).toFixed(2);

  return (
    <Modal
      isCentered
      size={"2xl"}
      isOpen={isOpen}
      onClose={() => {
        closeSalesModal();
        onClose();
      }}
      closeOnOverlayClick={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent p={1} overflow={"hidden"}>
        <ModalHeader borderBottom={"1px"} borderColor={"gray.400"}>
          Sale Information
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="10">
          <form
            gap={2}
            id="buyerInfo"
            onSubmit={async (e) => {
              e.preventDefault();
            }}
            m="2"
          >
            <InputGroup p="1" w="60" mb="4">
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" mt="2" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearchText(e.target.value.toLowerCase());
                }}
              />
            </InputGroup>
            <TableContainer
              maxH="15rem"
              minH="8rem"
              mb={8}
              overflowY="auto"
              pb="2"
              borderBottom={"1px solid"}
              borderBottomColor={"gray.300"}
            >
              <Table size="md">
                <Thead position="sticky" top="0" bg="gray.100" zIndex={10}>
                  <Tr>
                    {Object.keys(tableEntries).map((field) => {
                      return <Th fontSize={"11px"}>{field}</Th>;
                    })}
                    <Th fontSize={"11px"}>{"Action"}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredList?.map((product, i) => {
                    return (
                      <>
                        <Tr key={i}>
                          {Object.entries(tableEntries).map(([k, v]) => (
                            <Td fontSize={"15px"}>
                              {k === "Description" ? (
                                <Text w={"15rem"} whiteSpace={"pre-wrap"}>
                                  {v(product) ?? "N/A"}
                                </Text>
                              ) : (
                                v(product) ?? "N/A"
                              )}
                            </Td>
                          ))}
                          <Td fontSize={"15px"}>
                            <Flex gap="2">
                              {!pdtIds.has(product.id) ? (
                                <Badge
                                  colorScheme="blue"
                                  rounded="md"
                                  w="6rem"
                                  textAlign={"center"}
                                  p="1"
                                  cursor={"pointer"}
                                  onClick={() => {
                                    if (!pdtIds.has(product.id)) {
                                      setSelectedProducts((prev) =>
                                        prev.concat({...product, quantity: 1})
                                      );
                                      pdtIds.add(product.id);
                                    }
                                  }}
                                >
                                  Add to Sale
                                </Badge>
                              ) : (
                                <Badge
                                  colorScheme="green"
                                  w="6rem"
                                  textAlign={"center"}
                                  rounded="md"
                                  p="1"
                                >
                                  Selected
                                </Badge>
                              )}
                            </Flex>
                          </Td>
                        </Tr>
                      </>
                    );
                  })}
                </Tbody>
                {products.length === 0 && (
                  <TableCaption mb="4">No products to display.</TableCaption>
                )}
              </Table>
            </TableContainer>

            {selectedProducts.length > 0 && (
              <>
                <Flex
                  justify={"space-between"}
                  align={"flex-end"}
                  mx="1"
                  mb="2"
                >
                  <Text fontWeight={500} color={"blue.500"}>
                    Product Selection
                  </Text>
                  <Button
                    colorScheme="red"
                    variant="outline"
                    size={"xs"}
                    onClick={() => {
                      setSelectedProducts([]);
                      setpdtIds(new Set());
                      callback({
                        products: [],
                        amount: 0,
                        tax: 0,
                      });
                    }}
                  >
                    Clear
                  </Button>
                </Flex>

                <TableContainer
                  overflowY="auto"
                  variant="simple"
                  maxHeight={"250px"}
                  mb="5"
                  rounded={"md"}
                  border={"1px solid"}
                  borderColor={"gray.300"}
                  p="2"
                  pt="0"
                >
                  <Table size="md">
                    <Thead position="sticky" zIndex={10} top="0" bg="white">
                      <Tr>
                        {Object.keys(selectionsEntries).map((field, i) => {
                          return (
                            <Th key={i} fontSize={"11px"}>
                              {field}
                            </Th>
                          );
                        })}
                        <Th fontSize={"11px"}>{"Action"}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedProducts?.map((product, i) => {
                        return (
                          <>
                            <Tr key={i}>
                              {Object.values(selectionsEntries).map((v, i) => (
                                <Td key={i} fontSize={"15px"}>
                                  {v(product, i) ?? "N/A"}
                                </Td>
                              ))}
                              <Td fontSize={"15px"}>
                                <Badge
                                  colorScheme="red"
                                  rounded="md"
                                  p="1"
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setpdtIds((prev) => {
                                      prev.delete(product.id);
                                      return prev;
                                    });
                                    setSelectedProducts((prev) =>
                                      prev.filter((p) => p.id !== product.id)
                                    );
                                  }}
                                >
                                  <Icon
                                    as={AiOutlineDelete}
                                    fontSize="15px"
                                    mt="-1"
                                    mr="1"
                                  />
                                  Remove
                                </Badge>
                              </Td>
                            </Tr>
                          </>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}

            <Flex align={"center"} mb="2" gap={1} fontSize="1em">
              <Text fontWeight={600}>{`Tax (%):`}</Text>

              <Input
                w="3rem"
                fontWeight={600}
                type="number"
                value={productTax}
                onChange={(e) => {
                  let value = e.target.value;

                  if (!isNaN(value) && !value.match(/-/g)) {
                    if (value === "") value = 0;
                    setProductTax(value);
                  }
                }}
                textAlign={"right"}
                size={"xs"}
                p="2"
                rounded={"md"}
              />
            </Flex>
            <Flex align={"center"} gap={1} fontSize="1em">
              <Text fontWeight={600}>{`Total Amount:`}</Text>
              <Badge rounded={"md"} fontSize="1.2em">
                {`$${amount}`}
              </Badge>
            </Flex>
            <></>
          </form>
        </ModalBody>

        {
          <ModalFooter>
            <Button
              type="submit"
              form="buyerInfo"
              w="40"
              colorScheme="blue"
              mr={3}
              cursor={"pointer"}
              onClick={async () => {
                callback({
                  products: selectedProducts,
                  amount,
                  tax: productTax,
                });
                closeSalesModal();
                onClose();
              }}
            >
              Apply
            </Button>
            <Button
              w="40"
              onClick={() => {
                closeSalesModal();
                onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        }
      </ModalContent>
    </Modal>
  );
}

function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
