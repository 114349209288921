export default function transactionFilter(query, transaction) {
  let allowed = true;
  if (
    (!isNaN(query.fromAmount) || !isNaN(query.toAmount)) &&
    query.amountType
  ) {
    let transactionAmount = 0;
    switch (query.amountType) {
      case "overallOutstandingBalance":
        transactionAmount =
          transaction?.tppAmount -
          transaction?.amountPaidByTpp +
          (transaction?.patientAmount - transaction?.amountPaidByPatient);
        break;
      case "patientOutstandingBalance":
        transactionAmount =
          transaction?.patientAmount - transaction?.amountPaidByPatient;
        break;
      case "tppOutstandingBalance":
        transactionAmount =
          transaction?.tppAmount - transaction?.amountPaidByTpp;
        break;
      case "overallCostPaid":
        transactionAmount =
          Number(transaction?.amountPaidByTpp) +
          Number(transaction?.amountPaidByPatient);
        break;
      case "patientCostPaid":
        transactionAmount = transaction?.amountPaidByPatient;
        break;
      case "tppCostPaid":
        transactionAmount = transaction?.amountPaidByTpp;
        break;
      case "overallServiceCost":
        transactionAmount =
          Number(transaction?.tppAmount || 0) +
          Number(transaction?.patientAmount);
        break;
      case "patientServiceCost":
        transactionAmount = transaction?.patientAmount;
        break;
      case "tppServiceCost":
        transactionAmount = transaction?.tppAmount;
        break;
    }
    const fromAmount = Number(query.fromAmount) || -Infinity;
    const toAmount = Number(query.toAmount) || Infinity;

    allowed = transactionAmount >= fromAmount && transactionAmount <= toAmount;
  }

  if (query.insuranceType && allowed) {
    allowed = transaction.insuranceType === query.insuranceType;
  }

  if (query.clinicianName && allowed) {
    const clinicianName = query.clinicianName.toLowerCase();
    allowed = transaction.dName.toLowerCase().includes(clinicianName);
  }

  if (query.fromDate && query.toDate && allowed) {
    const transactionDate = new Date(transaction.serviceDate);
    const fromDate = new Date(query.fromDate);
    const toDate = new Date(query.toDate);

    return transactionDate >= fromDate && transactionDate <= toDate;
  }

  return allowed;
}
