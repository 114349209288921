import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
} from "@chakra-ui/react";

export default function AppointmentNotes({apptNotes, setApptNotes}) {
  return (
    <Modal isOpen={apptNotes} onClose={() => setApptNotes(null)} size={"md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Appointment Notes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList color={"#A1A1A1"}>
              <Tab fontSize={"xs"}>Notes</Tab>
              {apptNotes?.cancellationReason && (
                <Tab fontSize={"xs"}>Cancellation Reason</Tab>
              )}
            </TabList>

            <TabPanels fontSize={"sm"}>
              <TabPanel>
                <Box>{apptNotes?.note}</Box>
              </TabPanel>
              {apptNotes?.cancellationReason && (
                <TabPanel>
                  <Box>{apptNotes?.cancellationReason}</Box>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
