import {crud} from "../../../../crudRequests";

export async function fetchRelations(setRelations, dashState, patient, toast) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "relations",
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ]);
    setRelations(res?.data[0] || []);
  } catch (err) {
    toast({
      title: "Failed to fetch relations data",
      description:
        err?.message || "An error occurred while fetching relations data.",
      status: "error",
      isClosable: true,
    });
  }
}
