import {Box, Text} from "@chakra-ui/react";

export function getNextVisit(patient, appointments) {

  var appointmentDates = [];
  let nextAppt = null;
  let differentString = ""

  appointments.forEach((appt) => {
    if (
      appt["pid"] === patient["pid"] &&
      !appt["cancelled"] &&
      new Date(appt.ISOdate || appt["date"] + " " + appt["time"]) > new Date()
    ) {
      let date = new Date(appt.ISOdate || appt["date"] + " " + appt["time"]);
      appointmentDates.push(date);

      if (nextAppt) {
        nextAppt =
          date <
          new Date(
            nextAppt.ISOdate || nextAppt["date"] + " " + nextAppt["time"]
          )
            ? appt
            : nextAppt;
      } else nextAppt = appt;
    }
  });

  var maxDate = new Date(Math.min(...appointmentDates));
  if (appointmentDates.length !== 0) {
    var diff = Math.floor(maxDate.getTime() - new Date().getTime());
    var day = 1000 * 60 * 60 * 24;
    var days = Math.floor(diff / day);
    var months = Math.floor(days / 31);
    if (days < 31) {
      if (days === 0) {
        differentString = "<24 hours left";
      } else if (days === 1) {
        differentString = "a day left";
      } else {
        differentString = days + " days left";
      }
    } else {
      if (months === 1) {
        differentString = "a month left";
      } else {
        differentString = months + " months left";
      }
    }

    return {
      sortText: maxDate.getTime(),
      component: (
        <Box>
          <Text>
            {maxDate.getDate() +
              "/" +
              (maxDate.getMonth() + 1) +
              "/" +
              maxDate.getFullYear()
            }
          </Text>
          <Text fontSize="10px">{differentString}</Text>
        </Box>
      ),
    };
  } else {
    return {
      component: <Text>unbooked</Text>,
      sortText: Number.MAX_SAFE_INTEGER,
    };
  }
}
