import {
  Box,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Button,
  Badge,
  Tooltip,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  HStack,
  Text,
  CheckboxGroup,
  Checkbox,
  VStack,
} from "@chakra-ui/react";
import {useState} from "react";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function Weekly({date, recurrentCriteriaRef}) {
  const [wRecurrence, setWrecurrence] = useState({
    every: "1",
    weekdays: date
      ? new Set([String(new Date(date).getDay?.())])
      : new Set([String(new Date().getDay?.())]),
  });

  recurrentCriteriaRef.current.frequency = "weekly";
  recurrentCriteriaRef.current.pattern = wRecurrence;

  return (
    <VStack fontSize={"sm"} gap={3} align={"self-start"}>
      <HStack gap="2" align={"center"} fontWeight={600} color={"gray0.600"}>
        <Flex gap="2">
          <Text>Recur every</Text>

          <NumberInput
            size="xs"
            w="10"
            max={99}
            min={1}
            value={wRecurrence.every}
            onBlur={() => {
              if (!wRecurrence.every)
                setWrecurrence((prev) => {
                  return {
                    ...prev,
                    every: 1,
                  };
                });
            }}
            onChange={(e) =>
              setWrecurrence((prev) => {
                if (e.match(/^\d{0,2}$/)) {
                  let v = e.replace(/[-+,.]/g, "");

                  return {
                    ...prev,
                    every: v,
                  };
                } else return prev;
              })
            }
          >
            <NumberInputField textAlign={"end"} p="1" />
          </NumberInput>
        </Flex>
        <Text>week(s) on:</Text>
      </HStack>

      <Flex gap="2" flexWrap={"wrap"}>
        {weekday.map((e, i) => {
          let key = String(i);

          return (
            <Checkbox
              size={"md"}
              colorScheme="blue"
              key={i}
              isChecked={wRecurrence.weekdays.has(key)}
              onChange={(e) => {
                setWrecurrence((prev) => {
                  let d = new Set(prev.weekdays);
                  d.has(key) ? d.delete(key) : d.add(key);

                  return {...prev, weekdays: d};
                });
              }}
              value={key}
            >
              <Text fontSize={"sm"}>{e}</Text>
            </Checkbox>
          );
        })}
      </Flex>
    </VStack>
  );
}
