import SignatureCanvas from "react-signature-canvas";
import {useRef, useState, useEffect} from "react";
import {AiOutlineCloseCircle} from "react-icons/ai";

function SignaturePad({onCreate, blobValue, onDelete = () => {}}) {
  const [openModel, setOpenModal] = useState(false);
  const sigCanvas = useRef();
  const [penColor, setPenColor] = useState("black");
  const [imageURL, setImageURL] = useState(null);

  const colors = ["black", "green", "red"];

  useEffect(() => {
    if (!imageURL || imageURL === undefined || imageURL === "") {
      setImageURL(blobValue);
    }
  }, [blobValue]);

  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setImageURL(URL);
    onCreate(URL);
    setOpenModal(false);
  };

  return (
    <div>
      {imageURL && imageURL !== undefined ? (
        <div className="flex items-center">
          <img
            src={blobValue}
            alt="signature"
            className="signature  w-[200px] h-[60px] p-[10px] bg-white rounded-full"
          />
          <AiOutlineCloseCircle
            onClick={() => {
              setImageURL(null);
              onDelete();
            }}
            size={20}
            color="black cursor-pointer"
          />
          {/* <button
            className="p-[10px_20px]"
            
            style={{padding: "5px", marginTop: "5px"}}
          >
            X
          </button> */}
        </div>
      ) : (
        <button
          className="p-[10px_20px] h-[100%] rounded-full flex justify-center items-center bg-[#757575] p-x-10 text-md text-white"
          onClick={() => setOpenModal(true)}
        >
          create new
        </button>
      )}
      {openModel && (
        <div className="fixed w-[100vw] h-[100%] flex top-0 bottom-0 left-0 right-0 justify-center items-center bg-[rgba(0,0,0,0.4)] z-[50]">
          <div className="w-[90%] w-max-[500px] border-[5px] p-[10px] bg-white">
            <div className="sigPad__penColors mb-[10px]">
              <p className="mr-[5px]">Pen Color:</p>
              {colors.map((color) => (
                <span
                  key={color}
                  className="p-[0px_9px] rounded-[100%] mr-[5px]"
                  style={{
                    backgroundColor: color,
                    border: `${color === penColor ? `2px solid ${color}` : ""}`,
                  }}
                  onClick={() => setPenColor(color)}
                ></span>
              ))}
            </div>
            <div className="bg-[rgb(238,235,235)] p-[0_10px_10px]">
              <SignatureCanvas
                penColor={penColor}
                canvasProps={{className: "w-[100%] h-[200px]"}}
                ref={sigCanvas}
              />
              <hr className="mb-auto bg-[#dcdcdc]" />
              <button
                className="p-[10px_20px]"
                onClick={() => {
                  sigCanvas.current.clear();
                }}
              >
                Clear
              </button>
            </div>

            <div className="text-right mt-[10px]">
              <button
                className="p-[6px_10px]"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </button>
              <button className="p-[6px_10px] create" onClick={create}>
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignaturePad;
