import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
} from "@chakra-ui/react";

import Personal from "./Personal";
import MedicalHistory from "./MedicalHistory";
import Insurance from "./Insurance";
import SocialHistory from "./SocialHistory";
import Payment from "./Payment";
import Relations from "./Relations";
import Other from "./Other";
import ReferralHistory from "./ReferralHistory";
import Notes from "../Notes";

export default function Information() {
  return (
    <Box mx="auto" mb="6" py="2" px="6" boxShadow="md" bg="white">
      <Tabs variant="soft-rounded" colorScheme="blue">
        <Center mb="6">
          <TabList>
            <Tab>Personal</Tab>
            <Tab>Medical History</Tab>
            <Tab>Insurance</Tab>
            <Tab>Referral History</Tab>
            <Tab>Social History</Tab>
            <Tab>Payment</Tab>
            <Tab>Relations</Tab>
            <Tab>Notes</Tab>
            <Tab>Other</Tab>
          </TabList>
        </Center>
        <TabPanels>
          <TabPanel>
            <Personal />
          </TabPanel>
          <TabPanel>
            <MedicalHistory />
          </TabPanel>
          <TabPanel>
            <Insurance />
          </TabPanel>
          <TabPanel>
            <ReferralHistory />
          </TabPanel>
          <TabPanel>
            <SocialHistory />
          </TabPanel>
          <TabPanel>
            <Payment />
          </TabPanel>
          <TabPanel>
            <Relations />
          </TabPanel>
          <TabPanel>
            <Notes />
          </TabPanel>
          <TabPanel>
            <Other />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
