import {BsFillSendFill} from "react-icons/bs";
import {Box, Icon, Badge, Flex, Button, ButtonGroup} from "@chakra-ui/react";
import {faxFile} from "../../../Patients/Files/helpers/faxFile";
import SendEmail from "./SendEmail";
import {useContext, useState} from "react";
import {FiSend} from "react-icons/fi";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {PatientContext} from "../../../../pages/Patients";

export default function Controls({state, preview, toast, appointment}) {
  const [emailTo, setEmailTo] = useState(false);
  const [appt, setAppt] = useState(appointment);
  const [prevAppt, setPrevAppt] = useState(appointment);

  if (prevAppt !== appointment && appointment) {
    setPrevAppt(appointment);
    setAppt(appointment);
  }

  return (
    <Flex
      textAlign={"center"}
      right={"150px"}
      top={6}
      zIndex={80}
      position="absolute"
      justifyContent={"center"}
    >
      {emailTo && (
        <SendEmail {...{state, preview, toast, emailTo, setEmailTo}} />
      )}
      {
        <Menu>
          <MenuButton
            as={Button}
            leftIcon={<FiSend className="mr-1" />}
            colorScheme="BlakAlpha"
            border="1px solid white"
            fontSize="14px"
            p="1"
            pr="2"
          >
            Email/Fax
          </MenuButton>
          <MenuList fontSize="xs">
            {(preview.relatedData.type === "insurer" ||
              preview.relatedData.type === "full") &&
              appt?.tpp && (
                <MenuGroup title="Insurer" textAlign="start">
                  <MenuItem
                    onClick={() => {
                      setEmailTo("insurer");
                    }}
                    pl={4}
                  >
                    Email to Insurer
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      let tpp = appointment?.tpp;

                      let tppFax =
                        state.insurers.find((i) => i.iid === tpp)?.fax || "";

                      let faxNo = prompt("Enter Phone Number", tppFax);
                      faxNo &&
                        faxFile(
                          state,
                          faxNo,
                          preview.path + "/",
                          preview.fileName,
                          toast
                        );
                    }}
                    pl={4}
                  >
                    Fax to Insurer{" "}
                  </MenuItem>
                </MenuGroup>
              )}
            {(preview.relatedData.type === "patient" ||
              preview.relatedData.type === "full") && (
              <>
                {" "}
                <MenuDivider />
                <MenuGroup title="Patient" textAlign="start">
                  <MenuItem
                    onClick={() => {
                      setEmailTo("patient");
                    }}
                    pl={4}
                  >
                    Email to Patient
                  </MenuItem>
                </MenuGroup>
              </>
            )}
          </MenuList>
        </Menu>
      }
    </Flex>
  );
}

/*
<ButtonGroup gap="1" size={{base: "xs", md: "sm"}}>
        {(preview.relatedData.type === "patient" ||
          preview.relatedData.type === "full") && (
          <Button
            colorScheme="whiteAlpha"
            onClick={() => {
              setEmailTo("patient");
            }}
          >
            {" "}
            Email to Patient
          </Button>
        )}
        {(preview.relatedData.type === "insurer" ||
          preview.relatedData.type === "full") &&
          appt?.tpp && (
            <>
              <Button
                colorScheme="whiteAlpha"
                onClick={() => {
                  setEmailTo("insurer");
                }}
              >
                {" "}
                Email to Insurer
              </Button>

              <Button
                colorScheme="whiteAlpha"
                onClick={() => {
                  let faxNo = prompt("Enter Phone Number");
                  faxNo &&
                    faxFile(
                      state,
                      faxNo,
                      preview.path + "/",
                      preview.fileName,
                      toast
                    );
                }}
              >
                {" "}
                Fax to Insurer
              </Button>
            </>
          )}
      </ButtonGroup>
*/
