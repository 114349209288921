import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  Text,
} from '@chakra-ui/react';
import {FaKey} from 'react-icons/fa';
import Note from './Note';

export const KeyNoteModal = ({isOpen, onClose, note, keyNote, adminsMap, cliniciansMap, dashState}) => {
  
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Text>Key Note</Text>
            <FaKey />
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Note
            adminsMap={adminsMap}
            cliniciansMap={cliniciansMap}
            state={dashState}
            key={keyNote?.noteId}
            note={note}
            keyNote={keyNote}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
