import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Select,
  Checkbox,
  Textarea,
  Icon,
  Button,
  useToast,
} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../../pages/Patients";
import {useContext, useState, useEffect, useMemo, useLayoutEffect} from "react";
import {FaDollarSign} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {BsPlusCircleDotted} from "react-icons/bs";
import {insuranceTypes} from "../../../../utils/globalConsts";

export default function UnlimitedInsurers() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const insurersMap = useMemo(() => {
    return (dashState.insurers || []).reduce((ac, e) => {
      ac[e.iid] = e;
      return ac;
    }, {});
  }, [dashState.insurers]);

  useLayoutEffect(() => {
    setPatient((prev) => {
      try {
        return {
          ...prev,
          tpp: (prev?.tpp || []).map((e) => {
            e.insurerAddress =
              e.insurerAddress || insurersMap[e.insurerId]?.address || "";
            e.attentionTo =
              e.attentionTo ||
              insurersMap[e.insurerId]?.defaultAttentionTo ||
              "";
            return e;
          }),
        };
      } catch (e) {
        console.log(e);
        return prev;
      }
    });
  }, []);

  const [insurers, setInsurers] = useState(patient.tpp || []);

  const updateTppAtIndex = (index, newValue) => {
    setPatient({
      ...patient,
      tpp: patient.tpp?.map((item, idx) => {
        if (idx === index) {
          return {...item, ...newValue};
        }
        return item;
      }),
    });
  };

  return (
    <Box textAlign="center">
      <FormControl is="referringClinicianIsGP">
        <Checkbox
          isChecked={patient.tpp}
          onChange={(e) => {
            setPatient({
              ...patient,
              tpp: e.target.checked ? [{insurerId: ""}] : false,
            });
            setInsurers(e.target.checked ? [{insurerId: ""}] : []);
          }}
          mb="6"
        >
          Is the patient insured by a <b>Third Party Payer</b>?
        </Checkbox>
      </FormControl>

      <Box>
        {insurers?.map((insurer, i) => {
          return (
            <Box key={i}>
              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
                gap={6}
                mt={i !== 0 ? "8" : "0"}
              >
                <Box as="span">
                  <FormControl id="insurerId" isDisabled={!patient.tpp}>
                    <FormLabel>Insurer Name</FormLabel>
                    <Select
                      variant="flushed"
                      type="text"
                      placeholder={"Select Insurer"}
                      value={patient?.tpp[i]?.insurerId}
                      onChange={(e) => {
                        updateTppAtIndex(i, {insurerId: e.target.value});
                      }}
                    >
                      {dashState.insurers?.map((insurer, i) => (
                        <option key={i} value={insurer.iid}>
                          {insurer.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <FormControl id="insuranceType" isDisabled={!patient.tpp}>
                  <FormLabel>Insurance Type</FormLabel>
                  <Select
                    variant="flushed"
                    placeholder="Select Insurance Type"
                    value={patient?.tpp[i]?.insuranceType}
                    onChange={(e) => {
                      updateTppAtIndex(i, {insuranceType: e.target.value});
                    }}
                  >
                    {insuranceTypes.map((type) => (
                      <option value={type}>{type}</option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl id="insurerNo" isDisabled={!patient.tpp}>
                  <FormLabel>Insurance Number</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    placeholder="123456"
                    value={patient?.tpp[i]?.insurerNo}
                    onChange={(e) => {
                      updateTppAtIndex(i, {insurerNo: e.target.value});
                    }}
                  />
                </FormControl>

                <FormControl id="policyNo" isDisabled={!patient.tpp}>
                  <FormLabel>Policy Number</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    placeholder="123456"
                    value={patient?.tpp[i]?.policyNo}
                    onChange={(e) => {
                      updateTppAtIndex(i, {policyNo: e.target.value});
                    }}
                  />
                </FormControl>

                <FormControl id="insurerAddress" isDisabled={!patient.tpp}>
                  <FormLabel>Insurer Address</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    placeholder={"123 Main St."}
                    value={patient?.tpp[i]?.insurerAddress || ""}
                    onChange={(e) => {
                      updateTppAtIndex(i, {insurerAddress: e.target.value});
                    }}
                  />
                </FormControl>

                <FormControl id="attentionTo" isDisabled={!patient.tpp}>
                  <FormLabel>Attention To</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    placeholder={"John Doe"}
                    value={patient?.tpp[i]?.attentionTo || ""}
                    onChange={(e) => {
                      updateTppAtIndex(i, {attentionTo: e.target.value});
                    }}
                  />
                </FormControl>
                <FormControl id="authNo" isDisabled={!patient.tpp}>
                  <FormLabel>Auth Number</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    placeholder="123456"
                    value={patient?.tpp[i]?.authNo}
                    onChange={(e) => {
                      updateTppAtIndex(i, {authNo: e.target.value});
                    }}
                  />
                </FormControl>

                <FormControl
                  id="sessionDeductionAmount"
                  isDisabled={!patient.tpp}
                >
                  <FormLabel>
                    Deduction Amount (
                    <Icon as={FaDollarSign} fontSize="15px" mt="-0.5" />)
                  </FormLabel>

                  <Input
                    variant="flushed"
                    type="number"
                    placeholder="75"
                    value={patient?.tpp[i]?.sessionDeductionAmount}
                    onChange={(e) => {
                      updateTppAtIndex(i, {
                        sessionDeductionAmount: e.target.value,
                      });
                    }}
                  />
                </FormControl>

                <FormControl id="noOfInsuredSessions" isDisabled={!patient.tpp}>
                  <FormLabel># of Insured Sessions</FormLabel>
                  <Input
                    variant="flushed"
                    type="number"
                    placeholder="10"
                    value={patient?.tpp[i]?.noOfInsuredSessions}
                    onChange={(e) => {
                      updateTppAtIndex(i, {
                        noOfInsuredSessions: e.target.value,
                      });
                    }}
                  />
                </FormControl>

                <FormControl id="insuranceStartDate" isDisabled={!patient.tpp}>
                  <FormLabel>Active Since</FormLabel>
                  <Input
                    variant="flushed"
                    type="date"
                    placeholder="30"
                    value={patient?.tpp[i]?.insuranceStartDate}
                    onChange={(e) => {
                      updateTppAtIndex(i, {insuranceStartDate: e.target.value});
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
                mt="6"
              >
                <FormControl
                  id="otherInsuranceDetails"
                  isDisabled={!patient.tpp}
                  w="17.77rem"
                >
                  <FormLabel>Other Insurance Details</FormLabel>
                  <Textarea
                    variant="flushed"
                    placeholder="Additional details for reference"
                    h="10rem"
                    value={patient?.tpp[i]?.otherInsuranceDetails}
                    onChange={(e) => {
                      updateTppAtIndex(i, {
                        otherInsuranceDetails: e.target.value,
                      });
                    }}
                  ></Textarea>
                </FormControl>
                <FormControl
                  id="invoiceNotes"
                  isDisabled={!patient.tpp}
                  w="17.77rem"
                >
                  <FormLabel>Invoice Notes</FormLabel>
                  <Textarea
                    variant="flushed"
                    placeholder="Any extra information to be included on the invoice"
                    h="10rem"
                    value={patient?.tpp[i]?.invoiceNotes}
                    onChange={(e) => {
                      updateTppAtIndex(i, {invoiceNotes: e.target.value});
                    }}
                  ></Textarea>
                </FormControl>
              </Grid>

              <Button
                mt="4"
                onClick={() => {
                  setPatient({
                    ...patient,
                    tpp:
                      insurers.length > 1
                        ? patient.tpp.filter((_, idx) => idx !== i)
                        : false,
                  });
                  setInsurers(insurers.filter((_, idx) => idx !== i));
                }}
              >
                <Icon as={MdDelete} mt="-0.5" /> &nbsp;Remove Insurer
              </Button>
            </Box>
          );
        })}
      </Box>
      {patient?.tpp && (
        <Icon
          as={BsPlusCircleDotted}
          fontSize={"26px"}
          textAlign={"center"}
          mt="6"
          mb="4"
          cursor="pointer"
          onClick={() => {
            setInsurers([...insurers, {insurerId: ""}]);
            setPatient({
              ...patient,
              tpp: [...patient.tpp, {insurerId: ""}],
            });
          }}
        />
      )}
    </Box>
  );
}
