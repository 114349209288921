import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {
  Box,
  Text,
  Icon,
  Button,
  Badge,
  Flex,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import {RiInformationFill} from "react-icons/ri";
import IntakeConversation from "./IntakeConversation";
import Risk from "./Risk";
import Differentials from "./Differentials";
import {formatDateTime} from "../helpers/timeFormatters";
import {fetchIntake} from "./helpers/fetchIntake";

export default function Intake() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [intake, setIntake] = useState(null);
  const [conversation, setConversation] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchIntake(setIntake, dashState, patient, toast);
  }, []);

  return (
    <Box mx="auto" my="6" pb="6" px="8" boxShadow="md" bg="white">
      {intake ? (
        <>
          <Text fontSize="10px" color="gray.500" mb="4">
            <Icon as={RiInformationFill} mr="1" fontSize={"14px"} />
            This assessment is based on the chatbot's conversation with the
            patient and should be interpreted with caution. It's essential to
            conduct a thorough evaluation and consult the patient directly
            before making definitive decisions. always approach with
            professional diligence and ensure comprehensive assessments are
            undertaken.
          </Text>

          <Badge variant="ghost" color="blue.600" rounded={"md"}>
            {formatDateTime(intake?.date)}
          </Badge>
          <Risk intake={intake} />
          <Differentials intake={intake} />

          {dashState.userType == "admin" && (
            <>
              <Box my="4">
                <Text mt="6" mb="2" fontSize={"12px"} color="gray.600">
                  The following clinicians are <b>available and qualified</b> to
                  review this case:
                </Text>
                <Flex
                  ml="2"
                  cursor={"pointer"}
                  onClick={() => {
                    navigate("../", {
                      state: {
                        ...dashState,
                        firstLoading: false,
                      },
                    });
                  }}
                >
                  <Flex>
                    <Avatar
                      size="lg"
                      name="Blacklaws, Brydon"
                      src="../images/brydon.png"
                    />
                    <Box ml="4" mt="2.5">
                      <Text fontWeight={"semibold"}>Blacklaws, Brydon</Text>
                      <Text fontWeight={"regular"} fontSize="12px">
                        ER Physician
                      </Text>
                    </Box>
                  </Flex>
                  <Flex
                    ml="6"
                    cursor={"pointer"}
                    onClick={() => {
                      navigate("../", {
                        state: {
                          ...dashState,
                          firstLoading: false,
                        },
                      });
                    }}
                  >
                    <Avatar
                      size="lg"
                      name="Phillips, Gosia"
                      src="../images/gosia.jpg"
                    />
                    <Box ml="4" mt="2.5">
                      <Text fontWeight={"semibold"}>Phillips, Gosia</Text>
                      <Text fontWeight={"regular"} fontSize="12px">
                        Sleep Specialist
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            </>
          )}
          <Box
            w="full"
            textAlign={"center"}
            onClick={() => setConversation(intake?.conversation)}
          >
            <Button colorScheme="blue" rounded="none" py="6" my="3" px="10">
              View Intake Questions & Answers
            </Button>
          </Box>
          <Box w="full" textAlign={"center"} onClick={window.print}>
            <Button
              variant="ghost"
              colorScheme="blue"
              rounded="none"
              px="8"
              py="6"
              my="3"
            >
              Print Report
            </Button>
          </Box>
          <IntakeConversation
            conversation={conversation}
            setConversation={setConversation}
          />
        </>
      ) : (
        <Text textAlign="center" fontWeight="medium" color="gray.600" mt="4">
          No intake found for this patient
        </Text>
      )}
    </Box>
  );
}
