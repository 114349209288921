import {
  Grid,
  Box,
  Text,
  useToast,
  Square,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
} from "@chakra-ui/react";
import {IoIosList} from "react-icons/io";
import {SlOptions} from "react-icons/sl";
import {LuLayoutGrid} from "react-icons/lu";
export default function Layout({setLayout, layout}) {
  return (
    <Flex
      borderRadius="4px"
      backgroundColor={"white"}
      align={"center"}
      justify={"center"}
      zIndex={8}
    >
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<SlOptions />}
          variant="outline"
        />

        <MenuList>
          <MenuGroup title="Layout" textAlign={"start"} color={"blue.500"}>
            <MenuItem
              onClick={() => setLayout("list")}
              color={layout === "list" ? "blue.500" : "gray.600"}
              icon={<IoIosList />}
            >
              List view
            </MenuItem>
            <MenuItem
              onClick={() => setLayout("grid")}
              color={layout !== "list" ? "blue.500" : "gray.600"}
              icon={<LuLayoutGrid />}
            >
              Cards view
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
}
