import {crud} from "../../../crudRequests";

export async function fetchPatient(state, pid, setPatient, toast) {
  try {
    const res = await crud(state, [
      {
        db: state.db,
        collection: "patients",
        parameters: [{pid}],
        method: "findOne",
      },
    ]);
    //console.log(res);
    setPatient(res?.data[0] || null);
  } catch (err) {
    console.log(err);
    toast({
      title: "Failed to fetch patient data",
      description:
        err?.message || "An error occurred while fetching patient data.",
      status: "error",
      isClosable: true,
    });
  }
}
export async function cancelAction(state, abortController) {
  try {
    let location = state.selectedLocation || state.admin.location?.[0];
    let oid = state.admin?.oid;
    let userId = location + oid;

    let requestObjs = [
      {
        db: state.db || "blh",
        collection: "doctors",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "patients",
        parameters: [
          {
            $or: [{lid: location}, {lid: ""}, {lid: {$size: 0}}],
          },
          {
            pid: 1,
            lid: 1,
            fName: 1,
            lName: 1,
            gender: 1,
            email: 1,
            phone: 1,
            referralVia: 1,
            did: 1,
            stpCustomerId: 1,
            healthCard: 1,
            dob: 1,
            tpp: 1,
            noOfInsuredSessions: 1,
            inactive: 1,
            deceased: 1,
            deleted: 1,
            memberships: 1,
            refGpId: 1,
            refClinicianId: 1,
            address: 1,
            city: 1,
            province: 1,
            postalCode: 1,
            country: 1,
            emergencyContactName: 1,
            emergencyContactEmail: 1,
            preferredPronoun: 1,
            address2: 1,
            address1: 1,
            mName: 1,
            preferredName: 1,
            phoneHome: 1,
            phoneAlternative: 1,
            emergencyContactPhone: 1,
            emergencyContactrRelation: 1,
            height: 1,
            weight: 1,
            medications: 1,
            allergies: 1,
            familyHealthProblems: 1,
          },
        ],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "appointments",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "services",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "notifications",
        parameters: [
          {userType: "admin", [userId]: {$exists: true}},
          {[userId]: 1},
        ],
        method: "findOne",
      },
      {
        db: state.db || "blh",
        collection: "admins",
        parameters: [{email: state.admin?.email}],
        method: "findOne",
      },
      {
        db: state.db || "blh",
        collection: "locations",
        parameters: [{lid: {$in: state.admin?.location || []}}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "insurers",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "serviceCriteria",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "provider_types",
        parameters: [{lid: location}],
        method: "find",
      },
    ];

    let requestObjs2 = [
      {
        db: "customers",
        collection: "organizations",
        parameters: [
          {db: state.db},
          {stpSecretKey: 0, clinicAidPassword: 0, _id: 0},
        ],
        method: "findOne",
      },
    ];
    //console.log(location)
    let res = await crud(state, requestObjs, null, abortController.signal);
    //  let res2 = await crud(state, requestObjs2, null, abortController.signal);
    console.log("loadData: ", res);
    //let data = res.data;
  } catch (err) {
    console.log(err);
  }
}
