import {useEffect, useState} from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  IconButton,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Tooltip,
  Icon,
  HStack,
  VStack,
} from "@chakra-ui/react";
import {addWeeks, addDays, lightFormat} from "date-fns";
import moment from "moment";
import {FaAnglesRight, FaAnglesLeft} from "react-icons/fa6";
import generateAvailability from "./helpers/generateAvailability";
import Loader from "./Loader";
export default function AvailabilityList({
  state,
  clinician,
  duration,
  onSelectAvailability,
  service,
}) {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pointer, setPointer] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const count = 5;
  useEffect(() => {
    if (clinician && service) {
      setIsLoading(true);
      setPointer(0);
      getList({
        start: new Date(),
        count,
        state,
        clinician,
        duration,
        service,
      }).then((e) => {
        setIsLoading(false);
        setList(e);
      });
    }
  }, [clinician, duration]);

  let pageItems = list.slice(pointer, pointer + count);

  return (
    <VStack w="full" gap="4" mt="6">
      <TableContainer w="full" overflowY="auto" minH={"100px"} maxH={"300px"}>
        <Table size="md" rounded={"md"}>
          <Thead position="sticky" bg="#9F9DFA" zIndex={10} top="0">
            <Tr textAlign={"center"} color={"white"}>
              <Th textAlign={"center"} color={"white"}>
                Date
              </Th>
              <Th textAlign={"center"} color={"white"}>
                Time
              </Th>
            </Tr>
          </Thead>
          <Tbody border="1px solid gray.200" bg={"white"} rounded={"md"}>
            {isLoading ? (
              <Loader />
            ) : (
              pageItems?.map((e, i) => {
                return (
                  <Tr
                    p="0"
                    cursor={"pointer"}
                    _hover={{
                      backgroundColor: "gray.200",
                    }}
                    key={e}
                    onClick={() => {
                      try {
                        onSelectAvailability(e[0]);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    <Td
                      p="2"
                      textAlign={"center"}
                      fontSize={"15px"}
                      fontWeight={600}
                    >
                      {moment(e[0]).format("MMMM Do YYYY")}
                    </Td>
                    <Td
                      p="2"
                      textAlign={"center"}
                      fontSize={"15px"}
                      fontWeight={600}
                    >
                      {`${moment(e[0]).format("h:mm a")} - ${moment(
                        e[1]
                      ).format("h:mm a")}`}
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <HStack w="full" justify={"space-between"}>
        <IconButton
          isDisabled={disabled}
          variant="outline"
          colorScheme="blue"
          aria-label="Previous"
          fontSize="20px"
          icon={<FaAnglesLeft />}
          onClick={async () => {
            if (disabled) return;
            let newPointer = pointer - count;
            if (list[newPointer]) {
              setPointer(newPointer);
            }
          }}
        />
        <IconButton
          isDisabled={disabled}
          variant="outline"
          colorScheme="blue"
          aria-label="Next"
          fontSize="20px"
          icon={<FaAnglesRight />}
          onClick={async () => {
            if (disabled) return;
            setDisabled(true);

            let newPointer = pointer + count;
            if (list[newPointer] && list.length >= newPointer + count) {
              setPointer(newPointer);
            } else {
              setIsLoading(true);
              let last = list[list.length - 1];
              let start = last[1] ? new Date(last[1]) : new Date();
              let l = await getList({start, count, state, clinician, duration});
              setList((prev) => prev.concat(l));
              setPointer(newPointer);
            }

            setDisabled(false);
            setIsLoading(false);
          }}
        />
      </HStack>
    </VStack>
  );
}

async function getList({start, count, state, clinician, duration}) {
  let list = [];

  try {
    let n = 1;
    while (list.length < count) {
      list = await generateAvailability({
        state,
        start,
        end: addWeeks(start, n),
        clinician,
        duration: duration.split(" ")[0],
      });
      n++;
    }
  } catch (error) {
    console.log(error);
  } finally {
    return list;
  }
}
