import React, {useState, useEffect, useRef} from "react";

import {View, Text, Image} from "@react-pdf/renderer";

export default function ({state}) {
  let src = "";
  if (state.admin) src = state.admin?.signature || state.admin?.signatureBlob;
  else src = state.doctor?.signature || state.doctor?.signatureBlob;
  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        paddingHorizontal: 20,
        marginTop: 60,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flex: 1,
          position: "relative",
        }}
      >
        <View
          style={{
            flexDirection: "col",
            position: "relative",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {src && (
            <View
              style={{
                flexDirection: "col",
                position: "relative",
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid #1f2937",
              }}
            >
              {
                <Image
                  style={{
                    height: "40px",
                    padding: "4px",
                  }}
                  src={src}
                ></Image>
              }
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              position: "relative",
              justifyContent: "flex-start",
              width: "100%",
              marginTop: 5,
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: 600,
              }}
            >
              {"Signed by: "}
            </Text>
            <Text
              style={{
                fontSize: "9px",
              }}
            >
              {state.admin?.name || state.doctor?.name}
            </Text>
          </View>

          {state.doctor && (
            <>
              <View
                style={{
                  flexDirection: "row",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: 600,
                    textAlign: "start",
                  }}
                >
                  {"Registration Number: "}
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                  }}
                >
                  {state.doctor?.registrationId || "N/A"}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: 600,
                    textAlign: "start",
                  }}
                >
                  {"License number: "}
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                  }}
                >
                  {state.doctor?.licenseNumber || "N/A"}
                </Text>
              </View>
            </>
          )}
        </View>
      </View>
    </View>
  );
}
