import React, {
  useState,
} from "react";
import {crud} from "../../crudRequests";
import {
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import {NotePanel} from "./payment/helpers/NotePanel";
export default function AppointmentNote({appt, schState, dispatch, socket}) {
  const [cancellationReason, setCancellationReason] = useState(
    appt?.cancellationReason || ""
  );
  const [note, setNote] = useState(appt?.note || "");
  const [diagnosticCodesNotes, setDiagnosticCodesNotes] = useState(appt?.diagnosticCodesNotes || "");
  const [prevNote, setPrevNote] = useState(appt?.note);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const toast = useToast();
  if (appt?.note !== prevNote) {
    setNote(appt?.note || "");
    setPrevNote(appt?.note);
    setEdit(false);
  }
  const handleTabsChange = (index) => {
    setTabIndex(index);
    setNote(appt?.note || "");
    setCancellationReason(appt?.cancellationReason || "");
    setDiagnosticCodesNotes(appt?.diagnosticCodesNotes || "")
    setEdit(false);
  };

  const saveNote = (noteToSave) => {
    if (appt && !loading) {
      setLoading(true);
      const generatingToast = toast({
        title: "Saving notes",
        description: "The note for this appointment is being updated.",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });

      crud(schState, [
        {
          db: schState.db,
          collection: "appointments",
          parameters: [{aid: appt.aid}, {$set: noteToSave}],
          method: "updateOne",
        },
      ])
        .then(async (res) => {
          toast.close(generatingToast);
          setLoading(false);
          dispatch({type: "UPDATE_APPOINTMENT", payload: {...appt, ...noteToSave}});
          setEdit(false);
          socket?.emit?.("update_appt", {...appt, ...noteToSave}, {});
          toast({
            title: "Note Saved",
            description: "The note was successfully saved.",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        })
        .catch(function (error) {
          setLoading(false);
          toast.close(generatingToast);
          toast({
            title: "Error",
            description: "An error occurred while updating the note.",
            status: "error",
            duration: 1500,
            isClosable: true,
          });
          console.log(error);
        });
    }
  };

  return (
    <div className="text-gray-700 space-y-1 mt-6">
      <Tabs index={tabIndex} onChange={handleTabsChange} isFitted>
        <TabList color={"#A1A1A1"}>
          <Tab fontSize={"0.7rem"}>Notes</Tab>
          <Tab fontSize={"0.7rem"}>Diagnostic Codes Notes</Tab>
          {appt?.cancellationReason && (
            <Tab fontSize={"0.7rem"}>Cancellation Reason</Tab>
          )}
        </TabList>
        <TabPanels>
          <TabPanel p="0" pt="2">
            <NotePanel
              value={note}
              onChange={(e) => {
                appt?.note !== e.target.value && setEdit(true);
                setNote(e.target.value);
              }}
              saveNoteData={() => saveNote({ note })}
              edit={edit}
              loading={loading}
            />
          </TabPanel>
          <TabPanel p="0" pt="2">
            <NotePanel
              value={diagnosticCodesNotes}
              onChange={(e) => {
                appt?.diagnosticCodesNotes !== e.target.value && setEdit(true);
                setDiagnosticCodesNotes(e.target.value);
              }}
              saveNoteData={() => saveNote({ diagnosticCodesNotes })}
              edit={edit}
              loading={loading}
            />
          </TabPanel>
          {appt?.cancellationReason && (
            <TabPanel p="0" pt="2">
              <NotePanel
                value={cancellationReason}
                onChange={(e) => {
                  appt?.cancellationReason !== e.target.value &&
                    setEdit(true);
                  setCancellationReason(e.target.value);
                }}
                saveNoteData={() => saveNote({ cancellationReason })}
                edit={edit}
                loading={loading}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </div>
  );
}
