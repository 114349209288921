import {Box, Text} from "@chakra-ui/react";

export function getLastVisit(patient, appointments) {

  let appointmentDates = appointments
    .filter((appt) => {
      return (
        appt["pid"] === patient["pid"] &&
        !appt["cancelled"] &&
        new Date(appt.ISOdate || appt["date"] + " " + appt["time"]) < new Date()
      );
    })
    .map((appt) => new Date(appt.ISOdate || appt["date"] + " " + appt["time"]));

  let maxDate = new Date(Math.max(...appointmentDates));
  let differentString = ""
  //assuming only one future appointment date/appointment (means can't allow doc to book 2 appointments for a patient) per patient

  if (appointmentDates.length !== 0) {
    var diff = Math.floor(new Date().getTime() - maxDate.getTime());
    var day = 1000 * 60 * 60 * 24;
    var days = Math.floor(diff / day);
    var months = Math.floor(days / 31);
    if (days < 31) {
      if (days === 0) {
        differentString = "<24 hours ago";
      } else if (days === 1) {
        differentString = "a day ago";
      } else {
        differentString = days + " days ago";
      }
    } else {
      if (months === 1) {
        differentString = "a month ago";
      } else {
        differentString = months + " months ago";
      }
    }
    return {
      component: (
        <Box>
          <Text >
            {maxDate.getDate() +
              "/" +
              (maxDate.getMonth() + 1) +
              "/" +
              maxDate.getFullYear()
            }
          </Text>
          <Text fontSize="10px">{differentString}</Text>
        </Box>
      ),
      sortText: maxDate.getTime(),
    };
  } else {
    return {sortText: 0, component: "never"};
  }
}
