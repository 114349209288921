import React from "react";
import {Box, Select} from "@chakra-ui/react";

export default function SelectSingleClinician({
  schState,
  dispatch,
  formData,
  source,
  label,
}) {
  return (
    <div className="mx-1 flex flex-col items-start relative">
      <p className="text-[11px] px-1 mb-1 text-off flex items-end w-full justify-between">
        {
          <Box>
            <span className="flex flex-col justify-start items-start leading-[11px]">
              <span>{label}</span>
            </span>
            {schState?.doctors && (
              <Select
                isRequired={true}
                value={formData[source]}
                onChange={(evt) => {
                  dispatch({
                    type: "SELECT_DOCTOR",
                    payload: {did: evt.target.value},
                  });
                }}
              >
                <option value={null}></option>
                {schState.doctors
                  .sort((a, b) =>
                    a.lastName
                      .trim()
                      .toLowerCase()
                      .localeCompare(b.lastName.trim().toLowerCase())
                  )
                  .map((clinician) => (
                    <option
                      key={clinician.did}
                      value={clinician.did}
                    >{`${clinician.lastName}, ${clinician.firstName}`}</option>
                  ))}
              </Select>
            )}
          </Box>
        }
      </p>
    </div>
  );
}
