import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  useToast,
  Textarea,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {useContext, useState} from "react";
import {updatePatient} from "./helpers/updatePatient";
import CustomSelectField from "./Fields/CustomSelectField";

export default function SocialHistory() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const usageOptions = [
    "Never",
    "Have Tried",
    "Occasionally",
    "Sometimes",
    "Frequently",
    "Daily",
    "Multiple Times Daily",
  ];

  return (
    <form
      onSubmit={(e) =>
        updatePatient(e, patient, dashState, setIsLoading, toast, dispatch)
      }
    >
      <VStack spacing={4}>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
          }}
          gap={6}
        >
          {/* Row 1 */}
          <FormControl id="occupation">
            <FormLabel>Occupation</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="Software Engineer"
              value={patient.occupation}
              onChange={(e) => {
                setPatient({...patient, occupation: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="employerName">
            <FormLabel>Employer Name</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="Google"
              value={patient.employerName}
              onChange={(e) => {
                setPatient({...patient, employerName: e.target.value});
              }}
            />
          </FormControl>

          <CustomSelectField
            id="economicStatus"
            label="Economic Status"
            value={patient.economicStatus}
            onChange={(e) =>
              setPatient({...patient, economicStatus: e.target.value})
            }
            options={[
              "Employed",
              "Unemployed",
              "Student",
              "Retired",
              "On Social Assist/Other"
            ].map((option) => ({
              label: option,
              value: option,
            }))}
          />
          {/* Row 2 */}
          <CustomSelectField
            id="educationLevel"
            label="Education Level"
            value={patient.educationLevel}
            onChange={(e) =>
              setPatient({...patient, educationLevel: e.target.value})
            }
            options={[
              "Elementary",
              "High School",
              "College",
              "Vocational/Trade School",
              "Associate Degree (1-2yrs)",
              "Bachelor Degree (3-5yrs)",
              "Masters",
              "Doctorate (PhD)"
            ].map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <CustomSelectField
            id="tobacco"
            label="Tobacco Use"
            value={patient.tobacco}
            onChange={(e) => setPatient({...patient, tobacco: e.target.value})}
            options={usageOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <CustomSelectField
            id="alcohol"
            label="Alcohol Use"
            value={patient.alcohol}
            onChange={(e) => setPatient({...patient, alcohol: e.target.value})}
            options={usageOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          {/* Row 3 */}
          <CustomSelectField
            id="caffeine"
            label="Caffeine Use"
            value={patient.caffeine}
            onChange={(e) => setPatient({...patient, caffeine: e.target.value})}
            options={usageOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <CustomSelectField
            id="cannabis"
            label="Cannabis Use"
            value={patient.cannabis}
            onChange={(e) => setPatient({...patient, cannabis: e.target.value})}
            options={usageOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <CustomSelectField
            id="maritalStatus"
            label="Marital Status"
            value={patient.maritalStatus}
            onChange={(e) =>
              setPatient({...patient, maritalStatus: e.target.value})
            }
            options={[
              "Single",
              "Married",
              "Common-Law",
              "Separated/Divorced",
              "Widowed",
            ].map((option) => ({
              label: option,
              value: option,
            }))}
          />
          {/* Row 4 */}
          <CustomSelectField
            id="sexualOrientation"
            label="Sexual Orientation"
            value={patient.sexualOrientation}
            onChange={(e) =>
              setPatient({...patient, sexualOrientation: e.target.value})
            }
            options={[
              "Androgynous",
              "Asexual",
              "Bisexual",
              "Gay",
              "Heterosexual",
              "Intersex",
              "Lesbian",
              "Pansexual",
              "Queer",
              "Questioning",
              "Transgender",
              "Two-Spirit (2S)"
            ].map((option) => ({
              label: option,
              value: option,
            }))}
          />
          {/* Row 5 */}
          <CustomSelectField
            id="exerciseFrequency"
            label="Exercise Frequency"
            value={patient.exerciseFrequency}
            onChange={(e) =>
              setPatient({...patient, exerciseFrequency: e.target.value})
            }
            options={[
              "Never",
              "Couple times a year",
              "Couple times a month",
              "Couple times a week",
              "Daily",
            ].map((option) => ({
              label: option,
              value: option,
            }))}
          />

          <Box as="span">
            <FormControl is="dietaryRestrictions">
              <Checkbox
                isChecked={patient.dietaryRestrictions}
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    dietaryRestrictions: e.target.checked,
                  });
                }}
              >
                Dietary Restrictions
              </Checkbox>
            </FormControl>

            <FormControl mt="2">
              <Input
                isDisabled={!patient.dietaryRestrictions}
                variant="flushed"
                type="text"
                placeholder={
                  !patient.dietaryRestrictions
                    ? "No restrictions"
                    : "Vegan and gluten-free"
                }
                value={
                  patient.dietaryRestrictions &&
                  patient.dietaryRestrictions != true
                    ? patient.dietaryRestrictions
                    : ""
                }
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    dietaryRestrictions: e.target.value,
                  });
                }}
              />
            </FormControl>
          </Box>

          {/* Row 5 */}
          <Box>
            <FormControl is="psychedelics">
              <Checkbox
                isChecked={patient.psychedelics}
                onChange={(e) => {
                  setPatient({...patient, psychedelics: e.target.checked});
                }}
              >
                Consume(d) Psychedelics?
              </Checkbox>
            </FormControl>

            {patient.psychedelics == "" ||
              (patient.psychedelics && (
                <FormControl>
                  <Textarea
                    variant="flushed"
                    placeholder="Went on a psilocibin macrodose trip in 2019."
                    value={
                      patient.psychedelics && patient.psychedelics != true
                        ? patient.psychedelics
                        : ""
                    }
                    onChange={(e) => {
                      setPatient({...patient, psychedelics: e.target.value});
                    }}
                  ></Textarea>
                </FormControl>
              ))}
          </Box>
          {/* Row 6 */}
          <Box>
            <FormControl is="hardDrugs">
              <Checkbox
                isChecked={patient.hardDrugs}
                onChange={(e) => {
                  setPatient({...patient, hardDrugs: e.target.checked});
                }}
              >
                Consume(d) Illicit Drugs?
              </Checkbox>
            </FormControl>

            {patient.hardDrugs == "" ||
              (patient.hardDrugs && (
                <FormControl>
                  <Textarea
                    variant="flushed"
                    placeholder="Have taken fentanyl in the past. 1 time occurence."
                    value={
                      patient.hardDrugs && patient.hardDrugs != true
                        ? patient.hardDrugs
                        : ""
                    }
                    onChange={(e) => {
                      setPatient({...patient, hardDrugs: e.target.value});
                    }}
                  ></Textarea>
                </FormControl>
              ))}
          </Box>

          <Box>
            <FormControl is="military">
              <Checkbox
                isChecked={patient.military}
                onChange={(e) => {
                  setPatient({...patient, military: e.target.checked});
                }}
              >
                Served in Military?
              </Checkbox>
            </FormControl>
            {patient.military == "" ||
              (patient.military && (
                <FormControl>
                  <Textarea
                    variant="flushed"
                    placeholder="One deployment to Afghanistan in 2006"
                    value={
                      patient.military && patient.military != true
                        ? patient.military
                        : ""
                    }
                    onChange={(e) => {
                      setPatient({...patient, military: e.target.value});
                    }}
                  ></Textarea>
                </FormControl>
              ))}
          </Box>

          <Box>
            <FormControl is="disability">
              <Checkbox
                isChecked={patient.disability}
                onChange={(e) => {
                  setPatient({...patient, disability: e.target.checked});
                }}
              >
                Disability/Impairment(s)?
              </Checkbox>
            </FormControl>
            {patient.disability == "" ||
              (patient.disability && (
                <FormControl>
                  <Textarea
                    variant="flushed"
                    placeholder="Have spinal cord injury preventing movement."
                    value={
                      patient.disability && patient.disability != true
                        ? patient.disability
                        : ""
                    }
                    onChange={(e) => {
                      setPatient({...patient, disability: e.target.value});
                    }}
                  ></Textarea>
                </FormControl>
              ))}
          </Box>
        </Grid>
        <Button
          isLoading={isLoading && true}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Save
        </Button>
      </VStack>
    </form>
  );
}
