import axios from "axios";

import {url} from "../../../../crudRequests";

export async function processSummary(state, sid, type) {
  return axios({
    method: "POST",
    url: `${url}/processSummary`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: state.doctor?.did || state.admin?.id,
      db: state.db,
      sid: sid,
      type: type,
    }),
  });
}
