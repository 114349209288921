import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverAnchor,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  AvatarGroup,
  Avatar,
  Text,
  Flex,
  Tooltip,
  HStack,
  Box,
} from "@chakra-ui/react";
import {useState} from "react";
import {PatientCliniciansDialog} from "./PatientCliniciansDialog";
import {CheckIcon} from "@chakra-ui/icons";
export default function CliniciansByPatient({
  clinicians,
  assignedClinicians,
  patient,
  size,
  isAdmin,
  schState,
  dispatch,
  setPatient
}) {
  const [isListOpen, setIsListOpen] = useState(false);

  if (clinicians?.length === 0)
    return (
      <HStack>
        <Text>Wait List</Text>
        {isAdmin && (
          <PatientCliniciansDialog
            schState={schState}
            patient={patient}
            clinicians={clinicians}
            assignedClinicians={assignedClinicians}
            dispatch={dispatch}
            setPatient={setPatient}
          />
        )}
      </HStack>
    );
  return (
    <HStack>
      {isAdmin && (
        <PatientCliniciansDialog
          patient={patient}
          assignedClinicians={assignedClinicians}
          clinicians={clinicians}
          schState={schState}
          dispatch={dispatch}
          setPatient={setPatient}
        />
      )}
      <AvatarGroup spacing={"-0.2rem"} size={size || "sm"}>
        {assignedClinicians?.slice(0, 2).map((e, i) => (
          <Box key={i} position="relative" display="inline-block">
            <Tooltip
              key={i}
              rounded={"md"}
              label={`${e.lastName ? e.lastName + ", " : ""}${e.firstName || ""}`}
            >
              <Avatar
                showBorder
                ml={"-1"}
                size={size || "sm"}
                name={`${e.lastName ? e.lastName + ", " : ""}${
                  e.firstName || ""
                }`}
                src={e?.photo}
              />
            </Tooltip>
            {e.did === patient.preferredClinicianId && (
              <Box
                position="absolute"
                top={0}
                right={0}
                bg="green.500"
                color="white"
                borderRadius="full"
                w={3.5}
                h={3.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CheckIcon w={2} h={2}/>
              </Box>
            )}
          </Box>
        ))}
        {assignedClinicians?.length > 2 && (
          <List
            {...{
              patient,
              clinicians: assignedClinicians,
              isListOpen,
              setIsListOpen,
            }}
          >
            <AvatarGroup
              outlineColor={"none"}
              ml={"-1"}
              spacing={"-0.5rem"}
              cursor={clinicians?.length > 3 ? "pointer" : "initial"}
              size={size || "sm"}
              max={1}
              onClick={() => {
                assignedClinicians?.length > 3 &&
                  setIsListOpen((prev) => !prev);
              }}
            >
              {assignedClinicians?.slice(2).map((e, i) => (
                <Tooltip
                  key={i}
                  onClick={(e) => e.stopPropagation()}
                  rounded={"md"}
                  label={`${e.lastName ? e.lastName + ", " : ""}${
                    e.firstName || ""
                  }`}
                >
                  <Avatar
                    onClick={(e) => e.stopPropagation()}
                    showBorder
                    size={size || "sm"}
                    cursor={"initial"}
                    name={`${e.lastName ? e.lastName + ", " : ""}${
                      e.firstName || ""
                    }`}
                    src={e?.photo}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
          </List>
        )}
      </AvatarGroup>
    </HStack>
  );
}

function List({patient, clinicians, isListOpen, setIsListOpen, children}) {
  return (
    <Popover
      trapFocus={false}
      placement="top-start"
      isOpen={isListOpen}
      onClose={() => setIsListOpen(false)}
    >
      <PopoverAnchor>{children}</PopoverAnchor>

      <PopoverContent shadow={"md"}>
        <PopoverHeader fontWeight="semibold">
          <Flex justify={"space-between"} align={"center"}>
            <Text>{`${patient?.fName || "Patient"}'s Clinicians`}</Text>{" "}
          </Flex>
        </PopoverHeader>
        <PopoverArrow />

        <PopoverBody>
          <TableContainer overflowY="auto" minH={"50px"} maxH={"150px"}>
            <Table size="md">
              <Tbody>
                {clinicians?.map((e, i) => {
                  return (
                    <Tr p="0" key={i}>
                      <Td p="0.5" fontSize={"15px"}>
                        <Flex gap="2" align={"center"}>
                          <Avatar
                            showBorder
                            size={"sm"}
                            cursor={"initial"}
                            name={e.name}
                            src={e?.photo}
                          />
                          <Text fontSize="sm" fontWeight="">
                            {e.name}
                          </Text>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
