import {
  Tag,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Divider,
  Avatar,
  Text,
} from "@chakra-ui/react";

export default function Symptoms({symptoms, differential}) {
  const uniqueSymptoms = symptoms?.filter(
    (symptom, index, self) =>
      symptom.symptom.associatedDisorders.includes(differential.disorder.did) &&
      index === self.findIndex((s) => s.symptom.name === symptom.symptom.name)
  );

  return (
    <>
      {uniqueSymptoms?.map((symptom) => {
        return (
          <Popover>
            <PopoverTrigger>
              <Tag
                borderRadius="full"
                variant="outline"
                colorScheme="blue"
                cursor="pointer"
              >
                {symptom.symptom.name}
              </Tag>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontWeight={"bold"}>
                {symptom.symptom.name}
              </PopoverHeader>
              <PopoverHeader>{symptom.symptom.metadata}</PopoverHeader>
              <PopoverBody mb="-6" mt="2">
                {symptom.conversation.map((ind) => {
                  return (
                    <>
                      <Flex>
                        <Avatar
                          name="Question"
                          bg="blue.800"
                          color="white"
                          size="xs"
                          mx="2"
                          h="17px"
                          w="17px"
                        />
                        <Text fontSize={"12px"} mb="2">
                          {ind.question}
                        </Text>
                      </Flex>
                      <Flex>
                        <Avatar
                          name="Response"
                          bg="blue.500"
                          color="white"
                          size="xs"
                          mx="2"
                          h="17px"
                          w="17px"
                        />
                        <Text fontSize={"12px"} fontWeight={"bold"}>
                          {ind.response}
                        </Text>
                      </Flex>
                      <Divider orientation="horizontal" my="4" />
                    </>
                  );
                })}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        );
      })}
    </>
  );
}
