import React, {useState} from "react";
import {crud} from "../crudRequests";
import {nanoid} from "nanoid";
import ImageInput from "./ImageInput";
//
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  VStack,
  Badge,
  Icon,
  StackDivider,
} from "@chakra-ui/react";
import {IoMdClose} from "react-icons/io";
import timeZones from "../additional_files/timeZones.json";
import AdditionalAddressModal from "./Locations/AdditionalAddressModal";
const ModalAddLocation = ({
  show,
  setShow,
  state,
  locationData,
  setLocationData,
  allLocations,
  populatePeopleByLid,
  setLocations,
  dispatch,
  modalMode = "add",
}) => {
  const oid =
    state.userType === "doctor" ? state.doctor?.oid : state.admin?.oid;
  const [errorMessage, setErrorMessage] = useState("");
  const [showAdditionalAddress, setShowAdditionalAddress] = useState(false);
  let timeZoneList = timeZones.sort();
  const insertLidIntoCurrentAdmin = async (state, newLid) => {
    if (state.userType !== "admin") return;
    const prevLocations = state.admin.location;
    const newLocations = [...prevLocations, newLid];
    const updateBody = {
      super: true,
      id: state.admin.id,
      location: newLocations,
    };

    if (newLocations.length < 2) {
      throw new Error("Admin must have at least one location");
    }

    console.log("updating admin", updateBody);
    const res = await crud(state, [
      {
        db: state.db,
        collection: "admins",
        parameters: [{id: updateBody.id}, {$set: updateBody}],
        method: "updateOne",
      },
    ]);
    if (res.status === 200) {
      console.log("inserted new Lid into admin", res);
      dispatch({type: "UPDATE_USER", payload: {location: newLocations}});
      populatePeopleByLid(newLid);
    } else {
      setErrorMessage("Error updating admin");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (modalMode === "edit") {
      let {_id, stpSecretKey, ...data} = locationData;

      if (stpSecretKey?.trim()) {
        data.stpSecretKey = stpSecretKey;
      }

      const res = await crud(state, [
        {
          db: state.db,
          collection: "locations",
          parameters: [{lid: data.lid}, {$set: data}],
          method: "updateOne",
        },
      ]);

      if (res.status === 200) {
        const updatedLocations = allLocations.current.map((location) => {
          if (location.lid === data.lid) {
            return locationData;
          }
          return location;
        });
        setLocationData({
          name: "",
          description: "",
          logo: "",
          imgList: "",
          address: "",
          phone: "",
          fax: "",
          phaxioNumber: "",
          productsTaxRate: 0,
          stpSecretKey: "",
          stpPublicKey: "",
          invRecNotes: "",
          timeZone: "",
          additionalAddress: [],
        });
        dispatch({type: "UPDATE_LOCATIONS", payload: updatedLocations});
        allLocations.current = updatedLocations;

        setLocations(updatedLocations);
        setShow(false);
      } else {
        setErrorMessage("Error updating location");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }

      return;
    }

    const locationPayload = {
      lid: nanoid(10),
      oid,
      name: locationData.name,
      description: locationData.description,
      logo: locationData.logo || "",
      address: locationData.address || "",
      phone: locationData.phone || "",
      fax: locationData.fax || "",
      phaxioNumber: locationData.phaxioNumber || "",
      productsTaxRate: locationData.productsTaxRate || 0,
      stpSecretKey: locationData.stpSecretKey || "",
      stpPublicKey: locationData.stpPublicKey || "",
      invRecNotes: locationData.invRecNotes || "",
      timeZone: locationData.timeZone || "",
      additionalAddress: locationData.additionalAddress || [],
    };

    const res = await crud(state, [
      {
        db: state.db,
        collection: "locations",
        parameters: [locationPayload],
        method: "insertOne",
      },
    ]);
    if (res.status === 200) {
      setLocationData({
        name: "",
        description: "",
        logo: "",
        imgList: "",
        address: "",
        phone: "",
        fax: "",
        phaxioNumber: "",
        productsTaxRate: 0,
        stpSecretKey: "",
        stpPublicKey: "",
        invRecNotes: "",
        timeZone: "",
        additionalAddress: [],
      });

      dispatch({type: "NEW_LOCATION", payload: locationPayload});
      await insertLidIntoCurrentAdmin(state, locationPayload.lid);

      const updatedLocations = [
        ...allLocations.current,
        {...locationPayload, _id: res.data.insertedId},
      ];
      allLocations.current = updatedLocations;
      setLocations(updatedLocations);
      setShow(false);
    } else {
      setErrorMessage("Error adding location");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };
  return (
    <Modal
      isCentered
      isOpen={show}
      onClose={() => {
        setShow(false);
      }}
      closeOnOverlayClick={false}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"2xl"} color={"rgb(192, 191, 255)"}>
          {modalMode === "add" ? "Add Location" : "Edit Location"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="w-full relative inline-block  align-bottom bg-[#f6f5ff] rounded-lg text-left overflow-hidden shadow-xl transform transition-all ">
            <form
              onSubmit={submitHandler}
              className="h-full relative"
              name="addLocationForm"
              id="addLocationForm"
            >
              {showAdditionalAddress && (
                <AdditionalAddressModal
                  {...{
                    setLocationData,
                    locationData,
                    show: showAdditionalAddress,
                    setShow: setShowAdditionalAddress,
                  }}
                />
              )}

              <div className=" overflow-hidden sm:rounded-md">
                <div className="px-4 pb-2">
                  <div className="flex items-center pt-8 justify-center mb-5">
                    <label className="block mx-3.5 ">
                      <ImageInput
                        setData={setLocationData}
                        field={"logo"}
                        previous={locationData.logo}
                        showModal={show}
                      />
                    </label>
                  </div>
                  <div className="flex justify-between w-full gap-2">
                    <label className="block mx-3.5 mt-1" htmlFor="locationName">
                      <span className="text-[13px] text-off ml-2">
                        Location Name
                      </span>
                      <input
                        type="text"
                        name="locationName"
                        id="locationName"
                        required
                        value={locationData.name}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            name: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label className="block mx-3.5 mt-1" htmlFor="address">
                      <span className="text-[13px] text-off ml-2">
                        Main Address
                      </span>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        required
                        value={locationData.address}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            address: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                  <div className="flex justify-between w-full gap-2">
                    <label className="block mx-3.5 mt-1" htmlFor="phone">
                      <span className="text-[13px] text-off ml-2">Phone</span>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        required
                        value={locationData.phone}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            phone: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label className="block mx-3.5 mt-1" htmlFor="fax">
                      <span className="text-[13px] text-off ml-2">Fax</span>
                      <input
                        type="text"
                        name="fax"
                        id="fax"
                        required
                        value={locationData.phaxioNumber}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            phaxioNumber: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                  <div className="flex  w-full gap-2">
                    <label
                      className="block mx-3.5 mt-1 flex-1"
                      htmlFor="productsTaxRate"
                    >
                      <span className="text-[13px] text-off ml-2">
                        Product Tax Rate (%)
                      </span>
                      <input
                        type="number"
                        name="productsTaxRate"
                        id="productsTaxRate"
                        required
                        value={locationData.productsTaxRate}
                        onChange={(e) => {
                          let value = e.target.value;

                          if (!isNaN(value) && !value.match(/-/g)) {
                            setLocationData({
                              ...locationData,
                              productsTaxRate: value,
                            });
                          }
                        }}
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label
                      className="block mx-3.5 mt-1 flex-1"
                      htmlFor="timeZone"
                    >
                      <span className="text-[13px] text-off ml-2">
                        Select Timezone
                      </span>
                      <select
                        onChange={(e) => {
                          setLocationData({
                            ...locationData,
                            timeZone: e.currentTarget.value,
                          });
                        }}
                        value={locationData.timeZone || ""}
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      >
                        <option value="">-</option>
                        {timeZoneList.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                  <div className="flex px-3.5 justify-between w-full">
                    <label
                      className="block  mt-3 w-full"
                      htmlFor="stpPublicKey"
                    >
                      <span className="text-[11px] text-off ml-1">
                        Stripe Public Key
                      </span>
                      <input
                        onChange={(e) => {
                          setLocationData({
                            ...locationData,
                            stpPublicKey: e.target.value,
                          });
                        }}
                        value={locationData.stpPublicKey || ""}
                        type="text"
                        name="stpPublicKey"
                        id="stpPublicKey"
                        className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>

                  <div className="flex px-3.5 justify-between w-full">
                    <label
                      className="block  mt-3 w-full"
                      htmlFor="stpSecretKey"
                    >
                      <span className="text-[11px] text-off ml-1">
                        Stripe Secret Key
                      </span>
                      <input
                        onChange={(e) => {
                          setLocationData({
                            ...locationData,
                            stpSecretKey: e.target.value,
                          });
                        }}
                        value={locationData.stpSecretKey || ""}
                        type="password"
                        name="stpSecretKey"
                        id="stpSecretKey"
                        className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                  <div className="flex  justify-between w-full">
                    <label
                      className="block w-full mx-3.5 mt-3"
                      htmlFor="invRecNotes"
                    >
                      <span className="text-[13px] text-off ml-2">
                        Invoice/Receipt Notes
                      </span>
                      <textarea
                        name="invRecNotes"
                        id="invRecNotes"
                        required
                        value={locationData.invRecNotes}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            invRecNotes: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                  <div className="flex  justify-between w-full">
                    <label
                      className="block w-full mx-3.5 mt-3"
                      htmlFor="locationDescription"
                    >
                      <span className="text-[13px] text-off ml-2">
                        Description
                      </span>
                      <textarea
                        name="locationDescription"
                        id="locationDescription"
                        required
                        value={locationData.description}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            description: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="px-2 py-3 w-full flex justify-center ">
                <div
                  className="mt-4 cursor-pointer text-center w-full mx-3.5 rounded-lg text-background bg-med py-2"
                  onClick={() => setShowAdditionalAddress(true)}
                >
                  Additional Address
                </div>
              </div>
              <div className="px-2 py-3 pb-7 w-full flex justify-center ">
                <button
                  type="submit"
                  className="w-full mb-4 mx-3.5 rounded-lg text-background bg-med py-2"
                >
                  {modalMode === "add" ? "Add Location" : "Save Changes"}
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddLocation;
