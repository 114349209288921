import {v4 as uuidv4} from "uuid";

export function apptStatus(appt) {
  let statusMap = {
    noShow: "No Show",
    cancelled: "Cancelled",
    pConfirmed: "Confirmed",
    rescheduled: "Rescheduled",
  };

  for (let [key, val] of Object.entries(statusMap)) {
    if (appt[key]) return val;
  }
  return "Unconfirmed";
}

export function notificationsData(userType, ntfType, payload, schState) {
  let ntfId = uuidv4();

  const ntfFormater = (ntfType) => {
    switch (ntfType) {
      case "Updated appointment":
      case "New appointment": {
        return {
          ntfId,
          class: "appointment",
          type: ntfType,
          pName: payload.pName,
          dName: payload.dName,
          status: apptStatus(payload),
          creationDate: new Date(),
          apptDate: payload.ISOdate,
          read: false,
        };
      }
      case "New Form Submission": {
        return {
          ntfId,
          class: "Form",
          type: ntfType,
          pName: payload.pName,
          submittedBy: schState?.doctor
            ? schState?.doctor.name
            : schState?.admin?.name,
          creationDate: new Date(),
          formTitle: payload.formTitle,
          read: false,
        };
      }

      case "New chatbot intake": {
        return {
          class: "chatbot",
          type: "New chatbot intake",
          pName: payload.pName,
          dName: payload.dName,
          creationDate: new Date(),
          read: false,
        };
      }

      default:
        {
        }
        break;
    }
  };

  let ntf = ntfFormater(ntfType);

  if (ntfType === "New Form Submission") return {ntf};

  switch (userType) {
    case "admin": {
      return {
        ntf,
        ntfList: [
          {userType: "doctor", id: payload.did, notification: ntf},
          {userType: "patient", id: payload.pid, notification: ntf},
        ],
      };
    }
    case "patient": {
      return {
        ntf,
        ntfList: [
          {userType: "doctor", id: payload.did, notification: ntf},
          {userType: "admin", id: payload.lid + payload.oid, notification: ntf},
        ],
      };
    }

    default: {
      return {
        ntf,
        ntfList: [
          {userType: "admin", id: payload.lid + payload.oid, notification: ntf},
          {userType: "patient", id: payload.pid, notification: ntf},
        ],
      };
    }
  }
}
