import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Badge,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import {formatDateTime, getTimeDifference} from "../helpers/timeFormatters";

export default function FormMetadataModal({formMetadata, setFormMetadata}) {
  const metadata = [
    {
      name: "Date Created",
      value: formatDateTime(formMetadata?.createdDate) || "N/A",
    },
    {
      name: "Date Assigned",
      value: formatDateTime(formMetadata?.assignedDate) || "N/A",
    },
    {
      name: "Date Completed",
      value: formMetadata?.completedDate
        ? formatDateTime(formMetadata?.completedDate)
        : "Incomplete",
    },
    {
      name: "Form Completion Time",
      value:
        getTimeDifference(
          formMetadata?.assignedDate,
          formMetadata?.completedDate
        ) || "Still Counting",
    },
    {
      name: "Form Created By",
      value: formMetadata?.createdBy,
    },
    {
      name: "Form Assigned By",
      value: formMetadata?.assignedBy,
    },
  ];

  return (
    <Modal
      isOpen={formMetadata}
      onClose={() => setFormMetadata(null)}
      size={"md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Form Metadata</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {metadata.map((m, key) => (
            <Flex my="2" w="full">
              <Text fontSize={"14px"} fontWeight={"medium"} as="span">
                {m.name}
              </Text>
              <Spacer />
              <Badge
                colorScheme={"blackAlpha"}
                variant="subtle"
                fontSize="12px"
                rounded="md"
                p="1"
                px="2"
              >
                {m.value}
              </Badge>
            </Flex>
          ))}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
