import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 25,
    fontFamily: 'Inter',
    color: '#1f2937',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  section: {
    width: '100%',
    marginBottom: 40,
  },
  tableHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'start',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottom: 'none',
    marginHorizontal: 20,
    padding: 4,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    marginHorizontal: 20,
    padding: 8
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeaderRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
  },
  tableCellHeader: {
    flex: 1,
    fontSize: 10,
    fontWeight: 'bold',
    padding: 2,
    textAlign: 'start',
  },
  tableCellHeaderDescription: {
    flex: 2,
    fontSize: 10,
    fontWeight: 'bold',
    padding: 2,
    textAlign: 'start',
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    padding: 2,
    textAlign: 'start',
  },
  tableCellDescription: {
    flex: 2,
    fontSize: 10,
    padding: 3,
    textAlign: 'start',
  },
  patientTable: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    marginHorizontal: 20,
    padding: 8
  },
  patientTableRow: {
    flexDirection: 'row',
  },
  patientTableCellLabel: {
    flex: 1,
    fontSize: 10,
    fontWeight: 'bold',
    padding: 2,
    textAlign: 'left',
  },
  patientTableCellValue: {
    flex: 1,
    fontSize: 10,
    padding: 2,
    textAlign: 'left',
  },
  providerTable: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    marginHorizontal: 20,
    padding: 8
  },
  providerTableRow: {
    flexDirection: 'row',
  },
  providerTableCellLabel: {
    flex: 1,
    fontSize: 10,
    fontWeight: 'bold',
    padding: 2,
    textAlign: 'left',
  },
  providerTableCellValue: {
    flex: 1,
    fontSize: 10,
    padding: 2,
    textAlign: 'left',
  },
  providerTableSingleRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  additionalTable: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    marginHorizontal: 20,
    padding: 8
  },
  additionalTableRow: {
    flexDirection: 'row',
  },
  additionalTableCellLabel: {
    flex: 1,
    fontSize: 10,
    fontWeight: 'bold',
    padding: 2,
    textAlign: 'left',
  },
  additionalTableCellValue: {
    flex: 1,
    fontSize: 10,
    padding: 2,
    textAlign: 'left',
  },
  additionalTableSingleRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const ClinicAidPdf = ({invoiceData, returnDoc}) => {
  const patient = invoiceData?.patient
  const provider = invoiceData?.provider
  const ontarioData = invoiceData?.invoice?.invoice_ontario_ohip_data

  const Doc = (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.section}>
          <Text style={styles.tableHeader}>Fee Details (Status: New):</Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              <Text style={styles.tableCellHeader}>Service Code</Text>
              <Text style={styles.tableCellHeader}>Service Date</Text>
              <Text style={styles.tableCellHeaderDescription}>Description</Text>
              <Text style={styles.tableCellHeader}>#</Text>
              <Text style={styles.tableCellHeader}>Fee</Text>
              <Text style={styles.tableCellHeader}>Dx Code</Text>
              <Text style={styles.tableCellHeader}>Dx Description</Text>
            </View>
            {ontarioData?.invoice_ontario_items?.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{item.service_code}</Text>
                <Text style={styles.tableCell}>{item.service_date}</Text>
                <Text style={styles.tableCellDescription}>{item.description ?? "N/A"}</Text>
                <Text style={styles.tableCell}>{item.number_of_services}</Text>
                <Text style={styles.tableCell}>${item.fee_submitted}</Text>
                <Text style={styles.tableCell}>{item.diagnostic_code}</Text>
                <Text style={styles.tableCell}>{item.diagnostic_description ?? "N/A"}</Text>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.tableHeader}>Patient Details:</Text>
          <View style={styles.patientTable}>
            <View style={styles.patientTableRow}>
              <Text style={styles.patientTableCellLabel}>Patient:</Text>
              <Text style={styles.patientTableCellValue}>{`${patient.last_name}, ${patient.first_name}`}</Text>
              <Text style={styles.patientTableCellLabel}>Health #:</Text>
              <Text style={styles.patientTableCellValue}>{patient.health_number}</Text>
              <Text style={styles.patientTableCellLabel}>Ver:</Text>
              <Text style={styles.patientTableCellValue}>{patient.ontario_version_code}</Text>
            </View>
            <View style={styles.patientTableRow}>
              <Text style={styles.patientTableCellLabel}>HC Type:</Text>
              <Text style={styles.patientTableCellValue}>{patient.hc_type ?? ""}</Text>
              <Text style={styles.patientTableCellLabel}>Birth Date:</Text>
              <Text style={styles.patientTableCellValue}>{patient.birth_date}</Text>
              <Text style={styles.patientTableCellLabel}>Age:</Text>
              <Text style={styles.patientTableCellValue}>{patient.age}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.tableHeader}>Provider Details:</Text>
          <View style={styles.providerTable}>
            <View style={styles.providerTableRow}>
              <Text style={styles.providerTableCellLabel}>Provider:</Text>
              <Text style={styles.providerTableCellValue}>{`${provider.last_name}, ${provider.first_name}`}</Text>
              <Text style={styles.providerTableCellLabel}>Provider ID:</Text>
              <Text style={styles.providerTableCellValue}>{provider.unique_id}</Text>
            </View>
            <View style={styles.providerTableRow}>
              <Text style={styles.providerTableCellLabel}>Visit Location:</Text>
              <Text style={styles.providerTableCellValue}>{provider.visit_location ?? ""}</Text>
              <Text style={styles.providerTableCellLabel}>Specialty Code:</Text>
              <Text style={styles.providerTableCellValue}>{provider.ontario_specialty}</Text>
            </View>
            <View style={styles.providerTableSingleRow}>
              <Text style={styles.providerTableCellLabel}>Group Number:</Text>
              <Text style={styles.providerTableCellValue}>{provider.ontario_group_number}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.tableHeader}>Additional Invoice Information:</Text>
          <View style={styles.additionalTable}>
            <View style={styles.additionalTableRow}>
              <Text style={styles.additionalTableCellLabel}>Health Number:</Text>
              <Text style={styles.additionalTableCellValue}>{patient.health_number}</Text>
              <Text style={styles.additionalTableCellLabel}>Version Code:</Text>
              <Text style={styles.additionalTableCellValue}>{patient.ontario_version_code}</Text>
            </View>
            <View style={styles.additionalTableRow}>
              <Text style={styles.additionalTableCellLabel}>Patient Birth Date:</Text>
              <Text style={styles.additionalTableCellValue}>{patient.birth_date}</Text>
              <Text style={styles.additionalTableCellLabel}>Patient Gender:</Text>
              <Text style={styles.additionalTableCellValue}>{patient.gender}</Text>
            </View>
            <View style={styles.additionalTableRow}>
              <Text style={styles.additionalTableCellLabel}>Payment Program:</Text>
              <Text style={styles.additionalTableCellValue}>{ontarioData.payment_program ?? "HCP"}</Text>
              <Text style={styles.additionalTableCellLabel}>Payee:</Text>
              <Text style={styles.additionalTableCellValue}>{ontarioData.payee}</Text>
            </View>
            <View style={styles.additionalTableSingleRow}>
              <Text style={styles.additionalTableCellLabel}>Service Location Indicator:</Text>
              <Text style={styles.additionalTableCellValue}>{ontarioData.service_location_indicator}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return Doc
}
