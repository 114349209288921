import axios from "axios";
import {url, jwtDecode} from "../../../../crudRequests";

// gets the JWT that is valid for the Jisti meeting with doctor permissions
export async function getJitsiJWT(state, AID, PID, db, doctor, meetingUrl) {
  // if (state.userType !== "doctor") return null;
  let configService = {
    method: "POST",
    url: `${url}/getJitsiJWT`,
    data: {
      AID,
      PID,
      db,
      DID: doctor?.did,
      JWT: state.jwt,
      drName: doctor?.name,
      id: state?.jwt ? jwtDecode(state.jwt).id : "",
      photo: doctor?.photo,
      drEmail: doctor?.email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };

  return axios(configService).then((res) => {
    // console.log({res});
    if (res?.data?.jwt) {
      console.log(meetingUrl);
      window.open(`${meetingUrl}?jwt=${res?.data?.jwt}`, "_blank");
    }
    return null;
  });
}
