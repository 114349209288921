import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  Heading,
  useToast,
} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {addRelation} from "../helpers/addRelation";
import CustomSelectField from "../Fields/CustomSelectField";
import {nanoid} from "nanoid";

export default function AddRelation({relations, setRelations}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [newRelation, setNewRelation] = useState({
    rid: nanoid(10),
    pid: patient.pid,
  });

  const toast = useToast();

  useEffect(() => {
    relations?.map((r) => {
      if (newRelation.email && r.email === newRelation.email) {
        toast({
          title: "Enter different email",
          description: "A relation with this email already exists",
          status: "error",
          isClosable: true,
        });
        setNewRelation({...newRelation, email: ""});
      }
      if (newRelation.phone && r.phone === newRelation.phone) {
        toast({
          title: "Enter different phone number",
          description: "A relation with this phone number already exists",
          status: "error",
          isClosable: true,
        });
        setNewRelation({...newRelation, phone: ""});
      }
    });
  }, [newRelation.email, newRelation.phone]);

  return (
    <form
      onSubmit={(e) =>
        addRelation(
          e,
          newRelation,
          dashState,
          setRelations,
          patient,
          setIsLoading,
          toast
        )
      }
    >
      <VStack spacing={4}>
        <Heading size="sm" my="6">
          Add a new relation
        </Heading>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gap={6}
        >
          {/* Row 1 */}
          <FormControl id="name">
            <FormLabel>Name</FormLabel>
            <Input
              variant="flushed"
              type="text"
              placeholder="John Doe"
              value={newRelation.name}
              onChange={(e) => {
                setNewRelation({...newRelation, name: e.target.value});
              }}
            />
          </FormControl>

          <CustomSelectField
            id="relation"
            label="Relationship to Patient"
            value={newRelation.relation}
            onChange={(e) =>
              setNewRelation({...newRelation, relation: e.target.value})
            }
            options={[
              "Mother",
              "Father",
              "Sibling",
              "Grandparent",
              "Legal Guardian",
              "Other Relative",
              "Friend",
              "Acquaintance",
            ].map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              variant="flushed"
              type="email"
              placeholder="john.doe@example.com"
              value={newRelation.email}
              onChange={(e) => {
                setNewRelation({...newRelation, email: e.target.value});
              }}
            />
          </FormControl>

          <FormControl id="phoneNumber">
            <FormLabel>Phone Number</FormLabel>
            <Input
              variant="flushed"
              type="tel"
              placeholder="123-456-7890"
              value={newRelation.phone}
              onChange={(e) => {
                setNewRelation({...newRelation, phone: e.target.value});
              }}
            />
          </FormControl>
        </Grid>
        <Button
          isLoading={isLoading && true}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Save
        </Button>
      </VStack>
    </form>
  );
}
