import {useState, useEffect} from "react";

export function AssignedProfessional({
  assignedProfessional,
  setAssignedProfessional,
}) {
  let entries = Object.entries(assignedProfessional);

  return (
    <div className="flex flex-col items-start space-y-2 mx-4 ml-5 my-[6px] mb-2">
      <p className="text-off font-normal text-xs text-start">
        Assigned Professional
      </p>
      <div className="flex space-x-5">
        {entries.map(([key, value], i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center space-y-[2px]"
          >
            <div
              className=" h-6 w-6 rounded-full border-2 border-[#9F9DFA] shadow-md bg-white hover:cursor-pointer"
              style={{
                backgroundColor: value ? "#9F9DFA" : null,
              }}
              onClick={() => {
                setAssignedProfessional((prev) => {
                  let n = Object.entries(prev).map(([k, v]) => [k, k == key]);
                  return Object.fromEntries(n);
                });
              }}
            ></div>
            <p
              style={{color: value ? "#9F9DFA" : null}}
              className="text-slate-400 text-xs"
            >
              {key}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export function BookingMedia({setBookingMedia, bookingMedia}) {
  let entries = Object.entries(bookingMedia);

  return (
    <div className="flex flex-col items-start space-y-2 mx-4 my-7">
      <p className="text-off font-normal text-sm text-start">Booking Media</p>
      <div className="flex space-x-5">
        {entries.map(([key, value], i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center space-y-2"
          >
            <div
              className=" h-7 w-7 rounded-md shadow02 bg-white hover:cursor-pointer"
              style={{
                backgroundColor: value ? "#9F9DFA" : null,
              }}
              onClick={() => {
                setBookingMedia((prev) => {
                  let n = Object.entries(prev).map(([k, v]) => [k, k == key]);
                  return Object.fromEntries(n);
                });
              }}
            ></div>
            <p className="text-slate-400 text-sm">{key}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
