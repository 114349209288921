import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

export default function AppointmentNotes({
  transcriptModal,
  setTranscriptModal,
  transcript,
}) {
  return (
    <Modal
      isOpen={transcriptModal}
      onClose={() => setTranscriptModal(null)}
      size={"md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Appointment Transcript</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {transcript ||
            "If you are speaking and no words are appearing here, then double check and ensure your microphone is functioning properly. You must have granted this page the appropriate permissions to access your microphone. If that does not work, then your browser may not be compatible to run this application. Presently this feature is only supported on Google Chrome and Safari."}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
