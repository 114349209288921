import {useContext} from "react";
import {Box, Flex, Text, Button, Select, keyframes} from "@chakra-ui/react";
import AudioInputVisualization from "./AudioInputVisualization";
import {elapseFormatter} from "../helpers/timeFormatters";
import {useParams, useNavigate} from "react-router-dom";
import {DashStateContext, PatientContext} from "../../../pages/Patients";

export default function RecordingControls({
  summaries,
  consentConfirmed,
  setSummaryConsent,
  selectedAppointment,
  setSelectedAppointment,
  setTranscriptModal,
  elapsedTime,
  endRecording,
  progress,
  discardTimer,
  transcript,
  toast,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const navigate = useNavigate();

  const validAppointments = dashState.appointments
    .slice()
    .reverse()
    .filter(
      (appointment) =>
        appointment.pid === patient.pid &&
        !summaries?.find((s) => s.aid == appointment.aid)
    );

  return !consentConfirmed ? (
    validAppointments.length === 0 ? (
      <Button
        rounded="md"
        ml="4"
        colorScheme="blue"
        px="8"
        py="6"
        onClick={() => {
          navigate("../", {
            state: {
              ...dashState,
              firstLoading: false,
            },
          });
        }}
      >
        You must create an appointment first
      </Button>
    ) : (
      <Flex maxW="20rem" mx="auto">
        <Select
          placeholder="Choose appointment"
          variant="flushed"
          mt="1.5"
          value={selectedAppointment}
          onChange={(e) => setSelectedAppointment(e.target.value)}
        >
          {validAppointments.map((appointment, key) => (
            <option value={appointment.aid}>
              {appointment.date + " @ " + appointment.time}
            </option>
          ))}
        </Select>
        <Button
          isDisabled={!selectedAppointment}
          ml="4"
          colorScheme="blue"
          rounded="none"
          px="8"
          py="6"
          onClick={() => setSummaryConsent(true)}
        >
          Start
        </Button>
      </Flex>
    )
  ) : (
    <Flex justifyContent={"center"}>
      <Box
        w="10px"
        h="10px"
        mr="2"
        borderRadius="50%"
        bg={"blue.500"}
        animation={`${keyframes`
      0% { opacity: 0; }
      50% { opacity: 1; }
      100% { opacity: 0; }
    `} 2s infinite`}
      />
      <Text
        color="blue.500"
        textAlign="right"
        mr="4"
        fontSize="11px"
        fontWeight={"light"}
      >
        <Text
          lineHeight="1.25"
          cursor="pointer"
          onClick={() => {
            setTranscriptModal(true);
          }}
        >
          View
          <br />
          Transcript
        </Text>
        <Text fontWeight={"bold"} mt="1">
          {elapseFormatter(elapsedTime)}{" "}
        </Text>
      </Text>
      <Box bg="blue.500" px="5">
        <AudioInputVisualization />
      </Box>

      <Button
        colorScheme="blue"
        variant="outline"
        rounded="none"
        px="8"
        py="6"
        onClick={() => {
          if (transcript) {
            endRecording();
          } else {
            toast({
              title: "No speech was detected",
              description:
                "No transcript or summary can be generated because no speech was detected thus far. This is either due to your microphone not functioning properly, or this being an incompatible browser. Please try again.",
              status: "error",
              isClosable: true,
            });
          }
        }}
      >
        Finish{" "}
        {progress && Math.floor(progress) != 100 ? <>{progress} %</> : null}
      </Button>
      <Button
        colorScheme="blue"
        variant="ghost"
        rounded="none"
        px="8"
        py="6"
        onClick={discardTimer}
      >
        Discard
      </Button>
    </Flex>
  );
}
