import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import Loader from "./Loader";

export default function CodesTable({codesType, onSelect, codes, loading}) {

  return (
    <>
      <TableContainer flex={1} overflowY="auto" height={"100%"} width={"100%"}>
      {loading ? (
            <Loader />
          ) : (
        <Table width={"100%"} variant="simple">
            <Thead>
              <Tr>
                  <Th
                    key="Code"
                    textAlign="center"
                  >
                    Code
                  </Th>
                  {codesType === "service" &&
                    <Th
                    key="Amount"
                    textAlign="center"
                    >
                      Amount
                    </Th>
                  }
                  <Th
                    key="Description"
                    textAlign="center"
                  >
                    Description
                  </Th>
              </Tr>
            </Thead>
            <Tbody>
              {codes.map((c, i) => (
                <Tr
                  key={i}
                  cursor={"pointer"}
                  fontSize="14px"
                  onClick={() => onSelect(c.code, c.amount || "")}
                  _hover={{bgColor: "gray.50"}}
                >
                  <Td p={"8px"} w="70px" textAlign="center">
                    {c.code}
                  </Td>
                  {codesType === "service" && (
                    <Td p="8px" w="80px" textAlign="center">
                      ${parseFloat(c.amount).toFixed(2)}
                    </Td>
                  )}
                  <Td maxW={"200px"} p="8px" whiteSpace={"wrap"} textAlign="center">
                    {c.description}
                  </Td>
                </Tr>
              ))}
            </Tbody>
        </Table>
      )}
      </TableContainer>
    </>
  );
}
